import { Flex } from 'components/common/flex/Flex'
import BubbleFixed from 'pages/playground/playgroundDashboard/components/general/bubble/Bubble'
import { Icon } from 'pages/playground/playgroundDashboard/components/sections/sectionNewFeatures/components/Icon'
import { LineVectorSVG } from 'pages/playground/playgroundDashboard/components/sections/sectionNewFeatures/components/LineVector.svg'
import { GridSections } from 'pages/playground/playgroundDashboard/components/sections/sectionNewFeatures/GridSections'
import styles from 'pages/playground/playgroundDashboard/components/sections/sectionNewFeatures/SectionNewFeatures.module.scss'

export const SectionNewFeatures = () => {
  return (
    <div>
      <div className={styles.subHeadingContainer}>
        <h2 className={styles.subHeading}>New Features & Fun</h2>
        <Flex direction="row" className={styles.bubbleContainer}>
          <LineVectorSVG />
          <BubbleFixed direction="up" text="We can't wait either" className={styles.bubble} />
        </Flex>
      </div>
      <div className={styles.grid}>
        {GridSections.map(section => {
          return (
            <div key={section.title} className={styles.sectionContainer}>
              <Icon>
                <img className={styles.icon} src={section.icon} alt={`icon ${section.title}`} />
              </Icon>
              <div className={styles.textContainer}>
                <h3 className={styles.sectionHeading}>{section.title}</h3>
                <p className={styles.text}>{section.text}</p>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
