import { useEffect } from 'react'

import { Delay } from 'constants/delay'
import { useToast } from 'providers/toast/ToastProvider'

interface Params {
  isError: boolean
  message: string
}

export const useProviderNoncriticalError = ({ isError, message }: Params) => {
  const { enqueueToast } = useToast()

  useEffect(() => {
    if (isError) {
      enqueueToast({
        duration: Delay.ToastLong,
        message,
        type: 'error',
      })
    }
  }, [enqueueToast, isError, message])
}
