import { format } from 'date-fns'
import { useCallback } from 'react'

import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { DateFormat, DateFormatType } from 'types/common/dates'

export const useDateFormat = (formatMap: DateFormatType = DateFormat) => {
  const {
    userDetails: { dateLocale },
  } = useOtherTenantsAndUserData()

  return {
    formatDate: useCallback(
      (dateToFormat: string): string => {
        const locale = formatMap[dateLocale as keyof typeof formatMap]
        return format(new Date(dateToFormat), locale)
      },
      [dateLocale, formatMap],
    ),
  }
}
