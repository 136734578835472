import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'

import { NoCodeAppExternalLink } from 'types/apps/noCodeApps'
import { simulateLinkClick } from 'utils/links'
import { routesManager } from 'utils/routesManager'

export const OsStateExternalLink = ({ app }: { app: NoCodeAppExternalLink }) => {
  useEffect(() => {
    simulateLinkClick({ href: app.url, target: '_blank' })
  }, [app.url])

  return <Navigate replace to={routesManager.home.root.url()} />
}
