import {
  WppActionButton,
  WppButton,
  WppIconChevron,
  WppIconClock,
  WppIconEdit,
  WppIconLocation,
  WppIconVideoOn,
  WppRichtextView,
  WppTypography,
} from '@platform-ui-kit/components-library-react'
import { t } from 'i18next'
import { Link } from 'react-router-dom'

import { useCalendarWidgetEventApi } from 'api/widgets/queries/useCalendarWidgetEventApi'
import { Flex } from 'components/common/flex/Flex'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { Permission } from 'constants/permission'
import { useHasPermission } from 'hooks/useHasPermission'
import { DotIndicator } from 'pages/home/systemWidgets/calendarWidget/dotIndicator/DotIndicator'
import { showManageEventSideModal } from 'pages/home/systemWidgets/calendarWidget/manageEventSideModal/ManageEventSideModal'
import { DEFAULT_DATE_FORMAT, TIME_FORMAT, useGetFormattedTime } from 'pages/home/systemWidgets/calendarWidget/utils'
import styles from 'pages/home/systemWidgets/calendarWidget/viewEventSideModal/ViewEventSideModal.module.scss'
import { CalendarWidgetEventDTO } from 'types/widgets/widget'
import { NiceModalWrappedProps, createNiceModal } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  readOnly?: boolean
  eventId?: string
  tenantId?: string
  hub_id?: string
  event?: CalendarWidgetEventDTO
}

const ViewEventSideModal = ({
  id,
  isOpen,
  onClose,
  onCloseComplete,
  eventId,
  tenantId,
  hub_id,
  event,
  readOnly = false,
}: Props) => {
  const { hasPermission } = useHasPermission()
  const getFormattedTime = useGetFormattedTime(true, DEFAULT_DATE_FORMAT, `${DEFAULT_DATE_FORMAT}, ${TIME_FORMAT}`)

  const { data: fetchedEvent } = useCalendarWidgetEventApi({
    enabled: !!eventId,
    params: {
      id: eventId!,
      hub_id: hub_id || event?.hub_id,
      tenantId: tenantId || event?.tenantId,
    },
  })

  const eventInfo = event || fetchedEvent
  return (
    <SideModal
      data-testid={id}
      open={isOpen}
      size="m"
      onWppSideModalClose={onClose}
      onWppSideModalCloseComplete={onCloseComplete}
      disableOutsideClick
      className={styles.modal}
    >
      <Flex slot="header" align="center" justify="between">
        <Flex>
          {!readOnly && (
            <WppActionButton onClick={onClose}>
              <WppIconChevron
                slot="icon-start"
                direction="left"
                className={styles.backIcon}
                data-testid="back-button"
              />
            </WppActionButton>
          )}
          <WppTypography type="2xl-heading" data-testid="header">
            {t('os.home.calendar_widget.view_event.header')}
          </WppTypography>
        </Flex>

        {!readOnly && hasPermission(Permission.OS_ADMIN_SETTING_ACCESS) && (
          <WppActionButton
            onClick={() => {
              onClose()
              showManageEventSideModal({ ...(!!eventInfo && { event: eventInfo }), calendarId: event!.calendarId })
            }}
            data-testid="edit-button"
          >
            <WppIconEdit slot="icon-start" />
            {t('os.common.edit')}
          </WppActionButton>
        )}
      </Flex>

      <Flex slot="body" direction="column" gap={24}>
        <Flex direction="column" gap={16}>
          <Flex gap={16} align="center">
            <DotIndicator size={10} className={styles.dotIndicator} color={eventInfo?.color} />
            <WppTypography type="xl-heading" data-testid="event-title">
              {eventInfo?.title}
            </WppTypography>
          </Flex>
          <Flex gap={14} className={styles.info}>
            <WppIconClock />
            <Flex direction="column" gap={4}>
              {eventInfo?.startsAt && eventInfo?.endsAt && (
                <WppTypography data-testid="event-time">
                  {getFormattedTime(new Date(eventInfo.startsAt), new Date(eventInfo.endsAt))}
                </WppTypography>
              )}
            </Flex>
          </Flex>
          {eventInfo?.location && (
            <Flex gap={14} className={styles.info}>
              <WppIconLocation />
              <WppTypography data-testid="event-location">{eventInfo.location}</WppTypography>
            </Flex>
          )}
        </Flex>

        {eventInfo?.videoConferencingUrl && (
          <Link to={eventInfo.videoConferencingUrl} target="_blank">
            <WppButton size="s" variant="primary" data-testid="continue">
              <WppIconVideoOn slot="icon-start" />
              {t('os.home.calendar_widget.view_event.video_link_button')}
            </WppButton>
          </Link>
        )}
        {eventInfo?.description && <WppRichtextView value={eventInfo.description} data-testid="event-description" />}
      </Flex>
    </SideModal>
  )
}

export const { showModal: showViewEventSideModal, useModal: useViewEventSideModal } = createNiceModal(
  ViewEventSideModal,
  'calendar-widget-view-event-modal',
)
