import { MayBeNull } from '@wpp-open/content-builder'
import qs from 'qs'

import { facadeApi } from 'api'

interface Props {
  tenantId: string
  ids: MayBeNull<string[]>
}

export const deleteBulkFeedsApi = ({ tenantId, ids }: Props) => {
  return facadeApi.delete('/feeds/bulk', {
    params: {
      ...(!!ids && { filter: { id: ids } }),
    },
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
    headers: {
      'X-Tenant-Id': tenantId,
    },
  })
}
