import { NavigateFunction } from 'react-router-dom'

import { MicroAppAction, MicroAppActionType, ProtectedMicroAppActionType } from 'legacy/types/osWebRootApi'
import { legacyState } from 'legacy/utils/state'
import { trackAnalytics } from 'utils/analytics'
import { routesManager } from 'utils/routesManager'

interface HandleAppEventParams {
  event: MicroAppAction
  navigate: NavigateFunction
  logout: Function
}

export const handleAppEvent = ({ event, navigate, logout }: HandleAppEventParams) => {
  switch (event.type) {
    // @TODO: Uncomment this when locale service will be implemented
    // case ProtectedMicroAppActionType.CHANGE_DISPLAY_LANGUAGE: {
    //   legacyState.displaySettings$.next({
    //     ...legacyState.displaySettings$.value,
    //     displayLanguage: event.data,
    //   })
    //   break
    // }
    // case ProtectedMicroAppActionType.CHANGE_DATE_FORMAT: {
    //   legacyState.displaySettings$.next({
    //     ...legacyState.displaySettings$.value,
    //     dateLocale: event.data,
    //   })
    //   break
    // }
    // case ProtectedMicroAppActionType.CHANGE_NUMBER_FORMAT: {
    //   legacyState.displaySettings$.next({
    //     ...legacyState.displaySettings$.value,
    //     numberLocale: event.data,
    //   })
    //   break
    // }
    case ProtectedMicroAppActionType.CHANGE_WORKSPACE: {
      const workspace = event.data

      // legacyState.workspace$.next(workspace)
      console.error('Legacy CHANGE_WORKSPACE event is not available anymore. Passed data:', workspace)
      break
    }

    case ProtectedMicroAppActionType.LOGOUT: {
      logout()
      break
    }

    case MicroAppActionType.CHANGE_ROUTE: {
      const linkParams = event.data.map(data => data.route)

      if (linkParams.length !== 1 || event.extras) {
        console.error('Legacy MicroAppActionType.CHANGE_ROUTE with unsupported props')
      } else {
        navigate(
          linkParams[0].endsWith('/markets') || linkParams[0].includes('you')
            ? routesManager.home.root.url()
            : linkParams[0],
        )
      }
      break
    }

    case MicroAppActionType.USER_UNAUTHORIZED: {
      logout()
      break
    }

    case MicroAppActionType.UPDATE_BREADCRUMBS:
    case MicroAppActionType.RESET_BREADCRUMBS:
    case MicroAppActionType.SET_HAS_PRODUCT_TOURS:
    case MicroAppActionType.RUN_PRODUCT_TOURS_REMINDER:
    case MicroAppActionType.HIDE_PRODUCT_TOURS_REMINDER:
    case MicroAppActionType.TRACK_ACTION: {
      legacyState.events$.next(event)
      break
    }

    case MicroAppActionType.TRACK_ANALYTICS: {
      trackAnalytics(event.data)
      break
    }

    default:
      break
  }
}
