import qs from 'qs'

import { facadeApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'
import { CalendarWidgetEventDTO } from 'types/widgets/widget'

export type CalendarWidgetEventResponse = PaginatedResponse<CalendarWidgetEventDTO>

type Params = PaginationParams<{
  minDate: string
  maxDate?: string
  search?: string
  calendarId: string
  hub_id: string
  tenantId: string
}>

export const fetchCalendarWidgetAllEvents = ({
  hub_id,
  tenantId,
  calendarId,
  itemsPerPage,
  minDate,
  maxDate,
  page,
  search,
}: Params) =>
  facadeApi.get<CalendarWidgetEventResponse>(`/hubs/${hub_id}/events`, {
    params: {
      page,
      itemsPerPage,
      'filter[date>]': minDate || null,
      'filter[calendarId]': calendarId,
      ...(!!search && { 'filter[search]': search }),
      ...(!!maxDate && { 'filter[date<]': maxDate }),
    },
    headers: {
      'X-Tenant-Id': tenantId,
    },
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
  })
