export interface Props {
  width: number
  height: number
  className?: string
}
export const ClockBackground = (props: Props) => {
  const { width, height, className } = props
  return (
    <>
      <svg
        width={width}
        height={height}
        className={className}
        viewBox="0 0 112 113"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="-0.253906"
          y="1.04688"
          width="111.272"
          height="111.272"
          rx="55.6362"
          transform="rotate(-0.305768 -0.253906 1.04688)"
          fill="#EBFF00"
        />
      </svg>
    </>
  )
}
