import { WppTypography, WppButton } from '@platform-ui-kit/components-library-react'
import { t } from 'i18next'
import { Trans } from 'react-i18next'

import { useDeleteCalendarWidgetEventApi } from 'api/calendars/mutations/useDeleteCalendarWidgetEventApi'
import { Flex } from 'components/common/flex/Flex'
import { Modal } from 'components/surface/modal/Modal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import styles from 'pages/home/systemWidgets/calendarWidget/deleteCalendarEventModal/DeleteCalendarEventModal.module.scss'
import { useHubId } from 'pages/hubs/utils'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { queryClient } from 'providers/osQueryClient/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { CalendarWidgetEventDTO } from 'types/widgets/widget'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  event: CalendarWidgetEventDTO
}

const DeleteCalendarEventModal = ({ isOpen, onClose, id, onCloseComplete, event }: Props) => {
  const { enqueueToast } = useToast()

  const hubId = useHubId()
  const { currentTenant } = useCurrentTenantData()

  const { mutateAsync: handleRemoveCalendarWidgetEvent, isLoading } = useDeleteCalendarWidgetEventApi()

  const onDelete = async () => {
    try {
      await handleRemoveCalendarWidgetEvent({ id: event.id!, tenantId: currentTenant.id, hub_id: hubId })

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.CALENDAR_WIDGET_EVENTS] }),
        queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.CALENDAR_WIDGET_ALL_EVENTS] }),
      ])

      enqueueToast({
        message: t('os.home.calendar_widget.manage_event.toasts.remove_success'),
        type: 'success',
      })
    } catch {
      enqueueToast({
        message: t('os.common.errors.general'),
        type: 'error',
      })
    } finally {
      onClose()
    }
  }

  return (
    <Modal
      data-testid={id}
      open={isOpen}
      onWppModalClose={onClose}
      onWppModalCloseComplete={onCloseComplete}
      disableOutsideClick={isLoading}
    >
      <Flex slot="header" align="center" gap={9}>
        <WppTypography type="xl-heading">{t('os.home.calendar_widget.delete_modal.title')}</WppTypography>
      </Flex>

      <WppTypography slot="body" type="s-body">
        <Trans
          i18nKey="os.home.calendar_widget.delete_modal.description"
          values={{
            eventName: event.title,
          }}
          components={[
            <b key="0" className={styles.bold}>
              eventName
            </b>,
          ]}
        />
      </WppTypography>
      <WppTypography slot="body" type="s-body">
        {t('os.home.calendar_widget.delete_modal.message')}
      </WppTypography>

      <Flex slot="actions" justify="end" gap={12}>
        <WppButton variant="secondary" size="s" onClick={onClose} data-testid="cancel">
          {t('os.common.cancel')}
        </WppButton>
        <WppButton size="s" variant="primary" loading={isLoading} onClick={onDelete} data-testid="apply">
          {t('os.common.delete')}
        </WppButton>
      </Flex>
    </Modal>
  )
}

export const { showModal: showDeleteCalendarEventModal } = createNiceModal(
  DeleteCalendarEventModal,
  'delete-calendar-event-modal',
)
