import { matchAndThriveApi } from 'api'

export enum FeedbackHelpfull {
  NO = 0,
  YES = 1,
  NO_ANSWER = 2,
}

export const publishFeedback = ({
  feedbackId,
  helpfull,
  feedback,
}: {
  feedbackId: string
  helpfull: FeedbackHelpfull
  feedback: string
}) =>
  matchAndThriveApi.patch<{ data: any }>(`/nudges/feedback/${feedbackId}`, {
    helpfull: helpfull,
    feedback: feedback,
  })
