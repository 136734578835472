import clsx from 'clsx'

import styles from 'pages/home/systemWidgets/calendarWidget/dotIndicator/DotIndicator.module.scss'
import { CalendarWidgetEventDTO } from 'types/widgets/widget'

export const DotIndicator = ({
  color,
  size,
  className,
}: Partial<CalendarWidgetEventDTO> & { size: number; className?: string }) => {
  return (
    <div
      className={clsx(styles.dotIndicator, className)}
      style={{
        background:
          color || getComputedStyle(document.documentElement).getPropertyValue('--wpp-dataviz-color-cat-neutral-1'),
        width: `${size}px`,
        height: `${size}px`,
      }}
      data-testid="dot-indicator"
    />
  )
}
