import {
  BorderRadius,
  BoxShadow,
  Color,
  Colors,
  FontSize,
  FontWeight,
  StandardColors,
  LegacyTheme,
} from 'legacy/styles/osTheming/models'

export const kebabize = (str: string) =>
  str
    .split('')
    .map((letter, idx) =>
      !/\d/.test(letter) && letter.toUpperCase() === letter ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}` : letter,
    )
    .join('')

export const getThemeColors = ({ colors }: LegacyTheme) => {
  const result: Record<string, string> = {}

  Object.entries({ ...colors.brand, ...colors.system }).forEach(([contextKey, context]: [string, StandardColors]) => {
    Object.entries(context).forEach(([key, color]: [string, Color]) => {
      const property = `--os-color-${contextKey}-${key}`.replace('-normal', '')
      result[property] = color
    })
  })

  const greyScaleWithoutPrefix = ['white', 'black', 'white-rgb', 'black-rgb']
  Object.entries(colors.grey).forEach(([name, color]: [string | number, Color]) => {
    if (greyScaleWithoutPrefix.includes(name as string)) {
      result[`--os-color-${name}`] = color
    } else {
      result[`--os-color-grey-${name}`] = color
    }
  })

  Object.entries({ ...colors.special, ...colors.text }).forEach(([name, color]: [string | number, Color]) => {
    result[`--os-color-${kebabize(name.toString())}`] = color
  })

  const seqColors = Object.entries(colors.dataVisualisation.sequential).reduce((current, [key, colorsSet]) => {
    const mappedColors = Object.entries(colorsSet).reduce(
      (set, [subKey, color]) => ({
        ...set,
        [`${key}-${subKey}`]: color,
      }),
      {} as Colors,
    )

    return {
      ...current,
      ...mappedColors,
    }
  }, {} as Colors)

  const dvColors = { ...colors.dataVisualisation.diverging, ...colors.dataVisualisation.comparative, ...seqColors }

  Object.entries(dvColors).forEach(([name, color]: [string | number, Color]) => {
    result[`--os-color-dv-${name.toString().toLowerCase()}`] = color
  })

  return result
}

const getThemeTypography = ({ typography }: LegacyTheme) => {
  const result: Record<string, string> = {}

  result['--os-font-family'] = `${typography.font}, sans-serif`

  Object.entries(typography.sizes).forEach(([key, { size, height }]: [string, FontSize]) => {
    result[`--os-font-${key}-size`] = size
    result[`--os-font-${key}-line-height`] = height
  })

  Object.entries(typography.weights).forEach(([key, weight]: [string, FontWeight]) => {
    result[`--os-font-weight-${key}`] = String(weight)
  })

  Object.entries(typography.boxShadows).forEach(([key, shadow]: [string, BoxShadow]) => {
    result[`--os-box-shadow-${key}`] = shadow
  })

  Object.entries(typography.borderRadius).forEach(([key, radius]: [string, BorderRadius]) => {
    result[`--os-border-radius-${key}`] = radius
  })

  return result
}

export const getThemeStyles = (theme: LegacyTheme) => {
  const themeColors = getThemeColors(theme)
  const themeTypography = getThemeTypography(theme)

  return {
    ...themeColors,
    ...themeTypography,
  }
}
