import clsx from 'clsx'
import { useState } from 'react'

import styles from 'pages/login/customLogin/bubble/Bubble.module.scss'

interface Props {
  icon?: string
  direction?: 'up' | 'down'
  className?: string
}

const Bubble = ({ icon, className, direction }: Props) => {
  const [text, setText] = useState('The other (way better) F word ')

  const [expanded, setExpanded] = useState(true)

  const [animation, setAnimation] = useState(false)

  const color = '#EBFF00'

  const handleMouseEnter = () => {
    if (animation) return
    setText('Add a k to fun: upgrade!')
    setExpanded(false)
    setAnimation(true)
    setTimeout(() => {
      setAnimation(false)
    }, 250)
  }

  const handleMouseLeave = () => {
    setExpanded(true)
    setAnimation(true)
    setTimeout(() => {
      setAnimation(false)
      setText('The other (way better) F word ')
    }, 250)
  }

  function bubbleBorder() {
    if (direction === 'up') {
      return '0 26px 26px 26px'
    } else {
      return '26px 26px 26px 0'
    }
  }

  function bubblePadding() {
    if (icon) {
      return '11px 19px'
    } else {
      return '12px 18px'
    }
  }

  return (
    <div
      className={clsx(styles.container, className, !expanded && styles.small)}
      style={{ backgroundColor: color, borderRadius: bubbleBorder(), padding: bubblePadding() }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {icon && (
        <div className={styles.iconWrapper}>
          <img src={icon} alt="" />
        </div>
      )}
      <div className={styles.textWrapper}>
        <p>{text}</p>
      </div>
    </div>
  )
}

export default Bubble
