import { AppInstanceAssignmentType, AppInstanceFull, MayBeNull, NoCodeAppType } from '@wpp-open/core'

import { OsStateAppInstanceFixRoute } from 'providers/osState/sideEffects/OsStateAppInstanceFixRoute'
import { OsStateExternalLink } from 'providers/osState/sideEffects/OsStateExternalLink'
import { ConditionalValue, isValueSet, UnsetValue } from 'providers/osState/utils/conditionalData'
import { useProjectAppInstance } from 'providers/osState/utils/resolvers/appInstance/useProjectAppInstance'
import { useWorkspaceAppInstance } from 'providers/osState/utils/resolvers/appInstance/useWorkspaceAppInstance'
import { getInvalidResolvedAppData, getLoadingResolvedAppData } from 'providers/osState/utils/resolvers/common'
import { ResolvedAppData } from 'providers/osState/utils/useResolveAppData'
import { AppInstanceDataFromUrl } from 'types/osState/appDataFromUrl'
import { AppInstanceApp, RoutedAppInstanceApp } from 'utils/appInstances'
import { getAppInstanceUrl } from 'utils/navigation'

interface Params {
  appInstance: AppInstanceFull
  app: AppInstanceApp
  appDataShort: AppInstanceDataFromUrl
}

export const useAppInstanceLoaded = (params: MayBeNull<Params>): MayBeNull<ResolvedAppData> => {
  const isEnabled = !!params
  let result: MayBeNull<ResolvedAppData> = null
  let routedApp: ConditionalValue<RoutedAppInstanceApp> = UnsetValue
  let currentBaseUrl: ConditionalValue<string> = UnsetValue

  if (isEnabled) {
    const { appInstance, app, appDataShort } = params

    // External link will open in a separate tab and user will be redirected to a home page
    if (app.type === NoCodeAppType.ExternalLink) {
      result = getLoadingResolvedAppData({
        sideEffectNode: <OsStateExternalLink app={app} />,
      })
    }
    // Check if the human-readable part of the url is correct and is not misleading.
    else {
      const { appRoute } = appDataShort

      const isExactMatch = appRoute.path === app.osRoute
      const isStartsWithMatch = appRoute.path.startsWith(`${app.osRoute}/`)
      const isRouteMismatched = !isExactMatch && !isStartsWithMatch
      const isAppRouteEmpty = !appRoute.path.length
      const appInstanceUrl = getAppInstanceUrl({ id: appInstance.id, osRoute: app.osRoute })

      routedApp = app
      currentBaseUrl = appInstanceUrl.slice(1)

      if (isRouteMismatched) {
        result = getLoadingResolvedAppData({
          sideEffectNode: (
            <OsStateAppInstanceFixRoute
              isAppRouteEmpty={isAppRouteEmpty}
              to={{
                pathname: appInstanceUrl,
                search: appRoute.search,
                hash: appRoute.hash,
              }}
            />
          ),
        })
      }
    }
  }

  // Url state is correct and the app is renderable at this point
  const isAppInstanceDataReady = isEnabled && !result
  const isProjectAppInstance =
    isAppInstanceDataReady && params.appInstance.assignmentType === AppInstanceAssignmentType.Project
  const isWorkspaceAppInstance =
    isAppInstanceDataReady &&
    (params.appInstance.assignmentType === AppInstanceAssignmentType.Workspace ||
      params.appInstance.assignmentType === AppInstanceAssignmentType.Custom)

  // Process Project app instances
  const projectAppInstanceResult = useProjectAppInstance(
    isProjectAppInstance && isValueSet(routedApp) && isValueSet(currentBaseUrl)
      ? {
          appInstance: params.appInstance,
          app: routedApp,
          currentBaseUrl,
        }
      : null,
  )

  // Process Workspace app instances
  const workspaceAppInstanceResult = useWorkspaceAppInstance(
    isWorkspaceAppInstance && isValueSet(routedApp) && isValueSet(currentBaseUrl)
      ? {
          appInstance: params.appInstance,
          app: routedApp,
          currentBaseUrl,
        }
      : null,
  )

  if (projectAppInstanceResult) {
    result = projectAppInstanceResult
  } else if (workspaceAppInstanceResult) {
    result = workspaceAppInstanceResult
  }

  return isEnabled ? result || getInvalidResolvedAppData() : null
}
