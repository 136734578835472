import { workfrontApi } from 'api'

export interface WorkfrontUserResponse {
  role: {
    ID: string
    name: string
  }
  koid: string
  connectedToWorkfront: boolean
  userExistsInWorkfront: boolean
  companyName: string
  workfrontUser?: {
    id: string
    country: string | null
    emailAddr: string | null
    homeTeam: string | null
    name: string | null
    role: string | null
  }
}

export const fetchWorkfrontUser = () => workfrontApi.get<WorkfrontUserResponse>('/me', {})
