import { SupportService } from '@wpp-open/core'
import { createContext, useContext } from 'react'

export interface SupportServiceState {
  enabled: boolean
  active: boolean
  onActiveStateChange: (isActive: boolean) => void
}

export interface SupportServiceContextValue {
  zendesk: SupportServiceState
  intercom: SupportServiceState
}

export const OsSupportServiceContext = createContext<SupportServiceContextValue>(null!)

export const useOsSupportService = <
  T extends SupportService | undefined = undefined,
  R = T extends undefined ? SupportServiceContextValue : SupportServiceState,
>(
  supportService?: T,
): R => {
  const ctx = useContext(OsSupportServiceContext)

  if (supportService) {
    return ctx[supportService] as R
  }

  return ctx as R
}
