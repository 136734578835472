import styles from 'components/svg/common.module.scss'

export const SvgNoResultsCalendarEvents = () => (
  <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7454_12769)">
      <circle cx="60" cy="60" r="60" className={styles.fillPrimary100} />
      <path
        d="M106.985 44.7717C107.317 43.5377 109.144 43.5377 109.477 44.7717L109.651 45.4184C109.772 45.8663 110.143 46.2119 110.614 46.3136L110.977 46.392C112.315 46.6815 112.315 48.5109 110.977 48.8004L110.614 48.8788C110.143 48.9805 109.772 49.3261 109.651 49.774L109.477 50.4207C109.144 51.6547 107.317 51.6547 106.985 50.4207L106.81 49.774C106.69 49.3261 106.318 48.9805 105.847 48.8788L105.485 48.8004C104.146 48.5109 104.146 46.6815 105.485 46.392L105.847 46.3136C106.318 46.2119 106.69 45.8663 106.81 45.4184L106.985 44.7717Z"
        className={styles.fillPrimary400}
      />
      <ellipse cx="16.8456" cy="68.25" rx="2.99985" ry="3" className={styles.fillPrimary200} />
      <ellipse cx="13.0956" cy="59.25" rx="1.49993" ry="1.5" className={styles.fillPrimary200} />
      <ellipse cx="59.9999" cy="110.25" rx="2.24989" ry="2.25" className={styles.fillPrimary200} />
      <path
        d="M64.2459 8.36785C63.9134 7.13389 62.0864 7.1339 61.7538 8.36785L61.5795 9.01454C61.4588 9.46247 61.0871 9.80805 60.6165 9.90979L60.2537 9.98822C58.9151 10.2777 58.9151 12.1071 60.2537 12.3965L60.6165 12.475C61.0871 12.5767 61.4588 12.9223 61.5795 13.3702L61.7538 14.0169C62.0864 15.2509 63.9134 15.2509 64.2459 14.0169L64.4202 13.3702C64.5409 12.9223 64.9127 12.5767 65.3833 12.475L65.746 12.3965C67.0847 12.1071 67.0847 10.2777 65.746 9.98822L65.3833 9.90979C64.9127 9.80805 64.5409 9.46247 64.4202 9.01454L64.2459 8.36785Z"
        className={styles.fillPrimary300}
      />
      <circle cx="1.5" cy="1.5" r="1.5" transform="matrix(-1 0 0 1 56.25 15.2305)" className={styles.fillPrimary400} />
      <rect x="27.75" y="40.5" width="51" height="60" rx="6" className={styles.fillPrimary300} />
      <g filter="url(#filter0_d_7454_12769)">
        <path
          d="M49.5 85.5H88.5C91.8137 85.5 94.5 82.8137 94.5 79.5V37.5L82.5 25.5H49.5C46.1863 25.5 43.5 28.1863 43.5 31.5V79.5C43.5 82.8137 46.1863 85.5 49.5 85.5Z"
          className={styles.fillWhite}
        />
      </g>
      <path d="M82.5 37.5H94.5L82.5 25.5V37.5Z" className={styles.fillPrimary300} />
      <g filter="url(#filter1_b_7454_12769)">
        <path
          d="M52.2874 77.5748C67.0817 77.5748 79.0748 65.5817 79.0748 50.7874C79.0748 35.9931 67.0817 24 52.2874 24C37.4931 24 25.5 35.9931 25.5 50.7874C25.5 65.5817 37.4931 77.5748 52.2874 77.5748Z"
          className={styles.fillWhite}
          fillOpacity="0.4"
        />
      </g>
      <path
        d="M62.57 43.752L59.3228 40.5048L52.2874 47.5403L45.252 40.5048L42.0048 43.752L49.0403 50.7874L42.0048 57.8228L45.252 61.07L52.2874 54.0345L59.3228 61.07L62.57 57.8228L55.5345 50.7874L62.57 43.752Z"
        className={styles.fillPrimary500}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.3312 72.2431C57.8389 80.1094 42.8883 79.2714 33.3458 69.729C22.8847 59.2678 22.8847 42.307 33.3458 31.8458C43.807 21.3847 60.7678 21.3847 71.229 31.8458C80.7714 41.3883 81.6094 56.3389 73.7431 66.8312L94.5 87.5881L89.0881 93L68.3312 72.2431ZM67.9818 66.4818C59.314 75.1496 45.2608 75.1496 36.593 66.4818C27.9252 57.814 27.9252 43.7608 36.593 35.093C45.2608 26.4252 59.314 26.4252 67.9818 35.093C76.6496 43.7608 76.6496 57.814 67.9818 66.4818Z"
        className={styles.fillPrimary500}
      />
    </g>
    <defs>
      <filter
        id="filter0_d_7454_12769"
        x="31.5"
        y="17.5"
        width="75"
        height="84"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="6" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.368627 0 0 0 0 0 0 0 0 0 0.709804 0 0 0 0.302 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7454_12769" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_7454_12769" result="shape" />
      </filter>
      <filter
        id="filter1_b_7454_12769"
        x="10.1667"
        y="8.66667"
        width="84.2414"
        height="84.2414"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.66667" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_7454_12769" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_7454_12769" result="shape" />
      </filter>
      <clipPath id="clip0_7454_12769">
        <rect width="120" height="120" rx="60" className={styles.fillWhite} />
      </clipPath>
    </defs>
  </svg>
)
