import { WppIconClose } from '@platform-ui-kit/components-library-react'
import { animated, useSpring, config } from '@react-spring/web'
import { useEffect, useState, useRef } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/assignmentCard/feedbackBanner/FeedbackBanner.module.scss'
import stylesMain from 'pages/playground/playgroundDashboard/PlaygroundDashboard.module.scss'

export interface Props {
  onClose?: () => void
}

const FeedbackRecievedBanner = ({ onClose }: Props) => {
  const [visible, setVisible] = useState(false)
  const [closing, setClosing] = useState(false) // New state to handle closing animation
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    const showTimeout = setTimeout(() => {
      setVisible(true)
    }, 500)

    return () => {
      clearTimeout(showTimeout)
      clearTimeout(timeoutRef.current as NodeJS.Timeout)
    }
  }, [])

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      // Start the closing animation
      setClosing(true)
      // Delay onClose call to let animation finish
      setTimeout(() => {
        setVisible(false)
        onClose && onClose()
      }, 600) // Delay matches the animation duration
    }, 5000)

    return () => {
      clearTimeout(timeoutRef.current as NodeJS.Timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCloseClick = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    // Trigger closing animation and then hide the banner
    setClosing(true)
    setTimeout(() => {
      setVisible(false)
      onClose && onClose()
    }, 600) // Delay matches the animation duration
  }

  // Update the spring to include the closing state
  const fadeCookie = useSpring({
    opacity: visible && !closing ? 1 : 0, // Reduce opacity during closing
    bottom: visible && !closing ? '30px' : '-100px', // Move it down during closing
    config: config.slow,
  })

  return (
    <animated.div className={styles.banner} style={fadeCookie}>
      <Flex align="center" className={styles.bannerInnerRecieved}>
        <Flex justify="center" align="center" className={styles.BannerLeftContainer}>
          <svg
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 1,
            }}
            width="171"
            height="104"
            viewBox="0 0 171 104"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M132.901 154C156.353 126.365 170.5 90.5845 170.5 51.5C170.5 12.8853 156.691 -22.5043 133.743 -50H-20.5C-24.9183 -50 -28.5 -46.4183 -28.5 -42V146C-28.5 150.418 -24.9183 154 -20.5 154H132.901Z"
              fill="#EBFF00"
            />
          </svg>

          <img
            src={require('pages/playground/playgroundDashboard/components/sections/sectionAssignments/assignmentCard/assets/thumbs_up.gif')}
            width="102"
            height="102"
            style={{
              position: 'relative',
              zIndex: 2,
            }}
          />
        </Flex>
        <Flex justify="between" style={{ width: '100%' }}>
          <div className={stylesMain.whiteAction}>Thank you for your feedback!</div>
          <WppIconClose onClick={handleCloseClick} className={styles.closeIcon} />
        </Flex>
      </Flex>
    </animated.div>
  )
}

export default FeedbackRecievedBanner
