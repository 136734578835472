import { WppTypography } from '@platform-ui-kit/components-library-react'
import { t } from 'i18next'

import { Flex } from 'components/common/flex/Flex'
import styles from 'components/form/formTree/noFoundSearchResult/NoFoundSearchResult.module.scss'
import { SvgNoRecords } from 'components/svg/network/SvgNoRecords'

export const NoFoundSearchResult = ({ search }: { search: string }) => {
  return (
    <Flex data-testid="no-hierarchy-found" direction="column" align="center" gap={12} className={styles.container}>
      <SvgNoRecords data-testid="no-records-svg" />

      <WppTypography type="l-strong" data-testid="no-hierarchy-found-text">
        {t('os.admin.common.hierarchy_tree.no_search_result', { search })}
      </WppTypography>

      <WppTypography type="s-body" data-testid="no-hierarchy-found-subtitle">
        {t('os.admin.common.hierarchy_tree.no_search_result_subtitle')}
      </WppTypography>
    </Flex>
  )
}
