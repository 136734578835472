import {
  start,
  getAppNames,
  getAppStatus,
  registerApplication,
  unregisterApplication,
  AppError,
  LifeCycles,
  LOAD_ERROR,
  SKIP_BECAUSE_BROKEN,
  ParcelConfigObject,
  Application,
} from 'single-spa'

import { MicroAppLibraryType } from 'types/apps/microApps'
import { MicroAppConfig, MicroAppBundleConfig } from 'types/common/singleSpa'

const WAIT_FOR_DOM_NODE_INTERVAL = 250 // In ms
const WAIT_FOR_DOM_NODE_COUNT = 20 // 20 * 250ms = 5s

const isRegistered = (code: string) => getAppNames().includes(code)

const loadPageScript = (url: string): Promise<void> =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = url
    script.onload = () => resolve()

    script.onerror = () => {
      script.remove()
      reject()
    }

    document.head.appendChild(script)
  })

const loadAppBundle = <ExtraProps>({
  libraryType,
  windowLibraryName,
  bundleUrl,
}: MicroAppBundleConfig): Promise<LifeCycles<ExtraProps>> => {
  switch (libraryType) {
    case MicroAppLibraryType.SystemJS: {
      return System.import(bundleUrl)
    }

    case MicroAppLibraryType.Window: {
      const libraryStorage = window as unknown as Record<string, LifeCycles<ExtraProps>>

      if (libraryStorage[windowLibraryName!]) {
        return Promise.resolve(libraryStorage[windowLibraryName!])
      }

      return loadPageScript(bundleUrl).then(() => libraryStorage[windowLibraryName!])
    }

    case MicroAppLibraryType.ESM: {
      return import(/* webpackIgnore: true */ bundleUrl)
    }

    default:
      throw new Error('Unsupported libraryType')
  }
}

const getWrappedAppLoader =
  <ExtraProps>(appConfig: MicroAppConfig): Application<ExtraProps> =>
  async () => {
    const appBundle = await loadAppBundle(appConfig)

    const wrappedMount: LifeCycles<ExtraProps>['mount'] = async config => {
      const { domElementId, stableId } = appConfig

      // Ensure DOM element is already rendered
      await new Promise<void>((resolve, reject) => {
        // Avoiding annoying eslint error https://github.com/eslint/eslint/issues/15022
        let checkInterval: ReturnType<typeof setInterval> | undefined = undefined
        let executionCount = 0

        const checkDomNode = () => {
          executionCount++

          if (document.getElementById(domElementId)) {
            clearInterval(checkInterval)
            resolve()
          }

          if (executionCount > WAIT_FOR_DOM_NODE_COUNT) {
            clearInterval(checkInterval)
            reject(new Error(`Could not find container node with id '${domElementId}' for app '${stableId}'`))
          }
        }

        checkInterval = setInterval(checkDomNode, WAIT_FOR_DOM_NODE_INTERVAL)
        checkDomNode()
      })

      return Array.isArray(appBundle.mount)
        ? Promise.all(appBundle.mount.map(fn => fn(config)))
        : appBundle.mount(config)
    }

    return {
      ...appBundle,
      mount: wrappedMount,
    }
  }

export const loadParcelConfig = async (microAppBundleConfig: MicroAppBundleConfig): Promise<ParcelConfigObject> => {
  const parcel = await loadAppBundle(microAppBundleConfig)

  return {
    name: microAppBundleConfig.stableId,
    ...parcel,
  }
}

export const registerMicroApp = (microAppConfig: MicroAppConfig) => {
  const { stableId, activeWhen, customProps } = microAppConfig

  if (!isRegistered(stableId)) {
    registerApplication({
      name: stableId,
      app: getWrappedAppLoader<MicroAppConfig['customProps']>(microAppConfig),
      activeWhen,
      customProps,
    })
  }
}

export const unregisterMicroApp = async ({ stableId }: Pick<MicroAppConfig, 'stableId'>) => {
  try {
    if (isRegistered(stableId)) {
      await unregisterApplication(stableId)
      console.warn(`App '${stableId}' was unregistered`)
    }
  } catch (error) {
    console.error(`Failed to unregister '${stableId}' app`, error)
  }
}

export const unregisterAllMicroApps = async () => {
  try {
    await Promise.all(getAppNames().map(app => unregisterApplication(app)))
  } catch (error) {
    console.error('Failed to unregister all apps', error)
  }
}

export const isCriticalMicroAppError = (error: AppError) => {
  const { appOrParcelName } = error
  const appStatus = getAppStatus(appOrParcelName)

  return [SKIP_BECAUSE_BROKEN, LOAD_ERROR].includes(appStatus!)
}

export const runSingleSpa = () => {
  start({
    // true by default for single-spa v6+
    urlRerouteOnly: true,
  })
}
