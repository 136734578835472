import { MayBeNull } from '@wpp-open/content-builder'

import { facadeApi } from 'api'

interface Props {
  tenantId: string
  ids: MayBeNull<string[]>
}

export const updateMarkAsReadFeedsApi = ({ tenantId, ids }: Props) => {
  return facadeApi.patch(
    '/feeds/mark-as-read/bulk',
    { ids },
    {
      headers: {
        'X-Tenant-Id': tenantId,
      },
    },
  )
}
