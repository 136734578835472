export interface Country {
  currency: string
  region: number
  dateLocale?: string
  numberLocale?: string
  name: string
  isWithoutLivePanel?: boolean
  isCaasAvailabe?: boolean
}

export const countries: Record<string, Country> = {
  FR: {
    currency: '€',
    region: 1,
    dateLocale: 'fr',
    numberLocale: 'fr',
    name: 'France',
    isCaasAvailabe: true,
  },
  GB: {
    currency: '£',
    region: 1,
    dateLocale: 'en-gb',
    numberLocale: 'en-gb',
    name: 'United Kingdom',
    isCaasAvailabe: true,
  },
  DE: {
    currency: '€',
    region: 1,
    dateLocale: 'de',
    numberLocale: 'de',
    name: 'Germany',
    isCaasAvailabe: true,
  },
  NL: {
    currency: '€',
    region: 1,
    dateLocale: 'nl',
    numberLocale: 'nds-nl',
    name: 'Netherlands',
    isCaasAvailabe: true,
  },
  IT: {
    currency: '€',
    region: 1,
    dateLocale: 'it',
    numberLocale: 'it',
    name: 'Italy',
    isCaasAvailabe: true,
  },
  ES: {
    currency: '€',
    region: 1,
    dateLocale: 'es-es',
    numberLocale: 'es-es',
    name: 'Spain',
    isCaasAvailabe: true,
  },
  PL: {
    currency: 'zł',
    region: 1,
    dateLocale: 'pl',
    numberLocale: 'pl',
    name: 'Poland',
    isCaasAvailabe: true,
  },
  AT: {
    currency: '€',
    region: 1,
    name: 'Austria',
    isCaasAvailabe: true,
  },
  BE: {
    currency: '€',
    region: 1,
    name: 'Belgium',
    isCaasAvailabe: true,
  },
  CH: {
    currency: 'CHF',
    region: 1,
    name: 'Switzerland',
    isCaasAvailabe: true,
  },
  CZ: {
    currency: 'Kč',
    region: 1,
    name: 'Czechia',
    isCaasAvailabe: true,
  },
  DK: {
    currency: 'kr.',
    region: 1,
    name: 'Denmark',
    isCaasAvailabe: true,
  },
  FI: {
    currency: '€',
    region: 1,
    name: 'Finland',
    isCaasAvailabe: true,
  },
  GR: {
    currency: '€',
    region: 1,
    name: 'Greece',
    isCaasAvailabe: true,
  },
  LT: {
    currency: '€',
    region: 1,
    name: 'Lithuania',
  },
  NO: {
    currency: 'kr',
    region: 1,
    name: 'Norway',
    isCaasAvailabe: true,
  },
  PT: {
    currency: '€',
    region: 1,
    name: 'Portugal',
    isCaasAvailabe: true,
  },
  RO: {
    currency: 'L',
    region: 1,
    name: 'Romania',
    isCaasAvailabe: true,
  },
  RS: {
    currency: '€',
    region: 1,
    name: 'Serbia',
    isCaasAvailabe: true,
  },
  SE: {
    currency: 'kr',
    region: 1,
    name: 'Sweden',
    isCaasAvailabe: true,
  },
  SK: {
    currency: '€',
    region: 1,
    name: 'Slovakia',
    isCaasAvailabe: true,
  },
  TR: {
    currency: '₺',
    region: 1,
    name: 'Turkey',
    isCaasAvailabe: true,
  },
  UA: {
    currency: '₴',
    region: 1,
    name: 'Ukraine',
  },
  ZA: {
    currency: '£',
    region: 1,
    name: 'South Africa',
    isCaasAvailabe: true,
  },
  AE: {
    currency: 'د.إ',
    region: 1,
    name: 'United Arab Emirates',
  },
  EE: {
    currency: '€',
    region: 1,
    name: 'Estonia',
  },
  HU: {
    currency: 'Ft',
    region: 1,
    name: 'Hungary',
    isCaasAvailabe: true,
  },
  IE: {
    currency: '€',
    region: 1,
    name: 'Ireland',
    isCaasAvailabe: true,
  },
  LV: {
    currency: '€',
    region: 1,
    name: 'Latvia',
  },
  SA: {
    currency: 'ر.س',
    region: 1,
    name: 'Saudi Arabia',
  },
  RU: {
    currency: '₽',
    region: 1,
    name: 'Russian Federation',
  },
  SI: {
    currency: '€',
    region: 1,
    name: 'Slovenia',
  },
  EG: {
    currency: 'E£',
    region: 1,
    name: 'Egypt',
  },
  KW: {
    currency: 'د.ك',
    region: 1,
    name: 'Kuwait',
  },
  QA: {
    currency: 'ر.ق',
    region: 1,
    name: 'Qatar',
  },
  MA: {
    currency: 'DH',
    region: 1,
    name: 'Morocco',
  },
  LB: {
    currency: 'ل.ل.',
    region: 1,
    name: 'Lebanon',
  },
  IQ: {
    currency: 'د.ع',
    region: 1,
    name: 'Iraq',
  },
  TN: {
    currency: 'د.ت',
    region: 1,
    name: 'Tunisia',
  },
  AU: {
    currency: '$',
    region: 2,
    dateLocale: 'en-au',
    numberLocale: 'en-au',
    name: 'Australia',
    isCaasAvailabe: true,
  },
  IN: {
    currency: '₹',
    region: 2,
    dateLocale: 'hi',
    numberLocale: 'hi',
    name: 'India',
  },
  CN: {
    currency: '¥',
    region: 2,
    dateLocale: 'zh',
    numberLocale: 'zh',
    name: 'China',
  },
  HK: {
    currency: '$',
    region: 2,
    name: 'Hong Kong',
    isCaasAvailabe: true,
  },
  ID: {
    currency: 'Rp',
    region: 2,
    name: 'Indonesia',
  },
  JP: {
    currency: '¥',
    region: 2,
    name: 'Japan',
    isCaasAvailabe: true,
  },
  KR: {
    currency: '₩',
    region: 2,
    name: 'South Korea',
    isCaasAvailabe: true,
  },
  MY: {
    currency: 'RM',
    region: 2,
    name: 'Malaysia',
    isCaasAvailabe: true,
  },
  NZ: {
    currency: '$',
    region: 2,
    name: 'New Zealand',
    isCaasAvailabe: true,
  },
  PH: {
    currency: '₱',
    region: 2,
    name: 'Philippines',
    isCaasAvailabe: true,
  },
  SG: {
    currency: '$',
    region: 2,
    name: 'Singapore',
    isCaasAvailabe: true,
  },
  TH: {
    currency: '฿',
    region: 2,
    name: 'Thailand',
    isCaasAvailabe: true,
  },
  TW: {
    currency: 'NT$',
    region: 2,
    name: 'Taiwan, Province of China',
    isCaasAvailabe: true,
  },
  VN: {
    currency: '₫',
    region: 2,
    name: 'Viet Nam',
  },
  PK: {
    currency: 'Rs',
    region: 2,
    name: 'Pakistan',
  },
  LK: {
    currency: 'රු',
    region: 2,
    name: 'Sri Lanka',
  },
  AR: {
    currency: '$',
    region: 3,
    name: 'Argentina',
  },
  BR: {
    currency: 'R$',
    region: 3,
    name: 'Brazil',
  },
  CL: {
    currency: '$',
    region: 3,
    name: 'Chile',
  },
  CO: {
    currency: '$',
    region: 3,
    name: 'Colombia',
  },
  DO: {
    currency: '$',
    region: 3,
    name: 'Dominican Republic',
  },
  EC: {
    currency: 'S/.',
    region: 3,
    name: 'Ecuador',
  },
  PA: {
    currency: 'B/.',
    region: 3,
    name: 'Panama',
  },
  PE: {
    currency: 'S/',
    region: 3,
    name: 'Peru',
  },
  UY: {
    currency: '$',
    region: 3,
    name: 'Uruguay',
  },
  MX: {
    currency: '$',
    region: 3,
    name: 'Mexico',
  },
  PR: {
    currency: 'ps',
    region: 3,
    name: 'Puerto Rico',
  },
  CR: {
    currency: '₡',
    region: 3,
    name: 'Costa Rica',
  },
  PY: {
    currency: '₲',
    region: 3,
    name: 'Paraguay',
  },
  CA: {
    currency: ' ',
    region: 4,
    dateLocale: 'en-ca',
    numberLocale: 'en-ca',
    name: 'Canada',
    isCaasAvailabe: true,
  },
  US: {
    currency: '$',
    region: 4,
    dateLocale: 'en-us',
    numberLocale: 'en-us',
    name: 'United States of America',
    isCaasAvailabe: true,
  },
}
