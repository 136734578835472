import clsx from 'clsx'
import { useRef } from 'react'

import { usePostConversationIdByMessage } from 'api/playground/mutations/usePostConversationIdByMessage'
import { Flex } from 'components/common/flex/Flex'
import styles from 'components/renderError/errorState/notFound/NotFoundPlaygroundState.module.scss'
import ArrowsSvg from 'pages/playground/playgroundDashboard/assets/icons/arrows.svg'
import IntercomSvg from 'pages/playground/playgroundDashboard/assets/icons/intercom.svg'
import BubbleFixed from 'pages/playground/playgroundDashboard/components/general/bubble/Bubble'
import stylesMain from 'pages/playground/playgroundDashboard/PlaygroundDashboard.module.scss'
import { adjustTextSize } from 'pages/playground/playgroundDashboard/utils/fitText'

const NotFoundPlaygroundState = () => {
  const containerRefHomePage = useRef<HTMLDivElement>(null)
  const textRefHomePage = useRef<HTMLDivElement>(null)

  const { mutateAsync: getConversationId } = usePostConversationIdByMessage()

  // Initial adjustment
  adjustTextSize(containerRefHomePage.current, textRefHomePage.current, 370)

  // Adjust text size on window resize to maintain responsiveness
  window.addEventListener('resize', () => {
    adjustTextSize(containerRefHomePage.current, textRefHomePage.current, 370)
  })

  const openIntercom = async (message: string) => {
    getConversationId({ message }).then(data => {
      ;(window as any).Intercom?.('showConversation', data.data.conversationId)
    })
  }

  return (
    <Flex className={styles.container} direction="column" justify="between">
      <div className={styles.containerText} ref={containerRefHomePage}>
        <div ref={textRefHomePage} className={styles.weirdHeader}>
          404 404
        </div>
      </div>

      <div className={styles.containerInner}>
        <Flex justify="between">
          <div className={stylesMain['w-1/2']}>
            <div className={styles.bubbleFixed}>
              <BubbleFixed text="What’s a user like you, doing on a page like this?" />
            </div>
            <div className={clsx(styles.title, stylesMain.preWrap)}>{'Well, \nHello There'}</div>
            <div className={clsx(styles.secondTitle, stylesMain.preWrap, stylesMain.mt24)}>
              {
                "Did your favorite bookmarked link expire? \nPerhaps you typed too quickly in the URL bar. Or, \nlike bad poetry, mistakes happen. Since you're \nhere, check out this 404, AI-inspired poem, then \npop over to Intercom for help."
              }
            </div>
            <div className={clsx(styles.text, stylesMain.preWrap, stylesMain.mt24)}>
              {
                "Clicked a link, what did I see?\n404! No page for me. \nPolar bears in snow so white, \nGuess I'll watch them play all night!"
              }
            </div>
          </div>
          <Flex className={clsx(stylesMain['w-1/2'], stylesMain.relative)} justify="end">
            <div className={styles.intercomContainer}>
              <img src={ArrowsSvg} alt="" />
              <div
                className={styles.bubble}
                onClick={() => {
                  openIntercom('What is page 404?')
                }}
              >
                <div className={styles.bubbleInner}>
                  <div className={clsx(stylesMain.darkGreenBold, styles.bubbleText)}>Ask Intercom</div>
                </div>
              </div>
            </div>
            <img src={IntercomSvg} alt="" />
          </Flex>
        </Flex>
      </div>
      <div className={styles.arcContainer}>
        <div className={styles.arcBottom} />
      </div>
    </Flex>
  )
}

export default NotFoundPlaygroundState
