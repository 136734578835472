export const GridSections = [
  {
    title: 'Match & Thrive',
    text: 'A holistic support system for growth, matching your skills & interests to deliver top prioritized projects.',
    icon: require('assets/playground/whatscoming/hand.gif'),
  },
  {
    title: 'Agency Roster',
    text: 'Get to know our all-star line-up of Strategic Roster Agencies better and choose the right partner for your upcoming project.',
    icon: require('assets/playground/whatscoming/agency.gif'),
  },
  {
    title: 'Communities of Practitioners',
    text: 'Connect to discipline practitioners to support and learn from one another. The more we share, coach and apply, the faster we improve.',
    icon: require('assets/playground/whatscoming/community.gif'),
  },
  {
    title: 'Tools & Support',
    text: 'Skip the search. Quickly find direct links to the tools you need like Adobe Workfront, KO Assets and more.',
    icon: require('assets/playground/whatscoming/tools.gif'),
  },
  {
    title: 'Studio X',
    text: 'Learn more about the core and modern capabilities, our brief types and playbooks, and our best work around the world.',
    icon: require('assets/playground/whatscoming/studio.gif'),
  },
  {
    title: 'Reporting & Dashboard Catalog',
    text: 'Your one spot for navigating Simon, PowerBI, and Adobe Workfront Reports.',
    icon: require('assets/playground/whatscoming/chart.gif'),
  },
]
