import clsx from 'clsx'

import styles from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/assignmentCard/components/bubble/Bubble.module.scss'

interface Props {
  color: string
  icon?: string
  text: string
  direction?: 'up' | 'down'
  className?: string
}

export const Bubble = ({ color, icon, text, className, direction }: Props) => {
  function bubbleBorder() {
    if (direction === 'up') {
      return '0 26px 26px 26px'
    } else {
      return '26px 26px 26px 0'
    }
  }

  function bubblePadding() {
    if (icon) {
      return '11px 19px'
    } else {
      return '16px 19px'
    }
  }

  return (
    <div
      className={clsx(styles.container, className)}
      style={{ backgroundColor: color, borderRadius: bubbleBorder(), padding: bubblePadding() }}
    >
      {icon && (
        <div className={styles.iconWrapper}>
          <img src={icon} alt="" />
        </div>
      )}
      <div className={styles.textWrapper}>
        <p>{text}</p>
      </div>
    </div>
  )
}

export default Bubble
