import { useMemo } from 'react'

import { DASHBOARD_COLS_COUNT, DASHBOARD_ROW_HEIGHT, DASHBOARD_GRID_GAP } from 'constants/homePage'
import { useDomElementCss } from 'hooks/useDomElementCss'

export const usePopulateConfigurableCssVariables = () => {
  const css = useMemo(
    () => ({
      '--wpp-os-home-grid-cols': DASHBOARD_COLS_COUNT,
      '--wpp-os-home-grid-row-height': `${DASHBOARD_ROW_HEIGHT}px`,
      '--wpp-os-home-grid-gap': `${DASHBOARD_GRID_GAP}px`,
    }),
    [],
  )

  useDomElementCss(css)
}
