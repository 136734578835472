import { workfrontApi } from 'api'

interface WorkfrontAssignmentsResponse {
  data: {
    completed: boolean
    processing: boolean
    error: boolean | null
  }[]
}

export const fetchWorkfrontAssignmentsProgress = ({ projectsToLoad }: { projectsToLoad: string[] }) =>
  workfrontApi.get<WorkfrontAssignmentsResponse>(
    `/shadow-orchestration/background-projects-builder?wfProjectId=${projectsToLoad.join('&wfProjectId=')}`,
    {},
  )
