import {
  WppCard,
  WppTypography,
  WppSkeleton,
  WppMenuContext,
  WppActionButton,
  WppListItem,
  WppIconEdit,
  WppIconMore,
  WppIconTrash,
  WppDivider,
  WppIconVideoOn,
  WppTooltip,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { Permission } from 'constants/permission'
import { useHasPermission } from 'hooks/useHasPermission'
import { showDeleteCalendarEventModal } from 'pages/home/systemWidgets/calendarWidget/deleteCalendarEventModal/DeleteCalendarEventModal'
import { DotIndicator } from 'pages/home/systemWidgets/calendarWidget/dotIndicator/DotIndicator'
import styles from 'pages/home/systemWidgets/calendarWidget/eventsCard/EventsCard.module.scss'
import { showManageEventSideModal } from 'pages/home/systemWidgets/calendarWidget/manageEventSideModal/ManageEventSideModal'
import { useGetFormattedTime } from 'pages/home/systemWidgets/calendarWidget/utils'
import { showViewEventSideModal } from 'pages/home/systemWidgets/calendarWidget/viewEventSideModal/ViewEventSideModal'
import { CalendarWidgetEventDTO } from 'types/widgets/widget'

interface Props {
  events: CalendarWidgetEventDTO[]
  withAction?: boolean
  groupName?: string
  dateFormat?: string
  isTooltip?: boolean
  onOpenViewEventModal?: () => void
}

export const EventsCard = ({
  events,
  groupName,
  withAction = true,
  isTooltip = false,
  onOpenViewEventModal,
}: Props) => {
  const { t } = useTranslation()
  const { hasPermission } = useHasPermission()

  const ref = useRef<HTMLDivElement>(null)
  const getFormattedTime = useGetFormattedTime()

  return (
    <Flex
      direction="column"
      gap={4}
      ref={ref}
      className={clsx(styles.card, { [styles.scrolled]: isTooltip })}
      data-testid="events-grouped-by-date"
    >
      {groupName && (
        <WppTypography type="s-strong" data-testid="events-grouped-by-date-header">
          {groupName}
        </WppTypography>
      )}
      <Flex direction="column" gap={4} className={styles.eventsWrapper}>
        {events.map(event => (
          <WppCard
            key={event.id}
            size="s"
            data-testid="calendar-event-item"
            onClick={() => {
              onOpenViewEventModal?.()
              showViewEventSideModal({ event })
            }}
          >
            <Flex
              align="center"
              justify="between"
              gap={8}
              className={clsx(
                styles.cardInfo,
                { [styles.withLink]: event.videoConferencingUrl },
                { [styles.withActions]: hasPermission(Permission.OS_ADMIN_SETTING_ACCESS) && withAction },
              )}
            >
              <DotIndicator size={10} color={event.color} className={styles.dotIndicator} />
              <Flex direction="column" className={styles.info}>
                <Flex gap={8} align="baseline">
                  <WppTypography type="s-body" className={styles.title} data-testid="card-header">
                    {event.title}
                  </WppTypography>
                </Flex>
                {event.startsAt && event.endsAt && (
                  <WppTypography type="s-body" data-testid="card-time" className={styles.cardTimeRange}>
                    {getFormattedTime(new Date(event.startsAt), new Date(event.endsAt))}
                  </WppTypography>
                )}
              </Flex>
            </Flex>

            <Flex
              slot="actions"
              gap={6}
              align="center"
              justify="center"
              className={clsx(styles.actions, { [styles.withLink]: event.videoConferencingUrl })}
              onClick={e => {
                e.stopPropagation()
              }}
            >
              {event.videoConferencingUrl && (
                <WppTooltip
                  text={t('os.home.calendar_widget.view_event.video_link_button')}
                  config={{ placement: 'left', zIndex: 10000 }}
                >
                  <Link to={event.videoConferencingUrl} target="_blank">
                    <WppActionButton>
                      <WppIconVideoOn slot="icon-start" data-testid="join-online-button" />
                    </WppActionButton>
                  </Link>
                </WppTooltip>
              )}

              {hasPermission(Permission.OS_ADMIN_SETTING_ACCESS) && withAction && (
                <WppMenuContext
                  data-testid="card-actions"
                  dropdownConfig={{
                    placement: 'bottom-end',
                    zIndex: 10000,
                    appendTo: () => (isTooltip ? (ref.current as Element) : document.body),
                  }}
                >
                  <WppActionButton slot="trigger-element" variant="secondary">
                    <WppIconMore direction="horizontal" slot="icon-start" />
                  </WppActionButton>
                  <Flex direction="column" gap={8} className={styles.contextMenu}>
                    <WppListItem onClick={() => showManageEventSideModal({ event, calendarId: event.calendarId })}>
                      <WppIconEdit slot="left" />
                      <p slot="label">{t('os.common.edit')}</p>
                    </WppListItem>

                    <WppDivider />

                    <WppListItem onClick={() => showDeleteCalendarEventModal({ event })}>
                      <WppIconTrash slot="left" />
                      <p slot="label">{t('os.common.delete')}</p>
                    </WppListItem>
                  </Flex>
                </WppMenuContext>
              )}
            </Flex>
          </WppCard>
        ))}
      </Flex>
    </Flex>
  )
}

export const EventsCardSkeleton = () => {
  return (
    <Flex direction="column" gap={4}>
      <WppCard>
        <Flex gap={12}>
          <Flex direction="column" gap={8}>
            <WppSkeleton width={500} height={20} />
            <WppSkeleton width={500} height={40} />
          </Flex>
        </Flex>
      </WppCard>
    </Flex>
  )
}
