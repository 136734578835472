import { NavigationState } from '@platform-ui-kit/components-library'
import { WppTypography } from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType } from '@wpp-open/core'
import { PropsWithChildren, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'

import { ANALYTICS_EVENTS } from 'constants/analytics'
import { FullHeightPage } from 'layout/fullHeightPage/FullHeightPage'
import { Subheader } from 'layout/subheader/Subheader'
import styles from 'pages/hubsAdmin/HubsAdmin.module.scss'
import { getAnalyticsEventPayload, trackAnalytics } from 'utils/analytics'
import { routesManager } from 'utils/routesManager'

const useNavigation = (): NavigationState[] => {
  const { t } = useTranslation()

  return useMemo(
    () => [
      {
        label: t('os.admin.topbar.navigation.hubs'),
        value: 'hubs',
        path: routesManager.hubsAdmin.root.url(),
      },
      {
        label: t('os.admin.topbar.requests'),
        value: 'requests',
        path: routesManager.hubsAdmin.hubAccess.root.url(),
      },
    ],
    [t],
  )
}

export const HubsAdminPage = ({ children }: PropsWithChildren<{}>) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()

  const navigate = useNavigate()
  const navigation = useNavigation()
  const currentPage = matchPath(`${routesManager.hubsAdmin.hubs.url()}//*`, pathname)
    ? navigation[0].value
    : navigation[1].value

  return (
    <FullHeightPage>
      <Subheader
        value={currentPage}
        navigation={navigation}
        onWppChange={({ detail }) => {
          trackAnalytics({
            type: AnalyticsActionType.action,
            payload: getAnalyticsEventPayload(ANALYTICS_EVENTS.ADMIN.ACTIONS.OPEN_TAB, detail.label),
          })
          navigate(detail.path!)
        }}
      >
        <WppTypography slot="app" type="m-strong" tag="h3" style={{ minWidth: '100px' }}>
          {t('os.header.user_avatar_dropdown_menu.hubs_admin_section_header')}
        </WppTypography>
      </Subheader>

      <div className={styles.pageWrapper}>{children}</div>
    </FullHeightPage>
  )
}
