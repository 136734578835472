import { OsContextActivePage, MayBeNull } from '@wpp-open/core'
import { useEffect } from 'react'

import { useOsRoot } from 'providers/osRoot/OsRootContext'

export const useSetActivePage = (page: MayBeNull<OsContextActivePage>) => {
  const { setActivePage } = useOsRoot()

  useEffect(() => {
    if (page) {
      setActivePage(page)

      return () => {
        return setActivePage(activePage => (page === activePage ? null : activePage))
      }
    }
  }, [page, setActivePage])
}
