import { AppInstanceFull, AppInstanceMiroShort } from '@wpp-open/core'

import { facadeApi } from 'api'

export type PrelaunchedAppInstanceMiro = AppInstanceFull<AppInstanceMiroShort> & {
  context: AppInstanceFull<AppInstanceMiroShort>['context'] & {
    boardId: string
    url: string
  }
}

export const fetchPrelaunchedInstanceApi = ({ instanceId }: { instanceId: string }) =>
  facadeApi.post<PrelaunchedAppInstanceMiro>(`/app-instances/${instanceId}/hooks/pre-launch`)
