import { ThemeContent, ThemeStyles, resolveTheme } from '@platform-ui-kit/components-library'
import { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useCurrentTenantThemeApi } from 'api/tenant/queries/useCurrentTenantThemeApi'
import { useTaxonomyApi } from 'api/tenant/queries/useTaxonomyApi'
import { is404Error } from 'api/utils'
import { HeadData } from 'components/headData/HeadData'
import { useProviderNoncriticalError } from 'hooks/useProviderNoncriticalError'
import { i18n } from 'i18n/i18n'
import { useOsRoot } from 'providers/osRoot/OsRootContext'
import { PublicDataContext } from 'providers/publicData/PublicDataContext'
import { SUPPORTED_THEME, useApplyTheme } from 'providers/publicData/utils'
import { HostType } from 'types/tenants/tenant'
import { capitalize, excludeFalsy } from 'utils/common'

export const PublicDataProvider = ({ children }: PropsWithChildren<{}>) => {
  const { t } = useTranslation()
  const { hostInfo, defaultTenant } = useOsRoot()
  const [mode] = useState<keyof ThemeContent>(SUPPORTED_THEME)

  const {
    data: taxonomy,
    isLoading: isTaxonomyLoading,
    isError: isTaxonomyError,
  } = useTaxonomyApi({
    enabled: !!hostInfo.currentTenant,
  })

  const {
    data: currentTheme,
    isLoading: isCurrentThemeLoading,
    error: currentThemeError,
  } = useCurrentTenantThemeApi({
    enabled: hostInfo.type === HostType.TENANT,
  })

  useProviderNoncriticalError({
    isError: isTaxonomyError,
    message: t('os.provider_errors.taxonomy'),
  })

  useProviderNoncriticalError({
    isError: !!currentThemeError && !is404Error(currentThemeError),
    message: t('os.provider_errors.theme'),
  })

  useEffect(() => {
    if (taxonomy && i18n.options.interpolation) {
      i18n.options.interpolation.defaultVariables = Object.entries(taxonomy).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value,
          [capitalize(key)]: capitalize(value),
        }),
        {},
      )
    }
  }, [taxonomy])

  const theme = currentTheme || defaultTenant.theme

  const resolvedThemeStyles = useMemo(() => {
    try {
      return resolveTheme(theme, mode).content[mode]!
    } catch {
      console.warn('Default theme or tenant theme is invalid')
      return {} as ThemeStyles
    }
  }, [theme, mode])

  const themeFonts = [theme.settings?.fontUrl].filter(excludeFalsy).flat()

  useApplyTheme({ theme, mode })

  const isLoading = isCurrentThemeLoading || isTaxonomyLoading

  // Nothing is shown until we have a theme loaded
  if (isLoading) {
    return null
  }

  return (
    <PublicDataContext.Provider
      value={{
        theme,
        resolvedTheme: resolvedThemeStyles,
        currentTaxonomy: taxonomy || defaultTenant.taxonomy,
      }}
    >
      <HeadData themeFonts={themeFonts} />
      {children}
    </PublicDataContext.Provider>
  )
}
