import { workfrontApi } from 'api'

export interface WorkdayResponse {
  associateLocation: string | null
  associateProfile: string | null
  associateRole: string | null
  interests: string | null
  managerEmail: string | null
  managerName: string | null
  managerSurname: string | null
  photo: string | null
  skills: string | null
  jobFamilyName: string | null
  jobFamilyGroup: string | null
}
export const fetchWorkday = () => workfrontApi.get<{ data: WorkdayResponse[] }>('/workday', {})
