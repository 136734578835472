import { facadeApi } from 'api'
import { getMiroAuthRedirectUrl } from 'utils/miro'

interface Params {
  clientId?: string
  teamId?: string
  state?: string
}

interface MiroAuthLinkResponse {
  url: string
}

export const fetchMiroAuthLinkApi = ({ clientId, teamId, state }: Params) =>
  facadeApi.get<MiroAuthLinkResponse>('/miro/auth/link', {
    params: {
      clientId,
      teamId,
      state,
      redirectUri: getMiroAuthRedirectUrl(),
    },
  })
