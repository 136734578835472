import { MayBeNull, OsContextActivePage } from '@wpp-open/core'
import { Dispatch, SetStateAction, createContext, useContext } from 'react'

import { TenantDefault, KnownHostInfo } from 'types/tenants/tenant'

export const OsRootContext = createContext<{
  hostInfo: KnownHostInfo
  defaultTenant: TenantDefault
  activePage: MayBeNull<OsContextActivePage>
  setActivePage: Dispatch<SetStateAction<MayBeNull<OsContextActivePage>>>
}>(null!)

export const useOsRoot = () => useContext(OsRootContext)
