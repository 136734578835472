import { WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { SvgNoResultsCalendarEvents } from 'components/svg/SvgNoResultsCalendarEvents'
import styles from 'pages/home/systemWidgets/calendarWidget/viewEventsSideModal/ViewEventsSideModal.module.scss'

export const NoResultsState = ({ search }: { search: string }) => {
  const { t } = useTranslation()

  return (
    <Flex direction="column" align="center" justify="center" gap={24} className={styles.noResultsWrapper}>
      <SvgNoResultsCalendarEvents />
      <Flex direction="column" align="center" justify="center" gap={8}>
        <WppTypography type="l-strong" data-testid="no-results-header">
          {t('os.home.calendar_widget.view_events.no_results_state', { search })}
        </WppTypography>
        <WppTypography type="s-body" data-testid="no-results-description">
          {t('os.home.calendar_widget.view_events.no_results_state_description')}
        </WppTypography>
      </Flex>
    </Flex>
  )
}
