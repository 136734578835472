import { AppInstanceAssignmentType, AppInstanceFull, MayBeNull } from '@wpp-open/core'
import { useMemo } from 'react'

import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { getAppInstanceAppData } from 'providers/osState/utils/appData'
import { isValueSet, UnsetValue } from 'providers/osState/utils/conditionalData'
import {
  filterAppInstanceWorkspaceId,
  getInvalidResolvedAppData,
  isValidAppInstanceWorkspace,
} from 'providers/osState/utils/resolvers/common'
import { ResolvedAppData } from 'providers/osState/utils/useResolveAppData'
import { formatAppCustomConfig, RoutedAppInstanceApp } from 'utils/appInstances'

interface Params {
  appInstance: AppInstanceFull
  app: RoutedAppInstanceApp
  currentBaseUrl: string
}

export const useWorkspaceAppInstance = (params: MayBeNull<Params>): MayBeNull<ResolvedAppData> => {
  const { navigationTree } = useCurrentTenantData()

  const isEnabled = !!params
  const appCustomConfig = useMemo(
    () => (isEnabled ? formatAppCustomConfig(params.appInstance) : UnsetValue),
    [isEnabled, params?.appInstance],
  )

  // Validate and process workspace data
  if (isEnabled && isValueSet(appCustomConfig)) {
    const { appInstance, app, currentBaseUrl } = params
    const isCustomNavigation = appInstance.assignmentType === AppInstanceAssignmentType.Custom
    const workspaceAzId = appInstance.assignmentId
    const additionalContext = appInstance.context

    const filteredWorkspaceAzId = filterAppInstanceWorkspaceId({
      workspaceAzId,
      navigationTree,
    })
    const isWorkspaceValid = isValidAppInstanceWorkspace({
      workspaceAzId: filteredWorkspaceAzId,
      navigationTree,
      app,
    })

    if (isWorkspaceValid) {
      return {
        appData: getAppInstanceAppData({
          app,
          currentBaseUrl,
          activeWorkspaceId: isCustomNavigation ? null : filteredWorkspaceAzId,
          appInstance,
          project: null,
          projectCanvas: null,
          projectPhase: null,
          projectItem: null,
          appCustomConfig,
          additionalContext,
          isCustomNavigation,
        }),
        sideEffectNode: null,
      }
    } else {
      console.error(`Invalid workspace level for app '${app.name}'`)
    }

    return getInvalidResolvedAppData()
  }

  return null
}
