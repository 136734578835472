import { PropsWithChildren } from 'react'
import { Outlet } from 'react-router-dom'

import { ForbiddenPageError } from 'components/renderError'
import { Permission } from 'constants/permission'
import { useHasPermission } from 'hooks/useHasPermission'
import { useUserCanManageApps } from 'hooks/useUserCanManageApps'

type Props = PropsWithChildren<{
  permission: Permission
}>

export const PermittedLayerAdminAccess = ({ permission, children = <Outlet /> }: Props) => {
  const { isUserCanManage: isUserCanManageApps } = useUserCanManageApps()
  const { hasPermission } = useHasPermission()

  return hasPermission(permission) || isUserCanManageApps ? children : <ForbiddenPageError />
}
