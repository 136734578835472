import { useMemo } from 'react'

import {
  useGenerateUserDetailsUploadUrlsApi,
  useUploadUserDetailsFileApi,
} from 'api/attachments/mutation/useUploadUserDetailsFileApi'
import { createUploadCallback } from 'hooks/attachments/createUploadCallback'

export const useUploadUserDetailsFilesApi = () => {
  const { mutateAsync: handleGenerateUserDetailsUploadUrls } = useGenerateUserDetailsUploadUrlsApi()
  const { mutateAsync: handleUploadUserDetailsFile } = useUploadUserDetailsFileApi()

  return useMemo(
    () =>
      createUploadCallback({
        generateUploadUrls: handleGenerateUserDetailsUploadUrls,
        uploadFile: handleUploadUserDetailsFile,
      }),
    [handleGenerateUserDetailsUploadUrls, handleUploadUserDetailsFile],
  )
}
