import { useEffect, useState } from 'react'

import { FeedbackResponse } from 'api/workfront/fetchers/fetchFeedback'
import FeedbackBanner from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/assignmentCard/feedbackBanner/FeedbackBanner'

export interface FeedbackQueueProps {
  feedbackArray: FeedbackResponse[]
  handleFeedback: (index: number, helpful: boolean) => void
  handleNudgePreview: () => void
  onEnd?: () => void
  loading?: boolean
  stackSize?: number
}

export const FeedbackQueue = (props: FeedbackQueueProps) => {
  const { feedbackArray, handleFeedback, handleNudgePreview, loading, onEnd, stackSize = 3 } = props

  const [currentFeedbackIndex, setCurrentFeedbackIndex] = useState<number | null>(0)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [stackSizeState, setStackSizeState] = useState(stackSize)

  useEffect(() => {
    if (feedbackArray.length > 0) {
      setCurrentFeedbackIndex(0) // Reset index when new feedback comes in
    } else {
      setCurrentFeedbackIndex(null) // If no feedbacks, set index to null
    }
  }, [feedbackArray])

  const moveToNextFeedback = () => {
    setCurrentFeedbackIndex(prevIndex =>
      prevIndex === null || prevIndex >= feedbackArray.length - 1 ? null : prevIndex + 1,
    )
    if (currentFeedbackIndex === feedbackArray.length - 1) {
      onEnd && onEnd()
    }
  }

  const feedbackHandler = (index: number, helpful: boolean) => {
    handleFeedback(index, helpful)
    moveToNextFeedback()
  }

  const handleCloseById = (id: string) => {
    const index = feedbackArray.findIndex(feedback => feedback.id === id)
    if (index !== -1) {
      moveToNextFeedback()
    }
  }

  return (
    <>
      {feedbackArray.map((feedback, index) => {
        if (currentFeedbackIndex === index && currentFeedbackIndex !== null) {
          return (
            <FeedbackBanner
              key={feedback.id}
              nudgeId={feedback.id}
              nudgeName={feedback.nudgeName}
              assignmentName={feedback.assignmentName}
              loading={loading || false}
              handleFeedback={helpful => feedbackHandler(index, helpful)}
              handleNudgePreview={handleNudgePreview}
              onClose={() => handleCloseById(feedback.id)}
            />
          )
        }
        return null
      })}
    </>
  )
}
