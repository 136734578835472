import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

export const initI18n = (readyCallback: () => void) => {
  i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    // https://github.com/locize/react-tutorial/blob/main/step_2/src/i18n.js
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init(
      {
        lng: 'en',
        fallbackLng: 'en',
        debug: true,
        load: 'languageOnly',
        interpolation: {
          escapeValue: false,
        },
        react: {
          useSuspense: false,
        },
      },
      () => readyCallback(),
    )
}

export { i18n }
