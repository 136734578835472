import { useCallback, useRef } from 'react'

/**
 * Returns a stable callback to the provided value that won't trigger re-rendering
 */
export const useStableCallback = <T extends Function>(fn: T): T => {
  const fnRef = useRef(fn)
  fnRef.current = fn

  return useCallback((...args: any[]) => fnRef.current.apply(null, args), []) as unknown as T
}
