import { useCallback } from 'react'
import { mountRootParcel } from 'single-spa'
import Parcel from 'single-spa-react/parcel'

import { RenderErrorBoundary } from 'components/renderError'
import { RenderErrorType, useDispatchRenderError } from 'components/renderError/utils'
import { useAppsContext } from 'providers/apps/AppsContext'
import { NativeMicroAppConfig } from 'types/apps/microApps'
import { loadParcelConfig } from 'utils/singleSpa'

interface Props {
  app: NativeMicroAppConfig
}

export const CompactMicroApp = ({ app }: Props) => (
  <RenderErrorBoundary catchErrors={[RenderErrorType.CriticalError]}>
    <RenderApp app={app} />
  </RenderErrorBoundary>
)

const RenderApp = ({ app }: Props) => {
  const { getCompactAppCustomProps } = useAppsContext()
  const dispatchError = useDispatchRenderError()

  const customProps = getCompactAppCustomProps(app)
  const config = useCallback(() => loadParcelConfig(app), [app])

  return (
    <Parcel
      config={config}
      mountParcel={mountRootParcel}
      handleError={err => {
        console.error(`Compact App '${app.stableId}' has encountered an error and crashed`)
        console.error(err)

        // TODO: Create a new error state with no action buttons or a reset error button
        dispatchError(RenderErrorType.CriticalError)
      }}
      {...customProps}
    />
  )
}
