import { WppActionButton, WppTag } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { hideNotificationItemSideModal } from 'components/notificationItemSideModal/NotificationItemSideModal'
import { useStatusTagOptions } from 'components/notificationItemSideModal/notificationSideModalContent/utils'
import { hideNotificationsSideModal } from 'components/notificationsSideModal/NotificationsSideModal'
import { Requests } from 'constants/requests'
import { useStableCallback } from 'hooks/useStableCallback'
import { AccessRequestStatus } from 'pages/admin/requests/utils'

interface Props {
  allRequestsUrl: string
  status?: AccessRequestStatus
  requestType: Requests
}

export const RequestStatusDetails = ({ allRequestsUrl, status, requestType }: Props) => {
  const { variant, label } = useStatusTagOptions(requestType, status)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleAllRequestsClick = useStableCallback(() => {
    navigate(allRequestsUrl)
    hideNotificationsSideModal()
    hideNotificationItemSideModal()
  })

  return (
    <Flex gap={4} justify="between" align="center">
      <WppTag variant={variant} data-testid="notification-item-status" label={label} />

      <WppActionButton onClick={handleAllRequestsClick} data-testid="notification-item-all-requests">
        {t('os.requests.all_requests')}
      </WppActionButton>
    </Flex>
  )
}
