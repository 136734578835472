import { WppTypography } from '@platform-ui-kit/components-library-react'
import ReactMarkdown from 'react-markdown'
import { JsxRuntimeComponents } from 'react-markdown/lib'

import styles from 'components/notificationsSideModal/Markdown.module.scss'

interface Props {
  markdown: string
}
const components: Partial<JsxRuntimeComponents> = {
  strong({ children }) {
    return <WppTypography type="s-strong">{children}</WppTypography>
  },
  em({ children }) {
    return (
      <WppTypography className={styles.italic} type="s-body">
        {children}
      </WppTypography>
    )
  },
  a({ children, ...rest }) {
    return (
      <a className={styles.link} {...rest}>
        {children}
      </a>
    )
  },
}

export const Markdown = ({ markdown }: Props) => {
  return <ReactMarkdown components={components}>{markdown}</ReactMarkdown>
}
