import { useEffect } from 'react'
import { useController, useFormContext, UseControllerProps, FieldValues, FieldPath } from 'react-hook-form'

export const useField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  ...rest
}: UseControllerProps<TFieldValues, TName>) => {
  const { setValue } = useFormContext<TFieldValues>()
  const controller = useController<TFieldValues, TName>({ name, ...rest })

  const {
    field: { value },
    fieldState: { error, isTouched },
  } = controller

  /*
    react-hook-form validates all fields on submit
    but doesn't touch them due to their architecture.
    Each field covers this case individually.
  */
  useEffect(() => {
    if (error && !isTouched) {
      setValue(name, value, { shouldTouch: true })
    }
  }, [name, value, error, isTouched, setValue])

  return controller
}
