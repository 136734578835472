import { facadeApi } from 'api'
import { Feed } from 'types/feeds/feeds'

interface Props {
  tenantId: string
  ids: string[]
  updates: Pick<Feed, 'isRead'>
}

export const updateFeedsApi = ({ tenantId, ids, updates }: Props) => {
  return facadeApi.patch(
    '/feeds',
    { ids, updates },
    {
      headers: {
        'X-Tenant-Id': tenantId,
      },
    },
  )
}
