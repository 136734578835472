import { facadeApi } from 'api'
import { getMiroAuthRedirectUrl } from 'utils/miro'

interface Params {
  authCode: string
}

export const updateUserMiroAuthTokenApi = ({ authCode }: Params) =>
  facadeApi.post<null>('/miro/auth/me', null, {
    params: {
      authCode,
      redirectUri: getMiroAuthRedirectUrl(),
    },
  })
