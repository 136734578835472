import clsx from 'clsx'
import { ComponentPropsWithoutRef } from 'react'

import styles from 'components/navigationMenu/navigationMenuContent/navigationMenuTreeListColumn/NavigationMenuTreeListColumn.module.scss'
import { TreeListColumn } from 'components/treeList/treeListColumn/TreeListColumn'

export const NavigationMenuTreeListColumn = ({
  className,
  ...rest
}: ComponentPropsWithoutRef<typeof TreeListColumn>) => (
  <TreeListColumn className={clsx(styles.root, className)} {...rest} />
)
