import { matchAndThriveApi } from 'api'

export interface IntercomConversationProps {
  message: string
}
export interface IntercomConversationResponse {
  conversationId: string
}
export const fetchConversationIdByMessage = ({ message }: IntercomConversationProps) =>
  matchAndThriveApi.post<IntercomConversationResponse>('intercom/conversation', { message })
