import { UserDetails, MayBeNull } from '@wpp-open/core'
import fastDeepEqual from 'fast-deep-equal/es6'
import { NavigateFunction } from 'react-router-dom'
import { BehaviorSubject, combineLatest, distinctUntilChanged, map, Observable, Observer, Subject } from 'rxjs'

import { defaultBoxNavigation, tenantNavigationMap } from 'legacy/navigation'
import { LegacyAppV3CustomProps, LegacyOpenAppHandler } from 'legacy/types/context'
import {
  AccountPermissions,
  Config,
  Context,
  DisplaySettings,
  MicroAppAction,
  LegacyTenant,
  LegacyTenantCode,
  LegacyWorkspace,
} from 'legacy/types/osWebRootApi'
import { defaults } from 'legacy/utils/defaults'
import { agencyUrlsConfig } from 'legacy/utils/environment'
import { handleAppEvent } from 'legacy/utils/events'
import { LegacyMicroAppConfig, LocalLegacyMicroAppConfig } from 'types/apps/microApps'

export class LegacyState {
  static currentOrganisationId?: string

  isLoading$ = new BehaviorSubject<boolean>(false)
  authJwt$ = new BehaviorSubject<MayBeNull<string>>(null)
  baseUrl$ = new BehaviorSubject<MayBeNull<string>>(null)

  user$ = new BehaviorSubject<MayBeNull<UserDetails>>(null)
  permissions$ = new BehaviorSubject<MayBeNull<AccountPermissions[]>>(null)
  displaySettings$ = new BehaviorSubject<DisplaySettings>({
    numberLocale: defaults.defaultLocale.numberLocale,
    dateLocale: defaults.defaultLocale.dateLocale,
    displayLanguage: defaults.defaultLanguageCode,
  })
  workspace$ = new BehaviorSubject<MayBeNull<LegacyWorkspace>>(null)
  currentAgency$ = new BehaviorSubject<MayBeNull<LegacyTenant>>(null)
  agencies$ = new BehaviorSubject<MayBeNull<LegacyTenant[]>>(null)

  config$ = new BehaviorSubject<MayBeNull<Config>>(null)
  context$ = new BehaviorSubject<MayBeNull<Context>>(null)
  events$ = new Subject<MicroAppAction>()

  // These are updated on every render, so observed values may repeat
  rawSources = {
    authJwt$: new BehaviorSubject<MayBeNull<string>>(null),
    baseUrl$: new BehaviorSubject<MayBeNull<string>>(null),
    user$: new BehaviorSubject<MayBeNull<UserDetails>>(null),
    permissions$: new BehaviorSubject<MayBeNull<AccountPermissions[]>>(null),
    workspace$: new BehaviorSubject<MayBeNull<LegacyWorkspace>>(null),
    currentAgency$: new BehaviorSubject<MayBeNull<LegacyTenant>>(null),
    agencies$: new BehaviorSubject<MayBeNull<LegacyTenant[]>>(null),
  }

  private contextObservable$: Observable<Context> = combineLatest([
    this.user$,
    this.permissions$,
    this.displaySettings$,
    this.workspace$,
    this.currentAgency$,
  ]).pipe(
    map(([user, permissions, displaySettings, workspace, tenant]) => ({
      displaySettings,
      workspace,
      tenant,
      user,
      permissions,
    })),
  )

  private configObservable$: Observable<Config> = combineLatest([
    this.isLoading$,
    this.permissions$,
    this.workspace$,
    this.currentAgency$,
    this.agencies$,
  ]).pipe(
    map(([loadingAccess, rawPermissions, workspace, tenant, tenants]) => {
      const { dashboard, main, userSection, avatarNavigation, userSettings, networkSection } =
        tenantNavigationMap[tenant?.code as keyof typeof tenantNavigationMap] || defaultBoxNavigation

      const permissions: AccountPermissions[] = rawPermissions || []
      const navigation = {
        dashboard: dashboard(permissions),
        main: main(permissions, workspace?.country?.code),
        avatarNavigation: avatarNavigation(permissions),
        userSection: userSection(permissions),
        userSettings: userSettings(permissions),
        networkSection: networkSection(permissions),
      }

      return {
        navigation,
        tenants,
        loadingAccess,
        agenciesUrls: agencyUrlsConfig as Record<LegacyTenantCode, string>,
      }
    }),
  )

  constructor() {
    this.contextObservable$.subscribe(this.context$)
    this.configObservable$.subscribe(this.config$)

    this.rawSources.authJwt$.pipe(distinctUntilChanged()).subscribe(this.authJwt$)
    this.rawSources.baseUrl$.pipe(distinctUntilChanged()).subscribe(this.baseUrl$)
    this.rawSources.user$.pipe(distinctUntilChanged()).subscribe(this.user$)
    this.rawSources.workspace$.pipe(distinctUntilChanged(fastDeepEqual)).subscribe(this.workspace$)
    this.rawSources.permissions$.pipe(distinctUntilChanged(fastDeepEqual)).subscribe(this.permissions$)
    this.rawSources.currentAgency$.pipe(distinctUntilChanged(fastDeepEqual)).subscribe(this.currentAgency$)
    this.rawSources.agencies$.pipe(distinctUntilChanged(fastDeepEqual)).subscribe(this.agencies$)
  }

  static getEventEmitterData = (navigate: NavigateFunction, logout: Function, appName: string) => {
    let eventEmitter$: Observer<MicroAppAction>
    const subscription = new Observable<MicroAppAction>(subscriber => {
      eventEmitter$ = subscriber
    }).subscribe(event => {
      console.warn(`[${appName} APP EVENT]`, event)

      handleAppEvent({ event, navigate, logout })
    })

    return { eventEmitter$: eventEmitter$!, subscription }
  }

  emitEvent = (event: MicroAppAction) => {
    console.warn('[EMIT EVENT]', event)
    this.events$.next(event)
  }

  getCustomProps = (
    app: LegacyMicroAppConfig | LocalLegacyMicroAppConfig,
    eventEmitter$: Observer<MicroAppAction>,
    openApp: LegacyOpenAppHandler,
  ): LegacyAppV3CustomProps => ({
    domElementGetter: () => document.getElementById(app.containerId)!,

    baseHref: app.osRoute,
    assetsPath: app.bundleUrl.replace('main.js', ''),

    baseUrl$: this.baseUrl$,
    jwt$: this.authJwt$,
    context$: this.context$,
    config$: this.config$,
    eventEmitter$,
    events$: this.events$,
    overlayContainer: null,
    appState: { pristine: true },
    openApp,
  })
}

export const legacyState = new LegacyState()
