import { MayBeNull, NavigationTree } from '@wpp-open/core'

import { getInvalidAppData, getLoadingAppData } from 'providers/osState/utils/appData'
import { ResolvedAppData } from 'providers/osState/utils/useResolveAppData'
import { isNoCodeAppInstanceApp, RoutedAppInstanceApp } from 'utils/appInstances'
import { isRequiredHierarchyLevel, isWorkspaceIdValid } from 'utils/workspace'

export const getInvalidResolvedAppData = (): ResolvedAppData => ({
  appData: getInvalidAppData(),
  sideEffectNode: null,
})

export const getLoadingResolvedAppData = (
  { sideEffectNode }: Pick<ResolvedAppData, 'sideEffectNode'> = { sideEffectNode: null },
): ResolvedAppData => ({
  appData: getLoadingAppData(),
  sideEffectNode,
})

export const filterAppInstanceWorkspaceId = ({
  workspaceAzId,
  navigationTree,
}: {
  workspaceAzId: MayBeNull<string>
  navigationTree: NavigationTree
}) => (workspaceAzId === navigationTree.rootId ? null : workspaceAzId)

export const isValidAppInstanceWorkspace = ({
  workspaceAzId,
  navigationTree,
  app,
}: {
  workspaceAzId: MayBeNull<string>
  navigationTree: NavigationTree
  app: RoutedAppInstanceApp
}) => {
  const isWorkspaceValid = isWorkspaceIdValid({
    workspaceAzId,
    navigationTree,
  })
  const isNoCodeApp = isNoCodeAppInstanceApp(app)

  return (
    isWorkspaceValid &&
    (isNoCodeApp ||
      isRequiredHierarchyLevel({
        workspaceAzId,
        requiredHierarchy: app.requiredHierarchy,
        mapping: navigationTree.mapping,
      }))
  )
}
