import { useCallback } from 'react'

import { useOsState } from 'providers/osState/OsStateProvider'
import { ProjectCanvasApplicationShort, ProjectPhaseItemShort } from 'types/projects/projectNavigation'
import { excludeFalsy } from 'utils/common'
import { isProjectApplicationData, isProjectAppVisible } from 'utils/projects'

export const useFilterVisiblePhaseItems = () => {
  const { appData } = useOsState()
  const activeAppStableId = appData.app?.stableId

  return useCallback(
    (phaseItems: ProjectPhaseItemShort[]): ProjectPhaseItemShort[] => {
      const isActiveApp = ({ appInstanceId }: ProjectCanvasApplicationShort) => appInstanceId === activeAppStableId

      return phaseItems
        .map(phaseItem => {
          const { item } = phaseItem

          if (isProjectApplicationData(item)) {
            return isProjectAppVisible(item) || isActiveApp(item) ? phaseItem : null
          }

          const filteredApps = item.items.filter(
            ({ application }) => isProjectAppVisible(application) || isActiveApp(application),
          )

          return filteredApps.length > 0
            ? {
                ...phaseItem,
                item: {
                  ...item,
                  items: filteredApps,
                },
              }
            : null
        })
        .filter(excludeFalsy)
    },
    [activeAppStableId],
  )
}
