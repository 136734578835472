import { AppInstanceShort } from '@wpp-open/core'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useBulkEditAppsGroupApi } from 'api/apps/mutations/useBulkEditAppsGroupApi'
import { invalidateHierarchyNodeAppInstances } from 'components/hierarchyAppsManagement/utils'
import { useToast } from 'providers/toast/ToastProvider'

export interface UpdateGroupPositionProps {
  current: NonNullable<AppInstanceShort['group']> | NonNullable<AppInstanceShort['subgroup']>
  target: NonNullable<AppInstanceShort['group']> | NonNullable<AppInstanceShort['subgroup']>
  assignmentId?: string
  type: 'groups' | 'subgroups'
}

export const useUpdateAppsGroupPosition = () => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const { mutateAsync: handleBulkEditAppsGroupApi, isLoading: isInstanceUpdating } = useBulkEditAppsGroupApi()

  const updateAppsGroupPosition = useCallback(
    async ({ current, target, assignmentId, type }: UpdateGroupPositionProps) => {
      try {
        await handleBulkEditAppsGroupApi({
          type,
          assignmentId,
          [type]: [
            {
              id: current?.id,
              order: target?.order || null,
            },
            {
              id: target?.id,
              order: current?.order || null,
            },
          ],
        })
        await invalidateHierarchyNodeAppInstances(assignmentId!)
      } catch (e) {
        enqueueToast({ message: t('os.common.errors.general'), type: 'error' })
        throw e
      }
    },
    [enqueueToast, handleBulkEditAppsGroupApi, t],
  )

  return { updateAppsGroupPosition, isInstanceUpdating }
}
