import { GetSelectOptionLabelHandler, SelectOption } from '@platform-ui-kit/components-library'
import { WppSelect, WppListItem, WppDivider, WppSpinner } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { forwardRef, Ref, ReactNode, ComponentProps, useMemo, useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import styles from 'components/common/select/Select.module.scss'
import { getKey, useSelectDropdownConfig } from 'components/common/select/utils'
import { useCommonLabelProps } from 'components/common/utils'
import { NONE_SELECTED_VALUE } from 'constants/select'
import { useStableCallback } from 'hooks/useStableCallback'

type WppSelectProps = ComponentProps<typeof WppSelect>

export interface SelectProps extends WppSelectProps {
  options: SelectOption[]
  isOptionDisabled?: (option: SelectOption) => boolean
  getOptionValue?: (option: SelectOption) => SelectOption
  getOptionLabel?: GetSelectOptionLabelHandler
  getOptionKey?: (option: SelectOption) => string
  renderOptionContent?: (option: SelectOption) => ReactNode
  showNoneOption?: boolean
  /**
   * Provide only actionable items as actionOption
   */
  actionOption?: JSX.Element
  listItemStyle?: string
  infinite?: boolean
  search?: string
  'data-testid'?: string
}

export const Select = forwardRef(function Select(
  {
    options,
    dropdownPosition = 'fixed',
    getOptionValue = option => option.value,
    getOptionLabel = option => option.label,
    getOptionKey = option => getKey(option),
    renderOptionContent,
    isOptionDisabled = () => false,
    'data-testid': dataTestId,
    labelConfig,
    labelTooltipConfig,
    locales,
    dropdownConfig,
    className,
    showNoneOption,
    actionOption,
    withSearch,
    withFolder,
    search,
    loading,
    listItemStyle,
    infinite = false,
    type = 'single',
    value,
    ...rest
  }: SelectProps,
  ref: Ref<HTMLWppSelectElement>,
) {
  const { t } = useTranslation()
  const labelProps = useCommonLabelProps({ labelConfig, labelTooltipConfig })
  const dropdownConfigInner = useSelectDropdownConfig(dropdownConfig)

  const isMultiple = type === 'multiple'

  const getOptionValueStable = useStableCallback(getOptionValue)
  const getSelectedOption = useCallback((): MayBeNull<SelectOption> => {
    if (!infinite || isMultiple) {
      return null
    }

    return options.find(option => getOptionValueStable(option) === value) ?? null
  }, [getOptionValueStable, infinite, isMultiple, options, value])

  // TODO: This workaround solves [WPPLONOP-21452].
  //  The component stores selected option when it's available,
  //  so that it's still rendered during a search with infinite scroll.
  const [selectedOption, setSelectedOption] = useState<MayBeNull<SelectOption>>(() => getSelectedOption())
  const isSelectedOptionHidden =
    !!selectedOption && !options.find(option => getOptionValue(option) === getOptionValue(selectedOption))
  const optionsToRender = isSelectedOptionHidden ? [selectedOption, ...options] : options

  useEffect(() => {
    if (!selectedOption || getOptionValueStable(selectedOption) !== value) {
      setSelectedOption(getSelectedOption())
    }
  }, [getOptionValueStable, getSelectedOption, value, selectedOption])

  const defaultLocales = useMemo<NonNullable<WppSelectProps['locales']>>(
    () => ({
      emptyText: t('os.common.select.empty_text'),
      clearAllText: t('os.common.select.clear_all_text'),
      selectAllText: t('os.common.select.select_all_text'),
      searchInputPlaceholder: t('os.common.select.search_input_placeholder'),
      allSelectedText: t('os.common.select.all_selected_text'),
      selectLabel: t('os.common.select.select_label'),
    }),
    [t],
  )

  return (
    <WppSelect
      ref={ref}
      {...rest}
      {...labelProps}
      withSearch={withSearch}
      withFolder={withFolder && !!options.length}
      // TODO WPPLONOP-19180
      className={clsx(
        styles.select,
        { [styles.withSearch]: withSearch, [styles.showJustClearAll]: infinite },
        className,
      )}
      infinite={infinite}
      loading={loading}
      type={type}
      value={value}
      dropdownPosition={dropdownPosition}
      locales={locales ? { ...defaultLocales, ...locales } : defaultLocales}
      dropdownConfig={dropdownConfigInner}
      data-testid={dataTestId}
    >
      {!!actionOption && !search && (
        <>
          {actionOption}
          <WppDivider className={styles.divider} />
        </>
      )}
      {showNoneOption && (
        <>
          <WppListItem
            className={clsx({
              [styles.hiddenOption]: !!search,
            })}
            value={NONE_SELECTED_VALUE}
          >
            <span slot="label">{t('os.common.selects.none')}</span>
          </WppListItem>
          {!search && <WppDivider className={styles.divider} />}
        </>
      )}
      {optionsToRender.map(option => {
        const optionValue = getOptionValue(option)

        return (
          <WppListItem
            // listItemStyle - to add posibility show tooltip on disabled items
            className={clsx(listItemStyle, {
              [styles.hiddenOption]: isSelectedOptionHidden && option === selectedOption,
            })}
            key={getOptionKey(option)}
            value={optionValue}
            disabled={isOptionDisabled(option)}
          >
            {renderOptionContent ? renderOptionContent?.(option) : <span slot="label">{getOptionLabel(option)}</span>}
          </WppListItem>
        )
      })}
      {/* TODO: WPPLONOP-200048 */}
      {!options.length && !loading && infinite && (
        <WppListItem disabled multiple={false} key="empty_text_item">
          <span slot="label">{t('os.common.select.empty_text')}</span>
        </WppListItem>
      )}
      {loading && (!!search || !options.length) && infinite && (
        <WppListItem disabled multiple={false} className={styles.loading} key="loading-spinner">
          <WppSpinner slot="label" />
        </WppListItem>
      )}
    </WppSelect>
  )
})
