import { jwtDecode } from 'jwt-decode'
import { useEffect, PropsWithChildren, useState } from 'react'

import { matchAndThriveApi } from 'api'
import { useAZToken } from 'pages/playground/playgroundDashboard/hooks/useAzToken'
import { isTokenExpired } from 'pages/playground/playgroundDashboard/utils/isTokenExpired'
import { useAuth } from 'providers/auth/AuthContext'

const apiInstances = [matchAndThriveApi]

export const AzApiProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isReady, setIsReady] = useState(false)
  const { jwt } = useAuth()

  // TODO Playground local hardcode jwt from stage
  // const jwt = 'stage token'
  const { azAccessToken, removeToken, refreshToken } = useAZToken(jwt)

  //   const tenantId = '40c0ccbc-e69f-461d-93cf-cf5e08cf9e3c'

  useEffect(() => {
    const interceptors = apiInstances.map(instance => {
      const interceptorToEject = instance.client.interceptors.request.use(
        instanceConfig => {
          if (azAccessToken) {
            instanceConfig!.headers!.Authorization = `Bearer ${azAccessToken}`
          }
          instanceConfig!.headers!['Client'] = 'playground-dev-fe'

          //   if (tenantId) {
          //     instanceConfig!.headers!['X-Tenant-Id'] = tenantId
          //   }

          return instanceConfig
        },
        error => Promise.reject(error),
      )
      instance.client.interceptors.response.use(
        response => response,
        error => {
          try {
            if (!azAccessToken && error.response.status === 401) {
              refreshToken()
              error.config._retry = true
              return instance.client(error.config)
            }

            const decodedToken = jwtDecode(azAccessToken)
            if (error.response.status === 401 && isTokenExpired(decodedToken?.exp)) {
              removeToken()
              refreshToken()
              error.config._retry = true
              return instance.client(error.config)
            }
          } catch (error) {
            // console.error('Error handling response', error)
          }
          return Promise.reject(error)
        },
      )
      return interceptorToEject
    })

    if (azAccessToken) {
      setIsReady(true)
    }

    return () => {
      interceptors.forEach((interceptor, index) => {
        apiInstances[index].client.interceptors.request.eject(interceptor)
      })
    }
    // eslint-disable-next-line
  }, [azAccessToken])

  if (!isReady) {
    return null // TODO: Add loading spinner
  }
  return <>{isReady && children}</>
}
