import { PropsWithChildren } from 'react'
import { Outlet } from 'react-router-dom'

import { ForbiddenPageError } from 'components/renderError'
import { Permission } from 'constants/permission'
import { useHasPermission } from 'hooks/useHasPermission'

type Props = PropsWithChildren<{
  permission: Permission
}>

export const PermittedAccess = ({ permission, children = <Outlet /> }: Props) => {
  const { hasPermission } = useHasPermission()

  return hasPermission(permission) ? children : <ForbiddenPageError />
}
