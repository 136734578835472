import { facadeApi } from 'api'
import { WorkspaceType } from 'legacy/types/osWebRootApi'
import { Workspace } from 'types/workspaces/workspace'

export interface Params {
  azId: string
  type?: WorkspaceType
  includeAll?: boolean
}

export const fetchOtherWorkspacesApi = ({ azId, type, includeAll }: Params) =>
  facadeApi.get<Workspace[]>('/workspaces/preview', {
    params: {
      parentId: azId,
      type,
      includeAll,
    },
  })
