import { navigation as choreographNavigation } from 'legacy/navigation/choreograph'
import * as navigationUtils from 'legacy/navigation/common'
import { navigation as defaultBoxNavigation } from 'legacy/navigation/default'
import { navigation as essenceNavigation } from 'legacy/navigation/essence'
import { navigation as greenhouseNavigation } from 'legacy/navigation/greenhouse'
import { navigation as mindshareNavigation } from 'legacy/navigation/mindshare'
import { navigation as msixNavigation } from 'legacy/navigation/msix'
import { navigation as wavemakerNavigation } from 'legacy/navigation/wavemaker'
import { LegacyTenantCode } from 'legacy/types/osWebRootApi'

const tenantNavigationMap = {
  [LegacyTenantCode.WAVEMAKER]: wavemakerNavigation,
  [LegacyTenantCode.CHOREOGRAPH]: choreographNavigation,
  [LegacyTenantCode.MINDSHARE]: mindshareNavigation,
  [LegacyTenantCode.ESSENCE]: essenceNavigation,
  [LegacyTenantCode.MSIX]: msixNavigation,
  [LegacyTenantCode.GREENHOUSE]: greenhouseNavigation,
}

export {
  wavemakerNavigation,
  choreographNavigation,
  msixNavigation,
  mindshareNavigation,
  essenceNavigation,
  defaultBoxNavigation,
  navigationUtils,
  tenantNavigationMap,
  greenhouseNavigation,
}
