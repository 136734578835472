import { MayBeNull } from '@wpp-open/core'
import fastDeepEqual from 'fast-deep-equal/es6'
import { Component, ComponentPropsWithoutRef } from 'react'
import { Location, useLocation } from 'react-router-dom'

import { RenderErrorBoundaryLayout } from 'components/renderError/boundary/renderErrorBoundaryLayout/RenderErrorBoundaryLayout'
import { RenderErrorBoundaryView } from 'components/renderError/boundary/RenderErrorBoundaryView'
import { RenderError, RenderErrorType } from 'components/renderError/utils'

type Props = ComponentPropsWithoutRef<typeof RenderErrorBoundaryLayout> & {
  catchErrors?: RenderErrorType[]
}

type InnerProps = Props & { location: Location }

interface State {
  error: MayBeNull<RenderError>
}

const initialState: State = {
  error: null,
}

class RenderErrorBoundaryInner extends Component<InnerProps, State> {
  static defaultProps = {
    catchErrors: [RenderErrorType.OsIsNotAvailable],
  }

  state = initialState

  componentDidUpdate(prevProps: InnerProps) {
    const { error } = this.state
    const { location } = this.props

    const shouldResetError =
      !!error &&
      !fastDeepEqual([prevProps.location.pathname, prevProps.location.search], [location.pathname, location.search])

    if (shouldResetError) {
      this.resetError()
    }
  }

  static getDerivedStateFromError(error: unknown): MayBeNull<State> {
    return {
      error: error instanceof RenderError ? error : new RenderError(RenderErrorType.OsIsNotAvailable),
    }
  }

  componentDidCatch(error: unknown, info: object) {
    console.error(error, info)
  }

  resetError = () => {
    this.setState(initialState)
  }

  render() {
    const { error } = this.state
    const { children, catchErrors, location, ...rest } = this.props

    if (error) {
      const isProcessable = catchErrors!.includes(error.cause)

      if (isProcessable) {
        return (
          <RenderErrorBoundaryLayout {...rest}>
            <RenderErrorBoundaryView error={error} resetError={this.resetError} />
          </RenderErrorBoundaryLayout>
        )
      }

      // Pass to upper boundary
      throw error
    }

    return children
  }
}

export const RenderErrorBoundary = (props: Props) => {
  const location = useLocation()

  return <RenderErrorBoundaryInner {...props} location={location} />
}
