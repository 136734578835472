import { Point } from 'types/connection/connection'

interface Props {
  tip: Point
  size: number
  stroke?: string
  strokeWidth?: number
  rotateAngle?: number
}

export const LineConnectionArrow = ({
  tip,
  size,
  stroke = 'var(--wpp-dataviz-color-seq-brand-500)',
  strokeWidth = 1,
  rotateAngle = 0,
}: Props) => {
  const path = `
    M ${tip.x} ${tip.y}
    l ${-size} ${-size}
    M ${tip.x} ${tip.y}
    l ${-size} ${size}
                `
  return (
    <path
      d={path}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      transform={`rotate(${rotateAngle} ${tip.x} ${tip.y})`}
    />
  )
}
