import { MayBeNull } from '@wpp-open/core'
import { useRef, useEffect, useMemo } from 'react'

export function useDebounceFn<T extends (...args: any) => void>(fn: T, delay: number): T {
  const timeoutIdRef = useRef<number | undefined>(undefined)
  const fnRef = useRef<MayBeNull<T>>(null)

  useEffect(() => {
    fnRef.current = fn

    return () => {
      fnRef.current = null
    }
  }, [fn])

  useEffect(
    () => () => {
      clearTimeout(timeoutIdRef.current)
    },
    [],
  )

  return useMemo(
    () =>
      ((...args: any[]) => {
        clearTimeout(timeoutIdRef.current)
        timeoutIdRef.current = window.setTimeout(() => fnRef.current?.(...args), delay)
      }) as unknown as T,
    [delay],
  )
}
