import { WppListItem, WppMenuGroup, WppSkeleton } from '@platform-ui-kit/components-library-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { useProjectBreadcrumbsApps } from 'layout/header/breadcrumbs/project/appsProvider/ProjectBreadcrumbsAppsProvider'
import { ProjectBreadcrumbsMenuAppItem } from 'layout/header/breadcrumbs/project/menuAppItem/ProjectBreadcrumbsMenuAppItem'
import styles from 'layout/header/breadcrumbs/project/projectLinear/ProjectLinearBreadcrumbs.module.scss'
import { useFilterVisiblePhaseItems } from 'layout/header/breadcrumbs/project/projectLinear/utils'
import { ProjectPhaseShort } from 'types/projects/projectNavigation'
import { sortBy } from 'utils/common'
import { isProjectApplicationData } from 'utils/projects'

interface Props {
  selectedPhase: ProjectPhaseShort
}

export const ProjectLinearBreadcrumbsAppsColumn = ({ selectedPhase }: Props) => {
  const { t } = useTranslation()
  const { isLoading } = useProjectBreadcrumbsApps()
  const filterVisiblePhaseItems = useFilterVisiblePhaseItems()

  const shownPhaseItems = useMemo(() => {
    const filtered = filterVisiblePhaseItems(selectedPhase.phaseItems)

    return sortBy(filtered, ({ orderNumber }) => orderNumber)
  }, [filterVisiblePhaseItems, selectedPhase.phaseItems])

  return (
    <Flex className={styles.appsColumn} gap={4} direction="column">
      {isLoading ? (
        <Flex gap={12} direction="column">
          <WppSkeleton height={20} />
          <WppSkeleton height={20} />
          <WppSkeleton height={20} />
        </Flex>
      ) : (
        <>
          {shownPhaseItems.map(({ id, item }) => {
            if (isProjectApplicationData(item)) {
              return <ProjectBreadcrumbsMenuAppItem key={id} canvasApp={item} />
            }

            return (
              <Flex
                key={id}
                as={WppMenuGroup}
                header={item.name || ''}
                gap={4}
                direction="column"
                data-testid="breadcrumbs-menu-project-linear-activity"
              >
                {sortBy(item.items, ({ orderNumber }) => orderNumber).map(({ id, application }) => (
                  <ProjectBreadcrumbsMenuAppItem key={id} canvasApp={application} />
                ))}

                {!item.items.length && (
                  <WppListItem disabled data-testid="breadcrumbs-menu-project-linear-no-apps">
                    <span slot="label">{t('os.header.breadcrumbs.no_apps')}</span>
                  </WppListItem>
                )}
              </Flex>
            )
          })}

          {!shownPhaseItems.length && (
            <WppListItem disabled data-testid="breadcrumbs-menu-project-linear-no-apps">
              <span slot="label">{t('os.header.breadcrumbs.no_apps')}</span>
            </WppListItem>
          )}
        </>
      )}
    </Flex>
  )
}
