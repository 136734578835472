import { WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ComponentPropsWithoutRef, forwardRef } from 'react'

import styles from 'components/common/truncate/Truncate.module.scss'

type Props = ComponentPropsWithoutRef<typeof WppTypography> & {
  lines?: number
}

export const Truncate = forwardRef<HTMLWppTypographyElement, Props>(({ className, lines = 1, ...rest }, ref) => {
  const props = {
    ...rest,
    ...(lines > 1 && {
      style: { '--lines': lines },
    }),
    className: clsx(styles.root, className),
  }

  return <WppTypography ref={ref} {...props} />
})
