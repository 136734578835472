import { WppActionButton, WppIconPlus, WppTypography } from '@platform-ui-kit/components-library-react'
import { AppInstanceShort, MayBeNull } from '@wpp-open/core'
import { Fragment, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserAssignedRolesApi } from 'api/roles/queries/useUserAssignedRolesApi'
import { Flex } from 'components/common/flex/Flex'
import styles from 'components/treeList/appsTreeList/AppsTreeList.module.scss'
import { GroupMenuItems } from 'components/treeList/appsTreeList/components/groupMenuItems/GroupMenuItems'
import { LoadingTreeList } from 'components/treeList/loadingTreeList/LoadingTreeList'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { GroupedItem } from 'utils/appsGroups'
import { canUserSeeApp } from 'utils/rolesChecker'

interface Props {
  isLoading?: boolean
  ungrouped: AppInstanceShort[]
  grouped: GroupedItem[]
  renderApp: (appInstance: AppInstanceShort, i: number, source: AppInstanceShort[]) => JSX.Element
  renderGroups?: (
    renderAddAppButtonPerSection: (appGroupId?: string, appSubGroupId?: string) => MayBeNull<JSX.Element>,
  ) => JSX.Element
  emptyState?: ReactNode
  action?: ReactNode
  isNoGroupTitle?: boolean
  withAddAppButtonPerSection?: boolean
  handleShowAddAppSideModal?: (appGroupId?: string, appSubGroupId?: string) => void
  withGroupMenu?: boolean
  assignmentId?: string
}

export const AppsTreeList = ({
  isLoading = false,
  ungrouped,
  grouped,
  renderApp,
  renderGroups,
  emptyState = null,
  action = null,
  isNoGroupTitle = false,
  withAddAppButtonPerSection = false,
  handleShowAddAppSideModal,
  withGroupMenu = false,
  assignmentId,
}: Props) => {
  const { t } = useTranslation()

  const { userDetails } = useOtherTenantsAndUserData()
  const { data: userAssignedRoles } = useUserAssignedRolesApi({
    params: { email: userDetails.email, id: userDetails.id },
  })

  if (isLoading) {
    return <LoadingTreeList />
  }

  const renderAddAppButtonPerSection = (appGroupId?: string, appSubGroupId?: string) => {
    if (!withAddAppButtonPerSection) return null
    return (
      <WppActionButton
        onClick={() => !!handleShowAddAppSideModal && handleShowAddAppSideModal(appGroupId, appSubGroupId)}
        data-testid="apps-add-button-per-section"
      >
        <WppIconPlus slot="icon-start" />
        {t('os.admin.apps.add_app_button')}
      </WppActionButton>
    )
  }

  const renderUngroupedApps = (ungroupedApps: AppInstanceShort[]) => {
    if (!ungroupedApps.length) return null
    return (
      <Flex direction="column" gap={2} data-testid="ungrouped-apps">
        {ungroupedApps.map((appInstance, i) => (
          <Fragment key={appInstance.id}>{renderApp(appInstance, i, ungroupedApps)}</Fragment>
        ))}
        {!!ungroupedApps.length && renderAddAppButtonPerSection()}
      </Flex>
    )
  }

  return (
    <Flex direction="column" gap={8} data-testid="apps-tree-list">
      {renderUngroupedApps(ungrouped)}

      {renderGroups && !!grouped.length && <Fragment>{renderGroups(renderAddAppButtonPerSection)}</Fragment>}

      {!renderGroups &&
        grouped.map(({ group, appInstances, subgroups }, groupIndex) => (
          <Flex key={group.id} className={styles.group} gap={6} direction="column" data-testid="app-group">
            {!isNoGroupTitle && (
              <Flex className={styles.groupHeader} direction="column">
                <Flex align="center" justify="between">
                  <WppTypography type="m-strong" data-testid="app-group-header">
                    {group.name}
                  </WppTypography>
                  {withGroupMenu && (
                    <GroupMenuItems
                      type="groups"
                      current={group}
                      source={
                        grouped.filter(group => {
                          return canUserSeeApp(
                            group.appInstances.map(role => role.id) ?? null,
                            userAssignedRoles.map(role => role.role_id) ?? null,
                          )
                        }) ?? []
                      }
                      currentIndex={groupIndex}
                      assignmentId={assignmentId}
                    />
                  )}
                </Flex>

                {!!group.description && (
                  <WppTypography
                    className={styles.groupDescription}
                    type="xs-body"
                    title={group.description}
                    data-testid="app-group-subheader"
                  >
                    {group.description}
                  </WppTypography>
                )}
              </Flex>
            )}

            <Flex gap={2} direction="column">
              {appInstances.map((appInstance, i) => (
                <Fragment key={appInstance.id}>{renderApp(appInstance, i, appInstances)}</Fragment>
              ))}
              {!!appInstances.length && renderAddAppButtonPerSection(group.id)}
            </Flex>

            {!!subgroups.length &&
              subgroups.map(({ subgroup, appInstances }, subgroupIndex) => (
                <Flex direction="column" className={styles.groupHeader} key={subgroup.id} data-testid="app-sub-group">
                  <Flex direction="column">
                    <Flex align="center" justify="between">
                      <WppTypography type="s-strong" data-testid="app-sub-group-header">
                        {subgroup.name}
                      </WppTypography>
                      {withGroupMenu && (
                        <GroupMenuItems
                          current={subgroup}
                          source={subgroups.filter(subgroup => {
                            return canUserSeeApp(
                              subgroup.appInstances.map(role => role.id) ?? null,
                              userAssignedRoles.map(role => role.role_id) ?? null,
                            )
                          })}
                          currentIndex={subgroupIndex}
                          assignmentId={assignmentId}
                          type="subgroups"
                        />
                      )}
                    </Flex>

                    {!!subgroup.description && (
                      <WppTypography type="xs-body" title={subgroup.description} data-testid="app-sub-group-subheader">
                        {subgroup.description}
                      </WppTypography>
                    )}
                  </Flex>
                  <Flex gap={2} direction="column">
                    {appInstances.map((appInstance, i) => (
                      <Flex data-testid="app-sub-group-item" key={appInstance.id}>
                        {renderApp(appInstance, i, appInstances)}
                      </Flex>
                    ))}
                    {!!appInstances.length && renderAddAppButtonPerSection(group.id, subgroup.id)}
                  </Flex>
                </Flex>
              ))}
          </Flex>
        ))}
      {emptyState}
      {action}
    </Flex>
  )
}
