import { PropsWithChildren, useMemo } from 'react'
import { useAuth } from 'react-oidc-context'

import { useStableCallback } from 'hooks/useStableCallback'
import { useAZToken } from 'pages/playground/playgroundDashboard/hooks/useAzToken'
import { AuthContext } from 'providers/auth/AuthContext'
import { OidcUser } from 'types/auth/user'

export const AuthProvider = ({ children }: PropsWithChildren<{}>) => {
  const { user, signoutRedirect, removeUser } = useAuth()
  const { removeToken } = useAZToken(user!.access_token)
  const userProfile = user!.profile

  const oidcUser: OidcUser = useMemo(
    () => ({
      email: userProfile.email!,
    }),
    [userProfile],
  )

  // const loginIdentityProvider = useMemo(() => {
  //   switch (environment.LEGACY_BOX_ENV) {
  //     case LegacyBoxEnv.Stage:
  //       return IdentityProvider.PlaygroundProd

  //     case LegacyBoxEnv.Development:
  //       return IdentityProvider.PlaygroundStage

  //     default:
  //       return IdentityProvider.PlaygroundStage
  //   }
  // }, [])

  const logout = useStableCallback(() => {
    // await logoutKeycloak()
    // localStorage.clear()
    // sessionStorage.clear()
    // revokeTokens()

    // const providerData: MayBeNull<SignoutRedirectArgs> = loginIdentityProvider
    //   ? {
    //       extraQueryParams: {
    //         kc_idp_hint: loginIdentityProvider,
    //         initiating_idp: loginIdentityProvider,
    //       },
    //     }
    //   : null

    // // eslint-disable-next-line no-console
    // console.log('providerData', providerData)
    removeToken()
    signoutRedirect({ id_token_hint: user?.id_token }).then(() => {
      removeUser()
    })
  })

  // const logoutKeycloak = async () => {
  //   try {
  //     await fetch(`${environment.KEYCLOAK_AUTHORITY}/protocol/openid-connect/logout`, {
  //       method: 'POST',
  //       headers: {
  //         Authorization: `Bearer ${user!.access_token}`,
  //         'Content-Type': 'application/x-www-form-urlencoded',
  //         // client: 'playground-dev-fe',
  //       },
  //       body: JSON.stringify({
  //         client_id: 'playground-dev-fe',
  //         id_token_hint: user?.id_token,
  //         post_logout_redirect_uri: 'https://playground-stage.coke.com/login',
  //         initiating_idp: 'playground-stage',
  //       }),
  //     })
  //     // const data = await res.json()
  //     // setAndSaveToken(data.accessToken)
  //   } catch (error) {
  //     console.error('Error fetching token', error)
  //   }
  // }

  return (
    <AuthContext.Provider
      value={{
        jwt: user!.access_token,
        user: oidcUser,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
