import { MayBeNull } from '@wpp-open/core'
import { useMemo } from 'react'
import { matchPath } from 'react-router-dom'

import {
  LOCAL_APP_NAME,
  LOCAL_LEGACY_APP_BASE_URL,
  LOCAL_LEGACY_APP_CODE,
  LOCAL_LEGACY_CONTAINER_ID,
} from 'constants/apps'
import { LocalContext } from 'providers/osState/utils/appDataFromUrl/useLocalContextFromUrl'
import { MicroAppLibraryType, LocalLegacyMicroAppConfig, MicroAppType } from 'types/apps/microApps'
import { AppDataFromUrlType, LocalLegacyAppDataFromUrl } from 'types/osState/appDataFromUrl'

export const useLocalLegacyAppDataFromUrl = (
  localContext: MayBeNull<LocalContext>,
): MayBeNull<LocalLegacyAppDataFromUrl> => {
  const localLegacyAppParts = useMemo(() => getLocalLegacyAppParts(localContext), [localContext])

  const { osRoute, bundleUrl, windowLibraryName } = localLegacyAppParts || {}
  const { workspaceAzId } = localContext || {}

  const app = useMemo((): MayBeNull<LocalLegacyMicroAppConfig> => {
    // Make the app dependent only on the data parts to return a stable config
    if (!osRoute || !bundleUrl || !windowLibraryName) {
      return null
    }

    return {
      type: MicroAppType.LocalLegacy,
      stableId: LOCAL_LEGACY_APP_CODE,
      name: LOCAL_APP_NAME,
      permission: null,
      requiredHierarchy: [],
      containerId: LOCAL_LEGACY_CONTAINER_ID,
      osRoute,
      libraryType: MicroAppLibraryType.Window,
      windowLibraryName,
      bundleUrl,
    }
  }, [bundleUrl, osRoute, windowLibraryName])

  return useMemo(() => {
    if (!app) {
      return null
    }

    return {
      type: AppDataFromUrlType.LocalLegacyApp,
      app,
      workspaceAzId,
    }
  }, [app, workspaceAzId])
}

interface LocalLegacyAppParts {
  osRoute: string
  bundleUrl: string
  windowLibraryName: string
}

const getLocalLegacyAppParts = (localContext: MayBeNull<LocalContext>): MayBeNull<LocalLegacyAppParts> => {
  if (!localContext) {
    return null
  }

  const { appRoute } = localContext
  const localAppMatch = matchPath(
    `/${LOCAL_LEGACY_APP_BASE_URL}/:port/:jsBundleName/:windowLibraryName/*`,
    `/${appRoute}`,
  )

  if (!localAppMatch) {
    return null
  }

  const { port, jsBundleName, windowLibraryName = '' } = localAppMatch.params

  const osRoute = `${LOCAL_LEGACY_APP_BASE_URL}/${port}/${jsBundleName}/${windowLibraryName}`
  const bundleUrl = `http://localhost:${port}/${jsBundleName}.js`

  return {
    osRoute,
    bundleUrl,
    windowLibraryName,
  }
}
