import { useEffect } from 'react'
import { useCss } from 'react-use'

export const useDomElementCss = (
  css: Parameters<typeof useCss>[0],
  element: HTMLElement = document.documentElement,
) => {
  const className = useCss(css)

  useEffect(() => {
    element.classList.add(className)

    return () => {
      element.classList.remove(className)
    }
  }, [className, element])
}
