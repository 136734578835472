export const blurActiveElement = (): void => (document.activeElement as any)?.blur?.()

export const hasClosestInteractiveElement = (node: Element, extensions?: string[]) =>
  ['a', 'button', '.wpp-button', '.wpp-action-button', ...(extensions || [])].some(selector =>
    closestElement(selector, node),
  )

export const scrollWindowToTheTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

// Same as element.closest but with respect to shadow dom.
export function closestElement(selector: string, base: Window | Document | Element) {
  const isWindow = (value: any): value is Window => value === window
  const isDocument = (value: any): value is Document => value === document

  function __closestFrom(el: Window | Document | Element): Element | null {
    if (!el || isDocument(el) || isWindow(el)) {
      return null
    }

    let found: Element | null = el.closest(selector)

    const getShadowRoot = (value: HTMLSlotElement | ParentNode): Element => (value.getRootNode() as ShadowRoot).host

    if (!found) {
      if (el.assignedSlot) {
        found = el.assignedSlot.closest(selector) || __closestFrom(getShadowRoot(el.assignedSlot))
      } else {
        if (el.parentElement) {
          found = __closestFrom(el.parentElement)
        } else if (el.parentNode) {
          found = __closestFrom(getShadowRoot(el.parentNode))
        }
      }
    }

    return found
  }

  return __closestFrom(base)
}
