import { detectIncognito } from 'detect-incognito'
import { useLocation } from 'react-router-dom'

import { excludeFalsy } from 'utils/common'

export const getShouldMonitorSession = async () => {
  try {
    const { browserName, isPrivate } = await detectIncognito()

    return browserName === 'Chrome' && !isPrivate
  } catch {
    return false
  }
}

export const getTargetUrl = (location: ReturnType<typeof useLocation>) =>
  [location.pathname, location.search, location.hash].filter(excludeFalsy).join('')
