import { WppMenuContext, WppListItem, WppMenuGroup, WppIconWarning } from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType } from '@wpp-open/core'
import clsx from 'clsx'
import { ParseKeys } from 'i18next'
import { useTranslation } from 'react-i18next'
import { matchPath, useLocation, Location, useNavigate } from 'react-router-dom'

import { Avatar } from 'components/common/avatar/Avatar'
import { ANALYTICS_EVENTS, LaunchLocations } from 'constants/analytics'
import { Permission } from 'constants/permission'
import { useHasPermission } from 'hooks/useHasPermission'
import { useUserCanManageApps } from 'hooks/useUserCanManageApps'
import { useUserCanManageHubs } from 'hooks/useUserCanManageHubs'
import styles from 'layout/header/userProfileIconMenu/UserProfileIconMenu.module.scss'
import { useAuth } from 'providers/auth/AuthContext'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { trackAnalytics, trackAppOpenAnalytics } from 'utils/analytics'
import { join } from 'utils/common'
import { routesManager } from 'utils/routesManager'

enum MenuItemId {
  WorkspaceAdmin = 'WorkspaceAdmin',
  LayerAdmin = 'LayerAdmin',
  HubAdmin = 'HubAdmin',
}

interface MenuItem {
  id?: MenuItemId
  link: string
  label: ParseKeys
  permission?: string
  analyticsPayload: string
  analyticsProductName: string
  show?: boolean
  disabled?: boolean
  isActive?: (location: Location) => boolean
}

const adminSection: MenuItem[] = [
  {
    id: MenuItemId.WorkspaceAdmin,
    link: routesManager.admin.settings.url(),
    label: 'os.header.user_avatar_dropdown_menu.os_settings',
    permission: Permission.OS_ADMIN_SETTING_ACCESS,
    analyticsPayload: ANALYTICS_EVENTS.ADMIN.PAGE.OS_SETTINGS,
    analyticsProductName: ANALYTICS_EVENTS.OS_MODULES.PRODUCT_NAME.ADMIN_SETTINGS,
    isActive: ({ pathname }) => pathname.startsWith(routesManager.admin.root.url()),
  },
  {
    id: MenuItemId.LayerAdmin,
    link: routesManager.layerAdmin.root.url(),
    label: 'os.header.user_avatar_dropdown_menu.layer_settings',
    permission: Permission.OS_APP_INSTANCE_ENTITIES_MANAGE,
    analyticsPayload: ANALYTICS_EVENTS.ADMIN.PAGE.LAYER_ADMIN,
    analyticsProductName: ANALYTICS_EVENTS.OS_MODULES.PRODUCT_NAME.LAYER_ADMIN,
    isActive: ({ pathname }) => pathname.startsWith(routesManager.layerAdmin.root.url()),
  },
  {
    id: MenuItemId.HubAdmin,
    link: routesManager.hubsAdmin.root.url(),
    label: 'os.header.user_avatar_dropdown_menu.hubs_settings',
    analyticsPayload: ANALYTICS_EVENTS.ADMIN.PAGE.HUBS_ADMIN,
    analyticsProductName: ANALYTICS_EVENTS.OS_MODULES.PRODUCT_NAME.HUBS_ADMIN,
    isActive: ({ pathname }) => pathname.startsWith(routesManager.hubsAdmin.root.url()),
  },
]

const youSection: MenuItem[] = [
  {
    link: routesManager.user.profile.url(),
    label: 'os.header.user_avatar_dropdown_menu.profile',
    permission: Permission.OS_USERDETAILS_APP_ACCESS,
    analyticsPayload: ANALYTICS_EVENTS.USER.PAGE.PROFILE,
    analyticsProductName: ANALYTICS_EVENTS.OS_MODULES.PRODUCT_NAME.PROFILE,
    isActive: ({ pathname }) => pathname.startsWith(routesManager.user.root.url()),
  },
]

export const UserProfileIconMenu = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { isUserCanManage: isUserCanManageHubs } = useUserCanManageHubs()
  const { isUserCanManage: isUserCanManageApps } = useUserCanManageApps()

  const { userDetails } = useOtherTenantsAndUserData()
  const { hasPermission } = useHasPermission()
  const { logout } = useAuth()
  const { t } = useTranslation()

  const { firstname, lastname, avatarUrl, countryAlpha2Code } = userDetails

  const adminSectionMenuItems = adminSection.reduce(
    (acc: MenuItem[], item) =>
      ((item.permission && hasPermission(item.permission)) ||
        (item.id === MenuItemId.LayerAdmin && isUserCanManageApps) ||
        (item.id === MenuItemId.HubAdmin && isUserCanManageHubs)) &&
      !acc.length
        ? [...acc, item]
        : [...acc],
    [],
  )
  const youSectionMenuItems = youSection.filter(({ permission }) => hasPermission(permission))

  const renderItems = (menuItems: MenuItem[]) =>
    menuItems.map(
      ({
        link,
        label,
        disabled = false,
        analyticsPayload,
        analyticsProductName,
        isActive = ({ pathname }) => !!matchPath(link, pathname),
      }) => {
        const translation = t(label) as string

        return (
          <WppListItem
            key={translation}
            data-testid={translation}
            disabled={disabled}
            className={clsx({
              [styles.active]: isActive(location),
            })}
            linkConfig={{
              href: link,
              onClick: e => {
                e.preventDefault()
                trackAnalytics({
                  type: AnalyticsActionType.page,
                  payload: analyticsPayload,
                })
                trackAppOpenAnalytics({
                  productName: analyticsProductName,
                  productType: ANALYTICS_EVENTS.OS_MODULES.PRODUCT_TYPE,
                  userDetails,
                  launchedFrom: LaunchLocations.NavigationMenu,
                })
                navigate(link)
              },
            }}
          >
            <span slot="label" className={styles.listItemLabel}>
              {translation}
            </span>
            {!countryAlpha2Code && link === routesManager.user.profile.url() && (
              <WppIconWarning
                width={18}
                slot="right"
                data-testid="user-menu-profile-warning"
                className={styles.warningIconColor}
              />
            )}
          </WppListItem>
        )
      },
    )

  const menuUserName = join([firstname, lastname?.[0]], ' ')
  const adminItems = renderItems(adminSectionMenuItems)

  return (
    <WppMenuContext listWidth="200px" data-testid="user-menu">
      <div slot="trigger-element" className={styles.avatarContainer}>
        <Avatar name={firstname} src={avatarUrl || undefined} size="s" className={styles.avatar} />
        {!countryAlpha2Code && (
          <WppIconWarning width={18} className={styles.warningIcon} data-testid="user-avatar-warning" />
        )}
      </div>
      <div>
        {!!adminItems.length && (
          <WppMenuGroup
            className={styles.menuGroup}
            header={t('os.header.user_avatar_dropdown_menu.admin_section_header')}
          >
            {adminItems}
          </WppMenuGroup>
        )}
      </div>
      <WppMenuGroup className={styles.menuGroup} withDivider header={menuUserName}>
        {renderItems(youSectionMenuItems)}
      </WppMenuGroup>

      <WppListItem
        onWppChangeListItem={() => {
          logout()
        }}
      >
        <span slot="label">{t('os.header.user_avatar_dropdown_menu.log_out')}</span>
      </WppListItem>
    </WppMenuContext>
  )
}
