// import { jwtDecode } from 'jwt-decode'
import { useEffect, PropsWithChildren, useState } from 'react'

import { workfrontApi } from 'api'
import { environment } from 'environment'
import { useStableCallback } from 'hooks/useStableCallback'
import { useAuth } from 'providers/auth/AuthContext'

const apiInstances = [workfrontApi]

export const WorkfrontApiProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isReady, setIsReady] = useState(false)
  const { jwt } = useAuth()

  const getJwt = useStableCallback(() => jwt)
  // TODO Playground local hardcode jwt from stage
  // const getJwt = useStableCallback(
  //   () =>
  //     'stage token',
  // )

  //   const tenantId = '40c0ccbc-e69f-461d-93cf-cf5e08cf9e3c'

  useEffect(() => {
    const interceptors = apiInstances.map(instance => {
      const interceptorToEject = instance.client.interceptors.request.use(
        instanceConfig => {
          const token = getJwt()

          if (token) {
            instanceConfig!.headers!.Authorization = `Bearer ${token}`
          }
          //   if (jwt) {
          //     instanceConfig!.headers!.Authorization = `Bearer ${jwt}`
          //   }
          instanceConfig!.headers!['X-Tenant-Id'] = environment.PLAYGROUND_TENANT_ID
          // (instanceConfig!.headers!['Client'] = 'playground-dev-fe')

          //   if (tenantId) {
          //     instanceConfig!.headers!['X-Tenant-Id'] = tenantId
          //   }

          return instanceConfig
        },
        error => Promise.reject(error),
      )
      instance.client.interceptors.response.use(
        response => response,
        error => {
          return Promise.reject(error)
        },
      )
      return interceptorToEject
    })

    setIsReady(true)

    return () => {
      interceptors.forEach((interceptor, index) => {
        apiInstances[index].client.interceptors.request.eject(interceptor)
      })
    }
    // eslint-disable-next-line
  }, [getJwt])

  if (!isReady) {
    return null // TODO: Add loading spinner
  }
  return <>{isReady && children}</>
}
