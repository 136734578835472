import { createUseQuery } from 'api/common/createUseQuery'
import { fetchProjectsCount } from 'api/workfront/fetchers/matchAndThrive/fetchProjectsCount'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export interface ProjectsDtoApi {
  count: number
  total: number
}

export const useFetchProjectsCount = createUseQuery({
  queryKey: ApiQueryKeys.MT_PROJECTS_COUNT,
  fetcher: fetchProjectsCount,
  selector: res => res?.data ?? ({} as ProjectsDtoApi),
})
