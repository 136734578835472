export interface MiroConfig {
  clientId: string
  clientSecret: string
  teamId: string
}

export enum MiroConfigStatus {
  NotSet = 'NOT_SET',
  Valid = 'VALID',
}

export interface MiroConfigStatusResponse {
  status: MiroConfigStatus
}
