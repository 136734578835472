import { TypographyType } from '@platform-ui-kit/components-library/dist/types/components/wpp-typography/types'
import { WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { format } from 'date-fns'
import { ComponentPropsWithoutRef, useMemo } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/admin/notifications/currentTimeZoneLabel/CurrentTimeZoneLabel.module.scss'
import { getCurrentTimeZoneLabel } from 'utils/date'

type Props = ComponentPropsWithoutRef<typeof Flex> & {
  labelType?: TypographyType
  offsetType?: TypographyType
}

export const CurrentTimeZoneLabel = ({
  labelType = 'xs-strong',
  offsetType = 'xs-body',
  className,
  ...rest
}: Props) => {
  const { label, offset } = useMemo(
    () => ({
      label: getCurrentTimeZoneLabel(),
      offset: format(new Date(), 'OO'),
    }),
    [],
  )

  return (
    <Flex align="center" {...rest} className={clsx(styles.root, className)} data-testid="timezone-label">
      <WppTypography type={offsetType}>({offset})</WppTypography>
      &nbsp;
      <WppTypography type={labelType}>{label}</WppTypography>
    </Flex>
  )
}
