import { AnalyticsActionType } from '@wpp-open/core'
import clsx from 'clsx'
import { Link } from 'react-router-dom'

import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { environment } from 'environment'
import { ReactComponent as IconArrow } from 'pages/playground/playgroundDashboard/assets/icons/arrow-right.svg'
import { ReactComponent as IconSuccess } from 'pages/playground/playgroundDashboard/assets/icons/success.svg'
import { Button } from 'pages/playground/playgroundDashboard/components/general/button/Button'
import styles from 'pages/playground/playgroundDashboard/components/sections/sectionProjectSummary/card/Card.module.scss'
import {
  ProjectSummaryTask,
  ProjectSummaryDto,
  ProjectSummaryRisk,
} from 'pages/playground/playgroundDashboard/components/sections/sectionProjectSummary/sectionProjectSummaryMockData'
import { EVENTS } from 'pages/playground/playgroundDashboard/constants/events'
import stylesMain from 'pages/playground/playgroundDashboard/PlaygroundDashboard.module.scss'
import { trackAnalytics } from 'utils/analytics'

interface Props {
  type: 'late' | 'atRisk'
  projectSummary: ProjectSummaryDto
  task: ProjectSummaryTask | ProjectSummaryRisk
}

export const SectionProjectSummaryCard = ({ type, task, projectSummary }: Props) => {
  return (
    <Flex className={styles[type]} justify="between" align="center" gap={34}>
      <Flex className={styles.w80}>
        <div className={styles.nameContainer}>
          <div className={stylesMain.blackLStrong}>{projectSummary.name}</div>
          <Link
            to={
              type === 'late'
                ? `${environment.WORKFRONT_URL}?location=/task/${task.ID}/overview`
                : `${environment.WORKFRONT_URL}?location=/issue/${task.ID}/overview`
            }
          >
            <div className={clsx(stylesMain.blackUnderline, stylesMain.mt12)}>{task.name}</div>
          </Link>
        </div>

        <Flex className={styles.infoContainer} gap={16} direction="column" justify="center">
          {task.assignedTo?.name && (
            <div>
              <Avatar size="s" name={task.assignedTo.name} />
            </div>
          )}
          <div className={styles.assignedTitle}>ASSIGNED TO</div>
          <div className={styles.nameTitle}>{task.assignedTo?.name ? task.assignedTo.name : 'Not assigned'}</div>
        </Flex>

        {type === 'atRisk' && (
          <Flex align="center" justify="center" className={styles.separatorContainer}>
            <div className={styles.separator} />
          </Flex>
        )}
        {type === 'atRisk' && (
          <Flex align="center" justify="center" gap={16} className={styles.mitigationContainer}>
            {(task as ProjectSummaryRisk).plannedMitigation && (
              <Flex align="center" justify="center">
                <IconSuccess />
              </Flex>
            )}
            <div className={stylesMain.blackSStrong}>
              {(task as ProjectSummaryRisk).plannedMitigation ? 'HAS MITIGATION PLAN' : 'NO MITIGATION PLAN'}
            </div>
          </Flex>
        )}
      </Flex>

      <div className={styles.buttonContainer}>
        <Link
          to={
            type === 'late'
              ? `${environment.WORKFRONT_URL}?location=/task/${task.ID}/updates`
              : `${environment.WORKFRONT_URL}?location=/issue/${task.ID}`
          }
          onClick={() =>
            type === 'late'
              ? trackAnalytics({
                  type: AnalyticsActionType.action,
                  payload: EVENTS.PLAYGROUND_NOTIFY_PERSON,
                })
              : trackAnalytics({
                  type: AnalyticsActionType.action,
                  payload: EVENTS.PLAYGROUND_VIEW_RISK,
                })
          }
        >
          <Button variant="tertiary" text={type === 'late' ? 'View Task' : 'View Risk'} iconRight={<IconArrow />} />
        </Link>
      </div>
    </Flex>
  )
}
