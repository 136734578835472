import { useEffect } from 'react'
import { useCss } from 'react-use'

export const HideHeader = () => {
  const className = useCss({
    // When header is hidden. `:first-child` is needed to increase specificity.
    '&:first-child': {
      '--wpp-os-header-height': '0vh',
    },
  })

  useEffect(() => {
    document.documentElement.classList.add(className)

    return () => {
      document.documentElement.classList.remove(className)
    }
  }, [className])

  return null
}
