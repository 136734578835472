import { FullscreenAppCustomProps } from '@wpp-open/core'
import { useEffect } from 'react'

import { environment } from 'environment'
import { useAppsContext } from 'providers/apps/AppsContext'
import { MicroAppLibraryType } from 'types/apps/microApps'
import { MicroAppConfig } from 'types/common/singleSpa'
import { registerMicroApp, unregisterMicroApp } from 'utils/singleSpa'

export const OS_ASSISTANT_APP_CODE = 'OS-ASSISTANT'
const APP_DOM_ELEMENT_ID = 'chat-assistant'

const getMicroAppConfig = (customProps: FullscreenAppCustomProps): MicroAppConfig => ({
  stableId: OS_ASSISTANT_APP_CODE,
  libraryType: MicroAppLibraryType.SystemJS,
  windowLibraryName: null,
  bundleUrl: environment.AI_ASSISTANT_BUNDLE_URL,
  domElementId: APP_DOM_ELEMENT_ID,
  activeWhen: () => true,
  customProps,
})

export const OsAssistant = () => {
  const { getFullscreenAppCustomProps } = useAppsContext()

  useEffect(() => {
    const customProps: FullscreenAppCustomProps = {
      ...getFullscreenAppCustomProps({ stableId: OS_ASSISTANT_APP_CODE }),
      domElementGetter: () => document.getElementById(APP_DOM_ELEMENT_ID)!,
    }

    registerMicroApp(getMicroAppConfig(customProps))

    return () => {
      unregisterMicroApp({ stableId: OS_ASSISTANT_APP_CODE })
    }
  }, [getFullscreenAppCustomProps])

  return <div id={APP_DOM_ELEMENT_ID} />
}
