export enum Requests {
  TENANT_ACCESS = 'os_access',
  HIERARCHY_ACCESS = 'hierarchy_access',
  HUB_ACCESS = 'hub_access',
  SIGN_UP = 'sign_up',
}

export enum CompletedRequestStatus {
  APPROVED = 'approved',
  REJECTED = 'rejected',
}
