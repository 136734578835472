import { WppActionButton, WppIconChevron } from '@platform-ui-kit/components-library-react'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { BreadcrumbsContainer } from 'layout/header/breadcrumbs/common/container/BreadcrumbsContainer'
import { BreadcrumbItem } from 'layout/header/breadcrumbs/common/item/BreadcrumbItem'
import { ProjectBreadcrumbsMenuContext } from 'layout/header/breadcrumbs/project/menuContext/ProjectBreadcrumbsMenuContext'
import { ProjectLinearBreadcrumbsAppsColumn } from 'layout/header/breadcrumbs/project/projectLinear/appsColumn/ProjectLinearBreadcrumbsAppsColumn'
import { ProjectLinearBreadcrumbsFullMenu } from 'layout/header/breadcrumbs/project/projectLinear/fullMenu/ProjectLinearBreadcrumbsFullMenu'
import styles from 'layout/header/breadcrumbs/project/projectLinear/ProjectLinearBreadcrumbs.module.scss'
import { useOsState } from 'providers/osState/OsStateProvider'
import { BreadcrumbItemProject } from 'types/osState/breadcrumbs'
import { ProjectCanvasLinearShort } from 'types/projects/projectNavigation'

interface Props {
  items: BreadcrumbItemProject[]
}

export const ProjectLinearBreadcrumbs = ({ items }: Props) => {
  const navigate = useNavigate()
  const { appData } = useOsState()

  const { projectCanvas, projectPhase } = appData
  const initialPhase = projectPhase!
  const collapsibleItems = useMemo(() => items.slice(0, -1), [items])
  const lastItem = items.at(-1)

  const [selectedPhase, setSelectedPhase] = useState(initialPhase)

  const linearCanvas = projectCanvas as ProjectCanvasLinearShort
  const hasSinglePhase = linearCanvas.phases.length === 1

  useEffect(() => {
    setSelectedPhase(initialPhase)
  }, [initialPhase])

  const handleItemClick = ({ link }: BreadcrumbItemProject) => {
    if (link) {
      navigate(link)
    }
  }

  return (
    <BreadcrumbsContainer items={collapsibleItems} onItemClick={handleItemClick}>
      {!!lastItem && (
        <ProjectBreadcrumbsMenuContext
          className={styles.projectMenuWrapper}
          externalClass={styles.projectMenu}
          listWidth={hasSinglePhase ? '336px' : '600px'}
          dropdownConfig={{
            offset: [hasSinglePhase ? -26 : -290, 0], // Align with apps column
            maxWidth: 'initial',
            onHidden: () => {
              setSelectedPhase(initialPhase)
            },
          }}
          trigger={
            <BreadcrumbItem slot="trigger-element" label={lastItem.label}>
              <WppActionButton variant="secondary">
                <WppIconChevron slot="icon-start" direction="down" />
              </WppActionButton>
            </BreadcrumbItem>
          }
          menu={
            hasSinglePhase ? (
              <ProjectLinearBreadcrumbsAppsColumn selectedPhase={selectedPhase} />
            ) : (
              <ProjectLinearBreadcrumbsFullMenu
                linearCanvas={linearCanvas}
                selectedPhase={selectedPhase}
                setSelectedPhase={setSelectedPhase}
              />
            )
          }
        />
      )}
    </BreadcrumbsContainer>
  )
}
