import { AccountPermissions } from 'legacy/types/osWebRootApi'
import { LegacyState } from 'legacy/utils/state'

export const hasPermission = (
  permissions: AccountPermissions[],
  permission: string,
  accountId: string | undefined = LegacyState.currentOrganisationId,
): boolean => {
  const foundPermission = permissions?.find(({ account_id }) => account_id === accountId)

  return foundPermission?.permissions?.includes?.(permission) ?? false
}
