interface LoopModalDataInterface {
  [index: number]: {
    [key: string]: {
      title: string
      subtitle: string
      bubbleText: string
      backgroundColor: string
      titleColor: string
      nextPhase: string
      timeline: {
        [key: string]: {
          title: string
        }
      }
      dropdown: {
        [key: string]: {
          title: string
          text: string
          table?: {
            [key: string]: {
              title: string
              [key: string]: string | { [key: string]: string }
            }
          }
          margin?: {
            title: string
            value: string
          }
        }
      }
    }
  }
}

export const LoopModalData: LoopModalDataInterface = [
  {
    prioritize: {
      title: 'Prioritize',
      subtitle:
        'We’re brimming, overflowing, perhaps even teeming with amazing ideas and experiences. Prioritization will drive our goals forward based on business strategy and key metrics.',
      bubbleText: 'The (not so secret) secret ingredient',
      backgroundColor: 'rgba(152, 216, 0, 1)',
      titleColor: 'rgba(0, 83, 28, 1)',
      nextPhase: 'Assemble',
      timeline: {
        faze_1: {
          title: 'Unified Marketing & Innovation Project Request',
        },
        faze_2: {
          title: 'Project Prioritization & Approval',
        },
      },
      dropdown: {
        section_1: {
          title: 'Unified Marketing & Innovation Project Request',
          text: 'To see if a project will get the green light fill out all the need-to-know info.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'All Team Members',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'In Workfront, complete the required fields for the Unified Marketing & Innovation Project Request form.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'A finalized Unified Marketing & Innovation Project Request form submitted in Workfront',
            },
          },
          margin: {
            title: 'marginBottom',
            value: '113px',
          },
        },
        section_2: {
          title: 'Project Prioritization & Approval',
          text: 'Prioritization sessions are the project’s big audition. If it has the right stuff, it will move forward to the Assemble phase of the Experience Loop.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Project Lead',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Work with Category/OU PMO to attend a prioritization session.',
              row2: 'Project is reviewed based on key metrics, business impact, and effort level.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Project approved by the Category/OU network committee.',
              row2: 'Once prioritized, the project automatically turns into an active project on Workfront.',
            },
          },
          margin: {
            title: 'marginBottom',
            value: '79px',
          },
        },
      },
    },
    assemble: {
      title: 'Assemble',
      subtitle:
        'Can you feel the excitement? It’s all coming together as you establish the project, assemble and onboard team members and explore past learnings.',
      bubbleText: 'Associates! Assemble!',
      backgroundColor: 'rgba(205, 237, 166, 1)',
      titleColor: 'rgba(0, 140, 19, 1)',
      nextPhase: 'Explore',
      timeline: {
        faze_1: {
          title: 'Establish the<br>Project',
        },
        faze_2: {
          title: 'Assemble the<br>Team',
        },
        faze_3: {
          title: 'Onboard the<br>Team',
        },
        faze_4: {
          title: 'Explore Past<br>Learnings',
        },
      },
      dropdown: {
        section_1: {
          title: 'Establish the Project',
          text: 'Sit down at the drafting table, it’s time to create the Project Blueprint and upload it into the Playground.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Project Lead',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Consult with Sponsor to build the Project Blueprint.',
              row2: 'Map timelines and deliverables to align with stakeholders.',
              row3: 'Assemble the right team and be ready for kickoff.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Project Blueprint',
            },
          },
          margin: {
            title: 'marginBottom',
            value: '79px',
          },
        },
        section_2: {
          title: 'Assemble the Team',
          text: 'Bring the best people for the project together to make it happen.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Project Lead',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'For TCCC staffing, reach out to the PMO and/or consider Match & Thrive dynamic resourcing to optimize the talent assigned.',
              row2: 'Work with Open X PMO for assigning the Open X team members.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: '<b>Decision Moment:</b> Project Team Assignments',
              row2: 'POs established through MAP',
            },
          },
          margin: {
            title: 'marginBottom',
            value: '79px',
          },
        },
        section_3: {
          title: 'Onboard the Team',
          text: 'Let’s start off on the same page by giving everyone a great onboarding and access to platforms.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Project Lead',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Team member roles',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Team member roles confirmed, and responsibilities defined.',
              row2: '“Fall in Love with Your Team” exercise completed.',
            },
          },
          margin: {
            title: 'marginBottom',
            value: '152px',
          },
        },
        section_4: {
          title: 'Explore Past Learnings',
          text: 'Work smarter, not harder. Can you use any past learnings on similar projects?',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Project Lead',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Look internally and through your agency to see if this problem has been solved previously.',
              row2: 'Review past learnings.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: '<b>Decision Moment:</b> Finalized Project Blueprint',
            },
          },
          margin: {
            title: 'marginBottom',
            value: '79px',
          },
        },
      },
    },
    explore: {
      title: 'Explore',
      subtitle:
        'Explore the challenge! Get answers to the important questions so when it’s time to create, you’re boundless.',
      bubbleText: 'Get the likes & wonders rolling',
      backgroundColor: 'rgba(254, 222, 168, 1)',
      titleColor: 'rgba(252, 77, 15, 1)',
      nextPhase: 'Create',
      timeline: {
        faze_1: {
          title: 'Marketing<br>Opportunity Brief',
        },
        faze_2: {
          title: 'Insights',
        },
        faze_3: {
          title: 'Experience<br>Architecture',
        },
        faze_4: {
          title: 'Idea Brief',
        },
      },
      dropdown: {
        section_1: {
          title: 'Marketing Opportunity Brief',
          text: 'Grab your binoculars and explore the problem. Ask the questions that uncover the opportunity.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Project Lead',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Define why you are creating this brief.',
              row2: 'Answer key questions such as “What is the opportunity and/or problem?”, “Who are we talking to?”, “How will we measure success?”, etc.',
              row3: 'Embed the test and learn agenda in your brief.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: '<b>Decision Moment:</b> Marketing Opportunity brief including OKRs and key metrics.',
            },
          },
          margin: {
            title: 'marginBottom',
            value: '50px',
          },
        },
        section_2: {
          title: 'Insights',
          text: 'Get to know your consumer. Their likes, dislikes, wants and needs. Learn what they’re looking for.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Human Insights Lead',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Discover Weekly+ consumers and intenders.',
              row2: 'Determine brand equity opportunity and tension to overcome.',
              row3: 'Define touchpoints, channels, and partners measurement.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Lit Insights',
            },
          },
          margin: {
            title: 'marginBottom',
            value: '50px',
          },
        },
        section_3: {
          title: 'Experience Architecture',
          text: 'Draft a one-page strategy. It may be brief, but it will make a big impact.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'E2E Lead',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Write the Get/To/Buy.',
              row2: 'Define the KPIs, measurement, and key touchpoints.',
              row3: 'Finalize the learning agenda based on the Experience Architecture, connect with the E@S team if needed.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Experience Architecture',
            },
          },
          margin: {
            title: 'marginBottom',
            value: '30px',
          },
        },
        section_4: {
          title: 'Idea Brief',
          text: 'Build a one-page brief to launch the Create Phase. Keep it short and sweet.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Creative Lead',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Within the brief identify:',
              subrow: {
                row1: 'Problem',
                row2: 'Consumer',
                row3: 'Insight',
                row4: 'Expected outcomes',
              },
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: '<b>Decision Moment:</b> Idea Brief',
            },
          },
          margin: {
            title: 'marginBottom',
            value: '50px',
          },
        },
      },
    },
    create: {
      title: 'Create',
      subtitle:
        'Big thinking, brave dreaming and boundary pushing concepts come alive as we co-create and design the Experience Idea and validate its potential.',
      bubbleText: 'Grab your sunglasses, the thinking is that bright',
      backgroundColor: 'rgba(248, 229, 163, 1)',
      titleColor: 'rgba(128, 86, 1, 1)',
      nextPhase: 'Shape',
      timeline: {
        faze_1: {
          title: 'Brave and Boundless<br>Experience Idea Development',
        },
        faze_2: {
          title: 'Experience Testing',
        },
        faze_3: {
          title: 'Deliverables &<br>Timeline',
        },
      },
      dropdown: {
        section_1: {
          title: 'Brave and Boundless Experience Idea Development',
          text: 'Think. Create. Pitch. Test.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Creative Lead',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Develop compelling concepts and PoCs, using co-creation sessions, inviting Open-Source talent as needed.',
              row2: 'Feasibility check with IMX Capability Leads for BBEI expansion on each capability.',
              row3: 'Select ideas to go into early testing.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: '<b>Decision Moment:</b> Brave and Boundless Experience Idea and Experience Architecture.',
            },
          },
          margin: {
            title: 'marginBottom',
            value: '50px',
          },
        },
        section_2: {
          title: 'Experience Testing',
          text: 'If your concepts ace their test, it’s onward and upward!',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Human Insights Lead',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Prepare testing stimuli, including shopper for OU fast tracking purposes (if needed).',
              row2: 'Check your stimulus with the Claims and Communications Approval Process (CCAP) team.',
              row3: 'Validate the Experience Idea.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'A validated BBEI Experience Idea.',
              row2: 'Initial shopper strategy',
            },
          },
          margin: {
            title: 'marginBottom',
            value: '80px',
          },
        },
        section_3: {
          title: 'Deliverables & Timeline',
          text: 'Details matter, like how your experience will thrive in different markets.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Project Lead',
              row2: 'Creative Lead',
              row3: 'E2E Lead',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Draft high level experience plan detailing how the experience will be implemented in market(s) in partnership with OUs/markets.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'High level network deliverables and timeline',
            },
          },
          margin: {
            title: 'marginBottom',
            value: '120px',
          },
        },
      },
    },
    shape: {
      title: 'Shape',
      subtitle: 'Co-creation time! We’ve got the best partners to help shape amazing ideas to fit any market.',
      bubbleText: 'Together is always better',
      backgroundColor: 'rgba(255, 218, 211, 1)',
      titleColor: 'rgba(244, 0, 0, 1)',
      nextPhase: 'Produce',
      timeline: {
        faze_1: {
          title: 'OU CO-<br>Creation',
        },
        faze_2: {
          title: 'OU Delivery<br>Plan',
        },
        faze_3: {
          title: 'BBEI Execution<br>Plan',
        },
        faze_4: {
          title: 'Production<br>List',
        },
        faze_5: {
          title: 'Implement Experience<br>Performance Tracking<br>& Pre-Flight Test',
        },
      },
      dropdown: {
        section_1: {
          title: 'OU CO-Creation',
          text: 'Collaborate, co-create, and localize so the experience can thrive in any market.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Project Lead',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Charter team to collaborate with OU/market to co-create and localize the plan with the Studio X team.',
              row2: 'Feasibility check with OU CCAP to ensure no significant regional or local risk.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Localization of the co-creation plan',
            },
          },
          margin: {
            title: 'marginBottom',
            value: '115px',
          },
        },
        section_2: {
          title: 'OU Delivery Plan',
          text: 'Ready to deliver? Align your plan with specific needs and prep for customer sell-in.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'OU/Market Project Lead',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Localize the BBEI into an OU/market delivery plan.',
              row2: 'Prepare customer sell-in plan.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: '<b>Decision Moment:</b> OU/market delivery plan',
              row2: 'Customer sell-in toolkits',
            },
          },
          margin: {
            title: 'marginBottom',
            value: '166px',
          },
        },
        section_3: {
          title: 'BBEI Execution Plan',
          text: 'Plan for a great time! This task is full of planning for each part of the experience before, during and after.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'OU/Market Project Lead',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Develop a full execution plan of the BBEI experience.',
              row2: 'Capability leads to develop experience executions for each touchpoint.',
              row3: 'Connect with Experimentation @ Scale team to define the best methodology to learn per touchpoint.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: '<b>Decision Moment:</b> Completed BBEI execution plan',
            },
          },
          margin: {
            title: 'marginBottom',
            value: '32px',
          },
        },
        section_4: {
          title: 'Production List',
          text: 'It’s getting real. Production takes center stage to get all the parts moving in the right direction.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Project Lead',
              row2: 'OU/Market Project Lead',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Network team to align with Studio X on how to divide and conquer on the content delivery plan.',
              row2: 'Agree on the assets required to deliver on matrix plan, which includes network toolkits and local complimentary toolkits.',
              row3: 'Align on production budget, requirements and timeline.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Production list of all assets required and budget approval',
            },
          },
          margin: {
            title: 'marginBottom',
            value: '32px',
          },
        },
        section_5: {
          title: 'Implement Experience Performance Tracking & Pre-Flight Test',
          text: 'We’ll get everything set for experience performance tracking and get early insights.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Project Lead',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Implement final Experience Performance tracking design.',
              row2: 'Work with MPS and Studio X to identify considerations or nuance with tracking at an OU level.',
              row3: 'Leverage Experimentation @ Scale approach to conduct pre-flight test to get early insight.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Finalization of tracking design',
            },
          },
          margin: {
            title: 'marginBottom',
            value: '40px',
          },
        },
      },
    },
    produce: {
      title: 'Produce',
      subtitle:
        'Let’s build, build, build. Visuals, video, translations, and anything else we need to make this experience a success, everywhere.',
      bubbleText: 'The makers are busy making',
      backgroundColor: 'rgba(255, 177, 194, 1)',
      titleColor: 'rgba(203, 72, 101, 1)',
      nextPhase: 'Learn',
      timeline: {
        faze_1: {
          title: 'Production<br>Alignment',
        },
        faze_2: {
          title: 'Experience Toolkit<br>Production',
        },
        faze_3: {
          title: 'Localization &<br>Clearance',
        },
        faze_4: {
          title: 'Asset Upload &<br>Media Activation',
        },
      },
      dropdown: {
        section_1: {
          title: 'Production Alignment',
          text: 'Efficiency is the name of the game. Co-create and get clearances as needed.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Project Lead',
              row2: 'Capability Lead(s)',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Network team (squad) and Studio X to align to maximize efficiencies with co-creation.',
              row2: 'Get Claims and Communications Approval Process (CCAP) clearance with local markets’ regulations.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Aligned production schedule',
            },
          },
          margin: {
            title: 'marginBottom',
            value: '120px',
          },
        },
        section_2: {
          title: 'Experience Toolkit Production',
          text: 'Build out the central assets so the toolkit is fully supported.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Project Lead',
              row2: 'Capability Lead(s)',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Network team develops and produces central assets.',
              row2: 'Network team co-creates with Studio X OU team to deliver OU/regional experience toolkits based on matrix plans and local requirements.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Experience Toolkit',
              row2: '<b>Decision Moment:</b> Finalized OU/market activation plan',
            },
          },
          margin: {
            title: 'marginBottom',
            value: '166px',
          },
        },
        section_3: {
          title: 'Localization & Clearance',
          text: 'Studio X customizes and tailors until it’s the perfect fit, wherever the experience lives.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'OU Project Lead',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Studio X develops market specific executions, assets, translations, transcreation, and adaptation of charter toolkits.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Experience Toolkit',
              row2: '<b>Decision Moment:</b> Finalized network assets',
            },
          },
          margin: {
            title: 'marginBottom',
            value: '170px',
          },
        },
        section_4: {
          title: 'Asset Upload & Media Activation',
          text: 'Upload to KO Assets with the correct project name before the project goes live in market.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'OU Production Lead',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Upload assets to KO assets with the right project naming',
              row2: 'Project goes live in market',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'All assets uploaded to KO assets',
              row2: 'Project goes live',
            },
          },
          margin: {
            title: 'marginBottom',
            value: '170px',
          },
        },
      },
    },
    learn: {
      title: 'Learn',
      subtitle:
        'Here’s where we monitor and optimize in-flight, so we have the best learnings to take into the next great experience.',
      bubbleText: 'Study up on greatness',
      backgroundColor: '#FF8AA6',
      titleColor: 'rgba(203, 72, 101, 1)',
      nextPhase: '',
      timeline: {
        faze_1: {
          title: 'In-Flight Optimization',
        },
        faze_2: {
          title: 'After Action Review',
        },
        faze_3: {
          title: 'Project Offboarding',
        },
      },
      dropdown: {
        section_1: {
          title: 'In-Flight Optimization',
          text: 'Assemble your in-flight optimization team so you can respond quickly and gracefully.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'OU Production Lead',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Assemble an optimization team for in flight optimization with Studio X.',
              row2: 'Align with the different capabilities on the actions needed based on performance.',
              row3: 'Conduct regular reviews to look at applying and integrating learnings aimed at sharing early learnings and insights across the OU/markets.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Campaign performance results',
            },
          },
          margin: {
            title: 'marginBottom',
            value: '32px',
          },
        },
        section_2: {
          title: 'After Action Review',
          text: 'Write the epilogue! How did it all turn out? What can be learned, scaled and reused?',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Project Lead',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Prepare a consolidated after-action review- a consistent narrative of key performance.',
              row2: 'Call out key scalable and repeatable learnings.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: '<b>Decision Moment:</b> After Action Review',
            },
          },
          margin: {
            title: 'marginBottom',
            value: '166px',
          },
        },
        section_3: {
          title: 'Project Offboarding',
          text: 'Woohoo! What a trip. Let’s bask in the memories and talk about how it all went with the core team.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Project Lead',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Team reflection, project learnings, and celebration!',
              row2: 'Project Lead to conduct offboarding session to reflect and gather final learnings with the core team.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Complete the closing module template on Mural.',
              row2: 'Close out the project.',
            },
          },
          margin: {
            title: 'marginBottom',
            value: '166px',
          },
        },
      },
    },
  },
]

export const InnovationModalData: LoopModalDataInterface = [
  {
    idea: {
      title: 'Idea',
      subtitle:
        'To go from strategy to idea, you need a cross-functional team to ideate, evaluate and select innovation concepts that hit the brief out of the park.',
      bubbleText: 'Set the stage',
      backgroundColor: '#F4D9FF',
      titleColor: '#AE46D5',
      nextPhase: 'Concept',
      timeline: {
        faze_1: {
          title: 'Complete Consumer Desirability',
        },
        faze_2: {
          title: 'Complete Financial Viability',
        },
        faze_3: {
          title: 'Complete Technical Feasibility',
        },
        faze_4: {
          title: 'Pipeline Prioritization & Approval',
        },
      },
      dropdown: {
        section_1: {
          title: 'Complete Consumer Desirability',
          text: "Put yourself in the consumer’s place. Is this innovation they didn't even know they needed? The answer must be yes to have a winning idea.",
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Human Insights',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'A list of at least ideas to test through.',
              row2: 'Idea description and a representative image for each idea.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Proof of consumer desirability for a winning list of ideas ready for Financial Viability and Technical Feasibility assessments',
            },
          },
        },
        section_2: {
          title: 'Complete Financial Viability',
          text: 'Do the numbers add up? Assess the attractiveness of the idea compared with the technical effort required to bring it to life to determine its Financial Viability.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Finance',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Clear understanding of definition from each idea.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'One number score to assess the overall attractiveness of an idea to be compared with the technical effort required to achieve value.',
            },
          },
        },
        section_3: {
          title: 'Complete Technical Feasibility',
          text: 'Technical feasibility defines any obstacles and their complexity on the path to commercializing the innovation.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Research & Development',
              row2: 'Technical Consumer Research',
              row3: 'Technical Commercialization/Operations',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Clear understanding of each idea to assess the idea.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Completed T-print assessment',
              row2: 'Confirmed T-print Innovation classification',
            },
          },
        },
        section_4: {
          title: 'Pipeline Prioritization & Approval',
          text: 'Before resourcing, projects must be approved by the global innovation teams in marketing and Global Development and Innovation. They make sure the project meets important criteria and opportunities.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Innovation PMO',
              row2: 'Decision makers may vary by category and OU',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Data completed in Workfront to      complete gate pre-read',
              row2: 'Present project -recommended decisions, plan and resources required for next stage',
              row3: 'Agree and record Path forward (next gate date)',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Aligned list of projects to move into resourcing',
              row2: 'Reflect decision and comments in Workfront',
            },
          },
        },
      },
    },
    concept: {
      title: 'Concept',
      subtitle:
        'Here you’ll bring concepts to life and pick the one that passes every test. Get your concept up and running with a business case, project team and launch date.',
      bubbleText: 'Let’s make it IRL',
      backgroundColor: '#FFB1C2',
      titleColor: '#B32347',
      nextPhase: 'Solution',
      timeline: {
        faze_1: {
          title: 'Complete Technical<br>Briefs',
        },
        faze_2: {
          title: 'Create Preliminary<br>Business Case',
        },
        faze_3: {
          title: 'Complete Technical<br>Feasibility Risk<br>Assessment',
        },
        faze_4: {
          title: 'Decision to Develop<br>Solutions',
        },
      },
      dropdown: {
        section_1: {
          title: 'Complete Technical Briefs',
          text: 'Complete the technical brief by defining what the innovation tastes and looks like, how it will be packaged to attract attention, and more.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Initiative Lead',
              row2: 'Technical Commercialization/Operation',
              row3: 'Global Development and Innovation',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Complete the Technical Brief.',
              row2: 'Share with TI&SC for alignment.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Aligned on technical brief with TI&SC',
            },
          },
        },
        section_2: {
          title: 'Create Preliminary Business Case',
          text: 'Give your innovation the best chance to make it through by building a case that leverages preliminary Cost of Goods Sold and assumptions on availability, velocity and cannibalization.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Finance',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Confirm capabilities with lead markets and bottlers.',
              row2: 'Define development costs in Workfront.',
              row3: 'Define preliminary TCCS Value Chain for Lead Market and Scale.',
              row4: 'Confirm Value Chain meets thresholds.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Preliminary estimates on project’s financial value.',
            },
          },
        },
        section_3: {
          title: 'Complete Technical Feasibility Risk Assessment',
          text: 'Every great leap forward has a measure of risk. It’s time to determine yours. Assess technical risks based on innovation classification and define the probability and impact given the agreed requirements from the Technical Brief.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'TI&SC',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Completed brief documented definition of done',
              row2: 'Understanding key risks and creating a plan to address them.',
              row3: 'Cross functional team engaged to effectively assess risk',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Technical / Feasibility risk probability and impact',
              row2: 'Readiness to move to Stage 3',
            },
          },
        },
        section_4: {
          title: 'Decision to Develop Solutions',
          text: 'This is where we’ll use most of our development resources so getting clear on scope, business case and assumptions are critical. This decision-moment brings stakeholders along the journey to open us up to feedback that will give us the best chance for success.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'PMO',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Data completed in Workfront to complete gate pre-read',
              row2: 'Prepare Gate template',
              row3: 'Agree and record Path forward (next gate date)',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Alignment to allocate resources to develop solutions',
              row2: 'Clarity on any known risks as part of readiness to proceed',
              row3: 'Reflect decision and comments in Workfront',
            },
          },
        },
      },
    },
    solution: {
      title: 'Solution',
      subtitle:
        'Your solution: be awesome. That’s great, but before your innovation and concept can succeed, you’ll need a solution that goes through many important steps before it makes its debut.',
      bubbleText: 'Prep for success',
      backgroundColor: '#FFD9E1',
      titleColor: '#CB4865',
      nextPhase: 'Launch',
      timeline: {
        faze_1: {
          title: 'Test and Validate Solution',
        },
        faze_2: {
          title: 'Complete Technical<br>Feasibility Risk Assessment',
        },
        faze_3: {
          title: 'Decision to Launch',
        },
      },
      dropdown: {
        section_1: {
          title: 'Test and Validate Solution',
          text: "Can your innovation duo, do it? It's time to bring the pieces together and test the full proposition (product and concept) together.",
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Initiative Lead',
              row2: 'Human Insights',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Test and refine proposed solution with consumers based on success criteria.',
              row2: 'Update technical specification.',
              row3: 'Determine appropriate production tests for validation.',
              row4: 'Determine product validation plan and requirements.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Learnings to be applied to increase quality of launch and probability of success',
              row2: 'Clarity on how to best position the Bundle with consumers in marketing materials and on shelf (inclusive of VIS, Packaging, Marketing, Merchandising)',
            },
          },
        },
        section_2: {
          title: 'Complete Technical Feasibility Risk Assessment',
          text: 'Stop. Pause. Test. Complete the Technical Feasibility Risk Assessment for Gate 3.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Research & Development',
              row2: 'Technical Commercialization/Operations',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Completed brief documented definition of done',
              row2: 'Understanding key risks and creating a plan to address them.',
              row3: 'Cross functional team engaged to effectively assess risk.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Technical / Feasibility risk probability and impact',
              row2: 'Readiness to move to Stage 4',
            },
          },
        },
        section_3: {
          title: 'Decision to Launch',
          text: 'Your launch is coming up. Move into final production readiness including procuring graphics and allocating capital.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'PMO',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Prepare Gate template',
              row2: 'Present project -recommended decisions, plan and resources required for next stage',
              row3: 'Agree and record Path forward (next gate date)',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Alignment to allocate resources to develop solutions',
              row2: 'Clarity on any known risks as part of readiness to proceed',
              row3: 'Reflect decision and comments in Workfront',
            },
          },
        },
      },
    },
    launch: {
      title: 'Launch Prep',
      subtitle: 'Launch your beverage with marketing, sales, distribution and manufacturing plans in place.',
      bubbleText: '3, 2, 1 blast off!',
      backgroundColor: '#F9B7A9',
      titleColor: '#AA3527',
      nextPhase: 'Market',
      timeline: {
        faze_1: {
          title: 'Finalize<br>Production Plan',
        },
        faze_2: {
          title: 'Finalize<br>Distribution Plan',
        },
        faze_3: {
          title: 'Finalize Market<br>Launch Plan',
        },
        faze_4: {
          title: 'Complete<br>Technical<br>Feasibility Risk<br>Assessment',
        },
        faze_5: {
          title: 'Decision to<br>Produce',
        },
      },
      dropdown: {
        section_1: {
          title: 'Finalize Production Plan',
          text: 'Does your innovation have what it needs? Identify supply chain production plan including allocating capital to build new capacity or capabilities.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Bottler Supply Chain',
              row2: 'Bottler',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Make capability investments.',
              row2: 'Confirm production sales plan.',
              row3: 'Update or finalize all required forms.',
              row4: 'Implement intellectual property and strategy plans.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Confidence in our ability to produce a given innovation.',
            },
          },
        },
        section_2: {
          title: 'Finalize Distribution Plan',
          text: 'Get your innovation into the hands of its drinkers! Identify supply chain distribution plan by understanding transportation implications of getting the innovation from a production facility to a distribution center.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Bottler Supply Chain',
              row2: 'Bottler',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Update distribution plan based on bottler demand forecasts.',
              row2: 'Confirm distribution plan.',
              row3: 'Revise and place raw order materials.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Confirmation of supply chain distribution capacity as an enabler of commercial success.',
            },
          },
        },
        section_3: {
          title: 'Finalize Market Launch Plan',
          text: 'Let’s get on the same page! Confirmed launch plans with customers, bottlers and marketing to enable in-market success. Feeling the excitement!',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Initiative Lead',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Customer authorizations.',
              row2: 'Activation of DME levels and media channels for distribution.',
              row3: 'Bottler commitment to production and distribution plans to meet customer demand.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Confidence of probability of in-market success.',
            },
          },
        },
        section_4: {
          title: 'Complete Technical Feasibility Risk Assessment',
          text: 'Assess technical risks based on the Innovation classification and define the probability and impact given the agreed requirements from the Technical Brief.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Research & Development',
              row2: 'Technical Commercialization/Operations',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Cross functional team engaged to effectively assess risk.',
              row2: 'Understanding key risks and creating a plan to address them.',
              row3: 'Add additional risks, local or by category, that are known at this stage.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Technical Feasibility risk probability and impact',
              row2: 'Readiness to move to Stage 5',
            },
          },
        },
        section_5: {
          title: 'Decision to Produce',
          text: 'It’s the last stop before your innovation and concept go out into the world. Deep breath, you got this.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'PMO',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Prepare Gate template.',
              row2: 'Present project -recommended decisions, plan and resources required for next stage. ',
              row3: 'Agree and record Path forward (next gate date).',
              row4: 'Reflect decision and comments in Workfront.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Alignment to allocate resources to launch innovation',
              row2: 'Clarity on any known risks as part of readiness to proceed',
              row3: 'Reflect decision and comments in Workfront',
            },
          },
        },
      },
    },
    market: {
      title: 'Market Execution',
      subtitle:
        'Your idea has launched, but while you celebrate the milestone, it’s also time to measure, optimize as needed to ensure you reach your goals, share results and find potential for scaling.',
      bubbleText: 'Take center stage',
      backgroundColor: '#FFD9E1',
      titleColor: '#CB4865',
      nextPhase: 'Simplify',
      timeline: {
        faze_1: {
          title: '3rd Month Tracking',
        },
        faze_2: {
          title: '6th Month Tracking<br>and Review',
        },
        faze_3: {
          title: 'PLR & Decision to Produce',
        },
      },
      dropdown: {
        section_1: {
          title: '3rd Month Tracking',
          text: 'Track post-launch performance of all Innovation on Internal KPIs versus competition on Retail KPIs.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Marketing Analytics',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Assess process performance (Stage 0-5) and document learnings.',
              row2: 'Assess process performance (Stage 0-5) and document learnings. Assess 90 days performance at 3rd month mark vs. plan and success.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Holistic standardized and efficient Innovation tracking to provide data driven guidance on performance of innovations.',
            },
          },
        },
        section_2: {
          title: '6th Month Tracking and Review',
          text: 'Time for a 6-month check-up. Track post-launch performance of all Innovation on Internal KPIs versus competition on Retail KPIs.',
          table: {
            column1: {
              title: 'Marketing Analytics',
              row1: 'OU/Market Project Lead',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Assess process performance (Stage 0-5) and document learnings.',
              row2: 'Assess 180 days performance at 6th month mark vs. plan and success.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Holistic standardized and efficient Innovation tracking to provide data driven guidance on performance of innovations.',
            },
          },
        },
        section_3: {
          title: 'PLR & Decision to Produce',
          text: 'Are you ready to let your winner sail to new markets? Great innovations should be scaled to more packages within the same market to increase recruitment potential, as well as expanding to new geographies.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'PMO',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Confirm scale potential',
              row2: 'Document scale up recommendation in Workfront',
              row3: 'Review forecast at PLR',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Documentation of scale up recommendation in Workfront.',
            },
          },
        },
      },
    },
    simplify: {
      title: 'Simplify for Growth',
      subtitle:
        'Let’s build, build, build. Visuals, video, translations, and anything else we need to make this experience a success, everywhere.',
      bubbleText: 'The makers are busy making',
      backgroundColor: '#FEDEA8',
      titleColor: '#FC4D0F',
      nextPhase: '',
      timeline: {
        faze_1: {
          title: 'Production<br>Alignment',
        },
        faze_2: {
          title: 'Experience Toolkit<br>Production',
        },
        faze_3: {
          title: 'Localization &<br>Clearance',
        },
        faze_4: {
          title: 'Asset Upload &<br>Media Activation',
        },
      },
      dropdown: {
        section_1: {
          title: 'Production Alignment',
          text: 'Efficiency is the name of the game. Co-create and get clearances as needed.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Project Lead',
              row2: 'Capability Lead(s)',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Network team (squad) and Studio X to align to maximize efficiencies with co-creation.',
              row2: 'Get Claims and Communications Approval Process (CCAP) clearance with local markets’ regulations.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Aligned production schedule',
            },
          },
        },
        section_2: {
          title: 'Experience Toolkit Production',
          text: 'Build out the central assets so the toolkit is fully supported.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'Project Lead',
              row2: 'Capability Lead(s)',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Network team develops and produces central assets.',
              row2: 'Network team co-creates with Studio X OU team to deliver OU/regional experience toolkits based on matrix plans and local requirements.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Experience Toolkit',
              row2: '<b>Decision Moment:</b> Finalized OU/market activation plan',
            },
          },
        },
        section_3: {
          title: 'Localization & Clearance',
          text: 'Studio X customizes and tailors until it’s the perfect fit, wherever the experience lives.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'OU Project Lead',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Studio X develops market specific executions, assets, translations, transcreation, and adaptation of charter toolkits.',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'Experience Toolkit',
              row2: '<b>Decision Moment:</b> Finalized network assets',
            },
          },
        },
        section_4: {
          title: 'Asset Upload & Media Activation',
          text: 'Upload to KO Assets with the correct project name before the project goes live in market.',
          table: {
            column1: {
              title: 'Who’s Responsible?',
              row1: 'OU Production Lead',
            },
            column2: {
              title: 'Key Inputs',
              row1: 'Upload assets to KO assets with the right project naming',
              row2: 'Project goes live in market',
            },
            column3: {
              title: 'Key Outputs / Deliverables',
              row1: 'All assets uploaded to KO assets',
              row2: 'Project goes live',
            },
          },
        },
      },
    },
  },
]
