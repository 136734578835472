import { WppIconArrow } from '@platform-ui-kit/components-library-react'
import { animated, useSpring } from '@react-spring/web'
import { AnalyticsActionType } from '@wpp-open/core'
// import SuitcaseIcon from 'public/assets/playground/suitcase.svg'
import { useEffect, useMemo, useState } from 'react'

import { ANALYTICS_EVENTS } from 'constants/analytics'
import { environment } from 'environment'
import { LegacyBoxEnv } from 'environment/common'
import { AuthPlaygroundLayout } from 'layout/authLayout/authPlaygroundLayout/AuthPlaygroundLayout'
import Bubble from 'pages/login/customLogin/bubble/Bubble'
import CookieBanner from 'pages/login/customLogin/cookieBanner/CookieBanner'
import styles from 'pages/login/customLogin/CustomLogin.module.scss'
import { getIdpsAnalyticsPayload } from 'pages/login/utils'
import { Button } from 'pages/playground/playgroundDashboard/components/general/button/Button'
import { IdentityProvider } from 'types/auth/identityProvider'
import { TenantIdpAlias } from 'types/tenants/tenant'
import { trackAnalytics } from 'utils/analytics'

export interface LoginProps {
  className?: string
  login: (identityProvider?: IdentityProvider) => void
}

export const CustomLogin = ({ className, login }: LoginProps) => {
  const audioSound = new Audio('assets/playground/can-open.mp4')

  const [isContentVisible, setIsContentVisible] = useState(false)

  const fadeLeftStyles = useSpring({
    opacity: isContentVisible ? 1 : 0,
    marginLeft: isContentVisible ? '0px' : '-200px',
    config: {
      tension: 140,
      friction: 14,
    },
  })

  const fadeRightStyles = useSpring({
    opacity: isContentVisible ? 1 : 0.5,
    height: '292px',
    right: isContentVisible ? '0px' : '-150px',
    rotate: isContentVisible ? 0 : 45,
    transform: 'translateY(-50%)',
    config: { tension: 210, friction: 20 },
  })

  useEffect(() => {
    setTimeout(() => {
      setIsContentVisible(true)
    }, 1000)
  }, [])

  const logoUrl = 'assets/playground/cola-logo.svg'

  useEffect(() => {
    trackAnalytics({
      type: AnalyticsActionType.page,
      payload: ANALYTICS_EVENTS.TENANT_LOGIN_PAGE.PAGE.LOGIN,
    })
  }, [])

  const onOpenIntercomArticle = () => {
    if (window.Intercom) {
      window.Intercom('showArticle', '6441443')
    }
  }

  const loginIdentityProvider = useMemo(() => {
    // eslint-disable-next-line no-console
    console.log('environment', environment)
    // eslint-disable-next-line no-console
    console.log('environment.LEGACY_BOX_ENV', environment.LEGACY_BOX_ENV)
    // TODO fix this
    switch (environment.LEGACY_BOX_ENV) {
      case LegacyBoxEnv.Stage:
        return IdentityProvider.PlaygroundProd

      case LegacyBoxEnv.Development:
        return IdentityProvider.PlaygroundStage

      default:
        return IdentityProvider.PlaygroundStage
    }
  }, [])

  const handleLogin = () => {
    if (window.Intercom) {
      window.Intercom('hide')
    }
    login(loginIdentityProvider)
    getIdpsAnalyticsPayload(TenantIdpAlias.GroupM) &&
      trackAnalytics({
        type: AnalyticsActionType.page,
        payload: getIdpsAnalyticsPayload(TenantIdpAlias.GroupM) || '',
      })
  }

  return (
    <AuthPlaygroundLayout className={className}>
      <div className={styles.root}>
        <img className={styles.tenantLogo} alt="Tenant logo" src={logoUrl} />

        <div className={styles.leftContainer}>
          <div className={styles.title}>{'Welcome to \nThe Playground'}</div>
          {/* Animation part */}
          <div className={styles.fadeLeftContainer}>
            <animated.div style={fadeLeftStyles}>
              <div className={styles.title2}>
                {'Where done gets \ndone with'} <span className={styles.titleSpan}>FUN</span>.
              </div>
              <div className={styles.bubble}>
                <Bubble direction="up" />
              </div>

              <div className={styles.button}>
                <span
                  onClick={() => {
                    audioSound.play()
                    setTimeout(() => {
                      handleLogin()
                    }, 500)
                  }}
                >
                  <Button
                    text="Log in with Company Account"
                    variant="tertiary"
                    iconRight={<WppIconArrow direction="right" color="black" />}
                    // iconLeft={<img src={SuitcaseIcon} />}
                  />
                </span>
              </div>
              <div className={styles.buttonCaption}>
                <div>Logging in with Company Account requires a KOID.</div>
                <div>
                  <u className={styles.pointer} onClick={() => onOpenIntercomArticle()}>
                    Need help requesting a KOID?
                  </u>
                </div>
              </div>
            </animated.div>
          </div>
        </div>

        <animated.div className={styles.handContainer} style={fadeRightStyles}>
          <img src="assets/playground/hand.png" alt="" className={styles.wFull} />
        </animated.div>

        <CookieBanner />
      </div>
    </AuthPlaygroundLayout>
  )
}
