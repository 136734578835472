import { WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull, Tenant } from '@wpp-open/core'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { NotificationCard } from 'components/notificationsSideModal/notificationCard/NotificationCard'
import styles from 'components/notificationsSideModal/notificationItems/NotificationItems.module.scss'
import { getFeedsLists } from 'components/notificationsSideModal/utils'
import { Feed } from 'types/feeds/feeds'
import { TenantShort } from 'types/tenants/tenant'

interface Props {
  feeds: Feed[]
  selectedTenant: TenantShort | Tenant
  lastSeenAt?: MayBeNull<string>
}

const ItemsSection = ({ title, items, tenant }: { title: string; items: Feed[]; tenant: TenantShort | Tenant }) => (
  <>
    <WppTypography className={styles.sectionTitle} type="2xs-strong">
      {title}
    </WppTypography>
    <Flex direction="column" gap={12}>
      {items.map(item => (
        <NotificationCard key={item.id} notification={item} tenant={tenant} />
      ))}
    </Flex>
  </>
)

export const NotificationItems = ({ feeds, selectedTenant, lastSeenAt }: Props) => {
  const { t } = useTranslation()

  const { newItems, todayItems, earlierItems } = useMemo(
    () =>
      getFeedsLists({
        feeds,
        lastSeenAt,
      }),
    [feeds, lastSeenAt],
  )

  return (
    <>
      {!!newItems.length && <ItemsSection title={t('os.notification.new')} items={newItems} tenant={selectedTenant} />}

      {!!todayItems.length && (
        <ItemsSection title={t('os.notification.today')} items={todayItems} tenant={selectedTenant} />
      )}

      {!!earlierItems.length && (
        <ItemsSection title={t('os.notification.earlier')} items={earlierItems} tenant={selectedTenant} />
      )}
    </>
  )
}
