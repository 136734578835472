import { WppRichtext } from '@platform-ui-kit/components-library-react'
import { ComponentPropsWithoutRef, forwardRef, useRef } from 'react'
import { mergeRefs } from 'react-merge-refs'

import { useCommonLabelProps } from 'components/common/utils'
import { useField } from 'hooks/form/useField'

export interface FormRichtextProps
  extends Omit<ComponentPropsWithoutRef<typeof WppRichtext>, 'value' | 'onChange' | 'onWppChange'> {
  name: string
}

export const FormRichtext = forwardRef<HTMLWppRichtextElement, FormRichtextProps>(
  ({ name, message, messageType, onWppBlur, labelConfig, labelTooltipConfig, ...rest }, ref) => {
    const innerRef = useRef<HTMLWppRichtextElement>(null)

    const {
      field: { value, onChange, onBlur },
      fieldState: { isTouched, error },
    } = useField({ name })

    const errorText = error?.message
    const shouldShowError = isTouched && !!errorText

    const labelProps = useCommonLabelProps({ labelConfig, labelTooltipConfig })

    return (
      <WppRichtext
        {...rest}
        {...labelProps}
        ref={mergeRefs([innerRef, ref])}
        name={name}
        value={value}
        onWppChange={event => {
          onChange(event.detail.value)
        }}
        onWppBlur={e => {
          onBlur()
          onWppBlur?.(e)
        }}
        message={shouldShowError ? errorText : message}
        messageType={shouldShowError ? 'error' : messageType}
      />
    )
  },
)
