import { hasAccessToMaximize, NavigationConfigFunctions } from 'legacy/navigation/common'
import { navigation as defaultNavigation } from 'legacy/navigation/default'
import { LegacyPermission } from 'legacy/navigation/permission'
import { hasPermission } from 'legacy/navigation/utils'
import { AccountPermissions, NavigationSection } from 'legacy/types/osWebRootApi'

export const navigation: NavigationConfigFunctions = {
  ...defaultNavigation,
  main: (permissions: AccountPermissions[], countryCode?: string): NavigationSection => [
    {
      title: 'Distil',
      nav: [
        { title: 'Define Success', disabled: true },
        { title: 'Uncover Challenge', disabled: true },
        {
          title: 'BAV',
          nav: [
            {
              title: 'BAV Academy',
              path: 'https://academy.wppbav.com/',
              external: true,
            },
            {
              title: 'The Fount',
              path: 'https://fount.wppbav.com/',
              external: true,
            },
          ],
        },
        { title: 'Audience Planning', disabled: true },
        {
          title: 'Architect ',
          nav: [
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.AUDIENCES',
              path: '/audiences/map',
              disabled:
                !hasAccessToMaximize(countryCode) ||
                !hasPermission(permissions, LegacyPermission.MxmzAudiencesAppAccess),
            },
          ],
        },
      ],
    },
    {
      title: 'Balance',
      breadcrumbTitle: 'OS_HEADER.NAVIGATION_MAIN.OPTIMISE',
      nav: [
        { title: 'Experience & Innovation', disabled: true },
        { title: 'Brand & Performance', disabled: true },
        { title: 'Channels & Signals', disabled: true },
        {
          title: 'Architect',
          nav: [
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.TOUCHPOINTS',
              path: '/touchpoints/touchpoints',
              disabled:
                !hasAccessToMaximize(countryCode) ||
                !hasPermission(permissions, LegacyPermission.MxmzTouchpointsAppAccess),
            },
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.ARCHITECT_JUNIOR',
              path: '/junior',
              disabled:
                !hasAccessToMaximize(countryCode) || !hasPermission(permissions, LegacyPermission.MxmzJuniorAppAccess),
              hidden:
                !hasAccessToMaximize(countryCode) || !hasPermission(permissions, LegacyPermission.MxmzJuniorAppAccess),
            },
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.CAMPAIGNS_PARENT',
              disabled:
                !hasAccessToMaximize(countryCode) ||
                (!hasPermission(permissions, LegacyPermission.MxmzCampaignsAppAccess) &&
                  !hasPermission(permissions, LegacyPermission.MxmzMediaPlansComparisonAppAccess)),
              nav: [
                {
                  title: 'OS_HEADER.NAVIGATION_MAIN.CAMPAIGNS',
                  path: '/briefs/list',
                  disabled: !hasPermission(permissions, LegacyPermission.MxmzCampaignsAppAccess),
                },
                {
                  title: 'OS_HEADER.NAVIGATION_MAIN.MP_UPLOADER',
                  path: '/custom-campaigns/list',
                  hidden: !hasPermission(permissions, LegacyPermission.MxmzMediaPlansUploaderAppAccess),
                },
                {
                  title: 'OS_HEADER.NAVIGATION_MAIN.MP_COMPARISON',
                  path: '/mp-comparison/list',
                  disabled: !hasPermission(permissions, LegacyPermission.MxmzMediaPlansComparisonAppAccess),
                },
              ],
            },
          ],
        },
        {
          title: 'Jarvis',
          nav: [
            {
              title: 'Flights',
              path: 'https://flight.jarvis.greenhousegroup.com/',
              external: true,
            },
          ],
        },
      ],
    },
    {
      title: 'Adapt',
      nav: [
        { title: 'Analyse', disabled: true },
        { title: 'Optimise', disabled: true },
        { title: 'Learning', disabled: true },
      ],
    },
  ],
}
