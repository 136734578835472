import { MayBeNull } from '@wpp-open/core'
import { matchPath } from 'react-router-dom'

import { systemAppsList } from 'constants/apps'
import { AppDataFromUrlType, SystemAppDataFromUrl } from 'types/osState/appDataFromUrl'

interface Params {
  pathname: string
}

export const getSystemAppDataFromUrl = ({ pathname }: Params): MayBeNull<SystemAppDataFromUrl> => {
  const systemApp = systemAppsList.find(({ osRoute }) => matchPath(`/${osRoute}/*`, pathname))

  if (!systemApp) {
    return null
  }

  return {
    type: AppDataFromUrlType.SystemApp,
    app: systemApp,
  }
}
