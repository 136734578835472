import { workfrontApi } from 'api'

export const patchWorkfrontTask = ({ taskId, status }: { taskId: string; status: string }) =>
  workfrontApi.put(
    `/tasks/${taskId}`,
    {
      status: status,
    },
    {},
  )
