import { facadeApi } from 'api'
import { CalendarWidgetEventDTO } from 'types/widgets/widget'

interface Params {
  id: string
  tenantId?: string
  hub_id?: string
}

export const fetchCalendarWidgetEvent = ({ id, tenantId, hub_id }: Params) =>
  facadeApi.get<CalendarWidgetEventDTO>(`/hubs/${hub_id}/events/${id}`, {
    headers: {
      ...(!!tenantId && { 'X-Tenant-Id': tenantId }),
    },
  })
