import { WppActionButton, WppIconChevron } from '@platform-ui-kit/components-library-react'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { BreadcrumbsContainer } from 'layout/header/breadcrumbs/common/container/BreadcrumbsContainer'
import { BreadcrumbItem } from 'layout/header/breadcrumbs/common/item/BreadcrumbItem'
import { ProjectBreadcrumbsMenuContext } from 'layout/header/breadcrumbs/project/menuContext/ProjectBreadcrumbsMenuContext'
import { ProjectFluidBreadcrumbsMenu } from 'layout/header/breadcrumbs/project/projectFluid/menu/ProjectFluidBreadcrumbsMenu'
import styles from 'layout/header/breadcrumbs/project/projectFluid/ProjectFluidBreadcrumbs.module.scss'
import { useFluidCanvasConnectedApps } from 'layout/header/breadcrumbs/project/projectFluid/utils'
import { BreadcrumbItemProject } from 'types/osState/breadcrumbs'

export const FLUID_MENU_ITEM_WIDTH = 232
export const FLUID_MENU_COLUMNS_GAP = 40

interface Props {
  items: BreadcrumbItemProject[]
}

export const ProjectFluidBreadcrumbs = ({ items }: Props) => {
  const navigate = useNavigate()
  const { previousApps, currentApp, nextApps } = useFluidCanvasConnectedApps()

  const collapsibleItems = useMemo(() => items.slice(0, -1), [items])
  const lastItem = items.at(-1)

  const leftColumnFactor = previousApps.length > 0 ? -1 : 1
  const columnsCount = Math.sign(previousApps.length) + Math.sign(nextApps.length) + 1
  const hasEvenColumnsCount = columnsCount % 2 === 0

  const handleItemClick = ({ link }: BreadcrumbItemProject) => {
    if (link) {
      navigate(link)
    }
  }

  return (
    <BreadcrumbsContainer items={collapsibleItems} onItemClick={handleItemClick}>
      {!!lastItem && (
        <ProjectBreadcrumbsMenuContext
          className={styles.projectMenuWrapper}
          externalClass={styles.projectMenu}
          dropdownConfig={{
            placement: 'bottom',
            maxWidth: 'initial',
            offset: [
              // Align with current app column
              hasEvenColumnsCount ? ((FLUID_MENU_ITEM_WIDTH + FLUID_MENU_COLUMNS_GAP) * leftColumnFactor) / 2 : 0,
              0,
            ],
          }}
          trigger={
            <BreadcrumbItem slot="trigger-element" label={lastItem.label}>
              <WppActionButton variant="secondary">
                <WppIconChevron slot="icon-start" direction="down" />
              </WppActionButton>
            </BreadcrumbItem>
          }
          menu={<ProjectFluidBreadcrumbsMenu previousApps={previousApps} currentApp={currentApp} nextApps={nextApps} />}
        />
      )}
    </BreadcrumbsContainer>
  )
}
