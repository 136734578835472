import { WppSpinner } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useWorkfrontAuthenticate } from 'api/workfront/queries/auth/useFetchWorkfrontAuthenticate'
import { Flex } from 'components/common/flex/Flex'
import stylesMain from 'pages/playground/playgroundDashboard/PlaygroundDashboard.module.scss'

const tccLSKey = 'WPP_TCC_TOKEN'

const WorkfrontAuth = () => {
  const [params] = useSearchParams()
  const [authEnabled, setAuthEnabled] = useState(false)

  const navigate = useNavigate()

  const { response: responseAuthenticate } = useWorkfrontAuthenticate({
    params: {
      jwt: window.localStorage.getItem(tccLSKey) || '',
      code: params.get('code') || '',
      state: params.get('state') || '',
      redirectUri: `${window.location.origin}/user/profile/auth/workfront`,
    },
    enabled: authEnabled,
  })

  useEffect(() => {
    if (!authEnabled) return
    navigate('/home')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseAuthenticate])

  useEffect(() => {
    if (params.get('code') && params.get('state')) {
      setAuthEnabled(true)
    }
  }, [params])

  return (
    <div>
      <Flex justify="center" align="center" className={clsx(stylesMain.minHFull, stylesMain.hFull)}>
        <WppSpinner size="l" />
      </Flex>
    </div>
  )
}

export default WorkfrontAuth
