export const mailto = <T extends string>(email: T): `mailto:${T}` => `mailto:${email}`

export const simulateLinkClick = ({
  href,
  target,
}: {
  href: string
  target?: '_self' | '_blank' | '_parent' | '_top'
}) => {
  const link = document.createElement('a')
  link.href = href

  if (target) {
    link.target = target
  }

  if (target === '_blank') {
    link.setAttribute('rel', 'noreferrer')
  }

  link.click()
  link.remove()
}

export const getTeamsChatUrl = (email: string) => `https://teams.microsoft.com/l/chat/0/0?users=${email}`

export const linksUrlRegex =
  /^(http(s?):\/\/)[-a-zA-Z0-9@:%_+~#=]{2,256}[.]{1}[a-z0-9]{1,6}[-a-zA-Z0-9@:%_+/.~#?&=]{1}/i
