import { userDetailsApi } from 'api'
import { UploadInfo } from 'types/attachments/attachments'

interface FileParams {
  signedUrl: string
  file: File
}

export const uploadUserDetailsFileApi = ({ signedUrl, file }: FileParams) =>
  userDetailsApi.put<''>(signedUrl, file, {
    headers: { 'Content-Type': file.type || 'application/octet-stream' },
  })

interface UrlParams {
  names: string[]
  contentType: string
}

export const generateUserDetailsUploadUrlsApi = ({ names, contentType }: UrlParams) =>
  userDetailsApi.post<UploadInfo[]>('/images/generate-temp-upload-urls', { names, contentType })
