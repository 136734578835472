import { MayBeNull } from '@wpp-open/core'
import { PropsWithChildren, useEffect, useState } from 'react'

import { ConnectionCallback } from 'layout/header/breadcrumbs/project/projectFluid/menu/utils'

interface Props {
  tag: string
  connect: ConnectionCallback
  disconnect: ConnectionCallback
}

export const ConnectionItemWrapper = ({ tag, connect, disconnect, children }: PropsWithChildren<Props>) => {
  const [ref, setRef] = useState<MayBeNull<HTMLDivElement>>(null)

  useEffect(() => {
    if (ref) {
      connect(ref)

      return () => disconnect(ref)
    }
  }, [connect, disconnect, ref])

  return (
    <div className={tag} ref={node => setRef(node)}>
      {children}
    </div>
  )
}
