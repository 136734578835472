import { UserDetails } from '@wpp-open/core'
import { createContext, useContext } from 'react'

import { TenantPublicShort, TenantShort } from 'types/tenants/tenant'

export interface OtherTenantsAndUserDataContextValue {
  availableTenants: TenantShort[]
  requestableTenants: TenantPublicShort[]
  userDetails: UserDetails
}

export const OtherTenantsAndUserDataContext = createContext<OtherTenantsAndUserDataContextValue>(null!)

export const useOtherTenantsAndUserData = () => useContext(OtherTenantsAndUserDataContext)
