import { WppModal, WppSkeleton } from '@platform-ui-kit/components-library-react'
import { useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'
import { useEffect, useMemo, useState } from 'react'

import { useFetchFiles } from 'api/fileManager/mutations/useFetchFiles'
import { FeedbackResponse } from 'api/workfront/fetchers/fetchFeedback'
import { MTAssignment } from 'api/workfront/fetchers/matchAndThrive/fetchAssignments'
import { FeedbackHelpfull } from 'api/workfront/fetchers/publishFeedback'
import { usePublishFeedback } from 'api/workfront/mutations/usePublishFeedback'
import { useFetchFeedback } from 'api/workfront/queries/useFetchFeedback'
import { Flex } from 'components/common/flex/Flex'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import GifNoAssignments from 'pages/playground/playgroundDashboard/assets/icons/search.gif'
import BubbleFixed from 'pages/playground/playgroundDashboard/components/general/bubble/Bubble'
import {
  DEFAULT_ITEMS_PER_PAGE,
  Pagination,
} from 'pages/playground/playgroundDashboard/components/general/pagination/Pagination'
import { ApprovalCard } from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/approvalCard/ApprovalCard'
import { AssignmentCard } from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/assignmentCard/AssignmentCard'
import { NudgePreviewModal } from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/assignmentCard/components/NudgePreviewModal'
import { PhaseModal } from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/assignmentCard/components/phaseModal/PhaseModal'
import { Feedback } from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/assignmentCard/feedback/Feedback'
import FeedbackRecievedBanner from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/assignmentCard/feedbackBanner/FeedbackRecievedBanner'
import { FeedbackQueue } from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/FeedbackQueue/FeedbackQueue'
import { MtAssignmentCard } from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/mtAssignmentCard/MtAssignmentCard'
import styles from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/SectionAssignments.module.scss'
import {
  AssignmentTaskStatus,
  ProjectApiDto,
  TaskApprovalsDto,
} from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/sectionAssignmentsMockData'
import { SectionOauth } from 'pages/playground/playgroundDashboard/components/sections/sectionOauth/SectionOauth'
import stylesMain from 'pages/playground/playgroundDashboard/PlaygroundDashboard.module.scss'
import { canURLBePreviewed, isBlinkUrl } from 'pages/playground/playgroundDashboard/utils/file'
import { useAuth } from 'providers/auth/AuthContext'

export const SectionAssignments = ({
  filterAssignments,
  sortAssingmentsBy,
  assignments,
  workfrontError,
  isLoading,
  isLoadingProgress,
  approvals,
  mtAssignments,
  scrollToAssignments,
}: {
  filterAssignments: string
  sortAssingmentsBy: string
  assignments: ProjectApiDto[]
  workfrontError: boolean
  isLoading: boolean
  isLoadingProgress: boolean
  approvals: TaskApprovalsDto[]
  mtAssignments: MTAssignment[]
  scrollToAssignments: (bottom?: boolean) => void
}) => {
  const queryClient = useQueryClient()
  const { jwt } = useAuth()
  const { data: feedback } = useFetchFeedback()
  const { mutateAsync: publishFeedback, isLoading: publishFeedbackLoading } = usePublishFeedback()
  const { mutateAsync: fetchFiles } = useFetchFiles()

  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false)
  const [previewUrl, setPreviewUrl] = useState('')
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false)
  const [feedbackId, setFeedbackId] = useState('')
  const [selectedPhase, setSelectedPhase] = useState<string | ''>('')
  const [isPhaseModalOpen, setIsPhaseModalOpen] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [feedbackStep, setFeedbackStep] = useState(0)
  const [feedbackRecievedShown, setFeedbackRecievedShown] = useState<boolean | null>(null)

  const handleBlink = (button: { label: string; url: string; action: string }) => {
    fetchFiles({
      recursive: true,
      blinkLink: button.url,
      jwt: jwt,
    })
  }

  const handleFeedbackClick = async (element: FeedbackResponse, helpfull: boolean) => {
    if (helpfull) {
      setFeedbackRecievedShown(true)
      try {
        await publishFeedback({ feedbackId: element.id, helpfull: FeedbackHelpfull.YES, feedback: '' })
        queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.FEEDBACK] })
      } catch (e) {
        console.error(e)
      }
    } else {
      setFeedbackId(element.id)
      setIsFeedbackModalOpen(true)
    }
  }

  const handlePreviewClick = (feedback: FeedbackResponse) => {
    if (canURLBePreviewed(feedback.nudgeObj.url)) {
      setPreviewUrl(feedback.nudgeObj.url)
      setIsPreviewModalOpen(true)
    } else if (isBlinkUrl(feedback.nudgeObj.url)) {
      handleBlink({ label: feedback.nudgeObj.label, url: feedback.nudgeObj.url, action: feedback.nudgeObj.action })
    } else {
      window.open(feedback.nudgeObj.url, '_blank')
    }
  }

  const [selectedAssignments, setSelectedAssignments] = useState(
    // assignments && approvals ? [...assignments, ...approvals] : assignments ? assignments : approvals ? approvals : [],
    [...assignments, ...approvals, ...mtAssignments],
  )
  useEffect(() => {
    setSelectedAssignments([...assignments, ...approvals, ...mtAssignments])
  }, [assignments, approvals, mtAssignments])

  const [page, setPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(DEFAULT_ITEMS_PER_PAGE)

  useEffect(() => {
    setPage(1)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage])

  const onSelectPage = (page: number) => {
    setPage(page)
  }

  const fixDateFormat = (dateString: string) => {
    return dateString.replace(/:(\d{3})([+-]\d{2}:?\d{2})?$/, '.$1$2')
  }

  const assignmentsMemo = useMemo(() => {
    let filteredAssignments = selectedAssignments

    if (filterAssignments === 'Tasks') {
      filteredAssignments = selectedAssignments?.filter(
        el => el.taskStatus !== 'RISK' && el.taskStatus !== 'REQUEST' && el.taskStatus !== 'APPROVAL',
      )
    } else if (filterAssignments === 'Approvals') {
      filteredAssignments = selectedAssignments?.filter(el => el.taskStatus === 'APPROVAL')
    } else if (filterAssignments === 'Requests') {
      filteredAssignments = selectedAssignments?.filter(el => el.taskStatus === 'REQUEST')
    } else if (filterAssignments === 'Risk Requests') {
      filteredAssignments = selectedAssignments?.filter(el => el.taskStatus === 'RISK')
    }

    return (filteredAssignments || [])?.sort((a: any, b: any) => {
      if (sortAssingmentsBy === 'Due Date') {
        if (!a.dueDate || !b.dueDate) return 0
        const dateA = new Date(fixDateFormat(a.dueDate))
        const dateB = new Date(fixDateFormat(b.dueDate))
        if (!isNaN(dateA.getTime()) && !isNaN(dateB.getTime())) {
          return dateA.getTime() - dateB.getTime()
        } else {
          return 0
        }
      } else if (sortAssingmentsBy === 'Project name A-Z') {
        return (a?.project?.name || a?.projectName || '').localeCompare(b?.project?.name || b?.projectName || '')
      } else if (sortAssingmentsBy === 'Assignment type') {
        return a.taskStatus.localeCompare(b.taskStatus)
      }
      return 0
    })
  }, [filterAssignments, selectedAssignments, sortAssingmentsBy])

  const assignmentsPaginated = useMemo(() => {
    if (isLoading) return []
    return assignmentsMemo?.filter(el => el.status !== 'CPL').slice((page - 1) * itemsPerPage, page * itemsPerPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentsMemo, page, itemsPerPage, sortAssingmentsBy])

  const handlePhaseClick = (phase: Pick<ProjectApiDto['phase'], 'phaseNumber' | 'phaseName'>) => {
    setSelectedPhase(phase.phaseName)
    setIsPhaseModalOpen(true)
  }

  const handleFeedBackStepChange = (step: number) => {
    setFeedbackStep(step)
    if (step >= 3) {
      setFeedbackRecievedShown(true)
      setIsFeedbackModalOpen(false)
      if (window.Intercom) window.Intercom('update', { hide_default_launcher: false })
    }
  }

  return (
    <div>
      {workfrontError && (
        <div className={stylesMain.mt50}>
          <SectionOauth loading={false} type="link-assignments" />
        </div>
      )}

      {!workfrontError && assignmentsMemo?.length === 0 && filterAssignments === 'View All' && !isLoading && (
        <div className={stylesMain.mt50}>
          <SectionOauth loading={false} type="empty-assignments" />
        </div>
      )}

      {isLoading && (
        <Flex direction="column" gap={40} className={stylesMain.mt42}>
          <WppSkeleton variant="rectangle" width="100%" height="230px" />
          <WppSkeleton variant="rectangle" width="100%" height="230px" />
          <WppSkeleton variant="rectangle" width="100%" height="230px" />
        </Flex>
      )}
      {assignmentsPaginated.length > 0 && (
        <>
          {assignmentsPaginated?.map((assignment, idx) => (
            <div key={idx}>
              {assignment.taskStatus === AssignmentTaskStatus.APPROVAL ? (
                <ApprovalCard
                  assignment={assignment as TaskApprovalsDto}
                  onPhaseClick={phase => handlePhaseClick(phase)}
                />
              ) : assignment.taskStatus === AssignmentTaskStatus.MT_INTERNAL ? (
                <MtAssignmentCard assignment={assignment as MTAssignment} />
              ) : (
                <AssignmentCard
                  assignment={assignment as ProjectApiDto}
                  isLoadingProgress={isLoadingProgress}
                  onPhaseClick={phase => handlePhaseClick(phase)}
                />
              )}
            </div>
          ))}
          <Pagination
            page={page}
            allPages={Math.ceil((assignmentsMemo?.length || 0) / itemsPerPage)}
            itemsPerPage={itemsPerPage}
            allItems={assignmentsMemo?.length || 0}
            onSelectPage={onSelectPage}
            onSelectItemsPerPage={value => {
              setItemsPerPage(prev => {
                if (prev > value) {
                  scrollToAssignments(true)
                }
                return value
              })
            }}
          />
        </>
      )}
      {assignmentsPaginated?.length === 0 && !workfrontError && assignments?.length !== 0 && (
        <div className={stylesMain.mt100}>
          <Flex justify="center">
            <img src={GifNoAssignments} width={120} alt="" />
          </Flex>
          <Flex justify="center">
            <div className={clsx(styles.orangeHeader, stylesMain.relative)}>
              No assignments with selected filter.
              <div className={styles.bubbleAbsolute}>
                <BubbleFixed text="Try something different." />
              </div>
            </div>
          </Flex>
        </div>
      )}
      {feedbackRecievedShown && (
        <>
          <FeedbackRecievedBanner
            onClose={() => {
              setFeedbackRecievedShown(false)
            }}
          />
        </>
      )}
      {!isFeedbackModalOpen && (feedbackRecievedShown === false || feedbackRecievedShown === null) && (
        <FeedbackQueue
          handleFeedback={(index: number, helpful: boolean) => handleFeedbackClick(feedback[index], helpful)}
          handleNudgePreview={() => handlePreviewClick}
          feedbackArray={feedback || []}
          loading={publishFeedbackLoading}
        />
      )}
      <NudgePreviewModal
        className={styles.previewModal}
        nudgeUrl={previewUrl}
        open={isPreviewModalOpen}
        onClose={() => setIsPreviewModalOpen(false)}
      />
      <PhaseModal
        phase={selectedPhase}
        open={isPhaseModalOpen}
        onClose={() => {
          setSelectedPhase('')
          setIsPhaseModalOpen(false)
        }}
      />

      <WppModal open={isFeedbackModalOpen} className={`${styles.feedbackModal}`}>
        <p slot="body">
          <Feedback
            nudgeName={feedback?.find(f => f.id === feedbackId)?.nudgeName || ''}
            feedbackId={feedbackId}
            setModal={setIsFeedbackModalOpen}
            open={isFeedbackModalOpen}
            onStepChanged={step => handleFeedBackStepChange(step)}
          />
        </p>
      </WppModal>
    </div>
  )
}
