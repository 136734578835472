export const LineVectorSVG = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="277" height="8" viewBox="0 0 277 8" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M274.846 2.95878C220.424 -1.62746 165.179 1.92395 110.67 1.62975C74.6264 1.42785 38.5694 0.338039 2.55307 0.257829C1.47326 0.257109 0.595961 1.16232 0.594343 2.28763C0.593376 3.41294 1.46882 4.32448 2.54798 4.32521C38.5604 4.40546 74.6135 5.49532 110.653 5.69727C165.064 5.99263 220.205 2.43566 274.53 7.01629C275.607 7.10517 276.549 6.27375 276.64 5.15416C276.724 4.03464 275.917 3.05451 274.846 2.95878Z"
          fill="#EBFF00"
        />
      </svg>
    </>
  )
}
