export const validFileTypes = ['pdf', 'mp4', 'jpg', 'jpeg', 'gif', 'png', 'mp3']

export const canURLBePreviewed = (url: string): boolean => {
  const URI = new URL(url)
  const urlWithoutQueryParams = URI.origin + URI.pathname
  return validFileTypes.some(fileType => urlWithoutQueryParams.endsWith(fileType))
}

export const isEmbedableFile = (fileType: string): boolean => {
  return validFileTypes.includes(fileType)
}

export const isBlinkUrl = (url: string): boolean => {
  const isBlink = /^(https?:\/\/cokeurl.com)/
  return isBlink.test(url)
}
