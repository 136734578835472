import { LegacyTheme } from 'legacy/styles/osTheming/models'
import { LegacyTenantCode } from 'legacy/types/osWebRootApi'

export const wmsTheme: LegacyTheme = {
  name: 'WMS',
  code: LegacyTenantCode.WMS,

  colors: {
    brand: {
      primary: {
        background: '#F2F9FF',
        lighter: '#66B0FF',
        light: '#51A8FF',
        normal: '#007CFF',
        dark: '#0080FF',
        darker: '#006AD2',
      },
      secondary: {
        background: '#F2F4F7',
        lighter: '#475D80',
        light: '#425A80',
        normal: '#0A2756',
        dark: '#0A2756',
        darker: '#1E2838',
      },
    },
    system: {
      information: {
        background: '#F2F9FF',
        lighter: '#79BCFF',
        light: '#51A8FF',
        normal: '#2894FF',
        dark: '#0080FF',
        darker: '#006AD2',
      },
      error: {
        background: '#FFF6F3',
        lighter: '#FCEBE6',
        light: '#F6784F',
        normal: '#E73700',
        dark: '#D64A1D',
        darker: '#B83A11',
      },
      warning: {
        background: '#FFFBF2',
        lighter: '#FFF6E6',
        light: '#FFBA31',
        normal: '#FFA900',
        dark: '#DB9400',
        darker: '#B87F00',
      },
      success: {
        background: '#F2FAF3',
        lighter: '#E6F6F1',
        light: '#59B06D',
        normal: '#09A775',
        dark: '#118732',
        darker: '#087627',
      },
    },
    grey: {
      white: '#FFFFFF',
      50: '#F2F2F2',
      100: '#EBEBEB',
      200: '#D7D7D7',
      300: '#D9D9D9',
      400: '#B4B4B4',
      500: '#A7A7A7',
      600: '#919191',
      700: '#808080',
      800: '#595959',
      900: '#444444',
      1000: '#242424',
      black: '#000000',
      'white-rgb': '255, 255, 255',
      'black-rgb': '0, 0, 0',
    },
    special: {
      background: '#F2F2F2',
      overlay: '#B4B4B4',
      contrastBackground: '#0A2756',
      contrastPrimaryLight: '#66B0FF',
      contrastPrimary: '#007CFF',
      contrastPrimaryDark: '#006AD2',
    },
    text: {
      text: '#444444',
      textMuted: '#626262',
      textLink: '#007976',
      contrastText: '#FFFFFFE5',
      contrastTextMuted: '#FFFFFFBF',
      contrastTextLink: '#00B5B1',
      textInfo: '#006AD2',
      textError: '#D64A1D',
      textWarning: '#B87F00',
      textSuccess: '#118732',
    },
    dataVisualisation: {
      sequential: {
        S0: {
          100: '#EBEBEB',
          200: '#D7D7D7',
          300: '#C6C6C6',
          400: '#B4B4B4',
          500: '#A7A7A7',
          600: '#919191',
        },
        S1: {
          100: '#CAE5FF',
          200: '#A2D1FF',
          300: '#79BCFF',
          400: '#51A8FF',
          500: '#2894FF',
          600: '#0080FF',
        },
        S2: {
          100: '#FFD5C7',
          200: '#FEB59E',
          300: '#FB9676',
          400: '#F6784F',
          500: '#EF5B2B',
          600: '#D64A1D',
        },
        S3: {
          100: '#FFECC2',
          200: '#FFDC91',
          300: '#FFCC61',
          400: '#FFBA31',
          500: '#FFA800',
          600: '#DB9400',
        },
        S4: {
          100: '#CBE9CF',
          200: '#A4D7AD',
          300: '#7EC48C',
          400: '#59B06D',
          500: '#359C4F',
          600: '#118732',
        },
        S5: {
          100: '#C2F2F0',
          200: '#91E5E3',
          300: '#61D7D4',
          400: '#31C7C3',
          500: '#00B5B1',
          600: '#00A3A0',
        },
        S6: {
          100: '#D4DAE4',
          200: '#B7C0D1',
          300: '#99A7BD',
          400: '#7C8DA9',
          500: '#5F7395',
          600: '#425A80',
        },
      },
      comparative: {
        C0: '#A7A7A7',
        C1: '#2894FF',
        C2: '#EF5B2B',
        C3: '#FFA800',
        C4: '#359C4F',
        C5: '#00B5B1',
        C6: '#5F7395',
      },
      diverging: {
        D0: '#00A3A0',
        D1: '#26406B',
      },
    },
  },

  typography: {
    font: 'Poppins',
    sizes: {
      '5xl': {
        size: '36px',
        height: '40px',
      },
      '4xl': {
        size: '30px',
        height: '36px',
      },
      '3xl': {
        size: '24px',
        height: '32px',
      },
      '2xl': {
        size: '20px',
        height: '28px',
      },
      xl: {
        size: '18px',
        height: '28px',
      },
      l: {
        size: '16px',
        height: '24px',
      },
      m: {
        size: '14px',
        height: '20px',
      },
      s: {
        size: '12px',
        height: '16px',
      },
      xs: {
        size: '10px',
        height: '16px',
      },
    },
    weights: {
      bold: 600,
      medium: 500,
      regular: 400,
    },
    boxShadows: {
      '3xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
      '2xl': '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
      xl: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      l: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
      m: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
      s: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
      inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
    },
    borderRadius: {
      card: '30px',
      dropdown: '2px',
      popup: '30px',
      button: '2px',
      input: '2px',
    },
  },
}
