export enum Theme {
  CHOREOGRAPH = 'choreograph-theme',
  WAVEMAKER = 'wavemaker-theme',
  GREENHOUSE = 'greenhouse-theme',
  GROUPM = 'groupm-theme',
  MINDSHARE = 'mindshare-theme',
  MEDIACOM = 'mediacom-theme',
  MSIX = 'msix-theme',
  ESSENCE = 'essence-theme',
  WPP_OPEN = 'wpp-open-theme',
  WMS = 'wms-theme',
  NESTLE = 'nestle-theme',
  CLIENTS = 'clients-theme',
  LOREAL = 'loreal-theme',
  WPP_SNDBX = 'wpp-sndbx-theme',
  QATEST = 'qatest-theme',
  QA_TEST = 'qa-test-theme',
  GOOGLE = 'google-theme',
  DEMO = 'demo-theme',
  PAWS_AND_TAILS = 'pawsandtails-theme',
  AUDIBLE = 'audible-theme',
  ESSENCE_MEDIACOM = 'essence-mediacom-theme',
  CENTRAL = 'central-theme',
  WAVEMAKER_SNDBX = 'wavemaker-sndbx-theme',
  CARTIER_RICHEMONT_CN = 'cartier-richemont-cn-theme',
  CARTIER = 'cartier-theme',
  ALLIANZ = 'allianz-theme',
  THEANDPARTNERSHIP = 'theandpartnership-theme',
  ALDI = 'aldi-theme',
  TERMINALSIX = 'terminalsix-theme',
  LVMH = 'lvmh-theme',
  LVMH_APAC = 'lvmh-apac-theme',
  LVMH_CN = 'lvmh-cn-theme',
  MERCEDES_BENZ_CN = 'mercedes-benz-cn-theme',
  TUI = 'tui-theme',
  UNILEVER = 'unilever-theme',
  MEDIACOM_SNDBX = 'mediacom-sndbx-theme',
  VMLYR = 'vmlyr-theme',
  TFL = 'tfl-theme',
  WPP_PITCH_OS = 'wpppitchos-theme',
  TIFFANY = 'tiffany-theme',
  SANDBOX_CHOREOGRAPH = 'sandbox-choreograph-theme',
  OPPEN_IKEA = 'oppen-ikea-theme',
  WBA = 'wba-theme',
  SANDBOX_VMLYR = 'sandbox-vmlyr-theme',
  VOLKSWAGEN = 'volkswagen-theme',
  AMAZON = 'amazon-theme',
  ANZU = 'anzu-theme',
  VMLYR_COMMERCE = 'vmly&r-commerce-theme',
}

export const DEFAULT_THEME = Theme.CHOREOGRAPH

export enum LegacyTenantCode {
  CHOREOGRAPH = 'choreograph',
  WAVEMAKER = 'wavemaker',
  GREENHOUSE = 'greenhouse',
  GROUPM = 'groupm',
  MINDSHARE = 'mindshare',
  MEDIACOM = 'mediacom',
  MSIX = 'msix',
  ESSENCE = 'essence',
  WPP_OPEN = 'wpp-open',
  WMS = 'wms',
  NESTLE = 'nestle',
  CLIENTS = 'clients',
  LOREAL = 'loreal',
  WPP_SNDBX = 'sandbox-wpp-open',
  QATEST = 'qatest',
  QA_TEST = 'qatestcustomised',
  GOOGLE = 'google',
  DEMO = 'demo',
  PAWS_AND_TAILS = 'pawsandtails',
  AUDIBLE = 'audible',
  ESSENCE_MEDIACOM = 'essence-mediacom',
  CENTRAL = 'central',
  WAVEMAKER_SNDBX = 'sandbox-wavemaker',
  CARTIER_RICHEMONT_CN = 'cartier-richemont-china',
  CARTIER = 'cartier',
  ALLIANZ = 'allianz',
  THEANDPARTNERSHIP = 'msix',
  ALDI = 'aldi',
  TERMINALSIX = 'easyjet',
  LVMH = 'lvmh',
  LVMH_APAC = 'lvmh-apac',
  LVMH_CN = 'lvmh-cn',
  MERCEDES_BENZ_CN = 'mercedes-benz-cn',
  TUI = 'tui',
  UNILEVER = 'unilever',
  MEDIACOM_SNDBX = 'sandbox-mediacom',
  VMLYR = 'vmly&r',
  VMLYR_COMMERCE = 'vmlyr-commerce',
  TFL = 'tfl',
  WPP_PITCH_OS = 'wpppitchos',
  TIFFANY = 'tiffany',
  SANDBOX_CHOREOGRAPH = 'sandbox-choreograph',
  OPPEN_IKEA = 'oppen-ikea',
  WBA = 'wba',
  SANDBOX_VMLYR = 'sandbox-vmly&r',
  VOLKSWAGEN = 'volkswagen',
  AMAZON = 'amazon',
  ANZU = 'anzu',
}

export interface LegacyTenant {
  name: string
  code: LegacyTenantCode
  defaultTheme: Theme
  mainColor?: string
  id?: string
  organisationsId?: string
  applicationsId?: string
}

export const legacyTenants: LegacyTenant[] = [
  {
    name: 'Choreograph',
    code: LegacyTenantCode.CHOREOGRAPH,
    defaultTheme: Theme.CHOREOGRAPH,
    mainColor: '#ff5c00',
  },
  {
    name: 'Wavemaker',
    code: LegacyTenantCode.WAVEMAKER,
    defaultTheme: Theme.WAVEMAKER,
    mainColor: '#ed6b31',
  },
  {
    name: 'Greenhouse',
    code: LegacyTenantCode.GREENHOUSE,
    defaultTheme: Theme.GREENHOUSE,
    mainColor: '#6300a7',
  },
  {
    name: 'GroupM',
    code: LegacyTenantCode.GROUPM,
    defaultTheme: Theme.GROUPM,
    mainColor: '#007cff',
  },
  {
    name: 'MindShare',
    code: LegacyTenantCode.MINDSHARE,
    defaultTheme: Theme.MINDSHARE,
    mainColor: '#791c99',
  },
  {
    name: 'MediaCom',
    code: LegacyTenantCode.MEDIACOM,
    defaultTheme: Theme.MEDIACOM,
    mainColor: '#e1005d',
  },
  {
    name: 'Essence',
    code: LegacyTenantCode.ESSENCE,
    defaultTheme: Theme.ESSENCE,
    mainColor: '#ea533c',
  },
  {
    name: 'mSix',
    code: LegacyTenantCode.MSIX,
    defaultTheme: Theme.MSIX,
    mainColor: '#009FE3',
  },
  {
    name: 'WPP Open',
    code: LegacyTenantCode.WPP_OPEN,
    defaultTheme: Theme.WPP_OPEN,
    mainColor: '#7800db',
  },
  {
    name: 'WPP Media Services',
    code: LegacyTenantCode.WMS,
    defaultTheme: Theme.WMS,
    mainColor: '#007cff',
  },
  {
    name: 'Nestle',
    code: LegacyTenantCode.NESTLE,
    defaultTheme: Theme.NESTLE,
    mainColor: '#007cff',
  },
  {
    name: 'Clients',
    code: LegacyTenantCode.CLIENTS,
    defaultTheme: Theme.CLIENTS,
    mainColor: '#007cff',
  },
  {
    name: "L'Oreal",
    code: LegacyTenantCode.LOREAL,
    defaultTheme: Theme.LOREAL,
    mainColor: '#007cff',
  },
  {
    name: 'Sandbox WPP Open',
    code: LegacyTenantCode.WPP_SNDBX,
    defaultTheme: Theme.WPP_SNDBX,
    mainColor: '#007cff',
  },
  {
    name: 'QATest',
    code: LegacyTenantCode.QATEST,
    defaultTheme: Theme.QATEST,
    mainColor: '#007cff',
  },
  {
    name: 'QATestCustomised',
    code: LegacyTenantCode.QA_TEST,
    defaultTheme: Theme.QA_TEST,
    mainColor: '#007cff',
  },
  {
    name: 'Google',
    code: LegacyTenantCode.GOOGLE,
    defaultTheme: Theme.GOOGLE,
    mainColor: '#007cff',
  },
  {
    name: 'Demo',
    code: LegacyTenantCode.DEMO,
    defaultTheme: Theme.DEMO,
    mainColor: '#007cff',
  },
  {
    name: 'Paws and Tails',
    code: LegacyTenantCode.PAWS_AND_TAILS,
    defaultTheme: Theme.PAWS_AND_TAILS,
    mainColor: '#007cff',
  },
  {
    name: 'Audible',
    code: LegacyTenantCode.AUDIBLE,
    defaultTheme: Theme.AUDIBLE,
    mainColor: '#007cff',
  },
  {
    name: 'Essence & Mediacom',
    code: LegacyTenantCode.ESSENCE_MEDIACOM,
    defaultTheme: Theme.ESSENCE_MEDIACOM,
    mainColor: '#007cff',
  },
  {
    name: 'Central',
    code: LegacyTenantCode.CENTRAL,
    defaultTheme: Theme.CENTRAL,
    mainColor: '#007cff',
  },
  {
    name: 'Sandbox Wavemaker',
    code: LegacyTenantCode.WAVEMAKER_SNDBX,
    defaultTheme: Theme.WAVEMAKER_SNDBX,
    mainColor: '#007cff',
  },
  {
    name: 'Cartier',
    code: LegacyTenantCode.CARTIER,
    defaultTheme: Theme.CARTIER,
    mainColor: '#007cff',
  },
  {
    name: 'Cartier-Richemont China',
    code: LegacyTenantCode.CARTIER_RICHEMONT_CN,
    defaultTheme: Theme.CARTIER_RICHEMONT_CN,
    mainColor: '#007cff',
  },
  {
    name: 'Allianz',
    code: LegacyTenantCode.ALLIANZ,
    defaultTheme: Theme.ALLIANZ,
    mainColor: '#007cff',
  },
  {
    name: 'The&Partnership',
    code: LegacyTenantCode.THEANDPARTNERSHIP,
    defaultTheme: Theme.THEANDPARTNERSHIP,
    mainColor: '#007cff',
  },
  {
    name: 'Aldi',
    code: LegacyTenantCode.ALDI,
    defaultTheme: Theme.ALDI,
    mainColor: '#007cff',
  },
  {
    name: 'EasyJet',
    code: LegacyTenantCode.TERMINALSIX,
    defaultTheme: Theme.TERMINALSIX,
    mainColor: '#007cff',
  },
  {
    name: 'LVMH',
    code: LegacyTenantCode.LVMH,
    defaultTheme: Theme.LVMH,
    mainColor: '#007cff',
  },
  {
    name: 'LVMH APAC',
    code: LegacyTenantCode.LVMH_APAC,
    defaultTheme: Theme.LVMH_APAC,
    mainColor: '#007cff',
  },
  {
    name: 'LVMH CN',
    code: LegacyTenantCode.LVMH_CN,
    defaultTheme: Theme.LVMH_CN,
    mainColor: '#007cff',
  },
  {
    name: 'Mercedes-Benz CN',
    code: LegacyTenantCode.MERCEDES_BENZ_CN,
    defaultTheme: Theme.MERCEDES_BENZ_CN,
    mainColor: '#007cff',
  },
  {
    name: 'TUI',
    code: LegacyTenantCode.TUI,
    defaultTheme: Theme.TUI,
    mainColor: '#007cff',
  },
  {
    name: 'Unilever',
    code: LegacyTenantCode.UNILEVER,
    defaultTheme: Theme.UNILEVER,
    mainColor: '#007cff',
  },
  {
    name: 'Sandbox Mediacom',
    code: LegacyTenantCode.MEDIACOM_SNDBX,
    defaultTheme: Theme.MEDIACOM_SNDBX,
    mainColor: '#007cff',
  },
  {
    name: 'VMLY&R',
    code: LegacyTenantCode.VMLYR,
    defaultTheme: Theme.VMLYR,
    mainColor: '#007cff',
  },
  {
    name: 'VMLY&R Commerce',
    code: LegacyTenantCode.VMLYR_COMMERCE,
    defaultTheme: Theme.VMLYR_COMMERCE,
    mainColor: '#007cff',
  },
  {
    name: 'TFL',
    code: LegacyTenantCode.TFL,
    defaultTheme: Theme.TFL,
    mainColor: '#007cff',
  },
  {
    name: 'WPP Pitch.OS',
    code: LegacyTenantCode.WPP_PITCH_OS,
    defaultTheme: Theme.WPP_PITCH_OS,
    mainColor: '#007cff',
  },
  {
    name: 'Tiffany',
    code: LegacyTenantCode.TIFFANY,
    defaultTheme: Theme.TIFFANY,
    mainColor: '#007cff',
  },
  {
    name: 'Sandbox Choreograph',
    code: LegacyTenantCode.SANDBOX_CHOREOGRAPH,
    defaultTheme: Theme.SANDBOX_CHOREOGRAPH,
    mainColor: '#007cff',
  },
  {
    name: 'IKEA',
    code: LegacyTenantCode.OPPEN_IKEA,
    defaultTheme: Theme.OPPEN_IKEA,
    mainColor: '#007cff',
  },
  {
    name: 'WBA',
    code: LegacyTenantCode.WBA,
    defaultTheme: Theme.WBA,
    mainColor: '#007cff',
  },
  {
    name: 'Sandbox VMLY&R',
    code: LegacyTenantCode.SANDBOX_VMLYR,
    defaultTheme: Theme.SANDBOX_VMLYR,
    mainColor: '#007cff',
  },
  {
    name: 'Volkswagen',
    code: LegacyTenantCode.VOLKSWAGEN,
    defaultTheme: Theme.VOLKSWAGEN,
    mainColor: '#007cff',
  },
  {
    name: 'Amazon',
    code: LegacyTenantCode.AMAZON,
    defaultTheme: Theme.AMAZON,
    mainColor: '#007cff',
  },
  {
    name: 'Anzu',
    code: LegacyTenantCode.ANZU,
    defaultTheme: Theme.ANZU,
    mainColor: '#007cff',
  },
]

export const DEFAULT_LEGACY_TENANT = legacyTenants.find(({ defaultTheme }) => defaultTheme === DEFAULT_THEME)!
