import { WppIconArrow } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { useDigitalProfile } from 'api/playground/queries/digital-profile/useDigitalProfile'
import { useGrowthExperiences } from 'api/playground/queries/digital-profile/useGrowthExperiences'
import { useUserSkillsStats } from 'api/playground/queries/digital-profile/useUserSkillsStats'
import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { ReactComponent as IconArrow } from 'pages/playground/playgroundDashboard/assets/icons/arrow-right.svg'
import { ReactComponent as IconCircleText } from 'pages/playground/playgroundDashboard/assets/icons/curve-text.svg'
import { ReactComponent as IconTextStar } from 'pages/playground/playgroundDashboard/assets/icons/underline-star.svg'
import BubbleFixed from 'pages/playground/playgroundDashboard/components/general/bubble/Bubble'
import { Button } from 'pages/playground/playgroundDashboard/components/general/button/Button'
import BubbleChart from 'pages/playground/playgroundDashboard/components/sections/sectionPath/bubbleChart/BubbleChart'
import styles from 'pages/playground/playgroundDashboard/components/sections/sectionPath/SectionPath.module.scss'
import stylesMain from 'pages/playground/playgroundDashboard/PlaygroundDashboard.module.scss'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'

interface Props {
  avatarUrl: string
}

export default function SectionPath({ avatarUrl }: Props) {
  const { data: digitalProfile = {}, isLoading: isLoadingDigitalProfile } = useDigitalProfile()
  const { data: growthExperiences = [], isLoading: isLoadingGrowthExperiences } = useGrowthExperiences()

  const { data: userSkillsStats } = useUserSkillsStats()

  const bubbles = useMemo(() => {
    return (
      userSkillsStats?.map(skill => ({
        id: skill.capabilityId,
        label: skill.capabilityTitle,
        value: skill.completedExperiences,
        color: skill.capabilityColor,
      })) || []
    )
  }, [userSkillsStats])

  const { userDetails } = useOtherTenantsAndUserData()

  const { firstname, lastname } = userDetails

  return (
    <>
      {digitalProfile?.isCompleted ? (
        <Flex align="stretch" className="tour-experience-profile-filled">
          {/* LEFT SIDE */}
          <div className={styles.leftContainer}>
            <div className={styles.leftInner}>
              <div className={stylesMain.blackLStrong}>
                <div className={stylesMain.relative}>
                  As you grow you change.
                  <div className={styles.bubbleFixed}>
                    <BubbleFixed text="Obviously." />
                  </div>
                </div>
                <div>
                  Keep your Experience Profile up to date to ensure the projects you attract match your current goals.
                </div>
              </div>

              <Flex align="center">
                <div className={styles.relativeMinus}>
                  <IconCircleText />
                  <div className={styles.customCircle}>
                    <Avatar size="xl" name={firstname || ''} src={avatarUrl} />
                  </div>
                </div>
                <div>
                  <div className={clsx(stylesMain.accentBlackTitle, stylesMain.mt20)}>
                    <div>{firstname}</div>
                    <div>{lastname}</div>
                  </div>
                </div>
              </Flex>

              <div className={styles.pr40}>
                <div className={clsx(stylesMain.black2xlHeading)}>Top Skills</div>
                <div className={clsx(stylesMain.blackXSBody, stylesMain.mt4)}>
                  When a project needs a hands-on, savvy associate with these skills, we know who to call. You’re kind
                  of a big deal.
                </div>
                <Flex direction="column" gap={12} className={stylesMain.mt12}>
                  {!isLoadingDigitalProfile &&
                    digitalProfile?.coreSkills?.slice(0, 5)?.map((skill: any, index: number) => (
                      <div
                        style={{ borderLeftColor: skill.skill?.capabilities[0].color as string }}
                        key={index}
                        className={styles.skill}
                      >
                        <div className={clsx(stylesMain.blackXSStrong)}>{skill.skill.name}</div>
                      </div>
                    ))}
                  {!isLoadingDigitalProfile &&
                    (digitalProfile?.coreSkills?.length === 0 || !digitalProfile?.coreSkills) && (
                      <div className={clsx(stylesMain.blackXSBody, styles.textWrapper)}>
                        You haven’t indicated any top skills yet.
                      </div>
                    )}
                  {isLoadingDigitalProfile && (
                    <div className={clsx(stylesMain.blackXSBody, styles.textWrapper)}>Loading...</div>
                  )}
                </Flex>

                <div className={clsx(stylesMain.black2xlHeading, stylesMain.mt40)}>Growth Experiences</div>
                <div className={clsx(stylesMain.blackXSBody, stylesMain.mt4)}>
                  Awesome picks! Return to update your choices and keep on growing.
                </div>
                <Flex direction="column" gap={12} className={stylesMain.mt12}>
                  {!isLoadingGrowthExperiences &&
                    growthExperiences?.slice(0, 5)?.map((skill: any, index: number) => (
                      <div
                        key={index}
                        className={styles.skill}
                        style={{ borderLeftColor: skill.experience.skills[0]?.capabilities[0].color as string }}
                      >
                        <div className={clsx(stylesMain.blackXSStrong)}>{skill?.experience?.name}</div>
                      </div>
                    ))}
                  {!isLoadingGrowthExperiences && growthExperiences.length === 0 && (
                    <div className={clsx(stylesMain.blackXSBody, styles.textWrapper)}>
                      You haven’t indicated any growth experiences yet.
                    </div>
                  )}
                  {isLoadingGrowthExperiences && (
                    <div className={clsx(stylesMain.blackXSBody, styles.textWrapper)}>Loading...</div>
                  )}
                </Flex>
              </div>
            </div>
          </div>
          <div className={styles.separator} />
          {/* RIGHT SIDE */}
          <div className={styles.rightContainer}>
            <div className={styles.leftInner}>
              <div className={clsx(stylesMain.white2xlHeading, stylesMain.relative)}>
                Your Capabilities
                <IconTextStar className={styles.iconTextStar} />
              </div>
              <div className={clsx(stylesMain.whiteXSBody, stylesMain.mt16)}>
                See your Experiences in a whole new way. We’ve visualized your self-assessment through our five
                marketing capabilities. Click each color to learn more and edit.
              </div>
              <div>
                {bubbles.length === 0 ? (
                  <div className={clsx(stylesMain.whiteXSBody, stylesMain.mt16)}>
                    You haven’t indicated any capabilities yet.
                  </div>
                ) : (
                  <Flex justify="center">
                    <BubbleChart color="#505050" data={bubbles} />
                  </Flex>
                )}

                {/* BUBBLE CHART */}
              </div>
              <Flex justify="center">
                <Link
                  to={
                    window.location.host.includes('playground-stage')
                      ? '/application/f9007058-00fa-4730-bfe6-a9ebf79d00ff/experience-profile_0a68023a-8e9d-177d-818e-c706516e0000_experience-profile'
                      : '/application/5d7a3dc2-54a1-44a4-bf61-f9c7a278ff5a/experience-profile_0a0c000e-907d-10df-8191-36ed8e410023_experience-profile'
                  }
                  className={stylesMain.mt42}
                >
                  <Button variant="tertiary" text="Go to Experience Profile" iconRight={<IconArrow />} />
                </Link>
              </Flex>
            </div>
          </div>
        </Flex>
      ) : (
        <Flex justify="center" className={clsx(styles.emptyContainer, 'tour-experience-profile-not-filled')}>
          <Flex justify="between" className={styles.emptyInner}>
            <Flex gap={40} align="center">
              <Avatar size="xl" name={firstname || ''} src={avatarUrl} className={styles.avatar} />
              <div className={styles.name}>
                <div className={stylesMain.preWrap}>{`${firstname}\n${lastname}`}</div>
              </div>
            </Flex>
            <div>
              <div className={clsx(stylesMain.white3Xl, stylesMain.relative)}>
                Build Your Experience Profile!
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="279"
                  height="7"
                  viewBox="0 0 279 7"
                  fill="none"
                  className={styles.underline}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M277.2 2.77041C222.224 -2.35595 166.644 3.10655 111.7 3.55974C75.3682 3.84984 38.9983 2.98616 2.69747 3.434C1.57708 5.4506 0.782189 2.63856 0.81175 4.10112C0.841968 5.56365 1.74954 6.73494 2.83718 6.71934C39.1341 6.27162 75.5 7.13542 111.828 6.84544C166.673 6.39526 222.148 0.927146 277.025 6.04771C278.113 6.14671 278.944 5.68032 279.005 4.22383C279.059 2.76753 278.282 2.87842 277.2 2.77041Z"
                    fill="#EBFF00"
                  />
                </svg>
              </div>

              <div className={clsx(stylesMain.whiteLBody, stylesMain.mt35, stylesMain.preWrap)}>
                {'Personalize your learning path, starting with your \nExperience Self-Assessment.'}
              </div>

              <Flex justify="end">
                <BubbleFixed text="It only takes 35 min!" direction="up" className={styles.bubble} />
              </Flex>

              <Link
                to={
                  window.location.host.includes('playground-stage')
                    ? '/application/f9007058-00fa-4730-bfe6-a9ebf79d00ff/experience-profile_0a68023a-8e9d-177d-818e-c706516e0000_experience-profile'
                    : '/application/5d7a3dc2-54a1-44a4-bf61-f9c7a278ff5a/experience-profile_0a0c000e-907d-10df-8191-36ed8e410023_experience-profile'
                }
                className={stylesMain.mt24}
              >
                <Button text="Start Your Self-Assessment" iconRight={<WppIconArrow color="black" />} />
              </Link>
            </div>
          </Flex>
        </Flex>
      )}
    </>
  )
}
