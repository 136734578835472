import { WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import styles from 'components/notificationsSideModal/EmptyState.module.scss'
import { SvgNoNotifications } from 'components/svg/SvgNoNotifications'

export const EmptyState = () => {
  const { t } = useTranslation()

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      gap={16}
      data-testid="notifications-empty-state"
      className={styles.emptyState}
    >
      <SvgNoNotifications />
      <Flex direction="column" align="center" justify="center">
        <WppTypography type="m-strong">{t('os.notification.empty_state')}</WppTypography>
        <WppTypography type="s-body" className={styles.description}>
          {t('os.notification.empty_state_description')}
        </WppTypography>
      </Flex>
    </Flex>
  )
}
