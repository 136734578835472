import qs from 'qs'

import { facadeApi } from 'api'
import { PaginatedResponse } from 'api/common/types'
import { skipEmptyParams } from 'api/common/utils'
import { Feed } from 'types/feeds/feeds'

interface Params {
  page?: number
  itemsPerPage?: number
  tenantIds?: string[]
  categories?: string[]
  createdAt?: string
  search?: string
}

export type FeedsListResponse = PaginatedResponse<Feed>

export const getFeedURL = (isWithSearch = false): string => (isWithSearch ? 'feeds/search' : 'feeds')

export const fetchFeedsApi = ({ page, itemsPerPage, categories, createdAt, tenantIds, search }: Params) =>
  facadeApi.get<FeedsListResponse>(getFeedURL(!!search), {
    params: skipEmptyParams({
      ...(search ? { keywords: search } : {}),
      page,
      itemsPerPage,
      filter: {
        categories,
        'createdAt>': createdAt,
        tenantId: tenantIds,
      },
    }),
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
  })
