import { facadeApi } from 'api'

interface Props {
  tenantId: string
  id: string
}

export const deleteFeedApi = ({ tenantId, id }: Props) => {
  return facadeApi.delete(`/feeds/${id}`, {
    headers: {
      'X-Tenant-Id': tenantId,
    },
  })
}
