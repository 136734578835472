import { useEffect } from 'react'
import { useAuth } from 'react-oidc-context'

import { LoadingPage } from 'layout/loadingPage/LoadingPage'

export const AuthCallback = () => {
  const { isLoading, error } = useAuth()

  const isSpoiledCallbackState = error?.message === 'No matching state found in storage'

  useEffect(() => {
    if (!isLoading && isSpoiledCallbackState) {
      window.location.assign('/')
    }
  }, [isLoading, isSpoiledCallbackState])

  return <LoadingPage />
}
