import { LegacyTheme } from 'legacy/styles/osTheming/models'
import { LegacyTenantCode } from 'legacy/types/osWebRootApi'

export const msixTheme: LegacyTheme = {
  name: 'mSix',
  code: LegacyTenantCode.MSIX,

  colors: {
    brand: {
      primary: {
        background: '#F2FBFF',
        lighter: '#99D9F4',
        light: '#31B4EB',
        normal: '#009FE3',
        dark: '#008ECB',
        darker: '#007AB2',
      },
      secondary: {
        background: '#F3F4F7',
        lighter: '#2A4579',
        light: '#4E5E79',
        normal: '#1D3054',
        dark: '#192C4E',
        darker: '#15233D',
      },
    },
    system: {
      information: {
        background: '#F2FBFF',
        lighter: '#61C7F3',
        light: '#31B4EB',
        normal: '#00A0E1',
        dark: '#008ECB',
        darker: '#007AB2',
      },
      error: {
        background: '#FFF5F6',
        lighter: '#FCE9E9',
        light: '#FF6678',
        normal: '#E94949',
        dark: '#DB3549',
        darker: '#B82638',
      },
      warning: {
        background: '#FFFAF2',
        lighter: '#FEF3E7',
        light: '#FFAD31',
        normal: '#F59733',
        dark: '#DB8600',
        darker: '#B87200',
      },
      success: {
        background: '#F5FBF3',
        lighter: '#ECF6F1',
        light: '#66B347',
        normal: '#62B688',
        dark: '#378D15',
        darker: '#378D15',
      },
    },
    grey: {
      white: '#FFFFFF',
      50: '#F2F2F2',
      100: '#EBEBEB',
      200: '#D7D7D7',
      300: '#D9D9D9',
      400: '#B4B4B4',
      500: '#A7A7A7',
      600: '#919191',
      700: '#808080',
      800: '#595959',
      900: '#444444',
      1000: '#242424',
      black: '#000000',
      'white-rgb': '255, 255, 255',
      'black-rgb': '0, 0, 0',
    },
    special: {
      background: '#F2F2F2',
      overlay: '#B4B4B4',
      contrastBackground: '#1D3054',
      contrastPrimaryLight: '#99D9F4',
      contrastPrimary: '#009FE3',
      contrastPrimaryDark: '#008ECB',
    },
    text: {
      text: '#444444',
      textMuted: '#626262',
      textLink: '#007AB2',
      contrastText: '#FFFFFFE5',
      contrastTextMuted: '#FFFFFFBF',
      contrastTextLink: '#31B4EB',
      textInfo: '#007AB2',
      textError: '#DB3549',
      textWarning: '#704800',
      textSuccess: '#2C7B0D',
    },
    dataVisualisation: {
      sequential: {
        S0: {
          100: '#EBEBEB',
          200: '#D7D7D7',
          300: '#C6C6C6',
          400: '#B4B4B4',
          500: '#A7A7A7',
          600: '#919191',
        },
        S1: {
          100: '#C2EBFD',
          200: '#91D9F9',
          300: '#61C7F3',
          400: '#31B4EB',
          500: '#00A0E1',
          600: '#008ECB',
        },
        S2: {
          100: '#FFCED5',
          200: '#FFAAB4',
          300: '#FF8796',
          400: '#FF6678',
          500: '#FF475C',
          600: '#DB3549',
        },
        S3: {
          100: '#FFE8C2',
          200: '#FFD591',
          300: '#FFC161',
          400: '#FFAD31',
          500: '#FF9900',
          600: '#DB8600',
        },
        S4: {
          100: '#D1EBC6',
          200: '#ACD99B',
          300: '#89C770',
          400: '#66B347',
          500: '#439E1F',
          600: '#378D15',
        },
        S5: {
          100: '#C2EBFD',
          200: '#91D9F9',
          300: '#61C7F3',
          400: '#31B4EB',
          500: '#00A0E1',
          600: '#008ECB',
        },
        S6: {
          100: '#D7DBE2',
          200: '#BBC2CE',
          300: '#A0A9B9',
          400: '#8590A4',
          500: '#69778F',
          600: '#4E5E79',
        },
      },
      comparative: {
        C0: '#A7A7A7',
        C1: '#007AB2',
        C2: '#FF475C',
        C3: '#FF9900',
        C4: '#439E1F',
        C5: '#00A0E1',
        C6: '#69778F',
      },
      diverging: {
        D0: '#00A0E1',
        D1: '#344564',
      },
    },
  },

  typography: {
    font: 'Lato',
    sizes: {
      '5xl': {
        size: '36px',
        height: '40px',
      },
      '4xl': {
        size: '30px',
        height: '36px',
      },
      '3xl': {
        size: '24px',
        height: '32px',
      },
      '2xl': {
        size: '20px',
        height: '28px',
      },
      xl: {
        size: '18px',
        height: '28px',
      },
      l: {
        size: '16px',
        height: '24px',
      },
      m: {
        size: '14px',
        height: '20px',
      },
      s: {
        size: '12px',
        height: '16px',
      },
      xs: {
        size: '10px',
        height: '16px',
      },
    },
    weights: {
      bold: 600,
      medium: 500,
      regular: 400,
    },
    boxShadows: {
      '3xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
      '2xl': '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
      xl: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      l: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
      m: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
      s: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
      inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
    },
    borderRadius: {
      card: '0px',
      dropdown: '0px',
      popup: '0px',
      button: '0px',
      input: '0px',
    },
  },
}
