import { LegacyTheme } from 'legacy/styles/osTheming/models'
import { LegacyTenantCode } from 'legacy/types/osWebRootApi'

export const choreographTheme: LegacyTheme = {
  name: 'Choreograph',
  code: LegacyTenantCode.CHOREOGRAPH,

  colors: {
    brand: {
      primary: {
        background: '#ffffff',
        lighter: '#00000033',
        light: '#FF7B31',
        normal: '#000000',
        dark: '#DB4C00',
        darker: '#B83D00',
      },
      secondary: {
        background: '#E4E4E7',
        lighter: '#9B4CFF',
        light: '#0DDEE5',
        normal: '#7F3DD3',
        dark: '#632EA7',
        darker: '#009DA3',
      },
    },
    system: {
      information: {
        background: '#F5F9FE',
        lighter: '#62A2EC',
        light: '#3285E4',
        normal: '#0368DB',
        dark: '#0059C4',
        darker: '#004AAA',
      },
      error: {
        background: '#FFE6D6',
        lighter: '#EB7A7E',
        light: '#FF4332',
        normal: '#DB2B31',
        dark: '#C21F25',
        darker: '#8B0D11',
      },
      warning: {
        background: '#FEFBD3',
        lighter: '#FFC762',
        light: '#FCDF25',
        normal: '#FFA202',
        dark: '#DB8E00',
        darker: '#B87900',
      },
      success: {
        background: '#E9FBD6',
        lighter: '#62B18B',
        light: '#4DB72F',
        normal: '#007D43',
        dark: '#00703A',
        darker: '#006131',
      },
    },
    grey: {
      white: '#FFFFFF',
      50: '#F5F5F5',
      100: '#E9E9E9',
      200: '#D7D7D7',
      300: '#E4E4E7',
      400: '#B4B4B4',
      500: '#A1A1AA',
      600: '#919191',
      700: '#808080',
      800: '#52525B',
      900: '#444444',
      1000: '#18181B',
      black: '#000000',
      'white-rgb': '255, 255, 255',
      'black-rgb': '0, 0, 0',
    },
    special: {
      background: '#E4E4E7',
      overlay: '#18181B4C',
      contrastBackground: '#000000',
      contrastPrimaryLight: '#99F5FF',
      contrastPrimary: '#66EFFF',
      contrastPrimaryDark: '#B83D00',
    },
    text: {
      text: '#262626',
      textMuted: '#626262',
      textLink: '#DB4C00',
      contrastText: '#FFFFFFE5',
      contrastTextMuted: '#FFFFFFBF',
      contrastTextLink: '#FF7B31',
      textInfo: '#1358C0',
      textError: '#B41823',
      textWarning: '#E39506',
      textSuccess: '#3A8974',
    },
    dataVisualisation: {
      sequential: {
        S0: {
          100: '#E9E9E9',
          200: '#D7D7D7',
          300: '#C6C6C6',
          400: '#B4B4B4',
          500: '#A3A3A3',
          600: '#919191',
        },
        S1: {
          100: '#C4DCF9',
          200: '#93BFF3',
          300: '#62A2EC',
          400: '#3285E4',
          500: '#0368DB',
          600: '#0059C4',
        },
        S2: {
          100: '#F8CCCE',
          200: '#F2A3A6',
          300: '#EB7A7E',
          400: '#E35257',
          500: '#DB2B31',
          600: '#C21F25',
        },
        S3: {
          100: '#FFEAC4',
          200: '#FFD893',
          300: '#FFC762',
          400: '#FFB431',
          500: '#FFA202',
          600: '#DB8E00',
        },
        S4: {
          100: '#C4E2D4',
          200: '#93CAAF',
          300: '#62B18B',
          400: '#319767',
          500: '#007D43',
          600: '#00703A',
        },
        S5: {
          100: '#FFD9C4',
          200: '#FFB993',
          300: '#FF9A62',
          400: '#FF7B31',
          500: '#FF5C00',
          600: '#DB4C00',
        },
        S6: {
          100: '#BAF7F9',
          200: '#8BF0F4',
          300: '#5DEAEE',
          400: '#0DDEE5',
          500: '#04CCD2',
          600: '#00B7BD',
        },
        S7: {
          100: '#E0C4F9',
          200: '#C693F3',
          300: '#AC62EC',
          400: '#9231E4',
          500: '#7800DB',
          600: '#6800C4',
        },
      },
      comparative: {
        C0: '#919191',
        C1: '#0368DB',
        C2: '#DB2B31',
        C3: '#FFA202',
        C4: '#007D43',
        C5: '#FF5C00',
        C6: '#04CCD2',
        C7: '#7800DB',
      },
      diverging: {
        D0: '#FF5C00',
        D1: '#05CAD1',
      },
    },
  },

  typography: {
    font: 'Inter',
    sizes: {
      '5xl': {
        size: '36px',
        height: '40px',
      },
      '4xl': {
        size: '30px',
        height: '36px',
      },
      '3xl': {
        size: '24px',
        height: '32px',
      },
      '2xl': {
        size: '20px',
        height: '28px',
      },
      xl: {
        size: '18px',
        height: '28px',
      },
      l: {
        size: '16px',
        height: '24px',
      },
      m: {
        size: '14px',
        height: '20px',
      },
      s: {
        size: '12px',
        height: '16px',
      },
      xs: {
        size: '10px',
        height: '16px',
      },
    },
    weights: {
      bold: 600,
      medium: 500,
      regular: 400,
    },
    boxShadows: {
      '3xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
      '2xl': '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
      xl: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      l: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
      m: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
      s: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
      inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
    },
    borderRadius: {
      card: '3px',
      dropdown: '3px',
      popup: '5px',
      button: '3px',
      input: '3px',
    },
  },
}
