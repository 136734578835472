import { useRef, useEffect, useCallback } from 'react'

export function useThrottleFn<T extends (...args: any) => void>(fn: T, wait: number): T {
  const lastTriggered = useRef<number>(0)
  const timeoutIdRef = useRef<number | undefined>(undefined)
  const fnRef = useRef(fn)

  fnRef.current = fn

  const cancel = useCallback(() => {
    clearTimeout(timeoutIdRef.current)
    timeoutIdRef.current = undefined
  }, [])

  useEffect(() => cancel, [cancel])

  return useCallback(
    (...args: any[]) => {
      const remainingTime = lastTriggered.current + wait - Date.now()
      const execute = () => {
        lastTriggered.current = Date.now()
        cancel()
        fnRef.current(...args)
      }

      if (remainingTime <= 0) {
        execute()
      } else if (!timeoutIdRef.current) {
        timeoutIdRef.current = window.setTimeout(() => {
          execute()
        }, remainingTime)
      }
    },
    [cancel, wait],
  ) as unknown as T
}
