import {
  CompactAppContext,
  CompactAppContextListener,
  FullscreenAppContext,
  FullscreenAppContextListener,
} from '@wpp-open/core'
import PubSub from 'pubsub-js'
import { MutableRefObject, useCallback, useEffect } from 'react'
import { NOT_MOUNTED, SingleSpaCustomEventDetail } from 'single-spa'

import { unregisterAllMicroApps } from 'utils/singleSpa'

const formatFullscreenAppId = (appId: string) => `FULLSCREEN:${appId}`
const formatCompactAppId = (appId: string) => `COMPACT:${appId}`

export const useSubscriptionEffects = ({
  fullscreenSubscribersRef,
  fullscreenAppContextValue,
  compactSubscribersRef,
  compactAppContextValue,
}: {
  fullscreenSubscribersRef: MutableRefObject<Set<string>>
  fullscreenAppContextValue: FullscreenAppContext
  compactSubscribersRef: MutableRefObject<Set<string>>
  compactAppContextValue: CompactAppContext
}) => {
  // Publish fullscreen app context changes
  useEffect(() => {
    fullscreenSubscribersRef.current.forEach(appId => {
      const safeContextValue = structuredClone(fullscreenAppContextValue)

      PubSub.publish(appId, safeContextValue)
    })
  }, [fullscreenAppContextValue, fullscreenSubscribersRef])

  // Publish compact app context changes
  useEffect(() => {
    compactSubscribersRef.current.forEach(appId => {
      const safeContextValue = structuredClone(compactAppContextValue)

      PubSub.publish(appId, safeContextValue)
    })
  }, [compactAppContextValue, compactSubscribersRef])

  // Cleanup app subscriptions for apps that are no longer mounted
  useEffect(() => {
    const handleUnsubscribeFullscreenAppOnUnmount = ({ detail }: CustomEvent<SingleSpaCustomEventDetail>) => {
      detail.appsByNewStatus[NOT_MOUNTED].forEach(appId => {
        const fullscreenAppId = formatFullscreenAppId(appId)

        PubSub.unsubscribe(fullscreenAppId)
        fullscreenSubscribersRef.current.delete(fullscreenAppId)
      })
    }

    window.addEventListener('single-spa:before-app-change', handleUnsubscribeFullscreenAppOnUnmount)

    const fullscreenSubscriberNames = fullscreenSubscribersRef.current
    const compactSubscriberNames = compactSubscribersRef.current

    // Cleanup is called only when we enter public routes
    return () => {
      fullscreenSubscriberNames.forEach(appId => {
        PubSub.unsubscribe(appId)
      })
      compactSubscriberNames.forEach(appId => {
        PubSub.unsubscribe(appId)
      })

      window.removeEventListener('single-spa:before-app-change', handleUnsubscribeFullscreenAppOnUnmount)

      // As we cancel app subscriptions, we also unregister the apps
      unregisterAllMicroApps()
    }
  }, [compactSubscribersRef, fullscreenSubscribersRef])
}

export const useHandleSubscribeToFullscreenAppContext = ({
  fullscreenSubscribersRef,
  fullscreenAppContextValueRef,
}: {
  fullscreenSubscribersRef: MutableRefObject<Set<string>>
  fullscreenAppContextValueRef: MutableRefObject<FullscreenAppContext>
}) => {
  return useCallback(
    (appId: string, listener: FullscreenAppContextListener) => {
      const fullscreenAppId = formatFullscreenAppId(appId)
      const subscriberToken = PubSub.subscribe(fullscreenAppId, (msg, value) => {
        listener(value)
      })
      const safeContextValue = structuredClone(fullscreenAppContextValueRef.current)

      // Initial publish when apps subscribes to the context
      PubSub.publish(fullscreenAppId, safeContextValue)
      fullscreenSubscribersRef.current.add(fullscreenAppId)

      return () => {
        PubSub.unsubscribe(subscriberToken)

        const wasLast = PubSub.countSubscriptions(fullscreenAppId) === 0

        if (wasLast) {
          fullscreenSubscribersRef.current.delete(fullscreenAppId)
        }
      }
    },
    [fullscreenAppContextValueRef, fullscreenSubscribersRef],
  )
}

export const useHandleSubscribeToCompactAppContext = ({
  compactSubscribersRef,
  compactAppContextValueRef,
}: {
  compactSubscribersRef: MutableRefObject<Set<string>>
  compactAppContextValueRef: MutableRefObject<CompactAppContext>
}) => {
  return useCallback(
    (appId: string, listener: CompactAppContextListener) => {
      const compactAppId = formatCompactAppId(appId)
      const subscriberToken = PubSub.subscribe(compactAppId, (msg, value) => {
        listener(value)
      })
      const safeContextValue = structuredClone(compactAppContextValueRef.current)

      // Initial publish when apps subscribes to the context
      PubSub.publish(compactAppId, safeContextValue)
      compactSubscribersRef.current.add(compactAppId)

      return () => {
        PubSub.unsubscribe(subscriberToken)

        const wasLast = PubSub.countSubscriptions(compactAppId) === 0

        if (wasLast) {
          compactSubscribersRef.current.delete(compactAppId)
        }
      }
    },
    [compactAppContextValueRef, compactSubscribersRef],
  )
}
