/* eslint-disable @typescript-eslint/no-unused-vars */
import { WppIconChevron } from '@platform-ui-kit/components-library-react'
import { animated, useSpring } from '@react-spring/web'
import { useQueryClient } from '@tanstack/react-query'
import { excludeFalsy } from '@wpp-open/content-builder'
import { AnalyticsActionType } from '@wpp-open/core'
import clsx from 'clsx'
import { useEffect, useMemo, useRef, useState } from 'react'

import { useGenerateUserDetailsDownloadUrlsApi } from 'api/attachments/queries/useGenerateUserDetailsDownloadUrlsApi'
import { useCreateNotificationApi } from 'api/notifications/mutations/useCreateNotificationApi'
import { convertMTAssignmentResponseArrayToMTAssignmentArray } from 'api/workfront/fetchers/matchAndThrive/fetchAssignments'
import { useMTAssignments } from 'api/workfront/queries/matchAndThrive/useFetchAssignments'
import { useFetchWorkfrontApprovals } from 'api/workfront/queries/useFetchWorkfrontApprovals'
import { useWorkfrontAssignmentsApi } from 'api/workfront/queries/useFetchWorkfrontAssignmentsApi'
import { useWorkfrontAssignmentsProgress } from 'api/workfront/queries/useFetchWorkfrontAssignmentsProgress'
import { useWorkfrontProjectsApi } from 'api/workfront/queries/useFetchWorkfrontProjectsApi'
import { useWorkfrontSummaryApi } from 'api/workfront/queries/useFetchWorkfrontSummaryApi'
import { Flex } from 'components/common/flex/Flex'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { environment } from 'environment'
import { ReactComponent as BackToTop } from 'pages/playground/playgroundDashboard/assets/icons/back-to-top.svg'
import { Button } from 'pages/playground/playgroundDashboard/components/general/button/Button'
import { SectionAssignmentsHeader } from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/Header/Header'
import { SectionAssignments } from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/SectionAssignments'
import { SectionNewFeatures } from 'pages/playground/playgroundDashboard/components/sections/sectionNewFeatures/SectionNewFeatures'
import { SectionNews } from 'pages/playground/playgroundDashboard/components/sections/sectionNews/SectionNews'
import SectionPath from 'pages/playground/playgroundDashboard/components/sections/sectionPath/SectionPath'
import { SectionProjectSummaryGraph } from 'pages/playground/playgroundDashboard/components/sections/sectionProjectSummary/graph/Graph'
import { SectionProjectSummary } from 'pages/playground/playgroundDashboard/components/sections/sectionProjectSummary/SectionProjectSummary'
import { EVENTS } from 'pages/playground/playgroundDashboard/constants/events'
import { OverlayProvider } from 'pages/playground/playgroundDashboard/contexts/OverlayContext'
import styles from 'pages/playground/playgroundDashboard/PlaygroundDashboard.module.scss'
import { adjustTextSize } from 'pages/playground/playgroundDashboard/utils/fitText'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { trackAnalytics } from 'utils/analytics'

export interface Props {
  projectFocused: boolean
  actionFocused: boolean
  marketingFocused: boolean
}

export const PlaygroundDashboard = ({ projectFocused, actionFocused, marketingFocused }: Props) => {
  const { userDetails } = useOtherTenantsAndUserData()

  const queryClient = useQueryClient()

  const [hoursOfDay] = useState(new Date().getHours())
  const [isProjectSummaryVisible, setIsProjectSummaryVisible] = useState(projectFocused)
  const [selectedProjectId, setSelectedProjectId] = useState('')
  const assignmentsRef = useRef<HTMLDivElement>(null)

  const [projectsToLoad, setProjectsToLoad] = useState<string[]>([])
  const [projectsLoaded, setProjectsLoaded] = useState(false)

  const { data: workfrontProjects, error: errorProjects, isLoading: isLoadingProjects } = useWorkfrontProjectsApi()

  const {
    data: workfrontSummary,
    error: errorProjectSummary,
    isLoading: isLoadingProjectSummary,
  } = useWorkfrontSummaryApi({
    params: { projectId: selectedProjectId },
    enabled: workfrontProjects.length > 0,
  })

  const { data: assignments, error: errorAssignments, isLoading: isLoadingAssignments } = useWorkfrontAssignmentsApi()

  const {
    data: mtAssignments,
    error: errorMtAssignments,
    isLoading: isLoadingMtAssignments,
  } = useMTAssignments({ params: { page: 1, limit: 1000 } })

  const { data: approvals, error: errorApprovals, isLoading: isLoadingApprovals } = useFetchWorkfrontApprovals()

  const { data: assignmentsProgress } = useWorkfrontAssignmentsProgress({
    // enabled: false,
    enabled: projectsToLoad.length > 0 && !projectsLoaded,
    refetchInterval: 5000,
    refetchIntervalInBackground: true, // Stop polling when the browser tab is inactive
    params: { projectsToLoad: projectsToLoad },
  })

  const [filterAssignments, setFilterAssignments] = useState('View All')
  const [sortAssignmentsBy, setSortAssignmentsBy] = useState('Due Date')

  const [headerTitle, setHeaderTitle] = useState('HELLO AGAIN')
  const [maxHeaderSize, setHeaderSize] = useState(250)

  // On Mounted:
  useEffect(() => {
    trackAnalytics({
      type: AnalyticsActionType.page,
      payload: EVENTS.PLAYGROUND_HOMEPAGE,
    })

    const timestampDiff = new Date().getTime() - new Date(userDetails.createdAt).getTime()
    // const timestampDiff = new Date().getTime() - new Date('2024-07-11T09:03:47.668703+00:00').getTime()
    const timesStampDiffMinutes = timestampDiff / 1000 / 60

    if (timesStampDiffMinutes < 5) {
      setHeaderTitle('WELCOME')
      setHeaderSize(300)
    } else {
      setHeaderTitle('HELLO AGAIN')
      setHeaderSize(250)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (workfrontProjects.length > 0 && selectedProjectId === '') {
      setSelectedProjectId(workfrontProjects[0]?.ID)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workfrontProjects])

  useEffect(() => {
    if (!isProjectSummaryVisible && projectFocused) {
      setTimeout(() => {
        scrollToAssignments()
      }, 250)
    }
  }, [isProjectSummaryVisible, projectFocused])

  useEffect(() => {
    if (!projectsLoaded && assignments.length > 0) {
      const projects = assignments.map(({ project }) => project.ID)
      const uniqueIds = [...new Set(projects)]

      if (uniqueIds.length !== projectsToLoad.length) {
        setProjectsToLoad(uniqueIds)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignments])

  useEffect(() => {
    if (assignmentsProgress.length > 0) {
      const allCompleted = assignmentsProgress.every(({ completed }) => completed)
      if (allCompleted) {
        queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.WORKFRONT_ASSIGNMENTS] })
        setTimeout(() => {
          setProjectsLoaded(true)
        }, 500)
      }
    } else if (assignmentsProgress.length === 0) {
      setTimeout(() => {
        setProjectsLoaded(true)
      }, 500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentsProgress])

  const { avatarOriginal, avatarThumbnail } = userDetails
  const keys = [avatarOriginal, avatarThumbnail].filter(excludeFalsy).map(({ key }) => key)

  const { data: avatarsDownloadInfo } = useGenerateUserDetailsDownloadUrlsApi({
    params: { keys },
    enabled: !!keys.length,
  })
  const [avatarOriginalInfo, avatarThumbnailInfo] = avatarsDownloadInfo
  const avatarUrl = avatarThumbnailInfo?.signed_url || avatarOriginalInfo?.signed_url

  const [overlayVisible, setOverlayVisible] = useState(false)
  const overlayValues = {
    visible: overlayVisible,
    setVisible: () => {
      setOverlayVisible(prev => !prev)
    },
  }

  // const [selectedProjectTabIndex] = useState(0)
  // const [selectedProjectTabIndex, setSelectedProjectTabIndex] = useState(0)

  const newsBgStyles = useSpring({
    background: hoursOfDay < 12 ? '#FC4D0F' : 'linear-gradient(180deg, #9C26C1 0%, #25276F 100%)',
  })

  const circleBgStyles = useSpring({
    background:
      hoursOfDay < 12
        ? 'linear-gradient(180deg, #FC4D0F 0%, #940000 100%)'
        : 'linear-gradient(180deg, #25276F 0%, #260066 100%)',
    height: '40dvh',
  })

  const headerHeight =
    Object.keys(workfrontSummary).length == 0
      ? 650
      : 650 + 300 + 300 * workfrontSummary.lateTasks?.length + 300 * workfrontSummary.risks?.length

  const circleWrapperBgStyles = useSpring({
    maxHeight: `${headerHeight * +isProjectSummaryVisible}px`,
    background: 'white',
    opacity: isProjectSummaryVisible ? 1 : 0,
    display: isProjectSummaryVisible ? 'block' : 'none',
    // overflow: 'scroll',
  })

  const circleStyles = useSpring({
    background: isProjectSummaryVisible
      ? 'linear-gradient(180deg, #fbedd6 0%, #ffffff 100%)'
      : 'linear-gradient(180deg, #ffffff 0%, #ffffff 100%)',
  })

  const openProjectSummaryStyles = useSpring({
    opacity: isProjectSummaryVisible ? 0 : 1,
  })

  const assignmentsStyles = useSpring({
    marginTop: isProjectSummaryVisible ? '0dvh' : '-40dvh',
  })

  const containerRefHomePage = useRef<HTMLDivElement>(null)
  const textRefHomePage = useRef<HTMLDivElement>(null)

  const containerRefMatchAndThrive = useRef<HTMLDivElement>(null)
  const textRefMatchAndThrive = useRef<HTMLDivElement>(null)

  const containerRefWhatsComingHeader = useRef<HTMLDivElement>(null)
  const textRefWhatsComingHeader = useRef<HTMLDivElement>(null)

  // Initial adjustment
  adjustTextSize(containerRefHomePage.current, textRefHomePage.current, maxHeaderSize)
  adjustTextSize(containerRefMatchAndThrive.current, textRefMatchAndThrive.current, 190)
  adjustTextSize(containerRefWhatsComingHeader.current, textRefWhatsComingHeader.current, 220)

  // Adjust text size on window resize to maintain responsiveness
  window.addEventListener('resize', () => {
    adjustTextSize(containerRefHomePage.current, textRefHomePage.current, maxHeaderSize)
    adjustTextSize(containerRefMatchAndThrive.current, textRefMatchAndThrive.current, 190)
    adjustTextSize(containerRefWhatsComingHeader.current, textRefWhatsComingHeader.current, 220)
  })

  const scrollToAssignments = (bottom = false) => {
    if (bottom) {
      setTimeout(() => {
        assignmentsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }, 100)
      return
    }
    assignmentsRef.current?.scrollIntoView({ behavior: 'smooth' })
    trackAnalytics({
      type: AnalyticsActionType.action,
      payload: EVENTS.PLAYGROUND_VIEW_ASSIGNMENTS,
    })
  }

  const { mutateAsync: handleCreateNotification } = useCreateNotificationApi()
  const handleNotification = () => {
    /**
     * {
  "title": "string",
  "body": "string",
  "recipients": [
    {
      "type": "USER",
      "values": [
        "user@example.com"
      ]
    }
  ],
  "deliveryChannels": [
    "FEED"
  ]
}
     */
    handleCreateNotification({
      notification: {
        title: 'Hello',
        body: 'This is a test notification',
        recipients: {
          users: ['user@example.com'],
          groups: [],
        },
        scheduledAt: null,
        tenantId: environment.PLAYGROUND_TENANT_ID,
      },
    })
  }

  return (
    <div id="modals" className={styles.whiteBg}>
      {/* <AzApiProvider> */}
      <OverlayProvider value={overlayValues}>
        {/* Header Title */}
        <div className={styles.containerText} ref={containerRefHomePage}>
          <div ref={textRefHomePage} className={styles.weirdHeader}>
            {headerTitle}
          </div>
        </div>

        {/* News */}
        <animated.div style={newsBgStyles} className={styles.relative}>
          <div className={styles.container}>
            <SectionNews
              avatarUrl={avatarUrl}
              assignmentsNumber={
                marketingFocused
                  ? assignments?.length + mtAssignments?.length + approvals?.length
                  : assignments?.length + approvals?.length
              }
              lateTasksNumber={assignments.filter(assignment => assignment.taskStatus === 'LATE').length}
              scrollToAssignments={scrollToAssignments}
              loadingAssignments={isLoadingAssignments}
            />
          </div>
        </animated.div>

        {(projectFocused || actionFocused) && (
          <animated.div style={circleBgStyles} className={styles.relative}>
            <div className={clsx(styles.container, styles.z3, 'tour-home-project-health-overview')}>
              {isProjectSummaryVisible && (
                <SectionProjectSummary
                  selectedProjectId={selectedProjectId}
                  projects={workfrontProjects}
                  setSelectedProjectId={setSelectedProjectId}
                  projectSummary={workfrontSummary}
                  workfrontError={(errorProjects || errorProjectSummary) as any}
                  loading={isLoadingProjects || isLoadingProjectSummary}
                  projectsLength={workfrontProjects.length}
                  onHide={() => {
                    setIsProjectSummaryVisible(false)
                    trackAnalytics({
                      type: AnalyticsActionType.action,
                      payload: EVENTS.PLAYGROUND_HIDE_SUMMARY,
                    })
                  }}
                />
              )}
            </div>
            <animated.div style={circleStyles} className={styles.arcTop} />
            {!isProjectSummaryVisible && projectFocused && (
              <animated.div
                style={openProjectSummaryStyles}
                className={clsx(styles.projectSummaryButton, 'tour-home-project-health-accordion')}
              >
                <Button
                  variant="tertiaryBig"
                  text="Your Project Summary"
                  iconRight={<WppIconChevron direction="down" color="black" />}
                  onClick={() => {
                    setIsProjectSummaryVisible(true)
                    trackAnalytics({
                      type: AnalyticsActionType.action,
                      payload: EVENTS.PLAYGROUND_SHOW_SUMMARY,
                    })
                  }}
                />
              </animated.div>
            )}
          </animated.div>
        )}

        {/* Your Project Summary */}
        <animated.div style={circleWrapperBgStyles} className={styles.relative}>
          <div className={styles.whiteBg}>
            <div
              className={styles.container}
              style={{
                // boxShadow: 'rgb(229, 229, 229) 0px 34px 34px -25px',
                boxShadow: '0px 8px 32px 0px #3939390D',
                borderRadius: '0px 0px 24px 24px',
              }}
            >
              <SectionProjectSummaryGraph
                projectSummary={workfrontSummary}
                workfrontError={(errorProjects || errorProjectSummary) as any}
                projectsLength={workfrontProjects.length}
                loading={isLoadingProjects || isLoadingProjectSummary}
              />
            </div>

            <Flex justify="center" className={styles.hideProjectHealthContainer}>
              <div className={styles.blackSStrong}>
                Hide Project Summary
                <Flex justify="center" className={styles.mt16}>
                  <BackToTop
                    className={styles.pointer}
                    onClick={() => {
                      setIsProjectSummaryVisible(false)
                      trackAnalytics({
                        type: AnalyticsActionType.action,
                        payload: EVENTS.PLAYGROUND_HIDE_SUMMARY,
                      })
                    }}
                  />
                </Flex>
              </div>
            </Flex>
          </div>
        </animated.div>

        {(actionFocused || projectFocused) && (
          <animated.div style={assignmentsStyles} ref={assignmentsRef}>
            {/* Scroll Down Anchor */}
            <div className={clsx(styles.container, 'tour-home-assignments-overview')}>
              <SectionAssignmentsHeader
                filterAssignments={filterAssignments}
                setSelectedFilterAssignments={setFilterAssignments}
                workfrontError={errorAssignments as any}
                assignmentsLength={assignments.length || 0}
                sortAssingmentsBy={sortAssignmentsBy}
                setSortAssingmentsBy={setSortAssignmentsBy}
              />
              <div>
                <SectionAssignments
                  filterAssignments={filterAssignments}
                  sortAssingmentsBy={sortAssignmentsBy}
                  assignments={assignments}
                  approvals={approvals}
                  mtAssignments={
                    marketingFocused ? convertMTAssignmentResponseArrayToMTAssignmentArray(mtAssignments) : []
                  }
                  workfrontError={errorAssignments as any}
                  isLoading={isLoadingAssignments || isLoadingMtAssignments}
                  isLoadingProgress={!projectsLoaded}
                  scrollToAssignments={scrollToAssignments}
                />
              </div>
            </div>
          </animated.div>
        )}

        {marketingFocused && (
          <>
            <div className={styles.mt100} ref={containerRefMatchAndThrive}>
              <div ref={textRefMatchAndThrive} className={styles.weirdHeaderSecond}>
                CRAFT YOUR PATH
              </div>
            </div>

            <SectionPath avatarUrl={avatarUrl} />
          </>
        )}

        <div className={styles.mt100} ref={containerRefWhatsComingHeader}>
          <div ref={textRefWhatsComingHeader} className={styles.whatsComingHeader}>
            WHAT&#39;S COMING
          </div>
        </div>
        <div className="subheading" />
        <div className={styles.container}>
          <SectionNewFeatures />
        </div>

        <div className={styles.bgBlack}>
          <div className={styles.arcBottom} />
        </div>
      </OverlayProvider>

      {/* </AzApiProvider> */}
    </div>
  )
}
