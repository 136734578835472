import { WppTooltip, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { lightFormat } from 'date-fns'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import styles from 'components/notificationsSideModal/notificationHeader/NotificationHeader.module.scss'
import { isToday } from 'components/notificationsSideModal/utils'
import { useDateLongFormat } from 'hooks/useDateLongFormat'
import { Feed } from 'types/feeds/feeds'

interface Props {
  createdAt: Feed['createdAt']
  title?: string
}

export const NotificationHeader = ({ createdAt, title }: Props) => {
  const { t } = useTranslation()
  const { formatDate } = useDateLongFormat()

  const todayTime = lightFormat(new Date(createdAt), 'HH:mm')

  const headerTime = isToday(createdAt) ? t('os.notification.today_at', { todayTime }) : formatDate(createdAt)

  return (
    <Flex gap={4} data-testid="notification-header" className={styles.header}>
      <WppTooltip text={title ? title : t('os.notification.os')} className={styles.labelTooltip}>
        <WppTypography className={clsx(styles.greyTypography, styles.label)} type="xs-strong">
          {title ? title : t('os.notification.os')}
        </WppTypography>
      </WppTooltip>
      <WppTypography className={styles.greyTypography} type="xs-body">
        {`∙ ${headerTime}`}
      </WppTypography>
    </Flex>
  )
}
