import { useEffect } from 'react'
import { useFavicon, useTitle } from 'react-use'

import { Portal } from 'components/common/portal/Portal'
import { useTenantLogoAndMetaData } from 'hooks/useTenantLogoAndMetaData'

interface Props {
  themeFonts: string[]
}

export const HeadData = ({ themeFonts }: Props) => {
  const { title, description, favicon } = useTenantLogoAndMetaData()

  useTitle(title)
  useFavicon(favicon)

  useEffect(() => {
    const node = document.querySelector('meta[name="description"]') || document.createElement('meta')
    node.setAttribute('name', 'description')
    node.setAttribute('content', description)
    document.head.appendChild(node)
  }, [description])

  return (
    <>
      {themeFonts.length > 0 && (
        <Portal target={document.head}>
          {themeFonts.map(fontUrl => (
            <link key={fontUrl} rel="stylesheet" href={fontUrl} />
          ))}
        </Portal>
      )}
    </>
  )
}
