import { WppIconArrow, WppIconHandOn } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'

import { AssignmentActionTypeEnum, MTAssignment } from 'api/workfront/fetchers/matchAndThrive/fetchAssignments'
import { Flex } from 'components/common/flex/Flex'
import { ReactComponent as IconCardBorder } from 'pages/playground/playgroundDashboard/assets/icons/card-border.svg'
import { ReactComponent as IconCalendar } from 'pages/playground/playgroundDashboard/assets/icons/project/calendar.svg'
import { Button } from 'pages/playground/playgroundDashboard/components/general/button/Button'
import styles from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/approvalCard/ApprovalCard.module.scss'
import stylesMain from 'pages/playground/playgroundDashboard/PlaygroundDashboard.module.scss'

interface Props {
  assignment: MTAssignment
}

export const MtAssignmentCard = ({ assignment }: Props) => {
  const navigate = useNavigate()
  const formatDate = (dateString: string) => {
    // Hack for invalid date from workfront
    const dateStringTmp = dateString?.substring(0, dateString.lastIndexOf(':'))
    const date = new Date(dateStringTmp)
    const monthName = date.toLocaleString('default', { month: 'long' })
    const day = date.getDate()
    return `${monthName.toLocaleUpperCase()} ${day}`
  }

  return (
    <div className={clsx(styles.container, stylesMain.mt40)}>
      <Flex gap={0} justify="between">
        <Flex justify="between" className={clsx(styles.containerLeft, styles[assignment.taskStatus])}>
          <div className={styles.containerLeftInner}>
            <Flex justify="between">
              <div className={clsx(stylesMain.blackUnderline, stylesMain.mt10, styles.assignmentName)}>
                {assignment.name}
              </div>
            </Flex>

            <Flex className={clsx(stylesMain.mt20, styles.assignmentSections)} align="start" gap={24}>
              <Flex align="center" gap={8}>
                <WppIconHandOn color="black" />
                <div>MATCH & THRIVE</div>
              </Flex>
              <Flex align="center" gap={8}>
                <IconCalendar color="black" />
                {assignment.taskStatus === 'LATE' ? (
                  <div className={styles.assignmentSectionsLate}>{`ASSIGNMENT PAST DUE: ${formatDate(
                    assignment.dueDate,
                  )}`}</div>
                ) : (
                  <div>{`ASSIGNMENT DUE: ${formatDate(assignment.dueDate)}`}</div>
                )}
              </Flex>
            </Flex>
          </div>

          <div>
            <IconCardBorder className={styles.separator} />
          </div>
        </Flex>

        <Flex className={styles.containerRight} direction="column" justify="center" gap={16}>
          {assignment?.actions?.map((action, index) => (
            <div key={index} className={stylesMain['text-center']}>
              {action.type === AssignmentActionTypeEnum.NAVIGATE && (
                <Button
                  onClick={() => navigate(action.url)}
                  text={action.name}
                  variant="tertiary"
                  iconRight={<WppIconArrow color="black" />}
                />
              )}
            </div>
          ))}

          {/* <div className={stylesMain['text-center']}>
              <Link to={`${environment.WORKFRONT_URL}?location=/task/${assignment.ID}`}>
                <Button text="Open Task" variant="tertiary" />
              </Link>
            </div> */}
        </Flex>
      </Flex>
    </div>
  )
}
