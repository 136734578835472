export enum LegacyAppWindowLibraryName {
  SpeedDate = 'speedDateApp',
  Contacts = 'os-contacts',
  OsAdmin = 'os-admin',
  AudienceDB = 'audienceDataBuilder',
  MediaPlanner = 'mediaplanner',
  Audiences = 'audiencesApp',
  Touchpoints = 'touchpointsApp',
  CampaignsCreate = 'briefsApp',
  CampaignsList = 'campaignsUiApp',
  MediaPlansComparison = 'mediaPlansComparisonApp',
  WorkspaceManagement = 'os-workspace-management-app',
  IDNOnboarding = 'idxConsumerDataHome',
  MarketAdmin = 'marketAdminApp',
  Cart = 'wmCart',
  DataSourcesUpload = 'data-sources-upload',
  Documentation = 'mxmz-documentation',
  Scorecards = 'scorecardsApp',
  BAV = 'bavApp',
  AudiencesExplorer = 'audiences-viewer-ui',
  AudienceTranslator = 'audiencetranslator',
  AudienceComposer = 'audienceComposerLib',
  AudienceBuilder = 'audienceBuilderLib',
  AudienceLibrary = 'audienceLib',
  AudienceProfiler = 'audienceprofiler',
  MediaPlansResults = 'mpResultsApp',
  MediaPlansUploader = 'mpUploaderApp',
  ArchitectJunior = 'architect-junior-app',
  ProteusOnboarding = 'proteusOnboarding',
  Opportunities = 'growthOpportunitiesApp',
  RetailCompass = 'retail-compass',
  RFCurvesAdmin = 'architect-rf-curves-admin-app',
}
