import { lazy } from 'react'
import { Route } from 'react-router-dom'

import { SuspenseLoader } from 'components/common/SuspenseLoader'
import { DevResetQueries } from 'providers/osQueryClient/DevResetQueries'
import { routesManager } from 'utils/routesManager'

const GenericTenantRoutes = lazy(() => import('routes/genericTenantRoutes/GenericTenantRoutes'))

const genericTenantRoutesElement = (
  <SuspenseLoader>
    <GenericTenantRoutes />
  </SuspenseLoader>
)

export const genericTenantRoutes = process.env.DEV ? (
  <>
    <Route path={`${routesManager.generic.root.routePath}/*`} element={genericTenantRoutesElement} />
    {/**
     * Enable dev routes for generic tenant.
     * Other routes will only be rendered when the loaded data is related to generic tenant
     * instead of a specific one. This happens during auth callback or when the user
     * clicks a browser's back button.
     */}
    {process.env.DEV && <Route path="*" element={<DevResetQueries />} />}
  </>
) : (
  <Route path="*" element={genericTenantRoutesElement} />
)
