import { Route } from 'react-router-dom'

import WorkfrontAuth from 'pages/playground/workfrontAuth/WorkfrontAuth'
import { TermsOfService } from 'pages/termsOfService/TermsOfService'
import { routesManager } from 'utils/routesManager'

export const publicRoutes = (
  <>
    <Route path={routesManager.public.terms.routePath} element={<TermsOfService />} />

    <Route path="user/profile/auth/workfront">
      <Route index element={<WorkfrontAuth />} />
    </Route>
  </>
)
