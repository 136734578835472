import styles from 'components/connection/lineConnection/LineConnection.module.scss'
import { LineConnectionArrow } from 'components/connection/lineConnectionArrow/LineConnectionArrow'
import { FromToCoords } from 'types/connection/connection'

interface Props {
  withArrow?: boolean
  arrowLength?: number
  stroke?: string
  strokeWidth?: number
  turnRadiusMax?: number
  coordinates: FromToCoords
}

export const LineConnection = ({
  withArrow = true,
  arrowLength = 3,
  stroke = 'var(--wpp-dataviz-color-seq-brand-500)',
  strokeWidth = 1,
  turnRadiusMax = 30,
  coordinates,
}: Props) => {
  const distanceX = coordinates.end.x - coordinates.start.x
  const distanceY = coordinates.end.y - coordinates.start.y

  const isBackwards = distanceX < 0
  const diagonalFactor = distanceX * distanceY >= 0 ? 1 : -1
  const directionFactor = isBackwards ? -1 : 1

  const turnRadiusX = (distanceX - arrowLength * directionFactor) / 2
  const turnRadiusY = distanceY / 2
  const turnRadiusCommon = Math.min(Math.abs(turnRadiusX), Math.abs(turnRadiusY))
  const turnRadius = Math.min(turnRadiusCommon, turnRadiusMax)

  const distanceToTurn = (distanceX - arrowLength * directionFactor) / 2 - turnRadius * directionFactor

  const path = `
    M ${coordinates.start.x} ${coordinates.start.y}
    h ${distanceToTurn}
    q ${turnRadius * directionFactor} 0
    ${turnRadius * directionFactor} ${turnRadius * directionFactor * diagonalFactor}
    V ${coordinates.end.y - turnRadius * directionFactor * diagonalFactor}
    q 0 ${turnRadius * directionFactor * diagonalFactor}
    ${turnRadius * directionFactor} ${turnRadius * directionFactor * diagonalFactor}
    H ${coordinates.end.x}`

  return (
    <div className={styles.root}>
      <svg className={styles.canvas} width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
        <path d={path} stroke={stroke} strokeLinecap="round" strokeWidth={strokeWidth} fill="transparent" />

        {withArrow && (
          <LineConnectionArrow
            tip={coordinates.end}
            size={arrowLength}
            stroke={stroke}
            strokeWidth={strokeWidth}
            rotateAngle={isBackwards ? 180 : 0}
          />
        )}
      </svg>
    </div>
  )
}
