import axios, { AxiosInstance, CreateAxiosDefaults, RawAxiosRequestConfig } from 'axios'

import { CancelableRequestProducer } from 'api/common/types'

export class ApiInstance {
  client: AxiosInstance

  constructor(config?: CreateAxiosDefaults) {
    const client = axios.create(config)

    this.client = client
  }

  get<T extends unknown = unknown>(url: string, config?: RawAxiosRequestConfig): CancelableRequestProducer<T> {
    return signal => this.client.get(url, { ...config, signal })
  }

  post<T extends unknown = unknown>(
    url: string,
    data?: any,
    config?: RawAxiosRequestConfig,
  ): CancelableRequestProducer<T> {
    return signal => this.client.post(url, data, { ...config, signal })
  }

  put<T extends unknown = unknown>(
    url: string,
    data?: any,
    config?: RawAxiosRequestConfig,
  ): CancelableRequestProducer<T> {
    return signal => this.client.put(url, data, { ...config, signal })
  }

  patch<T extends unknown = unknown>(
    url: string,
    data?: any,
    config?: RawAxiosRequestConfig,
  ): CancelableRequestProducer<T> {
    return signal => this.client.patch(url, data, { ...config, signal })
  }

  delete<T extends unknown = unknown>(url: string, config?: RawAxiosRequestConfig): CancelableRequestProducer<T> {
    return signal => this.client.delete(url, { ...config, signal })
  }
}

export const createApi = (config?: CreateAxiosDefaults) => new ApiInstance(config)
