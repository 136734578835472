import { WppActionButton, WppCard, WppSpinner, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { Trans, useTranslation } from 'react-i18next'

import { useHubAccessRequestApi } from 'api/accessRequests/queries/useHubAccessRequestApi'
import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import styles from 'components/notificationItemSideModal/NotificationItemSideModal.module.scss'
import { RequestStatusDetails } from 'components/notificationItemSideModal/notificationSideModalContent/components/RequestStatusDetails'
import { isCompleted } from 'components/notificationItemSideModal/notificationSideModalContent/utils'
import { Requests } from 'constants/requests'
import { showApproveHubAccessRequestModal } from 'pages/admin/requests/hubAccessRequests/approveHubAccessRequestModal/ApproveHubAccessRequestModal'
import { showRejectHubAccessRequestModal } from 'pages/admin/requests/hubAccessRequests/rejectHubAccessRequestModal/RejectHubAccessRequestModal'
import { FeedContext } from 'types/feeds/feeds'
import { routesManager } from 'utils/routesManager'

interface Props {
  isOpen: boolean
  context: FeedContext
  isAvatarLoading: boolean
  src?: string
}

export const HubRequestedSideModalContent = ({ isOpen, context, isAvatarLoading, src }: Props) => {
  const { t } = useTranslation()
  const { request_id, user_name, user_email, tenant_id, request_reason, hub_name } = context

  const { data } = useHubAccessRequestApi({
    params: { id: request_id!, tenantId: tenant_id },
    enabled: isOpen && !!request_id,
  })

  return (
    <>
      <WppTypography type="s-body" data-testid="notification-item-content-tenant-requested">
        <Trans
          i18nKey="os.notification.access.hub.requested_access"
          values={{
            userName: `<0>${user_name}</0>`,
            hubName: `<1>${hub_name}</1>`,
          }}
          components={[
            <WppTypography key="0" type="s-strong">
              userName
            </WppTypography>,
            <WppTypography key="1" type="s-strong">
              tenantName
            </WppTypography>,
          ]}
        />
      </WppTypography>
      <Flex gap={4} direction="column">
        <WppCard size="s" variant="secondary">
          <Flex direction="column" gap={12}>
            <Flex direction="column" gap={8}>
              <Flex align="center" gap={8}>
                {isAvatarLoading ? (
                  <Flex className={styles.loader} justify="center" align="center">
                    <WppSpinner size="m" />
                  </Flex>
                ) : (
                  <Avatar size="xs" name={user_name} src={src} data-testid="notification-item-avatar" />
                )}
                <WppTypography type="s-body" data-testid="notification-item-email">
                  {user_email}
                </WppTypography>
              </Flex>

              {request_reason && (
                <WppTypography
                  type="xs-body"
                  className={clsx(styles.greyTypography, styles.message)}
                  data-testid="notification-item-request-reason"
                >
                  {request_reason}
                </WppTypography>
              )}
            </Flex>
          </Flex>
        </WppCard>
        {isCompleted(data?.status) ? (
          <RequestStatusDetails
            requestType={Requests.HUB_ACCESS}
            allRequestsUrl={routesManager.admin.requests.hubAccess.completed.url()}
            status={data?.status}
          />
        ) : (
          <Flex gap={8}>
            {data && (
              <>
                <WppActionButton
                  loading={!data}
                  onClick={() =>
                    data && showApproveHubAccessRequestModal({ request: data, getAttachmentUrlByKey: () => src! })
                  }
                  data-testid="notification-item-approve-button"
                >
                  {t('os.notification.access.hub.approve')}
                </WppActionButton>
                <WppActionButton
                  loading={!data}
                  onClick={() =>
                    data && showRejectHubAccessRequestModal({ request: data, getAttachmentUrlByKey: () => src! })
                  }
                  data-testid="notification-item-reject-button"
                >
                  {t('os.notification.access.hub.reject')}
                </WppActionButton>
              </>
            )}
          </Flex>
        )}
      </Flex>
    </>
  )
}
