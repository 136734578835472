import { WppTypography } from '@platform-ui-kit/components-library-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import styles from 'components/notificationsSideModal/EmptyState.module.scss'
import { SvgNoSearchResults } from 'components/svg/SvgNoSearchResults'

interface Props {
  search: string
}

export const EmptySearchState: React.FC<Props> = ({ search }) => {
  const { t } = useTranslation()

  return (
    <Flex direction="column" align="center" justify="center" gap={16} data-testid="notifications-empty-search-state">
      <SvgNoSearchResults />
      <Flex direction="column" align="center" justify="center">
        <WppTypography type="m-strong">{t('os.notification.empty_search', { search })}</WppTypography>
        <WppTypography type="s-body" className={styles.description}>
          {t('os.notification.empty_search_description')}
        </WppTypography>
      </Flex>
    </Flex>
  )
}
