import { Permission } from 'constants/permission'
import { hasAccessToMaximize, NavigationConfigFunctions } from 'legacy/navigation/common'
import { navigation as defaultNavigation } from 'legacy/navigation/default'
import { LegacyPermission } from 'legacy/navigation/permission'
import { hasPermission } from 'legacy/navigation/utils'
import { AccountPermissions, NavigationSection } from 'legacy/types/osWebRootApi'

export const navigation: NavigationConfigFunctions = {
  ...defaultNavigation,
  avatarNavigation: (permissions: AccountPermissions[]): NavigationSection => [
    {
      title: 'OS_HEADER.HEADER.ADMINISTRATION',
      nav: [
        {
          title: 'OS_HEADER.HEADER.APP_SETTING',
          hidden: !hasPermission(permissions, Permission.APPS_MARKET_MANAGE),
          path: '/apps/admin-panel',
        },
      ],
    },
    {
      title: 'OS_HEADER.HEADER.PERMISSIONS',
      nav: [
        {
          title: 'OS_HEADER.HEADER.ROLES',
          hidden: !hasPermission(permissions, LegacyPermission.AdminAppAccess),
          path: '/os-admin/panel/roles',
        },
        {
          title: 'OS_HEADER.HEADER.USERS',
          hidden: !hasPermission(permissions, LegacyPermission.AdminAppAccess),
          path: '/os-admin/panel/users',
        },
        {
          title: 'OS_HEADER.HEADER.GROUPS',
          hidden: !hasPermission(permissions, LegacyPermission.AdminAppAccess),
          path: '/os-admin/panel/groups',
        },
      ],
    },
    {
      title: 'OS_HEADER.HEADER.USER',
      nav: [
        {
          title: 'OS_HEADER.HEADER.PROFILE',
          disabled: !hasPermission(permissions, Permission.OS_USERDETAILS_APP_ACCESS),
          path: '/user/profile',
        },
        {
          title: 'OS_HEADER.HEADER.ACCOUNT_SETTINGS',
          path: '/user/settings',
        },
        {
          title: 'OS_HEADER.HEADER.FEEDBACK',
          disabled: true,
          path: 'userFeedback',
        },
        {
          title: 'OS_HEADER.HEADER.LOGOUT',
          path: 'logout',
        },
      ],
    },
  ],
  main: (permissions: AccountPermissions[], countryCode?: string): NavigationSection => [
    {
      title: 'Marketing',
      nav: [
        {
          title: 'Budget',
          nav: [
            {
              title: 'Budget Setting Toolkit',
              disabled: true,
            },
            {
              title: 'Portfolio Allocator',
              disabled: true,
            },
            {
              title: 'SOV vs. SOM',
              disabled: true,
            },
            {
              title: 'mBudget',
              disabled: true,
            },
          ],
          disabled: true,
        },
        {
          title: 'Audience & Task',
          nav: [
            {
              title: 'Time To Grow',
              disabled: true,
            },
            {
              title: 'Growth Audit',
              disabled: true,
            },
            {
              title: 'Audience Insight Matrix (AIM)',
              disabled: true,
            },
          ],
          disabled: true,
        },
        {
          title: 'Architecture',
          nav: [
            {
              title: 'Comms Task Guidance',
              disabled: true,
            },
            {
              title: 'Growth Maps',
              disabled: true,
            },
          ],
          disabled: true,
        },
        {
          title: 'Shape',
          nav: [
            {
              title: 'Timing Heatmap',
              disabled: true,
            },
          ],
          disabled: true,
        },
      ],
    },
    {
      title: 'Campaign',
      breadcrumbTitle: 'OS_HEADER.NAVIGATION_MAIN.OPTIMISE',
      nav: [
        {
          title: 'Base Plan',
          nav: [
            { title: 'Base Plan Matrix', disabled: true },
            { title: 'Odyssey', disabled: true },
            { title: 'Abacus', disabled: true },
            { title: 'MMM Optimisation', disabled: true },
            { title: 'mScreen', disabled: true },
          ],
        },
        {
          title: 'Architect',
          nav: [
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.AUDIENCES',
              path: '/audiences/map',
              disabled:
                !hasAccessToMaximize(countryCode) ||
                !hasPermission(permissions, LegacyPermission.MxmzAudiencesAppAccess),
            },
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.TOUCHPOINTS',
              path: '/touchpoints/touchpoints',
              disabled:
                !hasAccessToMaximize(countryCode) ||
                !hasPermission(permissions, LegacyPermission.MxmzTouchpointsAppAccess),
            },
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.ARCHITECT_JUNIOR',
              path: '/junior',
              disabled:
                !hasAccessToMaximize(countryCode) || !hasPermission(permissions, LegacyPermission.MxmzJuniorAppAccess),
              hidden:
                !hasAccessToMaximize(countryCode) || !hasPermission(permissions, LegacyPermission.MxmzJuniorAppAccess),
            },
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.CAMPAIGNS_PARENT',
              disabled:
                !hasAccessToMaximize(countryCode) ||
                (!hasPermission(permissions, LegacyPermission.MxmzCampaignsAppAccess) &&
                  !hasPermission(permissions, LegacyPermission.MxmzMediaPlansComparisonAppAccess)),
              nav: [
                {
                  title: 'OS_HEADER.NAVIGATION_MAIN.CAMPAIGNS',
                  path: '/briefs/list',
                  disabled: !hasPermission(permissions, LegacyPermission.MxmzCampaignsAppAccess),
                },
                {
                  title: 'OS_HEADER.NAVIGATION_MAIN.MP_UPLOADER',
                  path: '/custom-campaigns/list',
                  hidden: !hasPermission(permissions, LegacyPermission.MxmzMediaPlansUploaderAppAccess),
                },
                {
                  title: 'OS_HEADER.NAVIGATION_MAIN.MP_COMPARISON',
                  path: '/mp-comparison/list',
                  disabled: !hasPermission(permissions, LegacyPermission.MxmzMediaPlansComparisonAppAccess),
                },
              ],
            },
          ],
        },
        {
          title: 'Signals of Differentiation',
          nav: [
            {
              title: 'Differentiation Matrix',
              disabled: true,
            },
          ],
        },
      ],
    },
    {
      title: 'Outcomes',
      nav: [
        { title: 'Opportunity Signals', disabled: true },
        { title: 'Measurement Framework', disabled: true },
      ],
    },
  ],
}
