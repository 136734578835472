import { WppCard, WppTypography } from '@platform-ui-kit/components-library-react'

import { AvatarWithNameAndEmailCell } from 'components/cellRenderers/avatarWithNameAndEmail/AvatarWithNameAndEmailCell'
import { Flex } from 'components/common/flex/Flex'
import { useUsernameFormat } from 'hooks/useUsernameFormat'
import styles from 'pages/admin/requests/usersInfoCard/UsersInfoCard.module.scss'
import { HierarchyAccessRequest, HubAccessRequest, SignUpRequest, TenantAccessRequest } from 'types/requests/requests'

interface Props {
  request: HierarchyAccessRequest | HubAccessRequest | TenantAccessRequest | SignUpRequest
  date?: string
  getAttachmentUrlByKey?: (key?: string) => string
}

export const UsersInfoCard = ({ request, date, getAttachmentUrlByKey }: Props) => {
  const { formatUsername } = useUsernameFormat()
  const { firstname, lastname, email, avatarThumbnail } = request?.userData || {}

  const { firstName, lastName, createdBy } = request || {}

  const userName = formatUsername(firstname || firstName, lastname || lastName)

  return (
    <WppCard className={styles.card}>
      <Flex direction="column" gap={8}>
        <AvatarWithNameAndEmailCell
          email={email || createdBy || ''}
          userName={userName}
          avatarThumbnail={getAttachmentUrlByKey ? getAttachmentUrlByKey(avatarThumbnail?.key) : ''}
          emailTypographyType="s-body"
        />
        {'requestReason' in request && (
          <Flex direction="column">
            <WppTypography type="s-body">{request.requestReason}</WppTypography>
          </Flex>
        )}
        {date && (
          <Flex direction="column">
            <WppTypography type="xs-body" className={styles.date}>
              {date}
            </WppTypography>
          </Flex>
        )}
      </Flex>
    </WppCard>
  )
}
