import { AttachmentMetadata, AttachmentMetadataResponse } from './attachments'
import { MayBeNull } from './common'
import { ProjectType } from './project'

export enum VersionType {
  PRODUCTION = 'PRODUCTION',
  DEMO = 'DEMO',
  DEVELOPMENT = 'DEVELOPMENT',
}

export enum DevHubProductType {
  NATIVE_APPLICATION = 'NATIVE_APPLICATION',
  NO_CODE_APPLICATION = 'NO_CODE_APPLICATION',
}

export enum NoCodeAppType {
  EmbeddedLink = 'EMBEDDED_LINK',
  ExternalLink = 'EXTERNAL_LINK',
  PageBuilder = 'PAGE_BUILDER',
  EmbeddedCode = 'EMBEDDED_CODE',
  MiroBoard = 'MIRO_BOARD',
  CodeUpload = 'CODE_UPLOAD',
}

export enum AppInstanceAssignmentType {
  Workspace = 'WORKSPACE',
  Project = 'PROJECT',
  Custom = 'CUSTOM',
}

export enum AppInstanceStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  VersionDevelopment = 'VERSION_DEVELOPMENT',
  VersionDeleted = 'VERSION_DELETED',
  VersionAvailabilityDisabled = 'VERSION_AVAILABILITY_DISABLED',
  VersionHierarchyLevelDisabled = 'VERSION_HIERARCHY_LEVEL_DISABLED',
}

// TODO: AppInstance typings should be refactored

export interface CustomContextField {
  key: string
  value: string
}

export interface AppInstanceDevHubMetadata<Config extends MayBeNull<{}>, CustomContext extends CustomContextField[]> {
  id: string
  name: string
  isTemplate: boolean
  shortDescription: string
  productType: DevHubProductType
  noCodeAppType: MayBeNull<NoCodeAppType>
  version: {
    id: string
    name: string
    description: MayBeNull<string>
    routePath: MayBeNull<string>
    versionType: VersionType
    jsBundleUrl: MayBeNull<string>
    jsLibraryBundleType: MayBeNull<string>
    customContext: CustomContext
    deleted: MayBeNull<boolean>
    dataContext: MayBeNull<string[]>
    config: Config
    externalConfigId: MayBeNull<string>
  }
  ownerName: MayBeNull<string>
  ownerEmail: MayBeNull<string>
  logoMeta: MayBeNull<{
    original: AttachmentMetadata
    thumbnail: AttachmentMetadata
  }>
  markets: {
    region: string
    markets: {
      marketCode: string
      marketTitle: string
    }[]
  }[]
}

interface ProjectContext {
  appId: string
  phaseId: string
  projectId: string
  projectName: string
  projectType: ProjectType
  taskIsCompleted: boolean
  workspaceId: string
  miroOwner: string
}

type AppInstanceAssignmentContext<WorkspaceContext extends {}> =
  | {
      assignmentType: AppInstanceAssignmentType.Workspace
      context: WorkspaceContext
    }
  | {
      assignmentType: AppInstanceAssignmentType.Custom
      context: WorkspaceContext
    }
  | {
      assignmentType: AppInstanceAssignmentType.Project
      context: ProjectContext
    }

type AppInstanceShortBase<
  Context extends {},
  Config extends MayBeNull<{}>,
  CustomContext extends CustomContextField[] = CustomContextField[],
> = AppInstanceAssignmentContext<Context> & {
  id: string
  tenantId: string
  devhubAppId: string
  assignmentId: string
  devhubAppVersionId: string
  devhubMetadata: AppInstanceDevHubMetadata<Config, CustomContext>
  osRoute: MayBeNull<string>
  status: AppInstanceStatus
  position: number
  group: MayBeNull<{
    id: string
    name: string
    description: MayBeNull<string>
    order: MayBeNull<number>
  }>
  subgroup: MayBeNull<{
    id: string
    name: string
    description: MayBeNull<string>
    order: MayBeNull<number>
  }>
  flags: {
    visible: boolean
    accessRequestable: boolean
  }
  name: MayBeNull<string>
  description: MayBeNull<string>
  logoThumbnail: MayBeNull<AttachmentMetadataResponse>
  logoOriginal: MayBeNull<AttachmentMetadataResponse>
  roles?: MayBeNull<string[]>
}

export type AppInstanceEmbeddedCodeShort = AppInstanceShortBase<
  {
    embeddedCode: string
  },
  {
    appType: NoCodeAppType.EmbeddedCode
    routePath: string
    embeddedCode: string
  }
>

export type AppInstanceEmbeddedLinkShort = AppInstanceShortBase<
  {
    url: string
  },
  {
    appType: NoCodeAppType.EmbeddedLink
    routePath: string
    url: string
  }
>

export type AppInstanceExternalLinkShort = AppInstanceShortBase<
  {
    url: string
  },
  {
    appType: NoCodeAppType.ExternalLink
    url: string
  }
>

export type AppInstancePageBuilderShort = AppInstanceShortBase<
  {},
  {
    appType: NoCodeAppType.PageBuilder
    routePath: string
  }
>

export type AppInstanceMiroShort = AppInstanceShortBase<
  {
    url?: string
  },
  {
    appType: NoCodeAppType.MiroBoard
    routePath: string
  }
>

export type AppInstanceCodeUploadShort = AppInstanceShortBase<
  {},
  {
    appType: NoCodeAppType.CodeUpload
    routePath: string
    homePageUrl: string
  }
>

export type AppInstanceNativeCustomContext = CustomContextField[]
export type AppInstanceNativeLegacyCustomContext = [
  { key: 'legacy'; value: 'true' },
  { key: 'windowLibraryName'; value: string },
  { key: 'containerId'; value: string },
]

export type AppInstanceNativeShort = AppInstanceShortBase<
  {},
  null,
  AppInstanceNativeCustomContext | AppInstanceNativeLegacyCustomContext
>

export type AppInstanceNoCodeShort =
  | AppInstanceEmbeddedCodeShort
  | AppInstanceEmbeddedLinkShort
  | AppInstanceExternalLinkShort
  | AppInstancePageBuilderShort
  | AppInstanceMiroShort
  | AppInstanceCodeUploadShort

export type AppInstanceShort = AppInstanceNoCodeShort | AppInstanceNativeShort

export type AppInstanceFull<T extends AppInstanceShort = AppInstanceShort> = T & {
  createdAt: string
  createdBy: string
  updatedAt: MayBeNull<string>
  updatedBy: MayBeNull<string>
}
