import styles from 'components/svg/common.module.scss'

export const SvgNoNotifications = (props: JSX.IntrinsicElements['svg']) => (
  <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx="60" cy="60" r="60" className={styles.fillPrimary100} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.3076 38.6543C21.9645 38.6543 23.3076 37.3112 23.3076 35.6543C24.9645 35.6543 26.3076 36.9974 26.3076 38.6543C26.3076 40.3112 24.9645 41.6543 23.3076 41.6543C21.6508 41.6543 20.3076 40.3112 20.3076 38.6543ZM20.3076 38.6543C20.3076 36.9974 21.6508 35.6543 23.3076 35.6543C23.3076 33.9974 21.9645 32.6543 20.3076 32.6543C18.6508 32.6543 17.3076 33.9974 17.3076 35.6543C17.3076 37.3112 18.6508 38.6543 20.3076 38.6543Z"
      className={styles.fillPrimary400}
    />
    <path
      d="M101.274 68.3102C101.606 67.0763 103.433 67.0763 103.766 68.3102L103.94 68.9569C104.061 69.4049 104.432 69.7504 104.903 69.8522L105.266 69.9306C106.604 70.22 106.604 72.0495 105.266 72.3389L104.903 72.4174C104.432 72.5191 104.061 72.8647 103.94 73.3126L103.766 73.9593C103.433 75.1933 101.606 75.1933 101.274 73.9593L101.099 73.3126C100.979 72.8647 100.607 72.5191 100.136 72.4174L99.7735 72.3389C98.4349 72.0495 98.4349 70.22 99.7735 69.9306L100.136 69.8522C100.607 69.7504 100.979 69.4049 101.099 68.9569L101.274 68.3102Z"
      className={styles.fillPrimary400}
    />
    <path
      d="M14.2735 46.1559C14.6061 44.922 16.433 44.922 16.7656 46.1559L16.9398 46.8026C17.0606 47.2506 17.4323 47.5961 17.9028 47.6979L18.2656 47.7763C19.6042 48.0657 19.6042 49.8952 18.2656 50.1846L17.9028 50.2631C17.4323 50.3648 17.0606 50.7104 16.9398 51.1583L16.7656 51.805C16.433 53.039 14.6061 53.039 14.2735 51.805L14.0992 51.1583C13.9785 50.7104 13.6068 50.3648 13.1362 50.2631L12.7735 50.1846C11.4349 49.8952 11.4349 48.0657 12.7735 47.7763L13.1362 47.6979C13.6068 47.5961 13.9785 47.2506 14.0992 46.8026L14.2735 46.1559Z"
      className={styles.fillPrimary300}
    />
    <circle cx="106.558" cy="60.75" r="2.25" className={styles.fillPrimary300} />
    <ellipse cx="60.1636" cy="21.4038" rx="4.90385" ry="4.90385" className={styles.fillPrimary500} />
    <ellipse cx="60.1638" cy="87.6057" rx="13.4856" ry="13.4856" className={styles.fillPrimary500} />
    <path
      d="M31.8907 46.9648L30.5463 63.6225C30.0974 69.1844 27.8868 74.4325 24.2626 78.5402L22.1467 80.9384C20.2538 83.0838 20.3201 86.404 22.297 88.4654C23.275 89.4852 24.6015 90.0582 25.9846 90.0582H42.9924H77.0081H94.016C95.3991 90.0582 96.7256 89.4852 97.7036 88.4654C99.6805 86.404 99.7468 83.0838 97.8539 80.9384L95.7379 78.5402C92.1138 74.4325 89.9032 69.1844 89.4543 63.6225L88.1099 46.9648C86.9435 32.5141 75.3548 21.4043 61.4473 21.4043H60.0003H58.5533C44.6458 21.4043 33.057 32.5141 31.8907 46.9648Z"
      className={styles.fillWhite}
    />
    <ellipse cx="60.2458" cy="87.6057" rx="13.4856" ry="13.4856" className={styles.fillPrimary500} />
    <g opacity="0.5" filter="url(#filter0_f_4084_966)">
      <path
        d="M29.0768 90.6923C27.8661 90.2887 28.1564 88.5 29.4326 88.5H90.5674C91.8436 88.5 92.1339 90.2887 90.9232 90.6923C90.8085 90.7305 90.6884 90.75 90.5674 90.75H29.4326C29.3116 90.75 29.1915 90.7305 29.0768 90.6923Z"
        className={styles.fillPrimary500}
      />
    </g>
    <g filter="url(#filter1_b_4084_966)">
      <path
        d="M31.8907 46.9648L30.5463 63.6225C30.0974 69.1844 27.8868 74.4325 24.2626 78.5402L22.1467 80.9384C20.2538 83.0838 20.3201 86.404 22.297 88.4654C23.275 89.4852 24.6015 90.0582 25.9846 90.0582H42.9924H77.0081H94.016C95.3991 90.0582 96.7256 89.4852 97.7036 88.4654C99.6805 86.404 99.7468 83.0838 97.8539 80.9384L95.7379 78.5402C92.1138 74.4325 89.9032 69.1844 89.4543 63.6225L88.1099 46.9648C86.9435 32.5141 75.3548 21.4043 61.4473 21.4043H60.0003H58.5533C44.6458 21.4043 33.057 32.5141 31.8907 46.9648Z"
        className={styles.fillWhite}
        fillOpacity="0.9"
      />
    </g>
    <path
      opacity="0.85"
      d="M85.5003 48.8658C93.0836 48.8658 99.2311 42.7184 99.2311 35.1351C99.2311 27.5518 93.0836 21.4043 85.5003 21.4043C77.917 21.4043 71.7695 27.5518 71.7695 35.1351C71.7695 42.7184 77.917 48.8658 85.5003 48.8658Z"
      className={styles.fillPrimary300}
    />
    <path
      d="M81.5771 35.1292C81.5771 33.759 81.8904 32.6819 82.5169 31.8978C83.1519 31.1137 84.1259 30.7217 85.439 30.7217C86.7434 30.7217 87.7131 31.1177 88.3482 31.9097C88.9832 32.6938 89.3008 33.7669 89.3008 35.1292C89.3008 36.4994 88.9832 37.5805 88.3482 38.3725C87.7131 39.1566 86.7434 39.5486 85.439 39.5486C84.1259 39.5486 83.1519 39.1566 82.5169 38.3725C81.8904 37.5805 81.5771 36.4994 81.5771 35.1292ZM86.4817 35.1292C86.4817 34.4956 86.413 34.0204 86.2757 33.7036C86.1384 33.3789 85.8595 33.2165 85.439 33.2165C85.0099 33.2165 84.7267 33.3789 84.5894 33.7036C84.4521 34.0204 84.3834 34.4956 84.3834 35.1292C84.3834 35.7707 84.4521 36.2539 84.5894 36.5786C84.7267 36.8954 85.0099 37.0538 85.439 37.0538C85.8595 37.0538 86.1384 36.8954 86.2757 36.5786C86.413 36.2539 86.4817 35.7707 86.4817 35.1292Z"
      className={styles.fillPrimary800}
    />
    <defs>
      <filter
        id="filter0_f_4084_966"
        x="20.3066"
        y="80.5"
        width="79.3867"
        height="18.25"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="4" result="effect1_foregroundBlur_4084_966" />
      </filter>
      <filter
        id="filter1_b_4084_966"
        x="10.9618"
        y="11.5966"
        width="98.0768"
        height="88.2697"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="4.90385" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4084_966" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_4084_966" result="shape" />
      </filter>
    </defs>
  </svg>
)
