import { PropsWithChildren } from 'react'
import { Outlet } from 'react-router-dom'

import { ForbiddenPageError } from 'components/renderError'
import { Permission } from 'constants/permission'
import { useHasPermission } from 'hooks/useHasPermission'
import { useUserCanManageHubs } from 'hooks/useUserCanManageHubs'

type Props = PropsWithChildren<{
  permission: Permission
}>

export const PermittedHubsAdminAccess = ({ permission, children = <Outlet /> }: Props) => {
  const { isUserCanManage: isUserCanManageHubs } = useUserCanManageHubs()
  const { hasPermission } = useHasPermission()

  return hasPermission(permission) || isUserCanManageHubs ? children : <ForbiddenPageError />
}
