import { DefaultHierarchyLevelType, NoCodeAppType } from '@wpp-open/core'

import { Permission } from 'constants/permission'
import { environment } from 'environment'
import { MicroAppLibraryType, MicroAppType, SystemAppCode, SystemMicroAppConfig } from 'types/apps/microApps'

export const MICRO_APP_DOM_ELEMENT_ID = 'micro-app'

export const LOCAL_APP_BASE_URL = 'local'
export const LOCAL_LEGACY_APP_BASE_URL = 'local-legacy'
export const LOCAL_LEGACY_CONTAINER_ID = 'local-legacy-dom-container'
export const LOCAL_APP_CODE = 'LOCAL'
export const LOCAL_LEGACY_APP_CODE = 'LOCAL_LEGACY'
export const LOCAL_APP_NAME = 'Local App'
export const LOCAL_APP_DEFAULT_URL_CONFIG = '8500/sjs/main'
export const LOCAL_LEGACY_APP_DEFAULT_URL_CONFIG = '8500/main/localApp'

export const systemApps: Record<SystemAppCode, SystemMicroAppConfig> = {
  [SystemAppCode.News]: {
    type: MicroAppType.System,
    name: 'News',
    stableId: SystemAppCode.News,
    osRoute: 'news',
    libraryType: MicroAppLibraryType.SystemJS,
    bundleUrl: environment.NEWS_BUNDLE_URL,
    // bundleUrl: 'http://localhost:8600/main.js',
    permission: null,
    requiredHierarchy: [DefaultHierarchyLevelType.Tenant],
    windowLibraryName: null,
  },
  [SystemAppCode.Marketplace]: {
    type: MicroAppType.System,
    name: 'Marketplace',
    stableId: SystemAppCode.Marketplace,
    osRoute: 'marketplace',
    libraryType: MicroAppLibraryType.SystemJS,
    bundleUrl: environment.MARKETPLACE_BUNDLE_URL,
    // bundleUrl: 'http://localhost:8600/main.js',
    permission: Permission.WPP_OPEN_MARKETPLACE_APP_ACCESS,
    requiredHierarchy: [DefaultHierarchyLevelType.Tenant],
    windowLibraryName: null,
  },
  [SystemAppCode.Orchestration]: {
    type: MicroAppType.System,
    name: 'Orchestration',
    stableId: SystemAppCode.Orchestration,
    osRoute: 'orchestration',
    libraryType: MicroAppLibraryType.SystemJS,
    bundleUrl: environment.ORCHESTRATION_BUNDLE_URL,
    permission: Permission.WPP_ORCHESTRATION_APP_ACCESS,
    requiredHierarchy: [DefaultHierarchyLevelType.Tenant],
    windowLibraryName: null,
  },
  [SystemAppCode.MasterData]: {
    type: MicroAppType.System,
    name: 'Master Data',
    stableId: SystemAppCode.MasterData,
    osRoute: 'master-data',
    libraryType: MicroAppLibraryType.SystemJS,
    bundleUrl: environment.MASTERDATA_BUNDLE_URL,
    permission: Permission.OS_MASTERDATA_ENTITIES_MODIFY,
    requiredHierarchy: [DefaultHierarchyLevelType.Tenant],
    windowLibraryName: null,
  },
  [SystemAppCode.DevHub]: {
    type: MicroAppType.System,
    name: 'Dev Hub',
    stableId: SystemAppCode.DevHub,
    osRoute: 'devhub',
    libraryType: MicroAppLibraryType.SystemJS,
    bundleUrl: environment.DEVHUB_BUNDLE_URL,
    permission: Permission.WPP_DEVHUB_APP_ACCESS,
    requiredHierarchy: [DefaultHierarchyLevelType.Tenant],
    windowLibraryName: null,
  },
  [SystemAppCode.ThemeBuilder]: {
    type: MicroAppType.System,
    name: 'Theme Builder',
    stableId: SystemAppCode.ThemeBuilder,
    osRoute: 'theme-builder',
    libraryType: MicroAppLibraryType.SystemJS,
    bundleUrl: environment.THEME_BUILDER_BUNDLE_URL,
    permission: null,
    requiredHierarchy: [DefaultHierarchyLevelType.Tenant],
    windowLibraryName: null,
  },
  [SystemAppCode.ProcessBuilder]: {
    type: MicroAppType.System,
    name: 'Process Builder',
    stableId: SystemAppCode.ProcessBuilder,
    osRoute: 'process',
    libraryType: MicroAppLibraryType.SystemJS,
    bundleUrl: environment.PROCESS_BUILDER_BUNDLE_URL,
    permission: null,
    requiredHierarchy: [DefaultHierarchyLevelType.Tenant],
    windowLibraryName: null,
  },
}

export const systemAppsList = Object.values(systemApps)

export const categoricalColorIndexByTypeDevHubApps = {
  [NoCodeAppType.EmbeddedLink]: 7,
  [NoCodeAppType.EmbeddedCode]: 7,
  [NoCodeAppType.ExternalLink]: 9,
  [NoCodeAppType.PageBuilder]: 5,
  [NoCodeAppType.MiroBoard]: 4,
  [NoCodeAppType.CodeUpload]: 5,
  [MicroAppType.Native]: 1,
} as const

export const categoricalColorIndexByHierarchyLevelType = {
  [DefaultHierarchyLevelType.Tenant]: 4,
  [DefaultHierarchyLevelType.Brand]: 9,
  [DefaultHierarchyLevelType.Client]: 8,
  [DefaultHierarchyLevelType.Market]: 3,
} as const
