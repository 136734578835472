import { WppAccordion, WppIconChevron } from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType } from '@wpp-open/core'
import clsx from 'clsx'
import { useState } from 'react'

import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { ReactComponent as IconSpotify } from 'pages/playground/playgroundDashboard/assets/icons/spotify-white.svg'
import { ReactComponent as IconYellowLines } from 'pages/playground/playgroundDashboard/assets/icons/yellow-lines.svg'
import { IconSun } from 'pages/playground/playgroundDashboard/components/sections/sectionNews/icons/IconSun.svg'
import { IconSunset } from 'pages/playground/playgroundDashboard/components/sections/sectionNews/icons/IconSunset.svg'
import { InfoCard } from 'pages/playground/playgroundDashboard/components/sections/sectionNews/infoCard/InfoCard'
import styles from 'pages/playground/playgroundDashboard/components/sections/sectionNews/SectionNews.module.scss'
import { EVENTS } from 'pages/playground/playgroundDashboard/constants/events'
import stylesMain from 'pages/playground/playgroundDashboard/PlaygroundDashboard.module.scss'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { trackAnalytics } from 'utils/analytics'

export const SectionNews = ({
  avatarUrl,
  assignmentsNumber,
  lateTasksNumber,
  scrollToAssignments,
  loadingAssignments,
}: {
  avatarUrl: string
  assignmentsNumber: number
  lateTasksNumber: number
  scrollToAssignments: () => void
  loadingAssignments: boolean
}) => {
  const { userDetails } = useOtherTenantsAndUserData()
  const [accordionOpen, setAccordionOpen] = useState(false)

  const { firstname } = userDetails

  return (
    <div className={styles.container}>
      <Flex className={clsx(styles.flexBaseWidth, stylesMain.mt200)} align="start" justify="between" gap={28}>
        <div>
          <Flex align="start" gap={53}>
            <Flex align="end" justify="center">
              <div className="left">
                <IconSun className={styles.sunIcon} />
              </div>
              <div className="center">
                <div>
                  {avatarUrl ? (
                    <img src={avatarUrl} width={150} alt="" className={styles.avatarImage} />
                  ) : (
                    <Avatar size="xl" name={firstname || ''} src={avatarUrl} />
                  )}
                </div>
              </div>
              <div className="right">
                <IconSunset className={styles.sunIcon} />
              </div>
            </Flex>
            <div>
              <div className={clsx(stylesMain.accentWhiteTitle, stylesMain.mt20)}>{firstname}</div>
              <Flex gap={4} className={stylesMain.mt20} align="end">
                <div className={clsx(stylesMain.white2xl, stylesMain.preWrap)}>Make today a bop</div>
                <div>
                  <IconYellowLines />
                </div>
              </Flex>

              <WppAccordion
                withDivider={false}
                onWppChange={_e => {
                  trackAnalytics({
                    type: AnalyticsActionType.action,
                    payload: EVENTS.PLAYGROUND_OPEN_PLAYLIST,
                  })
                  setAccordionOpen(_e.detail.expanded)
                }}
                className={clsx(styles.accordionSpotify)}
              >
                <div slot="header">
                  <Flex gap={8} className={stylesMain.mt12}>
                    <div>
                      <IconSpotify />
                    </div>
                    <div className={clsx(stylesMain.whiteLStrong, stylesMain.preWrap)}>Coke Studio Playlist</div>
                    <WppIconChevron
                      className={`${styles.accordionChevron} ${accordionOpen ? styles.chevronUp : styles.chevronDown}`}
                      direction="down"
                      size="m"
                      color="white"
                    />
                  </Flex>
                </div>
                <iframe
                  style={{ borderRadius: '12px' }}
                  src="https://open.spotify.com/embed/playlist/1mgYJ5fxzgg3AX99VBVhju?utm_source=generator"
                  width="100%"
                  height="152"
                  allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                  loading="lazy"
                />
              </WppAccordion>
            </div>
          </Flex>
        </div>

        <Flex className={styles.containerRight} direction="column" gap={29}>
          <InfoCard
            text="Assignments"
            number={assignmentsNumber}
            lateNumber={lateTasksNumber}
            onClick={scrollToAssignments}
            loadingAssignments={loadingAssignments}
          />
          {/* <InfoCard text="Open Projects" number={2} onClick={() => {}} /> */}
        </Flex>
      </Flex>
    </div>
  )
}
