import { LegacyTheme } from 'legacy/styles/osTheming/models'
import { LegacyTenantCode } from 'legacy/types/osWebRootApi'

export const greenhouseTheme: LegacyTheme = {
  name: 'Greenhouse',
  code: LegacyTenantCode.GREENHOUSE,

  colors: {
    brand: {
      primary: {
        background: '#F7F2FC',
        lighter: '#E0CCED',
        light: '#8131BB',
        normal: '#6300A7',
        dark: '#570097',
        darker: '#570097',
      },
      secondary: {
        background: '#FFF2F7',
        lighter: '#FF0C68',
        light: '#ED357B',
        normal: '#E30B5D',
        dark: '#CD004E',
        darker: '#B20949',
      },
    },
    system: {
      information: {
        background: '#F2F6FE',
        lighter: '#6595E9',
        light: '#3A76DE',
        normal: '#1057D1',
        dark: '#0649BC',
        darker: '#003BA4',
      },
      error: {
        background: '#FDF3F3',
        lighter: '#FEE8EB',
        light: '#D34A43',
        normal: '#F71735',
        dark: '#B01810',
        darker: '#990E07',
      },
      warning: {
        background: '#FFFCF2',
        lighter: '#FDF9E1',
        light: '#F9C231',
        normal: '#EED434',
        dark: '#DB9900',
        darker: '#B88100',
      },
      success: {
        background: '#F6FCFA',
        lighter: '#DDF8E9',
        light: '#6FB140',
        normal: '#1DCE6C',
        dark: '#428B0E',
        darker: '#377907',
      },
    },
    grey: {
      white: '#FFFFFF',
      50: '#EEEEEE',
      100: '#EBEBEB',
      200: '#D7D7D7',
      300: '#CFCFCF',
      400: '#B4B4B4',
      500: '#A1A1A1',
      600: '#919191',
      700: '#808080',
      800: '#616161',
      900: '#444444',
      1000: '#262626',
      black: '#000000',
      'white-rgb': '255, 255, 255',
      'black-rgb': '0, 0, 0',
    },
    special: {
      background: '#EEEEEE',
      overlay: '#B4B4B4',
      contrastBackground: '#000000',
      contrastPrimaryLight: '#E0CCED',
      contrastPrimary: '#6300A7',
      contrastPrimaryDark: '#6300A7',
    },
    text: {
      text: '#444444',
      textMuted: '#626262',
      textLink: '#8131BB',
      contrastText: '#FFFFFFE5',
      contrastTextMuted: '#FFFFFFBF',
      contrastTextLink: '#BD91DF',
      textInfo: '#4568B4',
      textError: '#C4231B',
      textWarning: '#946700',
      textSuccess: '#377907',
    },
    dataVisualisation: {
      sequential: {
        S0: {
          100: '#EBEBEB',
          200: '#D7D7D7',
          300: '#C6C6C6',
          400: '#B4B4B4',
          500: '#A3A3A3',
          600: '#919191',
        },
        S1: {
          100: '#C2D6F9',
          200: '#92B5F1',
          300: '#6595E9',
          400: '#3A76DE',
          500: '#1057D1',
          600: '#0649BC',
        },
        S2: {
          100: '#F5C7C4',
          200: '#EB9C98',
          300: '#E0726C',
          400: '#D34A43',
          500: '#C4231B',
          600: '#B01810',
        },
        S3: {
          100: '#FFEFC2',
          200: '#FFE191',
          300: '#FED261',
          400: '#F9C231',
          500: '#F2B100',
          600: '#DB9900',
        },
        S4: {
          100: '#D4EAC4',
          200: '#B2D897',
          300: '#91C56B',
          400: '#6FB140',
          500: '#4E9B17',
          600: '#428B0E',
        },
        S5: {
          100: '#DAC2EE',
          200: '#BD91DF',
          300: '#9F61CE',
          400: '#8131BB',
          500: '#6300A7',
          600: '#570097',
        },
        S6: {
          100: '#FEC2D7',
          200: '#FA91B8',
          300: '#F46199',
          400: '#ED357B',
          500: '#E30B5D',
          600: '#CD004E',
        },
      },
      comparative: {
        C0: '#A3A3A3',
        C1: '#1057D1',
        C2: '#C4231B',
        C3: '#F2B100',
        C4: '#4E9B17',
        C5: '#6300A7',
        C6: '#E30B5D',
      },
      diverging: {
        D0: '#6300A7',
        D1: '#E30B5D',
      },
    },
  },

  typography: {
    font: 'Muli',
    sizes: {
      '5xl': {
        size: '36px',
        height: '40px',
      },
      '4xl': {
        size: '30px',
        height: '36px',
      },
      '3xl': {
        size: '24px',
        height: '32px',
      },
      '2xl': {
        size: '20px',
        height: '28px',
      },
      xl: {
        size: '18px',
        height: '28px',
      },
      l: {
        size: '16px',
        height: '24px',
      },
      m: {
        size: '14px',
        height: '20px',
      },
      s: {
        size: '12px',
        height: '16px',
      },
      xs: {
        size: '10px',
        height: '16px',
      },
    },
    weights: {
      bold: 600,
      medium: 500,
      regular: 400,
    },
    boxShadows: {
      '3xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
      '2xl': '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
      xl: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      l: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
      m: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
      s: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
      inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
    },
    borderRadius: {
      card: '3px',
      dropdown: '3px',
      popup: '5px',
      button: '3px',
      input: '3px',
    },
  },
}
