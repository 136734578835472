import { WppIconChevron, WppPopover, WppListItem, WppTooltip } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useRef, useState } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/playground/playgroundDashboard/components/general/dropdown/Dropdown.module.scss'

// const OPTIONS_LIST = ['Remove Tag', 'Resolved', 'Follow Up']

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  // You can add more custom props if needed
  value: string
  options?: { title: string; id: string }[]
  onWppChange: (event: { title: string; id: string }) => void
}

const CustomDropdown = ({ value, options = [], onWppChange, className }: Props) => {
  const [selectedOption, setSelectedOption] = useState(options.find(el => el.id === value) || options[0])
  const [isActive, setIsActive] = useState<boolean>(false)
  const popoverRef = useRef<HTMLWppPopoverElement>(null)

  const handleOptionSelect = (selectedOption: { title: string; id: string }) => {
    setSelectedOption(selectedOption)
    onWppChange(selectedOption)
    popoverRef.current?.closePopover()
  }

  return (
    <WppPopover
      ref={popoverRef}
      config={{
        onShow: () => setIsActive(true),
        onHide: () => setIsActive(false),
        triggerElementWidth: true,
      }}
      className={className}
    >
      <Flex
        className={clsx(styles.dropdown, isActive && styles.disabled)}
        gap={16}
        slot="trigger-element"
        align="center"
      >
        <WppTooltip text={selectedOption.title}>
          <div className={styles.dropdownText}>{selectedOption.title}</div>
        </WppTooltip>

        <WppTooltip text="Select Adobe Workfront Project">
          <WppIconChevron width={28} color="black" direction={isActive ? 'up' : 'down'} className={styles.iconEnd} />{' '}
        </WppTooltip>
      </Flex>
      <div className={styles.dropdownContainer}>
        {/* Any other content here */}
        {options.map(option => (
          // <WppTooltip key={option.id} text={option.title}>
          <WppListItem
            key={option.id}
            checked={selectedOption.id === option.id}
            onWppChangeListItem={() => handleOptionSelect(option)}
            className={styles.listItem}
          >
            <p slot="label">{option.title}</p>
          </WppListItem>
          // </WppTooltip>
        ))}
      </div>
    </WppPopover>
  )
}

export default CustomDropdown
