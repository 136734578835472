import { facadeApi } from 'api'

interface Params {
  tenantId: string
  requestReason: string
}

export const createHierarchyAccessRequestApi = ({ tenantId, requestReason }: Params) =>
  facadeApi.post('/hierarchy-requests', {
    tenantId,
    requestReason,
  })
