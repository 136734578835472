import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { Trans, useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { FeedContext } from 'types/feeds/feeds'
import { routesManager } from 'utils/routesManager'

export const HubApprovedSideModalContent = ({ context }: { context: FeedContext }) => {
  const { hub_id, tenant_id, hub_name } = context
  const { t } = useTranslation()
  const { availableTenants } = useOtherTenantsAndUserData()
  const hubsTenant = availableTenants.find(tenant => tenant.id === tenant_id)

  return (
    <Flex direction="column" gap={16}>
      <WppTypography type="s-body" data-testid="notification-item-content-hub-requested">
        <Trans
          i18nKey="os.notification.access.approved_access"
          values={{
            type: `<0>${t('os.notification.access.hub.admin_title', { hubName: hub_name })}</0>`,
          }}
          components={[
            <WppTypography key="0" type="s-strong">
              type
            </WppTypography>,
          ]}
        />
      </WppTypography>

      <a href={`${hubsTenant?.homeUrl}${routesManager.hubs.root.url(hub_id!).slice(1)}`}>
        <WppButton>{t('os.notification.access.hub.open_hub')}</WppButton>
      </a>
    </Flex>
  )
}
