import { ModalFormConfig } from '@platform-ui-kit/components-library'
import { WppModal } from '@platform-ui-kit/components-library-react'
import { ComponentPropsWithoutRef, forwardRef, ComponentProps } from 'react'

import { Portal } from 'components/common/portal/Portal'
import { IsInsideModalContext } from 'components/surface/IsInsideModalContext'

export type ModalProps = Omit<ComponentPropsWithoutRef<typeof WppModal>, 'formConfig'> & {
  /**
   * If you pass this prop wrapper of dialog will be rendered as form.
   */
  formConfig?: Pick<ComponentProps<'form'>, 'onSubmit' | 'onReset'>
}

export const Modal = forwardRef<HTMLWppModalElement, ModalProps>(({ formConfig, children, ...rest }, ref) => (
  <Portal>
    <WppModal ref={ref} {...rest} formConfig={formConfig as ModalFormConfig}>
      <IsInsideModalContext.Provider value>{children}</IsInsideModalContext.Provider>
    </WppModal>
  </Portal>
))
