export enum Permission {
  APPS_APP_ACCESS = 'APPS_APP_ACCESS',
  APPS_MARKET_MANAGE = 'APPS_MARKET_MANAGE',
  NETWORK_APP_ACCESS = 'NETWORK_APP_ACCESS',
  OS_APPS_ACCESS = 'OS_APPS_ACCESS',
  OS_APPS_MANAGE = 'OS_APPS_MANAGE',
  OS_WIDGETS_ENTITIES_MANAGE = 'OS_WIDGETS_ENTITIES_MANAGE',
  OS_USERDETAILS_APP_ACCESS = 'OS_USERDETAILS_APP_ACCESS',
  WPP_OPEN_MARKETPLACE_APP_ACCESS = 'WPP_OPEN_MARKETPLACE_APP_ACCESS',
  WPP_ORCHESTRATION_APP_ACCESS = 'ORCHESTRATION_APP_ACCESS',
  WPP_DATA_STREAM_APP_ACCESS = 'WPP_DATA_STREAM_APP_ACCESS',
  WPP_DEVHUB_APP_ACCESS = 'WPP_OPEN_DEVHUB_APP_ACCESS',
  SEGMENT_OPPORTUNITY_SIMULATOR_APP_ACCESS = 'SEGMENT_OPPORTUNITY_SIMULATOR_APP_ACCESS',
  SEGMENT_OPPORTUNITY_SIMULATOR_US_APP_ACCESS = 'SEGMENT_OPPORTUNITY_SIMULATOR_US_APP_ACCESS',
  OS_ADMIN_SETTING_ACCESS = 'OS_ADMIN_SETTING_ACCESS',
  OS_DMTR_APP_ACCESS = 'OS_DMTR_APP_ACCESS',
  OS_LEAN_APPS_ENTITIES_MANAGE = 'OS_LEAN_APPS_ENTITIES_MANAGE',
  OS_TENANT_IDPS_MANAGE = 'OS_TENANT_IDPS_MANAGE',
  OS_GLOBAL_CONFIG_ACCESS = 'OS_GLOBAL_CONFIG_ACCESS',
  OS_MASTERDATA_ENTITIES_MODIFY = 'OS_MASTERDATA_ENTITIES_MODIFY',
  OS_EXTERNAL_USER_ADD = 'OS_EXTERNAL_USER_ADD',
  NEWS_SETTINGS_MANAGE = 'NEWS_SETTINGS_MANAGE',
  OS_FAVORITE_LINKS_MANAGE = 'OS_FAVORITE_LINKS_MANAGE',
  OS_HUB_MANAGE = 'OS_HUB_MANAGE',
  OS_PREFIX = 'OS',
  OS_HUB_ACCESS = 'OS_HUB_ACCESS',
  AZ_ROLE_PERMISSIONS_LIST = 'AZ_ROLE_PERMISSIONS_LIST',
  OS_WORKSPACE_ACCESS = 'OS_WORKSPACE_ACCESS',
  OS_APP_INSTANCE_ENTITIES_MANAGE = 'OS_APP_INSTANCE_ENTITIES_MANAGE',
}
