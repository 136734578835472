import { LegacyTheme } from 'legacy/styles/osTheming/models'
import { LegacyTenantCode } from 'legacy/types/osWebRootApi'

export const mediacomTheme: LegacyTheme = {
  name: 'MediaCom',
  code: LegacyTenantCode.MEDIACOM,

  colors: {
    brand: {
      primary: {
        background: '#FFF2F9',
        lighter: '#F9CCDF',
        light: '#EB3181',
        normal: '#E1005D',
        dark: '#CB004E',
        darker: '#B20040',
      },
      secondary: {
        background: '#F5FDFB',
        lighter: '#67E0CE',
        light: '#61D8C2',
        normal: '#3FCBB6',
        dark: '#30B5A1',
        darker: '#2E9686',
      },
    },
    system: {
      information: {
        background: '#F3F8FE',
        lighter: '#85B0EF',
        light: '#6395E8',
        normal: '#4278E0',
        dark: '#225BD6',
        darker: '#1348BA',
      },
      error: {
        background: '#FFF4F6',
        lighter: '#FBE4E5',
        light: '#ED6168',
        normal: '#E74854',
        dark: '#CC3033',
        darker: '#B12225',
      },
      warning: {
        background: '#FFF9F2',
        lighter: '#F4DEB9',
        light: '#FFBB31',
        normal: '#F8AF2A',
        dark: '#DB9D00',
        darker: '#B88700',
      },
      success: {
        background: '#F2FCF6',
        lighter: '#EAF7F1',
        light: '#0CB26A',
        normal: '#47A66D',
        dark: '#009353',
        darker: '#008147',
      },
    },
    grey: {
      white: '#FFFFFF',
      50: '#F5F8F9',
      100: '#DBDFE1',
      200: '#C1C7CB',
      300: '#EBEDEE',
      400: '#8D979F',
      500: '#9FA6AD',
      600: '#5A6773',
      700: '#404E5C',
      800: '#404E5C',
      900: '#202A34',
      1000: '#011627',
      black: '#000000',
      'white-rgb': '255, 255, 255',
      'black-rgb': '0, 0, 0',
    },
    special: {
      background: '#F8F8F8',
      overlay: '#8D979F',
      contrastBackground: '#011627',
      contrastPrimaryLight: '#F9CCDF',
      contrastPrimary: '#E1005D',
      contrastPrimaryDark: '#E1005D',
    },
    text: {
      text: '#202A34',
      textMuted: '#5A6773',
      textLink: '#E1005D',
      contrastText: '#FFFFFFE5',
      contrastTextMuted: '#FFFFFFBF',
      contrastTextLink: '#F361A4',
      textInfo: '#225BD6',
      textError: '#CC3033',
      textWarning: '#B88700',
      textSuccess: '#008147',
    },
    dataVisualisation: {
      sequential: {
        S0: {
          100: '#DBDFE1',
          200: '#C1C7CB',
          300: '#A7B0B5',
          400: '#8D979F',
          500: '#737F89',
          600: '#5A6773',
        },
        S1: {
          100: '#CDE2FA',
          200: '#A9C9F6',
          300: '#85B0EF',
          400: '#6395E8',
          500: '#4278E0',
          600: '#225BD6',
        },
        S2: {
          100: '#FDCDD4',
          200: '#F9A8B1',
          300: '#F4848D',
          400: '#ED6168',
          500: '#E54043',
          600: '#CC3033',
        },
        S3: {
          100: '#FFE5C2',
          200: '#FFD591',
          300: '#FFC761',
          400: '#FFBB31',
          500: '#FFB200',
          600: '#DB9D00',
        },
        S4: {
          100: '#B6EDCE',
          200: '#7ADCA9',
          300: '#42C888',
          400: '#0CB26A',
          500: '#05A35E',
          600: '#009353',
        },
        S5: {
          100: '#FDC2E0',
          200: '#F991C3',
          300: '#F361A4',
          400: '#EB3181',
          500: '#E1005D',
          600: '#CB004E',
        },
        S6: {
          100: '#CEF6EB',
          200: '#A8EDDC',
          300: '#84E3CE',
          400: '#61D8C2',
          500: '#3FCBB6',
          600: '#30B5A1',
        },
      },
      comparative: {
        C0: '#737F89',
        C1: '#4278E0',
        C2: '#E54043',
        C3: '#FFB200',
        C4: '#05A35E',
        C5: '#E1005D',
        C6: '#3FCBB6',
      },
      diverging: {
        D0: '#E1005D',
        D1: '#3FCBB6',
      },
    },
  },

  typography: {
    font: 'Muli',
    sizes: {
      '5xl': {
        size: '36px',
        height: '40px',
      },
      '4xl': {
        size: '30px',
        height: '36px',
      },
      '3xl': {
        size: '24px',
        height: '32px',
      },
      '2xl': {
        size: '20px',
        height: '28px',
      },
      xl: {
        size: '18px',
        height: '28px',
      },
      l: {
        size: '16px',
        height: '24px',
      },
      m: {
        size: '14px',
        height: '20px',
      },
      s: {
        size: '12px',
        height: '16px',
      },
      xs: {
        size: '10px',
        height: '16px',
      },
    },
    weights: {
      bold: 600,
      medium: 500,
      regular: 400,
    },
    boxShadows: {
      '3xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
      '2xl': '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
      xl: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      l: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
      m: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
      s: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
      inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
    },
    borderRadius: {
      card: '1px',
      dropdown: '1px',
      popup: '3px',
      button: '3px',
      input: '1px',
    },
  },
}
