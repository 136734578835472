import { createContext, PropsWithChildren, useContext } from 'react'

import { AppInstanceApp } from 'utils/appInstances'

interface ProjectBreadcrumbsAppsContextValue {
  appsMap: Record<string, AppInstanceApp>
  isLoading: boolean
}

const ProjectBreadcrumbsAppsContext = createContext<ProjectBreadcrumbsAppsContextValue>(null!)

export const useProjectBreadcrumbsApps = () => useContext(ProjectBreadcrumbsAppsContext)

interface Props {
  appsMap: Record<string, AppInstanceApp>
  isLoading: boolean
}

export const ProjectBreadcrumbsAppsProvider = ({ children, appsMap, isLoading }: PropsWithChildren<Props>) => (
  <ProjectBreadcrumbsAppsContext.Provider
    value={{
      appsMap,
      isLoading,
    }}
  >
    {children}
  </ProjectBreadcrumbsAppsContext.Provider>
)
