import clsx from 'clsx'
import { ComponentPropsWithoutRef } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'components/treeList/treeListColumn/TreeListColumn.module.scss'

export const TreeListColumn = ({ className, ...rest }: ComponentPropsWithoutRef<typeof Flex>) => (
  <Flex {...rest} className={clsx(styles.root, className)} direction="column" />
)

export const TreeListColumnHeader = ({ className, ...rest }: ComponentPropsWithoutRef<'div'>) => (
  <div className={clsx(styles.header, className)} {...rest} />
)
