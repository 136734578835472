import { AppInstanceShort, MayBeNull } from '@wpp-open/core'

import { facadeApi } from 'api'

export interface Params {
  type: 'subgroups' | 'groups'
  assignmentId?: string
  groups?: {
    id?: string
    order: MayBeNull<number>
  }[]
  subgroups?: {
    id?: string
    order: MayBeNull<number>
  }[]
}

export const bulkEditAppsGroupApi = ({ type, ...data }: Params) =>
  facadeApi.patch<AppInstanceShort>(`/app-instances/${type}/bulk`, data)
