import { WppButton, WppModal } from '@platform-ui-kit/components-library-react'
import { useEffect, useState, useRef } from 'react'

import styles from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/assignmentCard/components/NudgePreviewModal.module.scss'

interface NudgePreviewModalProps {
  nudgeUrl: string
  open?: boolean
  onClose?: () => void
  onOpen?: () => void
  className?: string
}

export const NudgePreviewModal: React.FC<NudgePreviewModalProps> = (props: NudgePreviewModalProps) => {
  const { nudgeUrl, open = false, onClose = () => {}, className, onOpen = () => {} } = props

  const [isOpen, setIsOpen] = useState(open)
  const [prevState, setPrevState] = useState<'open' | 'close' | null>(null)
  const [iframeError, setIframeError] = useState(false)
  const [iframeHidden, setIframeHidden] = useState(true)

  const modalBodyRef = useRef<HTMLParagraphElement>(null)
  const iframeRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    const handleEscPress = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setIsOpen(false)
        onClose()
      }
    }
    document.addEventListener('keydown', handleEscPress)

    if (open && prevState !== 'open') {
      setIsOpen(true)
      onOpen()
      setPrevState('open')
    } else if (!open && prevState !== 'close') {
      setIsOpen(false)
      setPrevState('close')
    }
    if (prevState === 'open' && !open) {
      onClose()
    }
    return () => {
      document.removeEventListener('keydown', handleEscPress)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  const closeModal = () => {
    setIsOpen(false)
    onClose()
  }

  const openFileUrl = () => {
    window.open(nudgeUrl, '_blank')
    closeModal()
  }

  const handleIframeLoad = () => {
    try {
      const iframeDocument = iframeRef.current?.contentDocument || iframeRef.current?.contentWindow?.document

      if (iframeDocument) {
        // Example: Check for specific content in the iframe, like an error message
        const bodyText = iframeDocument.body.textContent || ''

        if (bodyText.includes('Error') || bodyText.includes('Access Denied')) {
          setIsOpen(false)
          setIframeError(true)
          window.open(nudgeUrl, '_blank')
        } else {
          setIframeError(false)
          setIframeHidden(false)
        }
      }
    } catch (error) {
      setIsOpen(false)
      setIframeError(true)
      window.open(nudgeUrl, '_blank')
    }
  }

  return (
    <WppModal
      open={isOpen}
      title="Nudge Preview"
      className={className}
      style={{ display: iframeHidden ? 'none' : 'block' }}
    >
      <div slot="header" />
      <div slot="body" ref={modalBodyRef}>
        {!iframeError ? (
          <iframe
            ref={iframeRef}
            src={nudgeUrl}
            width="100%"
            height="720"
            style={{ border: 'none' }}
            onLoad={handleIframeLoad}
          />
        ) : (
          <></>
        )}
      </div>
      <div slot="actions" className={styles.actions}>
        <WppButton className={styles.actionButton} variant="secondary" onClick={openFileUrl}>
          Go to File
        </WppButton>
        <WppButton className={styles.actionButton} onClick={closeModal}>
          Close
        </WppButton>
      </div>
    </WppModal>
  )
}
