import { MayBeNull } from '@wpp-open/core'

export interface AnalyticsEvent {
  action: string
  params: AnalyticsEventParam[]
}

export interface AnalyticsEventParam {
  key: string
  value: string | boolean | CustomAnalyticsEventParam[] | []
}

export interface CustomAnalyticsEventParam {
  levelCategory?: MayBeNull<string>
  levelValue?: MayBeNull<string>
}

export enum AnalyticsActionType {
  action = 'Action',
  page = 'Page',
}

export interface AnalyticsActionBase<T extends AnalyticsActionType, P> {
  type: T
  payload: P
}

export type AnalyticsPageAction = AnalyticsActionBase<AnalyticsActionType.page, string>
export type AnalyticsTrackActionPayload = string | AnalyticsEvent
export type AnalyticsTrackAction = AnalyticsActionBase<AnalyticsActionType.action, AnalyticsTrackActionPayload>
export type AnalyticsAction = AnalyticsPageAction | AnalyticsTrackAction
