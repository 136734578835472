import { NoCodeAppType, OpenCompactAppHandler } from '@wpp-open/core'
import { useCallback, useState } from 'react'

import { NoCodeAppEmbeddedLink } from 'types/apps/noCodeApps'

export interface OverlayConfig {
  id: string
  appStableId: string
  overlayProps: Record<string, unknown>
}

export const useOverlay = () => {
  const [overlay, setOverlay] = useState<OverlayConfig>()
  const [apps, setApps] = useState<Record<string, NoCodeAppEmbeddedLink>>({})

  const registerNoCodeAppEmbeddedLink = useCallback((appInstanceId: string, name: string, url: string) => {
    setApps(apps =>
      apps[appInstanceId]
        ? apps
        : {
            ...apps,
            [appInstanceId]: {
              stableId: appInstanceId,
              type: NoCodeAppType.EmbeddedLink,
              name,
              url,
              osRoute: '',
            },
          },
    )
  }, [])

  const openCompactApp: OpenCompactAppHandler = useCallback(
    ({ name, url, overlayProps }) => {
      const appInstanceId = `${name}-${url}`
      registerNoCodeAppEmbeddedLink(appInstanceId, name, url)

      setOverlay({
        id: crypto.randomUUID(),
        appStableId: appInstanceId,
        overlayProps,
      })
    },
    [registerNoCodeAppEmbeddedLink],
  )

  const closeCompactApp = useCallback(() => setOverlay(undefined), [])

  return {
    apps,
    overlay,
    openCompactApp,
    closeCompactApp,
  }
}
