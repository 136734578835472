import { MayBeNull } from '@wpp-open/core'
import { Component, PropsWithChildren } from 'react'

import { RenderErrorBoundaryLayout } from 'components/renderError/boundary/renderErrorBoundaryLayout/RenderErrorBoundaryLayout'
import { RenderErrorBoundaryHeight } from 'components/renderError/boundary/renderErrorBoundaryLayout/utils'
import { OsIsNotAvailableErrorState } from 'components/renderError/errorState'

type Props = PropsWithChildren<{}>

interface State {
  isError: boolean
}

/* Top level boundary. */
export class RootRenderErrorBoundary extends Component<Props, State> {
  state: State = {
    isError: false,
  }

  static getDerivedStateFromError(): MayBeNull<State> {
    return { isError: true }
  }

  componentDidCatch(error: unknown, info: object) {
    console.error(error, info)
  }

  render() {
    const { isError } = this.state
    const { children } = this.props

    return (
      <>
        {isError ? (
          <RenderErrorBoundaryLayout height={RenderErrorBoundaryHeight.Fullscreen}>
            <OsIsNotAvailableErrorState />
          </RenderErrorBoundaryLayout>
        ) : (
          children
        )}
      </>
    )
  }
}
