/* eslint-disable no-console */
import { WppIconArrow, WppSpinner } from '@platform-ui-kit/components-library-react'
import { animated, useSpring } from '@react-spring/web'
import { excludeFalsy } from '@wpp-open/content-builder'
import { AnalyticsActionType } from '@wpp-open/core'
import clsx from 'clsx'
import { useEffect, useRef, useState } from 'react'
import Marquee from 'react-fast-marquee'

import { useGenerateUserDetailsDownloadUrlsApi } from 'api/attachments/queries/useGenerateUserDetailsDownloadUrlsApi'
import { useProjectsCount } from 'api/playground/hooks/useProjectsCount'
import { useCheckUserPhotoHash } from 'api/playground/mutations/useCheckUserPhotoHash'
import { useUpdateUser } from 'api/playground/mutations/useUpdateUser'
import { useDigitalProfile } from 'api/playground/queries/digital-profile/useDigitalProfile'
import { useUserAssignedRolesApi } from 'api/roles/queries/useUserAssignedRolesApi'
import { useEditUserApi } from 'api/users/mutations/useEditUserApi'
import { useUpdateUserAvatar } from 'api/users/mutations/useUpdateUserAvatar'
import { useMe } from 'api/workfront/queries/matchAndThrive/useMe'
import { useFetchWorkfrontUser } from 'api/workfront/queries/useFetchWorkfrontUser'
import { useFetchWorkfrontUserRoles } from 'api/workfront/queries/useFetchWorkfrontUserRoles'
import { useFetchWorkday } from 'api/workfront/queries/useFetchWorkfrontWorkday'
import { Flex } from 'components/common/flex/Flex'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useUploadUserDetailsFilesApi } from 'hooks/attachments/useUploadUserDetailsFilesApi'
import IconClock from 'pages/playground/playgroundDashboard/assets/icons/clock.gif'
import IconTellMore from 'pages/playground/playgroundDashboard/assets/icons/tell-more.gif'
import LaunchVideoPlaceholder from 'pages/playground/playgroundDashboard/assets/images/video/launch-video-placeholder.png'
import { Button } from 'pages/playground/playgroundDashboard/components/general/button/Button'
import { SectionNewFeatures } from 'pages/playground/playgroundDashboard/components/sections/sectionNewFeatures/SectionNewFeatures'
import { SectionNewsEmpty } from 'pages/playground/playgroundDashboard/components/sections/sectionNews/SectionNewsEmpty'
import { SectionOauth } from 'pages/playground/playgroundDashboard/components/sections/sectionOauth/SectionOauth'
import { EVENTS } from 'pages/playground/playgroundDashboard/constants/events'
import { OverlayProvider } from 'pages/playground/playgroundDashboard/contexts/OverlayContext'
import { PlaygroundDashboard } from 'pages/playground/playgroundDashboard/PlaygroundDashboard'
import styles from 'pages/playground/playgroundDashboard/PlaygroundDashboard.module.scss'
import { adjustTextSize } from 'pages/playground/playgroundDashboard/utils/fitText'
import { queryClient } from 'providers/osQueryClient/utils'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { useToast } from 'providers/toast/ToastProvider'
import { trackAnalytics } from 'utils/analytics'
import { base64ToFile } from 'utils/files'

async function computeSHA256Hash(base64: string) {
  // Decode the base64 string to bytes
  const binaryString = window.atob(base64)
  const len = binaryString.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }

  // Compute the SHA-256 hash
  const hashBuffer = await crypto.subtle.digest('SHA-256', bytes)

  // Convert the hash to a hexadecimal string
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')

  return hashHex
}

export const PlaygroundDashboardContainer = () => {
  const { userDetails } = useOtherTenantsAndUserData()
  const { enqueueToast } = useToast()

  const [hoursOfDay] = useState(new Date().getHours())
  const { mutateAsync: updateUser } = useUpdateUser()

  const { mutateAsync: checkPhotoHash } = useCheckUserPhotoHash()
  const handleUploadUserDetailsFiles = useUploadUserDetailsFilesApi()
  // const { mutateAsync: generateTempUploadUrls } = useGenerateUserDetailsUploadUrlsApi()
  const { mutateAsync: updateMeUserAvatar } = useUpdateUserAvatar()
  const { mutateAsync: handleEditUser } = useEditUserApi()

  const { data: workdayData, isLoading: loadingWorkday } = useFetchWorkday()
  const { data: digitalProfile = {} } = useDigitalProfile()

  const { data: workfrontUser, isLoading: loadingWorkfrontUser } = useFetchWorkfrontUser()
  const { data: userRoles, refetch: refetchUserRoles } = useFetchWorkfrontUserRoles()

  const { data: userMe } = useMe()

  const { data: userAssignedRoles } = useUserAssignedRolesApi({
    params: { email: userDetails.email, id: userDetails.id },
  })

  const { total: totalProjects, isLoadingProjectsCount } = useProjectsCount()

  const [projectFocused, setProjectFocused] = useState(false)
  const [actionFocused, setActionFocused] = useState(false)
  const [marketingFocused, setMarketingFocused] = useState(false)
  const [bussinesFocused, setBussinesFocused] = useState(false)

  // On Mounted:
  useEffect(() => {
    trackAnalytics({
      type: AnalyticsActionType.page,
      payload: EVENTS.PLAYGROUND_HOMEPAGE,
    })

    if (workdayData && workfrontUser) {
      updateUser({
        firstname: userDetails.firstname,
        lastname: userDetails.lastname,
        email: userDetails.email,
        role: workdayData[0]?.associateRole,
        country: workdayData[0]?.associateLocation || userDetails.country || undefined,
        KOID: workfrontUser.koid,
        jobFamilyGroup: workdayData[0]?.jobFamilyGroup,
        jobFamilyName: workdayData[0]?.jobFamilyName,
        ...(workdayData[0]?.managerName && workdayData[0]?.managerSurname && workdayData[0]?.managerEmail
          ? {
              manager: {
                name: workdayData[0]?.managerName || '',
                surname: workdayData[0]?.managerSurname || '',
                email: workdayData[0]?.managerEmail || '',
              },
            }
          : {}),
      })

      // Action focused = OS_PG_ACTION_FOCUSED
      // Business focused = OS_PG_BUSINESS_FOCUSED
      // Project focused = OS_PG_PROJECT_FOCUSED

      window.Intercom?.('update', {
        pg_user_role:
          (userAssignedRoles || [])
            ?.filter(role => role.role_name.startsWith('tccc-'))
            .map(role => role.role_name)
            .join(',') || '',
        pg_wf_status: workfrontUser.userExistsInWorkfront || '',
        pg_koid: workfrontUser.koid || '',
        pg_company_name: workfrontUser.companyName || '',
        pg_job_family: workdayData?.jobFamilyName || '',
        pg_job_family_group: workdayData?.jobFamilyGroup || '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (workfrontUser.connectedToWorkfront && document.referrer.includes('workfront.com')) {
      refetchUserRoles().then(res => {
        if (res.data?.data?.rolesChanged) {
          window.location.reload()
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workfrontUser.connectedToWorkfront])

  useEffect(() => {
    if (userRoles) {
      if (userRoles.rolesChanged) {
        enqueueToast({
          header: 'User roles updated',
          message: 'Please refresh your page to see the changes.',
          type: 'success',
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRoles])

  useEffect(() => {
    if (userAssignedRoles) {
      window.Intercom?.('update', {
        pg_user_role:
          (userAssignedRoles || [])
            ?.filter(role => role.role_name.startsWith('tccc-'))
            .map(role => role.role_name)
            .join(',') || '',
      })
      setProjectFocused(
        userAssignedRoles.some(
          role => role.role_name === 'tccc-project-focused-manual' || role.role_name === 'tccc-project-focused',
        ),
      )
      setActionFocused(
        userAssignedRoles.some(
          role => role.role_name === 'tccc-action-focused-manual' || role.role_name === 'tccc-action-focused',
        ),
      )
      setMarketingFocused(
        userAssignedRoles.some(
          role => role.role_name === 'tccc-marketing-manual' || role.role_name === 'tccc-marketing',
        ),
      )
      setBussinesFocused(
        userAssignedRoles.some(
          role => role.role_name === 'tccc-business-focused-manual' || role.role_name === 'tccc-business-focused',
        ),
      )
    }
  }, [userAssignedRoles])

  useEffect(() => {
    window.Intercom?.('update', {
      pg_job_family: workdayData?.jobFamilyName || '',
    })
  }, [workdayData?.jobFamilyName])

  useEffect(() => {
    window.Intercom?.('update', {
      pg_job_family_group: workdayData?.jobFamilyGroup || '',
    })
  }, [workdayData?.jobFamilyGroup])

  useEffect(() => {
    window.Intercom?.('update', {
      pg_wf_status: workfrontUser.userExistsInWorkfront || '',
    })
  }, [workfrontUser?.userExistsInWorkfront])

  useEffect(() => {
    if (workfrontUser?.koid) {
      updateUser({
        email: userDetails.email,
        KOID: workfrontUser.koid,
      })
      window.Intercom?.('update', {
        pg_koid: workfrontUser.koid || '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workfrontUser?.koid])

  useEffect(() => {
    window.Intercom?.('update', {
      pg_company_name: workfrontUser.companyName || '',
    })
  }, [workfrontUser?.companyName])

  useEffect(() => {
    window.Intercom?.('update', {
      pg_exp_profile: digitalProfile?.isCompleted || false,
    })
  }, [digitalProfile?.isCompleted])

  useEffect(() => {
    window.Intercom?.('update', {
      pg_ou: userMe?.operatingUnit?.name || '',
    })
  }, [userMe?.operatingUnit?.name])

  useEffect(() => {
    if (!isLoadingProjectsCount) {
      if (totalProjects > 0) {
        window.Intercom?.('update', {
          pg_assigned_mt_blueprint: true,
        })
      } else {
        window.Intercom?.('update', {
          pg_assigned_mt_blueprint: false,
        })
      }
    }
  }, [totalProjects, isLoadingProjectsCount])

  useEffect(() => {
    // todo remove comment
    if (workdayData && workfrontUser) {
      if (workdayData?.[0]?.photo) {
        computeSHA256Hash(workdayData[0]?.photo).then(hash => {
          checkPhotoHash({ hash }).then(async res => {
            if (res?.data?.match) return
            updateUser({
              firstname: userDetails.firstname,
              lastname: userDetails.lastname,
              email: userDetails.email,
              role: workdayData[0]?.associateRole,
              country: workdayData[0]?.associateLocation || userDetails?.country || undefined,
              KOID: workfrontUser.koid,
              image: workdayData[0]?.photo || undefined,
            })
            const imageFile = base64ToFile(`${workdayData[0]?.photo}`, hash)
            const imageFile2 = base64ToFile(`${workdayData[0]?.photo}`, hash)

            const [avatarThumbnail, avatarOriginal] = await handleUploadUserDetailsFiles([imageFile, imageFile2])
            await updateMeUserAvatar({ avatarThumbnail, ...(avatarOriginal && { avatarOriginal }) })

            queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.CURRENT_USER] })
          })
        })
      }
      // CHECK IF COUNTRY IS UPDATED
      if (workdayData[0]?.associateLocation && workdayData[0]?.associateLocation !== (userDetails.country || '')) {
        handleEditUser({
          country: workdayData[0]?.associateLocation || '',
          email: userDetails.email,
          sourceId: userDetails.sourceId,
          firstname: userDetails.firstname,
          lastname: userDetails.lastname,
          countryAlpha2Code: userDetails.countryAlpha2Code,
          jobTitle: userDetails.jobTitle,
          department: userDetails.department,
          agency: 'tccc',
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workdayData, workfrontUser])

  const { avatarOriginal, avatarThumbnail } = userDetails
  const keys = [avatarOriginal, avatarThumbnail].filter(excludeFalsy).map(({ key }) => key)

  const { data: avatarsDownloadInfo } = useGenerateUserDetailsDownloadUrlsApi({
    params: { keys },
    enabled: !!keys.length,
  })
  const [avatarOriginalInfo, avatarThumbnailInfo] = avatarsDownloadInfo
  const avatarUrl = avatarThumbnailInfo?.signed_url || avatarOriginalInfo?.signed_url

  const [overlayVisible, setOverlayVisible] = useState(false)
  const overlayValues = {
    visible: overlayVisible,
    setVisible: () => {
      setOverlayVisible(prev => !prev)
    },
  }

  const containerRefHomePage = useRef<HTMLDivElement>(null)
  const textRefHomePage = useRef<HTMLDivElement>(null)

  const containerRefWhatsComingHeader = useRef<HTMLDivElement>(null)
  const textRefWhatsComingHeader = useRef<HTMLDivElement>(null)

  // Initial adjustment
  adjustTextSize(containerRefHomePage.current, textRefHomePage.current, 215)
  adjustTextSize(containerRefWhatsComingHeader.current, textRefWhatsComingHeader.current, 220)

  // Adjust text size on window resize to maintain responsiveness
  window.addEventListener('resize', () => {
    adjustTextSize(containerRefHomePage.current, textRefHomePage.current, 215)
    adjustTextSize(containerRefWhatsComingHeader.current, textRefWhatsComingHeader.current, 220)
  })

  const newsBgStyles = useSpring({
    background: hoursOfDay < 12 ? '#FC4D0F' : 'linear-gradient(180deg, #9C26C1 0%, #25276F 100%)',
  })
  const circleStyles = useSpring({
    background: 'white',
  })

  const openIntercom = () => {
    if (window.Intercom) {
      window.Intercom('showMessages')
    }
  }

  // TODO: test provision user popup
  // const openProvisionUserPopup = () => {
  //   // Calculate the position to center the popup on the screen
  //   const width = 1000
  //   const height = 600

  //   // Open a popup window with specific dimensions and features
  //   const popupWindow = window.open(
  //     `https://acrs.adobe.com/go/e9da5f98-95e3-4fde-858d-a3a3c74c6811?firstName=${encodeURIComponent(userDetails?.firstname || '')}&lastName=${encodeURIComponent(userDetails.lastname || '')}&email=${encodeURIComponent(userDetails.email || '')}`,
  //     // 'https://acrs.adobe.com/go/e9da5f98-95e3-4fde-858d-a3a3c74c6811',
  //     'Provision User',
  //     `popup=true,width=${width},height=${height},scrollbars=yes,resizable=yes`,
  //   )

  //   if (popupWindow) {
  //     popupWindow.onmessage = event => {
  //       console.log('event ===', event)
  //     }

  //     popupWindow.onbeforeunload = () => {
  //       console.log('popupWindow closed')
  //     }
  //   }

  //   // new Promise((resolve, reject) => {
  //   //   const interval = setInterval(() => {
  //   //     if (popupWindow) {
  //   //       // popupWindow.onload = () => {
  //   //       //   popupWindow.postMessage(
  //   //       //     {
  //   //       //       firstName: userDetails?.firstname,
  //   //       //       lastName: userDetails.lastname,
  //   //       //       email: userDetails.email,
  //   //       //     },
  //   //       //     '*',
  //   //       //   )
  //   //       // }
  //   //       console.log('popupWindow closed', popupWindow.closed)
  //   //       if (popupWindow.closed) {
  //   //         clearInterval(interval)
  //   //         reject('Window closed')
  //   //       } else {
  //   //         // popupWindow.close()

  //   //         resolve('wait...')
  //   //       }
  //   //     }
  //   //   }, 1000)
  //   // })

  //   // Bring the popup window into focus
  //   if (popupWindow) {
  //     popupWindow.focus()
  //   }
  // }
  // TODO: test provision user popup
  // const handleMessagesProvisionUser = useMemo(() => {
  //   const emailPattern = /^[^\s@]+@[^\s@]+@coca-cola\.com$/

  //   if (userDetails.email.match(emailPattern))
  //     return 'Use your @coca-cola.com email address to log in as an internal user.'
  //   else return `Use your ${workfrontUser?.koid}@alwaysko.com address to log in as an external user`
  // }, [userDetails.email, workfrontUser?.koid])

  return (
    <div id="modals" className={clsx(styles.whiteBg, 'tour-home-welcome-video')}>
      {/* <AzApiProvider> */}
      <OverlayProvider value={overlayValues}>
        {loadingWorkday || loadingWorkfrontUser ? (
          <Flex justify="center" align="center" className={clsx(styles.wFull, styles.minHScreen)}>
            <WppSpinner size="l" />
          </Flex>
        ) : workfrontUser?.connectedToWorkfront && (projectFocused || actionFocused || marketingFocused) ? (
          <PlaygroundDashboard
            projectFocused={projectFocused}
            actionFocused={actionFocused}
            marketingFocused={marketingFocused}
          />
        ) : (
          <div>
            {/* Header Title */}
            <div className={styles.containerText} ref={containerRefHomePage}>
              <div ref={textRefHomePage} className={styles.weirdHeader}>
                DO BIG THINGS
              </div>
            </div>

            <animated.div style={newsBgStyles} className={styles.relative}>
              <div className={clsx(styles.container, styles.relative, styles.z3)}>
                <SectionNewsEmpty avatarUrl={avatarUrl} />

                <Flex justify="center" className={styles.imageContainer} align="center">
                  <video
                    src={
                      window.location.hostname.includes('playground-stage.coke.com')
                        ? 'https://cokeurl.com/Sthe-playground-v'
                        : 'https://cokeurl.com/the-playground-v'
                    }
                    width={944}
                    height={531}
                    style={{ borderRadius: 16 }}
                    controls
                    poster={LaunchVideoPlaceholder}
                  />
                </Flex>
              </div>

              <animated.div style={circleStyles} className={styles.arcTop2} />
            </animated.div>

            <div className={clsx(styles.bgWhite, styles.mt120)}>
              {bussinesFocused && (
                <Marquee>
                  <div className={styles.orange6XL}>
                    • COMING SOON TO THE PLAYGROUND • COMING SOON TO THE PLAYGROUND• COMING SOON TO THE PLAYGROUND•
                    COMING SOON TO THE PLAYGROUND
                  </div>
                </Marquee>
              )}

              <div className={clsx(styles.container)}>
                {bussinesFocused ? (
                  <>
                    <div className={clsx(styles.connectContainer, styles.mt40)}>
                      <Flex direction="column" justify="center" align="center" gap={24}>
                        <div className={styles.relative}>
                          <div className={styles.gifbackdrop} />
                          <img src={IconClock} alt="" width={120} className={styles.relative} />
                        </div>
                        <div className={clsx(styles.relative, styles.header5xl, styles.preWrap, styles['text-center'])}>
                          {'Stay tuned! \nCustomized content is on its way.'}
                        </div>
                        <div className={clsx(styles.blackMBody, styles.preWrap, styles['text-center'], styles.mt12)}>
                          Thank you for your patience as we tailor The Playground to be just what you need. There’s
                          plenty to discover already, so play around and check back soon.
                        </div>
                      </Flex>
                    </div>
                  </>
                ) : !workfrontUser?.connectedToWorkfront ? (
                  <div className={clsx(styles.connectContainer)}>
                    {workfrontUser?.userExistsInWorkfront ? (
                      <SectionOauth loading={false} type="home-page" />
                    ) : (
                      <Flex direction="column" justify="center" align="center" gap={24}>
                        <div>
                          <img src={IconTellMore} alt="" width={120} />
                        </div>
                        <div className={clsx(styles.relative, styles.header5xl)}>Tell Us More About You!</div>
                        <div className={clsx(styles.blackMBody, styles.preWrap, styles['text-center'], styles.mt12)}>
                          {
                            'To customize The Playground to you and your role please open Intercom \nto set up your account access permissions.'
                          }
                        </div>

                        <Flex justify="center" align="center">
                          <div onClick={openIntercom}>
                            <Button text="Open Intercom" variant="black" iconRight={<WppIconArrow color="white" />} />
                          </div>
                        </Flex>

                        <div className={styles.greyMBody}>
                          FYI: Once you request access it may take a few days to complete.
                        </div>
                      </Flex>
                    )}
                  </div>
                ) : null}
              </div>

              <div className={styles.mt100} ref={containerRefWhatsComingHeader}>
                <div ref={textRefWhatsComingHeader} className={styles.whatsComingHeader}>
                  WHAT&#39;S COMING
                </div>
              </div>
              <div className="subheading" />
              <div className={styles.container}>
                <SectionNewFeatures />
              </div>

              {/* TODO: FOR TESTING PURPOSES */}
              {/* <Flex className={styles.mt24} direction="column" align="center" gap={8}>
                <div
                  className={styles.blackSBody}
                  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginBottom: 20 }}
                >
                  {handleMessagesProvisionUser}
                </div>
                <Button
                  text="Provision workfront user - preview env - test POPUP"
                  variant="black"
                  onClick={openProvisionUserPopup}
                />
                <Button
                  text="Provision workfront user - preview env - test SELF"
                  variant="black"
                  onClick={() => {
                    window.open('https://acrs.adobe.com/go/e9da5f98-95e3-4fde-858d-a3a3c74c6811', '_self')
                  }}
                />
                <Button
                  text="Provision workfront user - preview env - test TAB"
                  variant="black"
                  onClick={() => {
                    window.open('https://acrs.adobe.com/go/e9da5f98-95e3-4fde-858d-a3a3c74c6811', '_blank')
                  }}
                />
              </Flex> */}

              <div className={styles.bgBlack}>
                <div className={styles.arcBottom} />
              </div>
            </div>
          </div>
        )}
      </OverlayProvider>
      {/* </AzApiProvider> */}
    </div>
  )
}
