import { WppSkeleton } from '@platform-ui-kit/components-library-react'
import { animated, useSpring } from '@react-spring/web'
import clsx from 'clsx'
import { useEffect, useMemo, useRef, useState } from 'react'
import Confetti from 'react-confetti'

import { Flex } from 'components/common/flex/Flex'
import GifWarnningUrl from 'pages/playground/playgroundDashboard/assets/icons/late.gif'
import GifRiskUrl from 'pages/playground/playgroundDashboard/assets/icons/risk.gif'
import BubbleFixed from 'pages/playground/playgroundDashboard/components/general/bubble/Bubble'
import {
  DEFAULT_ITEMS_PER_PAGE,
  Pagination,
} from 'pages/playground/playgroundDashboard/components/general/pagination/Pagination'
import { SectionProjectSummaryCard } from 'pages/playground/playgroundDashboard/components/sections/sectionProjectSummary/card/Card'
import styles from 'pages/playground/playgroundDashboard/components/sections/sectionProjectSummary/graph/Graph.module.scss'
import { ProjectSummaryDto } from 'pages/playground/playgroundDashboard/components/sections/sectionProjectSummary/sectionProjectSummaryMockData'
import stylesMain from 'pages/playground/playgroundDashboard/PlaygroundDashboard.module.scss'
import { adjustTextSize } from 'pages/playground/playgroundDashboard/utils/fitText'

interface Props {
  projectSummary: ProjectSummaryDto
  workfrontError: boolean
  projectsLength: number
  loading: boolean
}

export const SectionProjectSummaryGraph = ({ projectSummary, workfrontError, projectsLength, loading }: Props) => {
  const containerRefLate = useRef<HTMLDivElement>(null)
  const textRefLate = useRef<HTMLDivElement>(null)
  const containerRefRisk = useRef<HTMLDivElement>(null)
  const textRefRisk = useRef<HTMLDivElement>(null)

  const [pageLate, setPageLate] = useState(1)
  const [itemsPerPageLate, setItemsPerPageLate] = useState(DEFAULT_ITEMS_PER_PAGE)

  const [pageRisk, setPageRisk] = useState(1)
  const [itemsPerPageRisk, setItemsPerPageRisk] = useState(DEFAULT_ITEMS_PER_PAGE)

  const [riskAnimationStep, setRiskAnimationStep] = useState(0)
  const [riskAnimationEnabled, setRiskAnimationEnabled] = useState(false)

  useEffect(() => {
    setRiskAnimationEnabled(false)
  }, [projectSummary.name])

  // Initial adjustment
  adjustTextSize(containerRefLate.current, textRefLate.current, 450)
  adjustTextSize(containerRefRisk.current, textRefRisk.current, 450)

  // Adjust text size on window resize to maintain responsiveness
  window.addEventListener('resize', () => {
    adjustTextSize(containerRefLate.current, textRefLate.current, 450),
      adjustTextSize(containerRefRisk.current, textRefRisk.current, 450)
  })

  const GraphData = useMemo(
    () =>
      [
        {
          title: 'Completed',
          value: projectSummary.projectHealth?.completed,
          className: clsx(styles.graph, styles.plannedGraph),
        },
        {
          title: 'On Track',
          value: projectSummary.projectHealth?.onTrack,
          className: clsx(styles.graph, styles.onTrackGraph),
        },
        {
          title: 'Late',
          value: projectSummary.projectHealth?.delayed,
          className: clsx(styles.graph, styles.lateGraph),
        },
        {
          title: 'Risk(s)',
          value: projectSummary.projectHealth?.riskIdentified,
          className: clsx(styles.graph, styles.atRiskGraph),
        },
      ]
        .filter(el => el.value !== 0)
        .sort((a, b) => b.value - a.value),
    [
      projectSummary.projectHealth?.completed,
      projectSummary.projectHealth?.delayed,
      projectSummary.projectHealth?.onTrack,
      projectSummary.projectHealth?.riskIdentified,
    ],
  )

  useEffect(() => {
    const timeout = setTimeout(() => {
      setRiskAnimationStep(currentStep => (currentStep + 1) % 6)
    }, 1500)

    return () => clearTimeout(timeout)
  }, [riskAnimationStep])

  useEffect(() => {
    setPageLate(1)
  }, [itemsPerPageLate])

  useEffect(() => {
    setPageRisk(1)
  }, [itemsPerPageRisk])

  const riskStyle = useSpring({
    opacity: riskAnimationStep === 0 || riskAnimationStep === 2 || riskAnimationStep === 4 ? 1 : 0,
  })

  const riskStyle2 = useSpring({
    opacity: riskAnimationStep === 1 || riskAnimationStep === 3 ? 1 : 0,
  })

  const riskStyle3 = useSpring({
    opacity: riskAnimationStep === 5 ? 1 : 0,
  })

  return (
    <div className={styles.container}>
      {loading ? (
        <Flex className={clsx(stylesMain.mt75)} justify="center" align="center" gap={130}>
          <WppSkeleton variant="rectangle" width="20%" height="100px" />
          <WppSkeleton variant="rectangle" width="40%" height="350px" />
        </Flex>
      ) : workfrontError ? (
        <div />
      ) : projectsLength === 0 ? (
        <div />
      ) : (
        <Flex className={clsx(stylesMain.black5XL)} justify="center" align="center" gap={130}>
          <div>
            <div className={styles.completePercent}>
              {Object.keys(projectSummary).length !== 0
                ? `${Math.round(projectSummary.projectHealth.percentComplete)}%`
                : '0%'}

              <Confetti width={300} height={155} recycle={false} />
            </div>
            {Object.keys(projectSummary).length !== 0 && <div className={styles.completeLabel}>Complete</div>}
          </div>

          <Flex align="end" className={styles.graphContainer}>
            {GraphData.map((element, index) => (
              <div key={index} className={stylesMain.relative}>
                <animated.div
                  className={clsx(element.className, styles[`graph${index}`])}
                  style={element.title === 'Risk(s)' && riskAnimationEnabled ? riskStyle : {}}
                  onMouseEnter={() => element.title === 'Risk(s)' && setRiskAnimationEnabled(true)}
                >
                  {element.value}
                  <div className={styles.label}>{element.title}</div>
                </animated.div>
                {element.title === 'Risk(s)' && riskAnimationEnabled && (
                  <animated.div className={styles.riskAnimation1} style={riskStyle2}>
                    DEEP BREATH
                  </animated.div>
                )}
                {element.title === 'Risk(s)' && riskAnimationEnabled && (
                  <animated.div className={clsx(styles.riskAnimation2, stylesMain.preWrap)} style={riskStyle3}>
                    {"Let's \ndo it"}
                  </animated.div>
                )}
              </div>
            ))}
          </Flex>
        </Flex>
      )}

      {/* LATE */}
      {projectSummary.lateTasks?.length > 0 && (
        <div>
          <Flex align="center" gap={12} className={stylesMain.mt100}>
            <Flex align="center" justify="center">
              <img src={GifWarnningUrl} alt="" width={48} />
            </Flex>
            <div className={styles.title}>Late Tasks</div>
          </Flex>

          <div className={stylesMain.containerText} ref={containerRefLate}>
            <div ref={textRefLate} className={styles.weirdHeaderLate}>
              LATE
            </div>
          </div>
        </div>
      )}
      {(projectSummary?.lateTasks || [])
        .slice((pageLate - 1) * itemsPerPageLate, pageLate * itemsPerPageLate)
        .map((task, index) => (
          <SectionProjectSummaryCard key={index} type="late" task={task} projectSummary={projectSummary} />
        ))}

      {(projectSummary?.lateTasks?.length || 0) > 0 && (
        <div style={{ zIndex: 2, width: '100%', position: 'relative' }}>
          <Pagination
            page={pageLate}
            allPages={Math.ceil((projectSummary.lateTasks?.length || 0) / itemsPerPageLate)}
            itemsPerPage={itemsPerPageLate}
            allItems={projectSummary.lateTasks?.length || 0}
            onSelectPage={setPageLate}
            onSelectItemsPerPage={setItemsPerPageLate}
          />
        </div>
      )}

      {/* AT RISK */}
      {projectSummary.risks?.length > 0 && (
        <div className={clsx(stylesMain.mt100, stylesMain.relative)}>
          <div className={styles.redBubble}>
            <BubbleFixed text="Did someone pull the fire alarm?" variant="red" />
          </div>
          <Flex align="center" gap={12}>
            <Flex align="center" justify="center">
              <img src={GifRiskUrl} alt="" width={48} />
            </Flex>
            <div className={styles.title}>Risks on the Projects</div>
          </Flex>
          <div className={stylesMain.containerText} ref={containerRefRisk}>
            <div ref={textRefRisk} className={styles.weirdHeaderRisk}>
              RISK
            </div>
          </div>
        </div>
      )}

      {(projectSummary.risks || [])
        .slice((pageRisk - 1) * itemsPerPageRisk, pageRisk * itemsPerPageRisk)
        .map((risk, index) => (
          <SectionProjectSummaryCard key={index} type="atRisk" task={risk} projectSummary={projectSummary} />
        ))}

      {(projectSummary?.risks?.length || 0) > 0 && (
        <div style={{ zIndex: 2, width: '100%', position: 'relative' }}>
          <Pagination
            page={pageRisk}
            allPages={Math.ceil((projectSummary.risks?.length || 0) / itemsPerPageRisk)}
            itemsPerPage={itemsPerPageRisk}
            allItems={projectSummary.risks?.length || 0}
            onSelectPage={setPageRisk}
            onSelectItemsPerPage={setItemsPerPageRisk}
          />
        </div>
      )}
    </div>
  )
}
