import { usePageBuilderConfigApi } from 'api/pageBuilder/queries/usePageBuilderConfigApi'
import { is404Error, is5xxError } from 'api/utils'
import { NoCodeAppLoading } from 'components/apps/common/noCodeAppLoading/NoCodeAppLoading'
import { PageBuilderView } from 'components/contentBuilderView/PageBuilderView'
import { CriticalError, NotFoundError } from 'components/renderError'
import { NoCodeAppPageBuilder } from 'types/apps/noCodeApps'

interface Props {
  app: NoCodeAppPageBuilder
}

export const PageBuilderRender = ({ app }: Props) => {
  const { isLoading, data, error } = usePageBuilderConfigApi({
    params: {
      configId: app.externalConfigId,
    },
  })

  if (isLoading) {
    return <NoCodeAppLoading />
  }

  if (!data || !data.config || is404Error(error)) {
    return <NotFoundError />
  }

  if (is5xxError(error)) {
    return <CriticalError />
  }

  return <PageBuilderView content={data.config} />
}
