import qs from 'qs'

import { facadeApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'
import { DevHubAppShort, DevHubFilteringType } from 'types/apps/devHubApps'

type Params = PaginationParams<{
  appType?: DevHubFilteringType
  search?: string
}>

export type DevHubAppsListResponse = PaginatedResponse<DevHubAppShort>

export const fetchDevHubAppsListApi = ({ appType, search, page, itemsPerPage }: Params) =>
  facadeApi.get<DevHubAppsListResponse>('/devhub/apps', {
    params: {
      page,
      itemsPerPage,
      filter: {
        appType,
        search,
      },
    },
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
  })
