import { AppInstanceShort } from '@wpp-open/core'
import { useMemo } from 'react'

import { sortBy } from 'utils/common'

export const useGroupAppInstances = ({ appInstances }: { appInstances: AppInstanceShort[] }) =>
  useMemo(() => {
    const ungrouped: AppInstanceShort[] = []

    const groupedMap: Record<
      string,
      {
        group: NonNullable<AppInstanceShort['group']>
        appInstances: AppInstanceShort[]
      }
    > = {}

    for (const appInstance of appInstances) {
      const { group } = appInstance

      if (group) {
        const groupId = group.id

        if (!groupedMap[groupId]) {
          groupedMap[groupId] = {
            group,
            appInstances: [appInstance],
          }
        } else {
          groupedMap[groupId].appInstances.push(appInstance)
        }
      } else {
        ungrouped.push(appInstance)
      }
    }

    const groups = Object.values(groupedMap).map(({ group, appInstances }) => {
      const unSubgrouped: AppInstanceShort[] = []
      const subgroupedMap: Record<
        string,
        {
          subgroup: NonNullable<AppInstanceShort['subgroup']>
          appInstances: AppInstanceShort[]
        }
      > = {}

      for (const appInstance of appInstances) {
        const { subgroup } = appInstance

        if (subgroup) {
          const subgroupId = subgroup.id

          if (!subgroupedMap[subgroupId]) {
            subgroupedMap[subgroupId] = {
              subgroup,
              appInstances: [appInstance],
            }
          } else {
            subgroupedMap[subgroupId].appInstances.push(appInstance)
          }
        } else {
          unSubgrouped.push(appInstance)
        }
      }

      const sortedSubgroups = sortBy(
        Object.values(subgroupedMap),
        ({ subgroup }) => subgroup.order || Number.POSITIVE_INFINITY,
      ).map(sortedSubgroup => ({
        subgroup: sortedSubgroup.subgroup,
        appInstances: sortBy(sortedSubgroup.appInstances, ({ position }) => position),
      }))

      return {
        group,
        subgroups: sortedSubgroups,
        appInstances: sortBy(unSubgrouped, ({ position }) => position),
      }
    })

    return {
      ungrouped: sortBy(ungrouped, ({ position }) => position),
      grouped: sortBy(groups, ({ group }) => group.order || Number.POSITIVE_INFINITY),
    }
  }, [appInstances])
