import {
  WppActionButton,
  WppIconNotification,
  WppIconNotificationNew,
  WppIconNotificationPause,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { subMilliseconds } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAsyncFn } from 'react-use'

import { useUpdateFeedsApi } from 'api/feeds/mutations/useUpdateFeedsApi'
import { useUpdateFeedsLastSeenAtApi } from 'api/feeds/mutations/useUpdateFeedsLastSeenAtApi'
import { useFeedsStatusesApi } from 'api/feeds/queries/useFeedsStatusesApi'
import { useFetchFeedsApi } from 'api/feeds/queryFetchers/useFetchFeedsApi'
import {
  showNotificationItemSideModal,
  useNotificationItemSideModal,
} from 'components/notificationItemSideModal/NotificationItemSideModal'
import {
  hideNotificationsSideModal,
  showNotificationsSideModal,
  useNotificationSideModal,
} from 'components/notificationsSideModal/NotificationsSideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { systemApps } from 'constants/apps'
import { useStableCallback } from 'hooks/useStableCallback'
import { useTenantLogoAndMetaData } from 'hooks/useTenantLogoAndMetaData'
import styles from 'layout/header/notificationsCenter/NotificationsCenter.module.scss'
import { useNotificationMessage, useNotificationPollingCenter } from 'layout/header/notificationsCenter/utils'
import {
  showViewEventSideModal,
  useViewEventSideModal,
} from 'pages/home/systemWidgets/calendarWidget/viewEventSideModal/ViewEventSideModal'
import { queryClient } from 'providers/osQueryClient/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { SystemAppCode } from 'types/apps/microApps'
import { EventName, Feed } from 'types/feeds/feeds'

const REFETCH_INTERVAL = 20 * 1000

const getCreatedAtForPreviousInterval = () => subMilliseconds(new Date(), REFETCH_INTERVAL).toISOString()

export const NotificationsCenter = () => {
  const { t } = useTranslation()
  const { enqueueToast, dequeueToast } = useToast()

  const fetchFeedsApi = useFetchFeedsApi()
  const { data: statuses } = useFeedsStatusesApi()
  const { mutateAsync: handleUpdateFeedsLastSeenAt } = useUpdateFeedsLastSeenAtApi()
  const { mutateAsync: handleUpdateFeeds } = useUpdateFeedsApi()

  const { logoThumbnail: logoUrl, tenantId } = useTenantLogoAndMetaData()
  const { visible: notificationSideModalVisible } = useNotificationSideModal()
  const { visible: notificationItemSideModalVisible } = useNotificationItemSideModal()
  const { visible: viewEventSideModalVisible } = useViewEventSideModal()
  const { getToastMessage, getToastHeader } = useNotificationMessage()
  const navigate = useNavigate()

  const { snoozedUntil: notificationCentreSnoozedTime = null } =
    statuses.find(({ tenantId: id }) => id === tenantId) || {}

  const hasNewNotifications = statuses.some(feed => feed.existsNew)

  const notificationsCenterVisible =
    notificationSideModalVisible || notificationItemSideModalVisible || viewEventSideModalVisible

  const handleViewToastClick = async (toastId: string, notification: Feed, notifications: Feed[]) => {
    dequeueToast(toastId)

    if (notification.eventName === EventName.NEWS_CREATED_NOTIFICATION) {
      navigate(`${systemApps[SystemAppCode.News].osRoute}/${notification.context.news_item_id}`)
    } else if (notification.eventName === EventName.CALENDAR_UPCOMING_EVENT) {
      showViewEventSideModal({
        eventId: notification.context.event_id,
        readOnly: true,
        hub_id: notification.context.hub_id,
      })
    } else {
      showNotificationItemSideModal({ notification, logoUrl })
    }

    await handleUpdateFeeds({ tenantId: tenantId!, ids: [notification.id], updates: { isRead: true } })
    await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.USER_FEEDS_INFINITE] })

    if (notifications?.length === 1) {
      await handleUpdateFeedsLastSeenAt({ tenantId })
      await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.FEEDS_STATUSES] })
    }
  }

  const showNotifications = (notifications: Feed[]) => {
    for (const notification of notifications) {
      const header = getToastHeader(notification)
      const message = getToastMessage(notification)

      if (message) {
        enqueueToast({
          header,
          message,
          icon: { url: logoUrl! },
          maxMessageLines: 2,
          primaryBtn: {
            label: t('os.common.view'),
            variant: 'inverted',
            onClick: async (toastId: string) => await handleViewToastClick(toastId, notification, notifications),
          },
        })
      }
    }
  }

  const checkNewNotifications = useStableCallback(async (createdAt: string) => {
    const { data } = await fetchFeedsApi({ createdAt, tenantIds: [tenantId!] })

    if (data?.data?.length > 0) {
      queryClient.invalidateQueries({
        queryKey: [ApiQueryKeys.USER_FEEDS_INFINITE, { tenantIds: [tenantId!] }],
      })

      if (!notificationsCenterVisible) {
        showNotifications(data?.data)
      }
    }
  })

  const [, handleCreatedAtChanged] = useAsyncFn(checkNewNotifications, [checkNewNotifications])

  const queryAndInvalidateHandler = useStableCallback(async () => {
    await handleCreatedAtChanged(getCreatedAtForPreviousInterval())
    await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.FEEDS_STATUSES] })
  })

  useNotificationPollingCenter({ statuses, tenantId, queryAndInvalidateHandler })

  return (
    <WppActionButton
      className={clsx({ [styles.notificationBellVisible]: notificationSideModalVisible })}
      onClick={() => (!notificationSideModalVisible ? showNotificationsSideModal() : hideNotificationsSideModal())}
      data-testid="notifications-bell-icon"
    >
      {notificationCentreSnoozedTime ? (
        <WppIconNotificationPause color="var(--wpp-grey-color-1000})" />
      ) : hasNewNotifications ? (
        <WppIconNotificationNew color={`var(--wpp-grey-color-${notificationSideModalVisible ? '1000' : '600'})`} />
      ) : (
        <WppIconNotification color={`var(--wpp-grey-color-${notificationSideModalVisible ? '1000' : '600'})`} />
      )}
    </WppActionButton>
  )
}
