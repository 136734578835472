import * as d3 from 'd3'
import { useRef, useEffect, useState } from 'react'

interface Bubble {
  id: string
  value: number
  label: string
  color?: string
}

interface BubbleChartProps {
  color: string
  data: Bubble[]
}

function getTextColor(backgroundColor: string) {
  const color = d3.color(backgroundColor)?.rgb() || { r: 0, g: 0, b: 0 }
  // Calculate brightness based on the formula for luminance
  const luminance = 0.2126 * color.r + 0.7152 * color.g + 0.0722 * color.b
  return luminance > 155 ? '#000' : '#fff' // Threshold can be adjusted
}

function wrapText(textElement: any, text: any, maxWidth: any) {
  const words = text.split(/\s+/).reverse()
  let word
  let line = []
  let lineNumber = 0
  const lineHeight = 1.1
  const x = textElement.getAttribute('x')
  const y = textElement.getAttribute('y')
  let tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan')
  tspan.setAttribute('x', x)
  tspan.setAttribute('y', y)
  textElement.appendChild(tspan)

  while ((word = words.pop())) {
    line.push(word)
    tspan.textContent = line.join(' ')
    if (tspan.getComputedTextLength() > maxWidth && line.length > 1) {
      line.pop()
      tspan.textContent = line.join(' ')
      line = [word]
      tspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan')
      tspan.setAttribute('x', x)
      tspan.setAttribute('y', y)
      tspan.setAttribute('dy', `${lineNumber * lineHeight + 1}em`)
      tspan.textContent = word
      textElement.appendChild(tspan)
      lineNumber++
    }
  }
}

const size = { width: 500, height: 500 }

const BubbleChart: React.FC<BubbleChartProps> = ({ data, color }) => {
  const ref = useRef<SVGSVGElement>(null)
  const [showSvg, setShowSvg] = useState(false)

  useEffect(() => {
    if (!ref.current) return

    const svg = d3.select(ref.current)
    svg.selectAll('*').remove() // Clear SVG content before redrawing

    const sizeScale = d3
      .scaleSqrt()
      .domain([0, d3.max(data, d => d.value) || 0])
      .range([30, 75]) // Adjusted for a better display

    const d3Color = d3.color(color)
    const lighter = d3Color?.brighter(1.5).formatHex() || '#BBBB'
    const darker = d3Color?.darker(1.5).formatHex() || '#000'
    // Create a color scale
    const colorScale = d3
      .scaleLinear<string>()
      .domain([0, d3.max(data, d => d.value) || 0])
      .range([lighter, darker])

    // Setup the force simulation
    const simulation = d3
      .forceSimulation(data as any)
      .force('x', d3.forceX(size.width / 2).strength(0.05))
      .force('y', d3.forceY(size.height / 2).strength(0.05))
      .force(
        'collide',
        d3.forceCollide((d: d3.SimulationNodeDatum, _i: number, _nodes: d3.SimulationNodeDatum[]) => {
          return sizeScale((d as Bubble).value) + 11
        }),
      )

    simulation.tick(1000)
    simulation.stop()

    ticked()

    function ticked() {
      svg
        .selectAll('.bubble')
        .data(data)
        .join('circle')
        .attr('class', 'bubble')
        .attr('cx', d => (d as any).x)
        .attr('cy', d => (d as any).y)
        .attr('r', d => sizeScale(d.value))
        .attr('fill', d => d.color || colorScale(d.value))

      svg
        .selectAll('.label')
        .data(data)
        .join('text')
        .attr('class', 'label flex flex-row items-center justify-center text-center')
        .attr('x', d => (d as any).x)
        .attr('y', d => (d as any).y)
        .attr('text-anchor', 'middle')
        // .attr('dominant-baseline', 'central')
        .style('fill', d => getTextColor(d.color || colorScale(d.value)))
        .style('font-size', d => `${Math.max(8, sizeScale(d.value) / 5)}px`)
        .style('font-family', 'TCCC-UnityText')
        .style('font-weight', '600')
        .each(function (d) {
          wrapText(this, d.label, sizeScale(d.value) * 1.5)
        })
      setTimeout(() => {
        setShowSvg(true)
      }, 50)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return (
    <svg
      style={{ visibility: !showSvg ? 'hidden' : 'visible', position: 'relative' }}
      ref={ref}
      width={size.width}
      height={size.height}
    />
  )
}

export default BubbleChart
