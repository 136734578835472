import { WppTypography, WppIconChevron } from '@platform-ui-kit/components-library-react'
import { useEffect, useState } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/assignmentCard/components/phaseModal/LoopModal.module.scss'

export interface AccordionHeaderProps {
  title: string
  expanded?: boolean
  onClick?: () => void
}

export const AccordionHeader = (props: AccordionHeaderProps) => {
  const { title, expanded = false, onClick } = props

  const [isExpanded, setIsExpanded] = useState(expanded)

  useEffect(() => {
    setIsExpanded(expanded)
  }, [expanded])

  const handleAccordionItemClick = () => {
    setIsExpanded(!isExpanded)
    onClick && onClick()
  }

  return (
    <>
      <Flex
        justify="between"
        slot="header"
        onClick={handleAccordionItemClick}
        style={{ cursor: 'pointer', width: '100%' }}
        className={styles.accordionHeader}
      >
        <WppTypography type="l-strong" className={styles.accordionTitle}>
          {title}
        </WppTypography>
        <WppIconChevron
          direction="down"
          className={`${styles.accordionIcon} ${isExpanded ? styles.rotateUp : styles.rotateDown}`}
        />
      </Flex>
    </>
  )
}
