interface IconProps {
  className?: string
  rounding?: number
  color?: string
  width?: number
  height?: number
}

export const Icon = (props: React.PropsWithChildren<IconProps>) => {
  const { className, rounding = 8, color = '#C65300', width = 86, height = 86, children } = props
  return (
    <div
      className={className}
      style={{
        borderRadius: rounding,
        backgroundColor: color,
        width: width,
        height: height,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {children}
    </div>
  )
}
