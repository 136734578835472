import { AttachmentMetadata, AttachmentMetadataResponse, MayBeNull } from '@wpp-open/core'

export enum HubType {
  Custom = 'CUSTOM',
  System = 'SYSTEM',
}

export interface Hub {
  id: string
  name: string
  tenantId: string
  azId: string
  organizationUnitId: string
  isActive: boolean
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
  type: HubType
  logoThumbnail: MayBeNull<AttachmentMetadataResponse>
  logoOriginal: MayBeNull<AttachmentMetadataResponse>
  azMeta: {
    groups: {
      viewerUsersId: string
      adminUsersId: string
    }
  }
  accessRequestable: boolean
}

export interface CreateHubDTO {
  name: string
  organizationUnitId: string
  isActive: boolean
  logoThumbnail: MayBeNull<AttachmentMetadata>
  logoOriginal: MayBeNull<AttachmentMetadata>
  type: HubType
  accessRequestable: boolean
}
