import { motion } from 'framer-motion'
import { ComponentPropsWithoutRef, forwardRef } from 'react'

export const FadeIn = forwardRef<HTMLDivElement, ComponentPropsWithoutRef<typeof motion.div>>((props, ref) => (
  <motion.div
    {...props}
    ref={ref}
    variants={{
      out: { opacity: 0 },
      in: { opacity: 1 },
    }}
    transition={{ duration: 0.5 }}
    initial="out"
    animate="in"
  />
))
