import { WppIconTick } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useMemo } from 'react'

import { getPalette } from 'components/common/colorPalette/utils'
import styles from 'components/common/colorPaletteMini/ColorPaletteMini.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { usePublicData } from 'providers/publicData/PublicDataContext'

interface Props {
  onChange: (selectedColor: string) => void
  selectedColor: string
}

export const ColorPaletteMini = ({ onChange, selectedColor }: Props) => {
  const { resolvedTheme } = usePublicData()

  const palette = useMemo(() => {
    const {
      color: {
        dataviz: { cat },
      },
    } = resolvedTheme
    return [...getPalette(['neutral'], cat).flat()]
  }, [resolvedTheme])

  return (
    <Flex as="ul" gap={4} className={styles.container}>
      {palette.map((color, colIndex) => (
        <li key={color + colIndex} onClick={() => onChange(color)}>
          <Flex className={styles.colorTag} style={{ backgroundColor: color }} justify="center" align="center">
            <WppIconTick
              color="var(--wpp-grey-color-000)"
              className={clsx({ [styles.hidden]: selectedColor.toLowerCase() !== color.toLowerCase() })}
            />
          </Flex>
        </li>
      ))}
    </Flex>
  )
}
