import { WppIconExternalLink, WppSpinner, WppIconArrow } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { useWorkfrontAuthLink } from 'api/workfront/queries/auth/useFetchWorkfrontAuthLink'
import { Flex } from 'components/common/flex/Flex'
// import GifFinishedAssignments from 'pages/playground/playgroundDashboard/assets/icons/assignmentsComplete.gif'
import GifFinishedAssignments from 'pages/playground/playgroundDashboard/assets/icons/assignments-complete.gif'
import GifNoProjects from 'pages/playground/playgroundDashboard/assets/icons/binocolous.gif'
import IconConnectWOrkfront from 'pages/playground/playgroundDashboard/assets/icons/connect-workfront.gif'
import GifNoAssignments from 'pages/playground/playgroundDashboard/assets/icons/no-assignments.gif'
import GifFinishedProjects from 'pages/playground/playgroundDashboard/assets/icons/projectComplete.gif'
import BubbleFixed from 'pages/playground/playgroundDashboard/components/general/bubble/Bubble'
import { Button } from 'pages/playground/playgroundDashboard/components/general/button/Button'
import styles from 'pages/playground/playgroundDashboard/components/sections/sectionOauth/SectionOauth.module.scss'
import stylesMain from 'pages/playground/playgroundDashboard/PlaygroundDashboard.module.scss'
import { useAuth } from 'providers/auth/AuthContext'
import { useToast } from 'providers/toast/ToastProvider'

export const tccLSKey = 'WPP_TCC_TOKEN'

export const SectionOauth = ({
  loading,
  type,
}: {
  loading: boolean
  type: 'link-projects' | 'link-assignments' | 'empty-projects' | 'empty-assignments' | 'home-page'
}) => {
  const { jwt } = useAuth()
  const { enqueueToast } = useToast()

  const [authLinkEnabled, setAuthLinkEnabled] = useState(false)
  // const [refreshEnabled, setRefreshEnabled] = useState(false)

  const {
    response: responseLink,
    isLoading: isLoading,
    error: isError,
  } = useWorkfrontAuthLink({
    params: { redirectUri: `${window.location.origin}/user/profile/auth/workfront`, jwt },
    enabled: authLinkEnabled,
  })

  useEffect(() => {
    if (!authLinkEnabled) return
    if (responseLink?.data.url) {
      window.localStorage.setItem(tccLSKey, jwt)
      window.open(responseLink.data.url, '_self')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseLink])

  useEffect(() => {
    if (isError) {
      enqueueToast({
        message: 'Error linking Workfront account. Please try again later.',
        type: 'error',
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  const handleOauth = () => {
    if (isLoading) return

    setAuthLinkEnabled(true)
  }

  const pageData = {
    ['link-projects']: {
      icon: GifNoProjects,
      headerText: 'No projects linked',
      text: 'Keep an eye on your project details and more when you link your \nWorkfront account to The Playground.',
      bubbleText: 'Connect to more fun',
      button: true,
    },
    ['link-assignments']: {
      icon: GifNoAssignments,
      headerText: 'No assignments linked',
      text: 'Connect your Workfront account to The Playground to get info on \nyour assignments and tasks.',
      bubbleText: 'Stay in-the-know',
      button: true,
    },
    ['empty-projects']: {
      icon: GifFinishedProjects,
      headerText: 'All projects complete!',
      text: 'We love this for you. Take a walk, take a moment, take a break.',
      bubbleText: 'What a great feeling',
      button: false,
    },
    ['empty-assignments']: {
      icon: GifFinishedAssignments,
      headerText: 'All your Assignments are complete!',
      text: 'It’s the perfect opportunity to complete or update your Experience Profile to keep your momentum going.',
      bubbleText: 'Enjoy the pause',
      button: true,
    },
    ['home-page']: {
      icon: IconConnectWOrkfront,
      headerText: 'Link Accounts',
      text: 'Take advantage of all The Playground has to offer by linking The \nPlayground to your Workfront profile.',
      bubbleText: undefined,
      button: true,
    },
  }

  return (
    <Flex direction="column" justify="center" align="center">
      <div>
        <img src={pageData[type].icon} alt="" width={120} />
      </div>
      <div className={clsx(stylesMain.relative, styles.orangeHeader)}>
        {pageData[type].headerText}
        {pageData[type]?.bubbleText && (
          <div className={styles.bubbleAbsolute}>
            <BubbleFixed text={pageData[type]?.bubbleText || ''} />
          </div>
        )}
      </div>
      <div className={clsx(stylesMain.blackMBody, stylesMain.preWrap, stylesMain['text-center'], stylesMain.mt12)}>
        {pageData[type].text}
      </div>
      {pageData[type].button && (
        <>
          {type === 'empty-assignments' ? (
            <Flex justify="center" align="center" className={stylesMain.mt30}>
              <Link
                to={
                  window.location.host.includes('playground-stage')
                    ? '/application/f9007058-00fa-4730-bfe6-a9ebf79d00ff/experience-profile_0a68023a-8e9d-177d-818e-c706516e0000_experience-profile'
                    : '/application/5d7a3dc2-54a1-44a4-bf61-f9c7a278ff5a/experience-profile_0a0c000e-907d-10df-8191-36ed8e410023_experience-profile'
                }
                className={stylesMain.mt42}
              >
                <Button
                  text="Review Experience Profile"
                  variant="black"
                  iconRight={<WppIconArrow direction="right" color="white" />}
                />
              </Link>
            </Flex>
          ) : (
            <Flex justify="center" align="center" className={stylesMain.mt30}>
              <div onClick={() => handleOauth()}>
                <Button
                  text="Connect to Adobe Workfront"
                  variant="black"
                  iconRight={
                    isLoading || loading ? <WppSpinner color="white" /> : <WppIconExternalLink color="white" />
                  }
                />
              </div>
            </Flex>
          )}
        </>
      )}
    </Flex>
  )
}
