import styles from 'components/svg/common.module.scss'

export const SvgForbidden = (props: JSX.IntrinsicElements['svg']) => (
  <svg width="191" height="167" viewBox="0 0 191 167" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_f_209_9417)">
      <ellipse cx="93.874" cy="131.818" rx="71.8332" ry="12.9414" className={styles.fillPrimary300} />
    </g>
    <path
      d="M99 148.491C137.66 148.491 169 117.151 169 78.4911C169 39.8312 137.66 8.49109 99 8.49109C60.3401 8.49109 29 39.8312 29 78.4911C29 117.151 60.3401 148.491 99 148.491Z"
      className={styles.fillPrimary100}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.8597 17.2373L71.3672 26.4662C71.5353 26.5905 71.6767 26.7467 71.783 26.9255C71.8894 27.1043 71.9585 27.3021 71.9862 27.507C72.0139 27.712 71.9997 27.9199 71.9444 28.1183C71.8891 28.3168 71.7939 28.5017 71.6644 28.6621C71.1422 29.3099 70.1763 29.4012 69.4971 28.9001L69.1015 28.6082C69.0733 28.5874 69.038 28.5784 69.0034 28.5833C68.9688 28.5881 68.9377 28.6064 68.9169 28.6341L67.2053 30.9218C67.1742 30.9633 67.1276 30.9908 67.0757 30.9981C67.0237 31.0054 66.9708 30.9919 66.9284 30.9606L65.2754 29.7409C65.2331 29.7096 65.2049 29.6631 65.197 29.6117C65.189 29.5602 65.2019 29.508 65.2329 29.4664L66.4158 27.8855C66.4365 27.8578 66.4451 27.8229 66.4398 27.7886C66.4345 27.7543 66.4157 27.7233 66.3875 27.7025L65.3482 26.9357C65.32 26.9148 65.2847 26.9059 65.2501 26.9107C65.2154 26.9156 65.1844 26.9339 65.1636 26.9616L64.2097 28.2365C64.1786 28.2779 64.132 28.3053 64.0801 28.3126C64.0282 28.3199 63.9752 28.3065 63.9329 28.2753L62.2798 27.0556C62.2375 27.0243 62.2094 26.9778 62.2014 26.9264C62.1934 26.8749 62.2064 26.8227 62.2373 26.7811L63.72 24.7995C63.7407 24.7718 63.7494 24.7369 63.7441 24.7026C63.7388 24.6683 63.7199 24.6373 63.6917 24.6165L57.0212 19.6945C56.9747 19.6603 56.9184 19.642 56.8608 19.6423C56.8032 19.6426 56.7475 19.6616 56.7022 19.6963C55.4249 20.683 53.8186 21.1632 52.1922 21.0445C50.5659 20.9259 49.0345 20.2167 47.8925 19.0534C46.7506 17.8901 46.0788 16.3549 46.0065 14.7432C45.9342 13.1315 46.4664 11.5571 47.5007 10.3229C49.6885 7.70352 53.621 7.23395 56.4336 9.24882C57.6254 10.1023 58.5015 11.3202 58.9242 12.7111C59.347 14.102 59.2925 15.5872 58.7694 16.9334C58.749 16.9865 58.7468 17.0449 58.7631 17.0998C58.7794 17.1547 58.8133 17.203 58.8597 17.2373ZM52.704 18.2149C54.7717 18.2149 56.448 16.5502 56.448 14.4968C56.448 12.4433 54.7717 10.7786 52.704 10.7786C50.6362 10.7786 48.9599 12.4433 48.9599 14.4968C48.9599 16.5502 50.6362 18.2149 52.704 18.2149Z"
      className={styles.fillPrimary400}
    />
    <g filter="url(#filter1_f_209_9417)">
      <ellipse cx="98.5" cy="126.5" rx="70.5" ry="13.5" className={styles.fillPrimary300} />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35 62.7067V74.9768L36.5895 129.764L43.2654 132V131.915L70.1308 131.915C71.7975 131.915 73.1485 130.557 73.1485 128.883L73.1482 62.7067C73.1482 56.8605 71.2543 51.2467 67.8711 47.0278H138V40H53.4382V40.0126C48.6102 40.2042 44.0136 42.5711 40.5867 46.6506C37.0096 50.909 35 56.6845 35 62.7067ZM65.341 79.7589V124.031H43.2654L43.2655 79.7589H65.341Z"
      className={styles.fillPrimary400}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35 62.7067V74.9768L36.5895 129.764L43.2654 132V131.915L70.1308 131.915C71.7975 131.915 73.1485 130.557 73.1485 128.883L73.1482 62.7067C73.1482 56.8605 71.2543 51.2467 67.8711 47.0278H138V40H53.4382V40.0126C48.6102 40.2042 44.0136 42.5711 40.5867 46.6506C37.0096 50.909 35 56.6845 35 62.7067ZM65.341 79.7589V124.031H43.2654L43.2655 79.7589H65.341Z"
      fill="url(#paint0_linear_209_9417)"
    />
    <path d="M41 60C41 52.268 47.268 46 55 46H127L121.028 72H41V60Z" className={styles.fillPrimary300} />
    <rect x="42" y="79" width="35" height="45" className={styles.fillPrimary300} />
    <rect x="85" y="79" width="35" height="45" className={styles.fillPrimary300} />
    <path
      d="M43.1714 71H40.6391C37.5247 71 35 73.5751 35 76.7517L35 126.11C35 129.287 37.5247 131.862 40.6391 131.862H42.8774V131.948H76.9861V132H85.3029V131.948H122.883V123.529H85.3029L85.3029 78.992H123V71H43.1714L43.1716 78.992H76.9861L76.9861 123.529H43.1714L43.1714 71Z"
      className={styles.fillPrimary400}
    />
    <path
      d="M43.1714 71H40.6391C37.5247 71 35 73.5751 35 76.7517L35 126.11C35 129.287 37.5247 131.862 40.6391 131.862H42.8774V131.948H76.9861V132H85.3029V131.948H122.883V123.529H85.3029L85.3029 78.992H123V71H43.1714L43.1716 78.992H76.9861L76.9861 123.529H43.1714L43.1714 71Z"
      fill="url(#paint1_linear_209_9417)"
    />
    <path
      d="M71 72V72.0876V74.8531V95H93.0507C93.575 95 94 94.575 94 94.0507V74.8531V72.9493C94 72.425 93.575 72 93.0507 72H71Z"
      className={styles.fillPrimary400}
    />
    <path
      d="M69.9493 72C69.425 72 69 72.425 69 72.9493V94.0507C69 94.575 69.425 95 69.9493 95H91V72H69.9493Z"
      fill="url(#paint2_linear_209_9417)"
    />
    <path d="M89 74H71V92H89V74Z" fill="url(#paint3_linear_209_9417)" />
    <path
      d="M82.2954 83.6215C82.9884 83.1763 83.5082 82.5411 83.7794 81.8078C84.0505 81.0746 84.059 80.2815 83.8035 79.5436C83.548 78.8056 83.0418 78.1613 82.3584 77.7039C81.675 77.2466 80.8499 77 80.0027 77C79.1556 77 78.3305 77.2466 77.6471 77.7039C76.9637 78.1613 76.4575 78.8056 76.202 79.5436C75.9465 80.2815 75.9549 81.0746 76.2261 81.8078C76.4972 82.5411 77.017 83.1763 77.71 83.6215L76 89H84L82.2954 83.6215Z"
      fill="url(#paint4_linear_209_9417)"
    />
    <path d="M42 79H46V124H42V79Z" className={styles.fillPrimary500} />
    <path d="M85 95H88V124H85V95Z" className={styles.fillPrimary500} />
    <path
      d="M81 104C93.7025 104 104 93.031 104 79.5C104 65.969 93.7025 55 81 55C68.2975 55 58 65.969 58 79.5C58 93.031 68.2975 104 81 104Z"
      className={styles.fillDanger400}
    />
    <path
      d="M86.557 80.5L93.9428 73.931C94.6348 73.2812 95.0139 72.4175 94.9996 71.5235C94.9853 70.6296 94.5788 69.7758 93.8664 69.1436C93.154 68.5114 92.1919 68.1506 91.1845 68.1379C90.1771 68.1253 89.2039 68.4617 88.4716 69.0757L81.0693 75.6447L73.6835 69.0757C73.3281 68.742 72.9008 68.4748 72.4271 68.2901C71.9533 68.1054 71.4426 68.0068 70.9251 68.0003C70.4077 67.9938 69.8941 68.0795 69.4146 68.2522C68.9351 68.4249 68.4995 68.6812 68.1336 69.006C67.7677 69.3307 67.4788 69.7172 67.2842 70.1427C67.0896 70.5682 66.9931 71.0241 67.0004 71.4833C67.0077 71.9424 67.1187 72.3956 67.3269 72.8161C67.535 73.2365 67.8361 73.6157 68.2122 73.931L75.6104 80.5L68.2122 87.0689C67.8361 87.3843 67.535 87.7635 67.3269 88.1839C67.1187 88.6044 67.0077 89.0576 67.0004 89.5167C66.9931 89.9759 67.0896 90.4317 67.2842 90.8573C67.4788 91.2828 67.7677 91.6693 68.1336 91.994C68.4995 92.3187 68.9351 92.5751 69.4146 92.7478C69.8941 92.9205 70.4077 93.0062 70.9251 92.9997C71.4426 92.9931 71.9533 92.8946 72.4271 92.7099C72.9008 92.5252 73.3281 92.258 73.6835 91.9243L81.0858 85.3553L88.4881 91.9243C89.2273 92.5021 90.187 92.808 91.1716 92.7797C92.1562 92.7514 93.0916 92.3909 93.7871 91.7718C94.4826 91.1527 94.8859 90.3216 94.9149 89.4478C94.9438 88.5739 94.5962 87.7232 93.9428 87.0689L86.557 80.5Z"
      className={styles.fillWhite}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M146 28.2857C147.262 28.2857 148.286 27.2624 148.286 26C148.286 24.7376 147.262 23.7143 146 23.7143C144.738 23.7143 143.714 24.7376 143.714 26C143.714 27.2624 144.738 28.2857 146 28.2857ZM146 30C148.209 30 150 28.2091 150 26C150 23.7909 148.209 22 146 22C143.791 22 142 23.7909 142 26C142 28.2091 143.791 30 146 30Z"
      className={styles.fillPrimary300}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M126 146.286C127.262 146.286 128.286 145.262 128.286 144C128.286 142.738 127.262 141.714 126 141.714C124.738 141.714 123.714 142.738 123.714 144C123.714 145.262 124.738 146.286 126 146.286ZM126 148C128.209 148 130 146.209 130 144C130 141.791 128.209 140 126 140C123.791 140 122 141.791 122 144C122 146.209 123.791 148 126 148Z"
      className={styles.fillPrimary300}
    />
    <path
      d="M20.2985 107.031C22.0586 106.744 23.4816 108.525 22.8456 110.219L21.9838 112.515C21.3544 114.192 19.1761 114.526 18.0518 113.119L16.5283 111.211C15.4041 109.804 16.1712 107.703 17.9131 107.419L20.2985 107.031Z"
      className={styles.fillPrimary300}
    />
    <path
      d="M164.067 103.833C163.656 102.141 165.209 100.63 166.894 101.08L170.289 101.986C172.014 102.447 172.582 104.605 171.306 105.847L168.737 108.347C167.461 109.589 165.312 108.97 164.892 107.239L164.067 103.833Z"
      className={styles.fillPrimary300}
    />
    <path
      d="M89.0064 7.48074C89.5384 5.50642 92.4616 5.50642 92.9936 7.48074L93.2725 8.51546C93.4656 9.23214 94.0604 9.78506 94.8133 9.94785L95.3937 10.0733C97.5354 10.5364 97.5354 13.4636 95.3937 13.9267L94.8133 14.0522C94.0604 14.2149 93.4656 14.7679 93.2725 15.4845L92.9936 16.5193C92.4616 18.4936 89.5384 18.4936 89.0064 16.5193L88.7275 15.4845C88.5344 14.7679 87.9396 14.2149 87.1867 14.0521L86.6063 13.9267C84.4646 13.4636 84.4646 10.5364 86.6063 10.0733L87.1867 9.94785C87.9396 9.78506 88.5344 9.23214 88.7275 8.51546L89.0064 7.48074Z"
      className={styles.fillPrimary400}
    />
    <path
      d="M54.3402 142.604C54.9166 140.465 58.0834 140.465 58.6598 142.604L58.9619 143.725C59.1711 144.501 59.8154 145.1 60.6311 145.277L61.2598 145.413C63.5801 145.914 63.5801 149.086 61.2598 149.587L60.6311 149.723C59.8154 149.9 59.1711 150.499 58.9619 151.275L58.6598 152.396C58.0834 154.535 54.9166 154.535 54.3402 152.396L54.0381 151.275C53.8289 150.499 53.1846 149.9 52.3689 149.723L51.7402 149.587C49.4199 149.086 49.4199 145.914 51.7402 145.413L52.3689 145.277C53.1846 145.1 53.8289 144.501 54.0381 143.725L54.3402 142.604Z"
      className={styles.fillPrimary400}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M120 71.9444V62.7067C120 56.6845 122.002 50.909 125.565 46.6506C129.128 42.3923 133.961 40 139 40C144.039 40 148.872 42.3923 152.435 46.6506C155.998 50.909 158 56.6845 158 62.7067L158 128.883C158 130.557 156.654 131.915 154.994 131.915L128.233 131.915V132H120L120 74.9768C120 73.3021 120 71.9444 120 71.9444ZM150.223 124.031V79.7589H128.233L128.233 124.031H150.223Z"
      className={styles.fillPrimary400}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M120 71.9444V62.7067C120 56.6845 122.002 50.909 125.565 46.6506C129.128 42.3923 133.961 40 139 40C144.039 40 148.872 42.3923 152.435 46.6506C155.998 50.909 158 56.6845 158 62.7067L158 128.883C158 130.557 156.654 131.915 154.994 131.915L128.233 131.915V132H120L120 74.9768C120 73.3021 120 71.9444 120 71.9444ZM150.223 124.031V79.7589H128.233L128.233 124.031H150.223Z"
      fill="url(#paint5_linear_209_9417)"
    />
    <path
      d="M126.982 65.8094V60.897C126.982 57.1189 128.172 53.4955 130.29 50.824C132.409 48.1525 135.282 46.6517 138.277 46.6517H139.314C142.31 46.6517 145.183 48.1525 147.301 50.824C149.42 53.4955 150.61 57.1189 150.61 60.897V65.8094H126.982Z"
      fill="url(#paint6_linear_209_9417)"
    />
    <mask
      id="mask0_209_9417"
      style={{
        maskType: 'alpha',
      }}
      maskUnits="userSpaceOnUse"
      x="126"
      y="46"
      width="25"
      height="20"
    >
      <path
        d="M126.982 65.8094V60.897C126.982 57.1189 128.172 53.4955 130.29 50.824C132.409 48.1525 135.282 46.6517 138.277 46.6517H139.314C142.31 46.6517 145.183 48.1525 147.301 50.824C149.42 53.4955 150.61 57.1189 150.61 60.897V65.8094H126.982Z"
        fill="url(#paint7_linear_209_9417)"
      />
    </mask>
    <g mask="url(#mask0_209_9417)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126.343 61.0278V67.0865H129.536V63.5822C129.536 58.9225 130.726 54.4537 132.844 51.1589C134.963 47.864 137.836 46.013 140.831 46.013H141.868C143.07 46.013 144.252 46.3108 145.373 46.8801C143.444 44.6711 141.1 43.4586 138.676 43.4586H137.639C134.643 43.4586 131.77 45.3097 129.652 48.6045C127.533 51.8994 126.343 56.3682 126.343 61.0278Z"
        className={styles.fillPrimary500}
      />
    </g>
    <rect x="127" y="79" width="24" height="45" className={styles.fillPrimary300} />
    <path d="M127 79H131V124H127V79Z" className={styles.fillPrimary500} />
    <defs>
      <filter
        id="filter0_f_209_9417"
        x="0.267776"
        y="97.1031"
        width="187.212"
        height="69.4289"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="10.8865" result="effect1_foregroundBlur_209_9417" />
      </filter>
      <filter
        id="filter1_f_209_9417"
        x="6.22701"
        y="91.227"
        width="184.546"
        height="70.546"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="10.8865" result="effect1_foregroundBlur_209_9417" />
      </filter>
      <linearGradient
        id="paint0_linear_209_9417"
        x1="54.2531"
        y1="19.3194"
        x2="122.968"
        y2="120.372"
        gradientUnits="userSpaceOnUse"
      >
        <stop className={styles.stopColorWhite} stopOpacity="0.32" />
        <stop offset="1" className={styles.stopColorWhite} stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_209_9417"
        x1="51.4492"
        y1="57.2879"
        x2="91.8933"
        y2="133.928"
        gradientUnits="userSpaceOnUse"
      >
        <stop className={styles.stopColorWhite} stopOpacity="0.32" />
        <stop offset="1" className={styles.stopColorWhite} stopOpacity="0" />
      </linearGradient>
      <linearGradient id="paint2_linear_209_9417" x1="80" y1="72" x2="80" y2="95" gradientUnits="userSpaceOnUse">
        <stop className={styles.stopColorPrimary300} />
        <stop offset="1" className={styles.stopColorPrimary400} />
      </linearGradient>
      <linearGradient
        id="paint3_linear_209_9417"
        x1="57.2856"
        y1="77.8516"
        x2="89.3211"
        y2="83.2289"
        gradientUnits="userSpaceOnUse"
      >
        <stop className={styles.stopColorPrimary300} />
        <stop offset="1" className={styles.stopColorPrimary400} />
      </linearGradient>
      <linearGradient
        id="paint4_linear_209_9417"
        x1="78.0498"
        y1="85.6351"
        x2="85.9842"
        y2="85.9691"
        gradientUnits="userSpaceOnUse"
      >
        <stop className={styles.stopColorPrimary500} />
        <stop offset="1" className={styles.stopColorPrimary400} />
      </linearGradient>
      <linearGradient
        id="paint5_linear_209_9417"
        x1="33.3125"
        y1="59.1169"
        x2="111.937"
        y2="32.182"
        gradientUnits="userSpaceOnUse"
      >
        <stop className={styles.stopColorWhite} stopOpacity="0.32" />
        <stop offset="1" className={styles.stopColorWhite} stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_209_9417"
        x1="159.349"
        y1="41.4421"
        x2="137.133"
        y2="64.1332"
        gradientUnits="userSpaceOnUse"
      >
        <stop className={styles.stopColorPrimary100} />
        <stop offset="1" className={styles.stopColorPrimary300} />
      </linearGradient>
      <linearGradient
        id="paint7_linear_209_9417"
        x1="159.349"
        y1="41.4421"
        x2="137.133"
        y2="64.1332"
        gradientUnits="userSpaceOnUse"
      >
        <stop className={styles.stopColorPrimary100} />
        <stop offset="1" className={styles.stopColorPrimary300} />
      </linearGradient>
    </defs>
  </svg>
)
