import { MayBeNull } from '@wpp-open/core'
import { useCallback, useMemo, useState } from 'react'

import { FromToCoords, Point } from 'types/connection/connection'

const CONNECTION_OFFSET_X = 4

export enum FluidMenuItemTag {
  Root = 'fluid-menu-root',
  Current = 'fluid-menu-current',
  Prev = 'fluid-menu-prev',
  Next = 'fluid-menu-next',
}

interface FluidConnectionTracking {
  prevConnections: FromToCoords[]
  nextConnections: FromToCoords[]
  connect: ConnectionCallback
  disconnect: ConnectionCallback
}

export type ConnectionCallback = (node: Element) => void

interface CoordsState {
  root: MayBeNull<NodeCoords>
  current: MayBeNull<NodeCoords>
  prev: NodeCoords[]
  next: NodeCoords[]
}

interface NodeCoords {
  node: Element
  coords: ItemBoundingCoords
}

interface ItemBoundingCoords {
  leftTop: Point
  bottomRight: Point
}

export const useFluidConnectionTracking = (): FluidConnectionTracking => {
  const [elementCoords, setElementCoords] = useState<CoordsState>({
    root: null,
    current: null,
    prev: [],
    next: [],
  })

  const [resizeObserver] = useState(
    () =>
      new ResizeObserver(entries => {
        setElementCoords(currentCoordsState => {
          const nextCoordsState: CoordsState = {
            ...currentCoordsState,
            prev: [...currentCoordsState.prev],
            next: [...currentCoordsState.next],
          }

          entries.forEach(entry => {
            const isRoot = entry.target.classList.contains(FluidMenuItemTag.Root)
            const isCurrent = entry.target.classList.contains(FluidMenuItemTag.Current)
            const isPrev = entry.target.classList.contains(FluidMenuItemTag.Prev)
            const isNext = entry.target.classList.contains(FluidMenuItemTag.Next)

            const newItem = {
              node: entry.target,
              coords: getEmptyBoundingCoords(),
            }

            if (isRoot) {
              nextCoordsState.root = newItem
            } else if (isRoot || isCurrent) {
              nextCoordsState.current = newItem
            } else if (isPrev || isNext) {
              const list = isPrev ? nextCoordsState.prev : nextCoordsState.next
              const elementIndex = list.findIndex(({ node }) => node === entry.target)

              if (elementIndex < 0) {
                list.push(newItem)
              }
            }
          })

          calculateNodeCoords(nextCoordsState.root)
          calculateNodeCoords(nextCoordsState.current)
          nextCoordsState.prev.forEach(item => calculateNodeCoords(item))
          nextCoordsState.next.forEach(item => calculateNodeCoords(item))

          return nextCoordsState
        })
      }),
  )

  const { prev: prevConnections, next: nextConnections } = useMemo(
    () => formatConnections(elementCoords),
    [elementCoords],
  )

  const connect: FluidConnectionTracking['connect'] = useCallback(
    node => {
      resizeObserver.observe(node)
    },
    [resizeObserver],
  )

  const disconnect: FluidConnectionTracking['disconnect'] = useCallback(
    node => {
      resizeObserver.unobserve(node)

      setElementCoords(currentCoordsState => ({
        root: currentCoordsState.root?.node === node ? null : currentCoordsState.root,
        current: currentCoordsState.current?.node === node ? null : currentCoordsState.current,
        prev: currentCoordsState.prev.filter(item => item.node !== node),
        next: currentCoordsState.next.filter(item => item.node !== node),
      }))
    },
    [resizeObserver],
  )

  return {
    prevConnections,
    nextConnections,
    connect,
    disconnect,
  }
}

const getEmptyBoundingCoords = (): ItemBoundingCoords => ({
  leftTop: {
    x: 0,
    y: 0,
  },
  bottomRight: {
    x: 0,
    y: 0,
  },
})

const calculateNodeCoords = (nodeCoords: MayBeNull<NodeCoords>) => {
  if (nodeCoords) {
    const itemRect = nodeCoords.node.getBoundingClientRect()

    nodeCoords.coords = {
      leftTop: {
        x: itemRect.left,
        y: itemRect.top,
      },
      bottomRight: {
        x: itemRect.right,
        y: itemRect.bottom,
      },
    }
  }
}

interface FormattedConnections {
  prev: FromToCoords[]
  next: FromToCoords[]
}

export const formatConnections = ({ root, current, prev, next }: CoordsState): FormattedConnections => {
  if (!root || !current) {
    return { prev: [], next: [] }
  }

  const getLeftPoint = (point: ItemBoundingCoords, offsetPoint: ItemBoundingCoords): Point => ({
    x: point.leftTop.x - CONNECTION_OFFSET_X - offsetPoint.leftTop.x,
    y: (point.leftTop.y + point.bottomRight.y) / 2 - offsetPoint.leftTop.y,
  })
  const getRightPoint = (point: ItemBoundingCoords, offsetPoint: ItemBoundingCoords): Point => ({
    x: point.bottomRight.x + CONNECTION_OFFSET_X - offsetPoint.leftTop.x,
    y: (point.leftTop.y + point.bottomRight.y) / 2 - offsetPoint.leftTop.y,
  })

  const currentLeftPoint = getLeftPoint(current.coords, root.coords)
  const currentRightPoint = getRightPoint(current.coords, root.coords)

  return {
    prev: prev.map(item => ({
      start: getRightPoint(item.coords, root.coords),
      end: currentLeftPoint,
    })),
    next: next.map(item => ({
      start: currentRightPoint,
      end: getLeftPoint(item.coords, root.coords),
    })),
  }
}
