import { WppGrid } from '@platform-ui-kit/components-library-react'
import { PropsWithChildren } from 'react'

import styles from 'layout/pageContent/PageContent.module.scss'

export const PageContent = ({ children }: PropsWithChildren<{}>) => (
  <div className={styles.root}>
    <WppGrid container>
      <WppGrid item all={24}>
        {children}
      </WppGrid>
    </WppGrid>
  </div>
)
