import { WppSkeleton } from '@platform-ui-kit/components-library-react'
import { IDatasource, IRowNode, RowClassRules, SortModelItem, ValueGetterFunc } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import clsx from 'clsx'
import { forwardRef, Ref, useMemo, useRef } from 'react'
import { mergeRefs } from 'react-merge-refs'

import { Flex } from 'components/common/flex/Flex'
import { ColDef, Table, TableProps } from 'components/common/table/Table'
import styles from 'components/common/table/tableInfinite/TableInfinite.module.scss'
import { RenderErrorType, useDispatchRenderError } from 'components/renderError/utils'
import { TableDefaults } from 'constants/table'
import { useStableCallback } from 'hooks/useStableCallback'
import { isNumber, isString, noop } from 'utils/common'

export type TableInfiniteLoader<TData = any> = (params: {
  startRow: number
  endRow: number
  sortModel: SortModelItem[]
}) => Promise<{ data: TData[]; totalRowsCount: number }>

export type TableInfiniteOnLoadSuccess<TData = any> = (params: {
  startRow: number
  endRow: number
  sortModel: SortModelItem[]
  data: TData[]
  totalRowsCount: number
  /**
   * Indicates if the loader returned 0 items for the first page.
   */
  isEmptySource: boolean
}) => void

export type TableInfiniteOnLoadError = (error: unknown) => void

export type TableInfiniteProps<TData = any> = Omit<
  TableProps<TData>,
  'rowModelType' | 'datasource' | 'rowData' | 'suppressRowClickSelection' | 'rowSelection'
> & {
  /**
   * Loader function used to create datasource.
   * This function reference should be maintained and changed only if you want
   * to table be reloaded with changed parameters.
   * Ex. filters or search was changed.
   */
  loader: TableInfiniteLoader<TData>
  onLoadSuccess?: TableInfiniteOnLoadSuccess<TData>
  onLoadError?: TableInfiniteOnLoadError
}

export const TableInfinite = forwardRef(function TableInfinite<TData = any>(
  {
    rowClassRules,
    className,
    loader,
    onLoadSuccess = noop,
    onLoadError = noop,
    columnDefs,
    cacheOverflowSize = 5,
    cacheBlockSize = TableDefaults.CacheBlockSize,
    infiniteInitialRowCount = cacheBlockSize,
    ...rest
  }: TableInfiniteProps<TData>,
  ref: Ref<AgGridReact<TData>>,
) {
  const dispatchError = useDispatchRenderError()
  const innerRef = useRef<AgGridReact<TData>>()
  const onLoadSuccessStable = useStableCallback(onLoadSuccess)
  const onLoadErrorStable = useStableCallback(onLoadError)

  const rowClassRulesInner = useMemo<RowClassRules<TData>>(
    () => ({
      ...rowClassRules,
      [[styles.loadingMoreRow, 'loading-more-row'].join(' ')]: ({ data, node }) =>
        isLoadingMoreRow<TData>({ data, node }),
    }),
    [rowClassRules],
  )

  const columnDefsInner = useMemo<ColDef<TData>[]>(
    () =>
      columnDefs.map(colDef => {
        const { cellRenderer, loadingCellRenderer, valueGetter, tooltipValueGetter, cellClassRules, ...rest } = colDef

        return {
          ...rest,
          ...(!!valueGetter && { valueGetter: getValueGetter<TData>(valueGetter) }),
          ...(!!tooltipValueGetter && { tooltipValueGetter: getToolipValueGetter<TData>(tooltipValueGetter) }),
          cellRenderer: getCellRenderer<TData>(colDef),
          cellClassRules: {
            ...cellClassRules,
            'infinite-default-cell': () => !cellRenderer,
          },
        }
      }),
    [columnDefs],
  )

  const datasource = useMemo<IDatasource>(() => {
    let timestamp = Date.now()

    return {
      async getRows({ startRow, endRow, successCallback, failCallback, sortModel }) {
        const operationStartTimestamp = timestamp
        const isDiscarded = () => operationStartTimestamp !== timestamp

        const hideOverlay = () => {
          innerRef.current?.api.hideOverlay()
        }

        const showNoRowsOverlay = () => {
          innerRef.current?.api.showNoRowsOverlay()
        }

        const isInitialLoading = startRow === 0

        try {
          hideOverlay()

          const { data, totalRowsCount } = await loader({ startRow, endRow, sortModel })

          if (isDiscarded()) {
            successCallback([], 0)
          } else {
            if (!data.length) {
              if (isInitialLoading) {
                showNoRowsOverlay()
              }

              successCallback([], 0)
            } else {
              hideOverlay()
              successCallback(data, totalRowsCount <= endRow ? totalRowsCount : undefined)
            }

            onLoadSuccessStable({
              startRow,
              endRow,
              sortModel,
              data,
              totalRowsCount,
              isEmptySource: startRow === 0 && !totalRowsCount,
            })

            try {
              const renderedNodes = innerRef.current?.api.getRenderedNodes()

              const shouldReflowRows = renderedNodes?.some(
                ({ rowTop, rowHeight }, index, arr) =>
                  index !== 0 &&
                  isNumber(rowTop) &&
                  isNumber(rowHeight) &&
                  rowTop - rowHeight !== arr[index - 1].rowTop,
              )

              if (shouldReflowRows) {
                innerRef.current?.api.redrawRows({ rowNodes: renderedNodes })
              }
            } catch (e) {
              if (process.env.DEV) {
                console.error('Rows reflow failed.')
              }
            }
          }
        } catch (e) {
          failCallback()

          if (process.env.DEV) {
            console.error(e)
          }

          if (!isDiscarded()) {
            hideOverlay()
            onLoadErrorStable(e)
            dispatchError(RenderErrorType.DataIsNotAvailable)
          }
        }
      },
      destroy() {
        timestamp = Date.now()
      },
    }
  }, [loader, onLoadSuccessStable, onLoadErrorStable, dispatchError])

  return (
    <Table
      {...rest}
      ref={mergeRefs([ref, innerRef])}
      className={clsx(styles.root, className)}
      rowModelType="infinite"
      datasource={datasource}
      columnDefs={columnDefsInner}
      cacheBlockSize={cacheBlockSize}
      rowClassRules={rowClassRulesInner}
      infiniteInitialRowCount={infiniteInitialRowCount}
      cacheOverflowSize={cacheOverflowSize}
      suppressRowClickSelection
      rowSelection="multiple"
    />
  )
}) as <TData = any>(props: { ref?: Ref<AgGridReact<TData>> } & TableInfiniteProps<TData>) => JSX.Element

type TooltipValueGetter<TData = any> = NonNullable<ColDef<TData>['tooltipValueGetter']>

function isLoadingMoreRow<TData = any>({ data, node }: { data?: TData; node: IRowNode<TData> }) {
  return !data && !node.group && !node.isExpandable()
}

function getValueGetter<TData = any>(valueGetter: string | ValueGetterFunc<TData>): ValueGetterFunc<TData> {
  return function (params) {
    const { data, node } = params

    if (node && isLoadingMoreRow({ data, node })) {
      return null
    }

    return isString(valueGetter) ? data![valueGetter as keyof typeof data] : valueGetter(params)
  }
}

function getToolipValueGetter<TData = any>(tooltipValueGetter: TooltipValueGetter<TData>): TooltipValueGetter<TData> {
  return function (params) {
    const { data, node } = params

    if (node && isLoadingMoreRow({ data, node })) {
      return null
    }

    return tooltipValueGetter(params)
  }
}

const DefaultLoadingCellRenderer = () => (
  <Flex className={styles.defaultLoadingCell} align="center">
    <WppSkeleton height="50%" />
  </Flex>
)

function getCellRenderer<TData = any>(colDef: ColDef<TData>): NonNullable<ColDef<TData>['cellRenderer']> {
  const { cellRenderer: CellRenderer, loadingCellRenderer: LoadingCellRenderer = DefaultLoadingCellRenderer } = colDef

  return function (params) {
    const { value, valueFormatted, data, node } = params

    return isLoadingMoreRow<TData>({ data, node }) ? (
      <LoadingCellRenderer {...params} />
    ) : (
      <>{CellRenderer ? <CellRenderer {...params} /> : valueFormatted || value}</>
    )
  }
}
