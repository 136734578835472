import clsx from 'clsx'
import { ReactNode } from 'react'
import { useCss } from 'react-use'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/playground/playgroundDashboard/components/general/button/Button.module.scss'

interface Props {
  text: string
  iconLeft?: ReactNode
  iconRight?: ReactNode
  variant?: 'primary' | 'secondary' | 'tertiary' | 'black' | 'tertiaryBig' | 'transparent'
  small?: boolean
  minWidth?: number
  onClick?: () => void
  disabled?: boolean
}
export const Button = ({
  text,
  iconLeft,
  iconRight,
  variant = 'primary',
  small = false,
  minWidth = 0,
  onClick,
  disabled = false,
}: Props) => {
  return (
    <Flex
      className={clsx(
        styles.flexBaseWidth,
        styles[variant],
        small && styles.small,
        useCss({ width: minWidth !== 0 ? `${minWidth}px` : 'fit-content', display: 'flex', justifyContent: 'center' }),
        disabled && styles.disabled,
      )}
      inline
      align="center"
      gap={8}
      onClick={() => {
        onClick && !disabled ? onClick() : {}
      }}
    >
      {iconLeft}
      <div>{text}</div>
      {iconRight}
    </Flex>
  )
}
