import { facadeApi } from 'api'

interface Params {
  rejectReason: string
  requestId: string
  tenantId: string
}

export const rejectHubAccessRequestApi = ({ rejectReason, requestId, tenantId }: Params) =>
  facadeApi.patch(
    '/hub-access-requests/reject/bulk',
    { ids: [requestId], rejectReason },
    { headers: { 'X-Tenant-Id': tenantId } },
  )
