import { createUseQuery } from 'api/common/createUseQuery'
import { fetchWorkfrontProjectSummary } from 'api/workfront/fetchers/fetchWorkfrontProjectSummary'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { ProjectSummaryDto } from 'pages/playground/playgroundDashboard/components/sections/sectionProjectSummary/sectionProjectSummaryMockData'

export const useWorkfrontSummaryApi = createUseQuery({
  queryKey: ApiQueryKeys.WORKFRONT_PROJECT_SUMMARY,
  fetcher: fetchWorkfrontProjectSummary,
  selector: res => res?.data?.data || ({} as ProjectSummaryDto),
})
