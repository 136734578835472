import gregory from 'pages/playground/playgroundDashboard/assets/images/gregory.png'

export interface ProjectHealth {
  projectName: string
  tasksComplete: number
  tasksOnTrack: number
  tasksDelayed: number
  risks: Risk[]
}

export interface Risk {
  title: string
  caption: string
  raisedByData: {
    img: string
    name: string
  }
}

export interface ProjectDto {
  ID: string
  description: string
  name: string
  plannedCompletionDate: string
  plannedStartDate: string
  priority: number
  program: null | any
  projectHealth: {
    completed: number
    onTrack: number
    tasksDelayed: number
    riskIdentified: number
    percentComplete: number
  }
  projectedCompletionDate: string
  status: string
  tasks: [] | any[]
}

export interface ProjectSummaryTask {
  ID: string
  name: string
  status: string
  plannedStartDate: string
  actualCompletionDate: string
  actualStartDate: string
  taskNumber: number
  canStart: boolean
  estCompletionDate: string
  assignedTo: {
    ID: string
    name: string
  }
}

export interface ProjectSummaryRisk {
  ID: string
  name: string
  status: string
  description: string
  riskCategory: string
  riskRag: string
  plannedMitigation: string | null
  riskImpact: string
  riskScore: string
  assignedTo: {
    ID: string
    name: string
  }
}

export enum ProjectSummaryCondition {
  ON_TARGET = 'ON',
  AT_RISK = 'AR',
  IN_TROUBLE = 'IT',
}

export interface ProjectSummaryDto {
  ID: string
  name: string
  status: string
  progressStatus: string
  plannedStartDate: string
  plannedCompletionDate: string
  actualStartDate: string
  priority: number
  condition: ProjectSummaryCondition
  program: {
    ID: string
    name: string
  }
  phase: {
    numberOfPhases: number
    phaseName: string
    phaseNumber: number
  }
  numberOfPhases: number
  lateTasks: ProjectSummaryTask[]
  risks: ProjectSummaryRisk[]
  projectHealth: {
    completed: number
    onTrack: number
    delayed: number
    riskIdentified: number
    percentComplete: number
  }
}

export const ProjectData: ProjectDto[] = [
  {
    ID: '65f9970617ef046e56ef2786936acce8',
    description: 'Test description',
    name: 'Test project 1',
    plannedCompletionDate: '2024-03-19T08:00:00:000-0400',
    plannedStartDate: '2024-03-19T08:00:00:000-0400',
    priority: 2,
    program: null,
    projectHealth: { completed: 0, percentComplete: 0, onTrack: 0, tasksDelayed: 0, riskIdentified: 0 },
    projectedCompletionDate: '2024-03-19T09:45:00:000-0400',
    status: 'PLN',
    tasks: [],
  },
  {
    ID: '65f9970617ef046e56ef2786936acce8',
    description: 'Test description',
    name: 'Test project 2',
    plannedCompletionDate: '2024-03-19T08:00:00:000-0400',
    plannedStartDate: '2024-03-19T08:00:00:000-0400',
    priority: 2,
    program: null,
    projectHealth: { completed: 0, onTrack: 0, percentComplete: 0, tasksDelayed: 0, riskIdentified: 0 },
    projectedCompletionDate: '2024-03-19T09:45:00:000-0400',
    status: 'PLN',
    tasks: [],
  },
]

export const ProjectHealthData: ProjectHealth[] = [
  {
    projectName: 'Sparkling Tea TikTok',
    tasksComplete: 46,
    tasksOnTrack: 17,
    tasksDelayed: 3,
    risks: [],
  },
  {
    projectName: 'Lemon ARTD',
    tasksComplete: 99,
    tasksOnTrack: 37,
    tasksDelayed: 15,
    risks: [
      {
        title: 'Paid Media Campaign Targeting\nWrong Audience',
        caption: 'Sparkling Tea TikTok Project',
        raisedByData: {
          img: gregory,
          name: 'Gregory Wilson',
        },
      },
    ],
  },
]
