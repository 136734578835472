import {
  WppIconInfo,
  WppIconTaskList,
  WppIconTaskListSquare,
  WppIconSuccess,
  WppIconClose,
  WppIconArrow,
  WppSpinner,
  WppTooltip,
} from '@platform-ui-kit/components-library-react'
import { useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import { useUpdateTaskApproval } from 'api/workfront/mutations/useUpdateTaskApproval'
import { Flex } from 'components/common/flex/Flex'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { environment } from 'environment'
import { ReactComponent as IconCardBorder } from 'pages/playground/playgroundDashboard/assets/icons/card-border.svg'
import { ReactComponent as IconCalendar } from 'pages/playground/playgroundDashboard/assets/icons/project/calendar.svg'
import { Button } from 'pages/playground/playgroundDashboard/components/general/button/Button'
import styles from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/approvalCard/ApprovalCard.module.scss'
import { specialFormatDate } from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/assignmentCard/AssignmentCard'
import {
  ProjectApiDto,
  TaskApprovalsDto,
} from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/sectionAssignmentsMockData'
import stylesMain from 'pages/playground/playgroundDashboard/PlaygroundDashboard.module.scss'

interface Props {
  assignment: TaskApprovalsDto
  onPhaseClick?: (phase: Pick<ProjectApiDto['phase'], 'phaseNumber' | 'phaseName'>) => void
}

export const ApprovalCard = ({ assignment, onPhaseClick }: Props) => {
  const queryClient = useQueryClient()
  const { mutateAsync: updateApproval, isLoading: loadingUpdate } = useUpdateTaskApproval()

  const [approvalStatus, setApprovalStatus] = useState(false)

  const formatDate = (dateString: string) => {
    // Hack for invalid date from workfront
    const dateStringTmp = dateString?.substring(0, dateString.lastIndexOf(':'))
    const date = new Date(dateStringTmp)
    const monthName = date.toLocaleString('default', { month: 'long' })
    const day = date.getDate()
    return `${monthName.toLocaleUpperCase()} ${day}`
  }

  const handleUpdateApproval = async (approve: boolean) => {
    if (loadingUpdate) return
    setApprovalStatus(approve)
    try {
      await updateApproval({ taskId: assignment.ID, approvalStepID: assignment.approvalStepID, approve })
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.WORKFRONT_APPROVALS] })
    } catch (e) {
      console.error(e)
    }
  }

  const handlePhaseClick = (phase: Pick<ProjectApiDto['phase'], 'phaseNumber' | 'phaseName'>) => {
    if (!phase) return
    if (onPhaseClick) onPhaseClick(phase)
  }

  return (
    <div className={clsx(styles.container, stylesMain.mt40)}>
      <Flex gap={0} justify="between">
        <Flex justify="between" className={clsx(styles.containerLeft, styles[assignment.taskStatus])}>
          <div className={styles.containerLeftInner}>
            {assignment.taskStatus !== 'REQUEST' && (
              <Flex gap={16}>
                <div className={stylesMain.black2xsStrong}>{assignment?.project?.name}</div>
                <div>
                  <WppTooltip
                    text={`Project In-Market Date: ${assignment?.project?.inMarketDate ? specialFormatDate(assignment?.project?.inMarketDate) : 'No data'}`}
                    config={{ interactive: true, placement: 'top' }}
                  >
                    <WppIconInfo color="black" width={20} />
                  </WppTooltip>
                </div>
              </Flex>
            )}

            <Flex justify="between">
              <Link
                to={
                  assignment.taskStatus === 'RISK' || assignment.taskStatus === 'REQUEST'
                    ? `${environment.WORKFRONT_URL}?location=/issue/${assignment.ID}/overview`
                    : `${environment.WORKFRONT_URL}?location=/task/${assignment.ID}/overview`
                }
                className={clsx(stylesMain.blackUnderline, stylesMain.mt10, styles.assignmentName)}
              >
                <div>{assignment.name}</div>
              </Link>
            </Flex>

            <Flex align="center" gap={16} className={clsx(stylesMain.mt20, styles.assignmentSections)}>
              <Flex align="center" gap={8}>
                {assignment.taskStatus === 'REQUEST' ? (
                  <WppIconTaskListSquare color="black" />
                ) : (
                  <WppIconTaskList color="black" />
                )}
                <div>{assignment.taskStatus}</div>
              </Flex>
              <div className={stylesMain.blackSMidiUnityText}>
                {'In phase: '}
                <u onClick={() => handlePhaseClick(assignment.phase)} style={{ cursor: 'pointer' }}>
                  {assignment?.phase ? assignment?.phase.phaseName : 'No data'}
                </u>
              </div>
              <Flex align="center">
                <WppTooltip
                  text={`Phase Due Date: ${assignment?.phase?.phaseDueDate ? specialFormatDate(assignment?.phase?.phaseDueDate) : 'No data'}`}
                  config={{ interactive: true, placement: 'bottom' }}
                >
                  <WppIconInfo color="black" width={20} />
                </WppTooltip>
              </Flex>
              <Flex align="center" gap={8}>
                <IconCalendar color="black" />
                {assignment.taskStatus === 'LATE' ? (
                  <div className={styles.assignmentSectionsLate}>{`ASSIGNMENT PAST DUE: ${formatDate(
                    assignment.dueDate,
                  )}`}</div>
                ) : (
                  <div>{`APPROVAL DUE: ${formatDate(assignment.dueDate)}`}</div>
                )}
              </Flex>
            </Flex>
          </div>

          <div>
            <IconCardBorder className={styles.separator} />
          </div>
        </Flex>

        {assignment.taskStatus !== 'REQUEST' ? (
          <Flex className={styles.containerRight} direction="column" justify="center" gap={16}>
            <div className={stylesMain['text-center']}>
              <Button
                text="Approve"
                variant="black"
                iconRight={
                  approvalStatus && loadingUpdate ? <WppSpinner color="#3AAD2C" /> : <WppIconSuccess color="#3AAD2C" />
                }
                disabled={!assignment.canApprove}
                minWidth={150}
                onClick={() => handleUpdateApproval(true)}
              />
            </div>
            <div className={stylesMain['text-center']}>
              <Button
                text="Reject"
                variant="tertiary"
                iconRight={
                  !approvalStatus && loadingUpdate ? <WppSpinner color="#FC4D0F" /> : <WppIconClose color="#FC4D0F" />
                }
                disabled={!assignment.canApprove}
                minWidth={150}
                onClick={() => handleUpdateApproval(false)}
              />
            </div>
            {/* <div className={stylesMain['text-center']}>
            <Link to={`${environment.WORKFRONT_URL}?location=/task/${assignment.ID}`}>
              <Button text="Open Task" variant="tertiary" />
            </Link>
          </div> */}
          </Flex>
        ) : (
          <Flex className={styles.containerRight} direction="column" justify="center" gap={24}>
            <Link to={`${environment.WORKFRONT_URL}?location=/issue/${assignment.ID}/overview`}>
              <Button text="View Request" variant="tertiary" iconRight={<WppIconArrow color="black" />} />
            </Link>
          </Flex>
        )}
      </Flex>
    </div>
  )
}
