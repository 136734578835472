import { WppButton, WppPill, WppPillGroup, WppTypography } from '@platform-ui-kit/components-library-react'
import { addMinutes, endOfToday } from 'date-fns'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAsyncFn } from 'react-use'

import { Flex } from 'components/common/flex/Flex'
import { Modal } from 'components/surface/modal/Modal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { queryClient } from 'providers/osQueryClient/utils'
import { useToast } from 'providers/toast/ToastProvider'
import { NiceModalWrappedProps, createNiceModal } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  updateFeedsActivities: Function
}

const SNOOZE_VALUES = {
  END_OF_DAY: 0,
  MINUTES_30: 30,
  HOUR_1: 60,
  HOURS_2: 120,
}

const PauseNotificationModal = ({ id, isOpen, updateFeedsActivities, onClose, onCloseComplete }: Props) => {
  const [snoozeValue, setSnoozeValue] = useState(SNOOZE_VALUES.MINUTES_30)

  const { t } = useTranslation()
  const { enqueueToast } = useToast()

  const [{ loading }, handleUpdateFeedsActivities] = useAsyncFn(async () => {
    try {
      await updateFeedsActivities(snoozeValue ? addMinutes(Date.now(), snoozeValue) : endOfToday())
      await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.FEEDS_STATUSES] })

      enqueueToast({
        message: t('os.notification.toast.paused_success'),
        type: 'success',
      })
    } catch {
      enqueueToast({
        message: t('os.common.errors.general'),
        type: 'error',
      })
    } finally {
      onClose()
    }
  }, [t, enqueueToast, updateFeedsActivities, onClose, snoozeValue])

  return (
    <Modal
      data-testid={id}
      open={isOpen}
      onWppModalClose={onClose}
      onWppModalCloseComplete={onCloseComplete}
      disableOutsideClick={loading}
    >
      <WppTypography slot="header" type="xl-heading">
        <Flex gap={8} align="center">
          {t('os.notification.actions.pause_notifications')}
        </Flex>
      </WppTypography>

      <Flex slot="body" direction="column" gap={20}>
        <WppTypography type="s-body" data-testid="remove-text">
          {t('os.notification.actions.pause_modal.body_text')}
        </WppTypography>

        <WppPillGroup
          type="single"
          value={snoozeValue}
          onWppChange={({ detail }) => setSnoozeValue(Number(detail.value!))}
        >
          <WppPill label={t('os.notification.actions.pause_modal.30_min')} value={SNOOZE_VALUES.MINUTES_30} />
          <WppPill label={t('os.notification.actions.pause_modal.1_hour')} value={SNOOZE_VALUES.HOUR_1} />
          <WppPill label={t('os.notification.actions.pause_modal.2_hours')} value={SNOOZE_VALUES.HOURS_2} />
          <WppPill label={t('os.notification.actions.pause_modal.until_tomorrow')} value={SNOOZE_VALUES.END_OF_DAY} />
        </WppPillGroup>
      </Flex>

      <Flex slot="actions" gap={12} justify="end">
        <WppButton onClick={onClose} variant="secondary" size="s" data-testid="cancel">
          {t('os.common.cancel')}
        </WppButton>
        <WppButton onClick={handleUpdateFeedsActivities} size="s" variant="primary" data-testid="apply">
          {t('os.common.pause')}
        </WppButton>
      </Flex>
    </Modal>
  )
}

export const { showModal: showPauseNotificationModal } = createNiceModal(PauseNotificationModal, 'pause-notif-modal')
