import { AttachmentMetadataResponse } from './attachments'
import { MayBeNull } from './common'

export interface TenantAzMeta {
  applicationsId: string
  organizationsId: string
  groups: {
    adminUsersId: string
    primaryUsersId: string
    defaultUsersId: string
  }
}
export interface TenantFlags {
  accessRequestable: boolean
  onlyAdminsAccessible: boolean
  isPrimary: boolean
  isFirstLevelHidden: boolean
}

export enum TenantConfigNavigationType {
  Hierarchy = 'HIERARCHY',
  Projects = 'PROJECTS',
  Custom = 'CUSTOM',
}

export interface TenantConfigNavigationFlags {
  expanded: boolean
  enabled: boolean
}

export interface TenantConfigNavigation {
  type: TenantConfigNavigationType
  flags: TenantConfigNavigationFlags
}

export interface TenantConfig {
  navigation: TenantConfigNavigation[]
}

export enum SupportService {
  Zendesk = 'zendesk',
  Intercom = 'intercom',
}

export interface Tenant {
  id: string
  name: string
  title: MayBeNull<string>
  description: MayBeNull<string>
  azId: string
  azMeta: TenantAzMeta
  subdomain: string
  homeUrl: string
  favicon: MayBeNull<AttachmentMetadataResponse>
  flags: TenantFlags
  logoThumbnail: MayBeNull<AttachmentMetadataResponse>
  logoOriginal: MayBeNull<AttachmentMetadataResponse>
  loginBackground: MayBeNull<AttachmentMetadataResponse>
  hierarchyLevels: HierarchyLevel[]
  createdAt: string
  createdBy: string
  updatedAt: MayBeNull<string>
  updatedBy: MayBeNull<string>
  config: TenantConfig
  supportService: SupportService
}

export interface HierarchyLevel<T extends HierarchyLevelType = HierarchyLevelType> {
  type: T
  flags: {
    apps: HierarchyLevelFlags
    level: HierarchyLevelFlags
  }
  /**
   * Indicates whether level type is one of the defaults or a custom one.
   */
  custom: boolean
}

export interface HierarchyLevelFlags {
  visible: boolean
  publicAccess: boolean
}

export type HierarchyLevelType = DefaultHierarchyLevelType | CustomHierarchyLevelType

export enum DefaultHierarchyLevelType {
  Tenant = 'TENANT',
  Client = 'CLIENT',
  Market = 'MARKET',
  Brand = 'BRAND',
  Region = 'REGION',
  Industry = 'INDUSTRY',
}

export type CustomHierarchyLevelType = string

export type RootHierarchyLevelType = DefaultHierarchyLevelType.Tenant
export type DefaultChildHierarchyLevelType = Exclude<DefaultHierarchyLevelType, RootHierarchyLevelType>
export type ChildHierarchyLevelType = Exclude<HierarchyLevelType, RootHierarchyLevelType>
