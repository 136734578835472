import { Permission } from 'constants/permission'
import { hasAccessToCaas, hasAccessToMaximize, NavigationConfigFunctions } from 'legacy/navigation/common'
import { navigation as defaultNavigation } from 'legacy/navigation/default'
import { LegacyPermission } from 'legacy/navigation/permission'
import { hasPermission } from 'legacy/navigation/utils'
import { AccountPermissions, NavigationItem } from 'legacy/types/osWebRootApi'

interface INavigationItem extends NavigationItem {
  fullPath?: boolean
  nav?: INavigationItem[]
}

type INavigationSection = INavigationItem[]

export const navigation: NavigationConfigFunctions = {
  ...defaultNavigation,
  dashboard: (permissions: AccountPermissions[]): INavigationSection => [
    {
      title: 'OS_HEADER.HEADER.YOU',
      path: '/you',
    },
    {
      title: 'OS_HEADER.HEADER.NETWORK',
      path: '/network/contacts',
      disabled: !hasPermission(permissions, Permission.NETWORK_APP_ACCESS),
    },
    {
      title: 'OS_HEADER.HEADER.APPS',
      path: '/apps/list',
      disabled: !hasPermission(permissions, Permission.APPS_APP_ACCESS),
    },
  ],
  main: (permissions: AccountPermissions[], countryCode?: string): INavigationSection => [
    {
      title: 'OS_HEADER.NAVIGATION_MAIN.APPS',
      nav: [
        {
          title: 'OS_HEADER.NAVIGATION_MAIN.AUDIENCE_EXPLORER',
          path: '/audience-explorer',
          hidden:
            !hasAccessToCaas(countryCode) || !hasPermission(permissions, LegacyPermission.AudiencesExplorerAccess),
        },
        {
          title: 'OS_HEADER.NAVIGATION_MAIN.OPTIMISE',
          nav: [
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.AUDIENCES',
              path: '/audiences/map',
              disabled:
                !hasAccessToMaximize(countryCode) ||
                !hasPermission(permissions, LegacyPermission.MxmzAudiencesAppAccess),
            },
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.TOUCHPOINTS',
              path: '/touchpoints/touchpoints',
              disabled:
                !hasAccessToMaximize(countryCode) ||
                !hasPermission(permissions, LegacyPermission.MxmzTouchpointsAppAccess),
            },
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.ARCHITECT_JUNIOR',
              path: '/junior',
              disabled:
                !hasAccessToMaximize(countryCode) || !hasPermission(permissions, LegacyPermission.MxmzJuniorAppAccess),
              hidden:
                !hasAccessToMaximize(countryCode) || !hasPermission(permissions, LegacyPermission.MxmzJuniorAppAccess),
            },
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.CAMPAIGNS_PARENT',
              disabled:
                !hasAccessToMaximize(countryCode) ||
                (!hasPermission(permissions, LegacyPermission.MxmzCampaignsAppAccess) &&
                  !hasPermission(permissions, LegacyPermission.MxmzMediaPlansComparisonAppAccess)),
              nav: [
                {
                  title: 'OS_HEADER.NAVIGATION_MAIN.CAMPAIGNS',
                  path: '/briefs/list',
                  disabled: !hasPermission(permissions, LegacyPermission.MxmzCampaignsAppAccess),
                },
                {
                  title: 'OS_HEADER.NAVIGATION_MAIN.MP_UPLOADER',
                  path: '/custom-campaigns/list',
                  hidden: !hasPermission(permissions, LegacyPermission.MxmzMediaPlansUploaderAppAccess),
                },
                {
                  title: 'OS_HEADER.NAVIGATION_MAIN.MP_COMPARISON',
                  path: '/mp-comparison/list',
                  disabled: !hasPermission(permissions, LegacyPermission.MxmzMediaPlansComparisonAppAccess),
                },
              ],
            },
          ],
        },
        {
          title: 'Audience Translator',
          hidden: !hasAccessToCaas(countryCode),
          path: '/xlator',
        },
        {
          title: 'Audience Finder',
          hidden: !hasAccessToCaas(countryCode),
          path: '/audlib',
        },
      ],
    },
  ],
}
