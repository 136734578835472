import { MayBeNull } from '@wpp-open/core'
import fastDeepEqual from 'fast-deep-equal/es6'
import { filesize } from 'filesize'
import { useEffect, useMemo } from 'react'

const isEmptyObject = (object: object) => fastDeepEqual(object, {})
export const bytes = (n: number, unit: 'kb' | 'mb' | 'gb'): number => {
  const binaryBase = 1024

  if (unit === 'kb') {
    return n * binaryBase
  }

  if (unit === 'mb') {
    return bytes(n * binaryBase, 'kb')
  }

  return bytes(n * binaryBase, 'mb')
}

export const formatBytes = (bytes: number) => filesize(bytes, { base: 2, standard: 'jedec' }) as string

export const isFile = (value: unknown): value is File => value instanceof File

export const downloadJson = (jsonData: Record<string, any>, fileName: string) => {
  // Convert the JSON object to a string with proper formatting (indented with 2 spaces)
  const dataStr: string = JSON.stringify(jsonData, null, 2)

  // Create a new Blob object containing the JSON string, with the 'application/json' MIME type
  const blob: Blob = new Blob([dataStr], { type: 'application/json' })

  // Create a URL for the Blob object to be used as a download link
  const url: string = URL.createObjectURL(blob)

  // Create an invisible anchor element to trigger the download
  const link: HTMLAnchorElement = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)

  // Trigger the download by simulating a click on the anchor element
  link.click()

  // Revoke the URL to release the memory that the browser allocated for it
  URL.revokeObjectURL(url)
}

export const readJsonFile = <T = any>(file: File): Promise<T> =>
  new Promise<T>((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = e => {
      try {
        resolve(JSON.parse(e.target!.result as string))
      } catch (error) {
        reject(error)
      }
    }

    reader.readAsText(file)
  })

export const useInMemoryFileUrl = (file: MayBeNull<File | Blob>) => {
  const fileUrl = useMemo(() => {
    if (file && !isEmptyObject(file)) {
      return URL.createObjectURL(file)
    }
  }, [file])

  useEffect(
    () => () => {
      if (fileUrl) {
        URL.revokeObjectURL(fileUrl)
      }
    },
    [fileUrl],
  )

  return fileUrl
}

const popularSignatures = {
  JVBERi0: 'application/pdf',
  R0lGODdh: 'image/gif',
  R0lGODlh: 'image/gif',
  iVBORw0KGgo: 'image/png',
  '/9j/': 'image/jpg',
  Qk02U: 'image/bmp',
}

function detectMimeType(base64 = '') {
  let mimeType

  Object.entries(popularSignatures).some(([signature, type]) => {
    const signatureWasFound = base64.startsWith(signature)
    if (signatureWasFound) mimeType = type
    return signatureWasFound
  })

  return mimeType
}

export const readImage = (file: File, callback: (data: string) => void) => {
  const reader = new FileReader()
  reader.onload = () => {
    const index = reader.result!.toString().indexOf(',')
    const base64 = reader.result!.slice(index + 1)
    callback(`data:${file.type};base64, ${base64}`)
  }
  reader.readAsDataURL(file)
}

export const base64ToFile = (base64String: string, fileName: string): File => {
  // Decode the base64 string
  const byteString = atob(base64String)

  // Create an array buffer to hold the binary data
  const arrayBuffer = new ArrayBuffer(byteString.length)
  const uintArray = new Uint8Array(arrayBuffer)

  // Write the decoded bytes to the array buffer
  for (let i = 0; i < byteString.length; i++) {
    uintArray[i] = byteString.charCodeAt(i)
  }

  // Create a Blob object from the array buffer
  const blob = new Blob([uintArray], { type: detectMimeType(base64String) })

  // Create a File object from the Blob object
  const file = new File([blob], fileName, { type: detectMimeType(base64String) })

  return file
}
