import { MayBeNull } from '@wpp-open/core'
import { useMemo } from 'react'
import { matchPath } from 'react-router-dom'

import { LOCAL_APP_BASE_URL, LOCAL_APP_CODE, LOCAL_APP_NAME } from 'constants/apps'
import { LocalMicroAppLibraryType } from 'constants/routes'
import { LocalContext } from 'providers/osState/utils/appDataFromUrl/useLocalContextFromUrl'
import { LocalMicroAppConfig, MicroAppLibraryType, MicroAppType } from 'types/apps/microApps'
import { AppDataFromUrlType, LocalAppDataFromUrl } from 'types/osState/appDataFromUrl'

export const useLocalAppDataFromUrl = (localContext: MayBeNull<LocalContext>): MayBeNull<LocalAppDataFromUrl> => {
  const localAppParts = useMemo(() => getLocalAppParts(localContext), [localContext])

  const { osRoute, libraryType, bundleUrl, windowLibraryName } = localAppParts || {}
  const { workspaceAzId } = localContext || {}

  const app = useMemo((): MayBeNull<LocalMicroAppConfig> => {
    // Make the app dependent only on the data parts to return a stable config
    if (!osRoute || !libraryType || !bundleUrl || windowLibraryName === undefined) {
      return null
    }

    return {
      type: MicroAppType.Local,
      stableId: LOCAL_APP_CODE,
      name: LOCAL_APP_NAME,
      permission: null,
      requiredHierarchy: [],
      osRoute,
      libraryType,
      windowLibraryName,
      bundleUrl,
    }
  }, [bundleUrl, libraryType, osRoute, windowLibraryName])

  return useMemo(() => {
    if (!app) {
      return null
    }

    return {
      type: AppDataFromUrlType.LocalApp,
      app,
      workspaceAzId,
    }
  }, [app, workspaceAzId])
}

interface LocalAppParts {
  osRoute: string
  libraryType: MicroAppLibraryType
  bundleUrl: string
  windowLibraryName: MayBeNull<string>
}

const getLocalAppParts = (localContext: MayBeNull<LocalContext>): MayBeNull<LocalAppParts> => {
  if (!localContext) {
    return null
  }

  const { appRoute } = localContext
  const localAppMatch = matchPath(`/${LOCAL_APP_BASE_URL}/:port/:type/:name/*`, `/${appRoute}`)

  if (!localAppMatch) {
    return null
  }

  const { port, type, name = '' } = localAppMatch.params

  const resolvedLibraryType = Object.values(LocalMicroAppLibraryType).includes(type as LocalMicroAppLibraryType)
    ? (type as LocalMicroAppLibraryType)
    : LocalMicroAppLibraryType.SystemJS

  const libraryType = mapLocalAppLibraryTypeToConfig(resolvedLibraryType)
  const osRoute = `${LOCAL_APP_BASE_URL}/${port}/${type}/${name}`
  const bundleUrl = `http://localhost:${port}/${name}.js`
  const windowLibraryName = libraryType === MicroAppLibraryType.Window ? name : null

  return {
    osRoute,
    libraryType,
    bundleUrl,
    windowLibraryName,
  }
}

const mapLocalAppLibraryTypeToConfig = (type: LocalMicroAppLibraryType): MicroAppLibraryType => {
  switch (type) {
    case LocalMicroAppLibraryType.Window:
      return MicroAppLibraryType.Window
    case LocalMicroAppLibraryType.SystemJS:
      return MicroAppLibraryType.SystemJS
    case LocalMicroAppLibraryType.ESM:
      return MicroAppLibraryType.ESM

    default:
      throw new Error('Unsupported LocalAppLibraryType')
  }
}
