import qs from 'qs'

import { facadeApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'
import { Market } from 'types/masterData/markets'

type Params = PaginationParams<{
  search?: string
}>

export type MarketsListResponse = PaginatedResponse<Market>

export const fetchMarketsList = ({ search, page, itemsPerPage }: Params) =>
  facadeApi.get<MarketsListResponse>('/markets', {
    params: {
      page,
      itemsPerPage,
      filter: {
        ...(!!search && { search }),
      },
    },
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
  })
