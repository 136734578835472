const isError = (test: (status: number) => boolean) => (error: unknown) => {
  const status = (error as any)?.response?.status
  return !!status && test(status)
}

export const is400Error = isError(status => status === 400)
export const is401Error = isError(status => status === 401)
export const is403Error = isError(status => status === 403)
export const is404Error = isError(status => status === 404)
export const is422Error = isError(status => status === 422)
export const isConflictError = isError(status => status === 409)
export const is5xxError = isError(status => status >= 500 && status < 600)
