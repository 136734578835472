import { countries } from 'legacy/navigation/countries'
import { AccountPermissions, NavigationSection } from 'legacy/types/osWebRootApi'

export const hasAccessToMaximize = (countryCode?: string): boolean =>
  !countryCode || !countries[countryCode].isWithoutLivePanel

export const hasAccessToCaas = (countryCode?: string): boolean =>
  !!countryCode && !!countries[countryCode]?.isCaasAvailabe

export enum UserSectionNavigationName {
  community = 'community',
  communityOsLeaderboard = 'community-os-leaderboard',
  communityProductPortal = 'community-product-portal',
  help = 'help',
  helpTrainingPortal = 'help-training-portal',
  helpProductTour = 'help-product-tour',
}

export interface NavigationConfigFunctions {
  dashboard: (permissions: AccountPermissions[]) => NavigationSection
  main: (permissions: AccountPermissions[], countryCode?: string) => NavigationSection
  avatarNavigation: (permissions: AccountPermissions[]) => NavigationSection
  userSection: (permissions: AccountPermissions[]) => NavigationSection
  userSettings: (permissions: AccountPermissions[]) => NavigationSection
  networkSection: (permissions: AccountPermissions[]) => NavigationSection
}
