import { WppCard, WppTooltip } from '@platform-ui-kit/components-library-react'

import { Avatar } from 'components/common/avatar/Avatar'
import styles from 'components/notificationsSideModal/tenantIconWithNotification/TenantIconWithNotification.module.scss'
import { useOsRoot } from 'providers/osRoot/OsRootContext'
import { TenantShort } from 'types/tenants/tenant'

interface Props {
  tenant: TenantShort
  hasNewNotification?: boolean
  onClick: () => void
}

export const TenantIconWithNotification = ({ tenant, hasNewNotification, onClick }: Props) => {
  const { defaultTenant } = useOsRoot()

  return (
    <WppTooltip config={{ placement: 'left' }} text={tenant.name}>
      <div className={styles.cardWrapper}>
        {hasNewNotification && <div className={styles.cardNotification} data-testid="tenant-red-icon" />}

        <WppCard className={styles.card} value={tenant.name} onClick={onClick}>
          <Avatar name={tenant.name} src={tenant.logoThumbnail?.url || defaultTenant.logoThumbnail?.url || undefined} />
        </WppCard>
      </div>
    </WppTooltip>
  )
}
