import { choreographTheme } from 'legacy/styles/osTheming/themes/choreograph'
import { essenceTheme } from 'legacy/styles/osTheming/themes/essence'
import { greenhouseTheme } from 'legacy/styles/osTheming/themes/greenhouse'
import { groupmTheme } from 'legacy/styles/osTheming/themes/groupm'
import { mediacomTheme } from 'legacy/styles/osTheming/themes/mediacom'
import { mindshareTheme } from 'legacy/styles/osTheming/themes/mindshare'
import { msixTheme } from 'legacy/styles/osTheming/themes/msix'
import { wavemakerTheme } from 'legacy/styles/osTheming/themes/wavemaker'
import { wmsTheme } from 'legacy/styles/osTheming/themes/wms'
import { wppOpenTheme } from 'legacy/styles/osTheming/themes/wpp-open'

export const legacyThemes = {
  choreographTheme,
  essenceTheme,
  greenhouseTheme,
  groupmTheme,
  mediacomTheme,
  mindshareTheme,
  msixTheme,
  wavemakerTheme,
  wmsTheme,
  wppOpenTheme,
}

export const legacyThemesList = Object.values(legacyThemes)
