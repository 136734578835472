import { Permission } from 'constants/permission'
import { NavigationConfigFunctions } from 'legacy/navigation/common'
import { navigation as defaultNavigation } from 'legacy/navigation/default'
import { hasPermission } from 'legacy/navigation/utils'
import { AccountPermissions, NavigationSection } from 'legacy/types/osWebRootApi'

export const navigation: NavigationConfigFunctions = {
  ...defaultNavigation,
  dashboard: (permissions: AccountPermissions[]): NavigationSection => [
    {
      title: 'OS_HEADER.HEADER.YOU',
      path: '/you',
    },
    {
      title: 'OS_HEADER.HEADER.NETWORK',
      path: '/network/contacts',
      disabled: !hasPermission(permissions, Permission.NETWORK_APP_ACCESS),
    },
    {
      title: 'OS_HEADER.HEADER.APPS',
      path: '/apps/list',
      disabled: !hasPermission(permissions, Permission.APPS_APP_ACCESS),
    },
    {
      title: 'OS_HEADER.HEADER.MARKETPLACE',
      path: '/marketplace',
      hidden: !hasPermission(permissions, Permission.WPP_OPEN_MARKETPLACE_APP_ACCESS),
    },
  ],
}
