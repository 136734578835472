import {
  AppContextUnsubscribeFn,
  CompactAppContextListener,
  CompactAppCustomProps,
  FullscreenAppContextListener,
  FullscreenAppCustomProps,
  OpenCompactAppHandler,
  OsApiContext,
} from '@wpp-open/core'
import { useCallback, useMemo, useRef } from 'react'

import { MICRO_APP_DOM_ELEMENT_ID } from 'constants/apps'
import { AppsContextValue } from 'providers/apps/AppsContext'
import { useOpenApp, useTriggerError } from 'providers/apps/utils/apiHandlers'
import { useAuth } from 'providers/auth/AuthContext'
import { useOsState } from 'providers/osState/OsStateProvider'
import { BaseAppConfig } from 'types/apps/apps'
import { trackAnalytics } from 'utils/analytics'

export const useGetAppCustomProps = ({
  handleSubscribeToFullscreenAppContext,
  handleSubscribeToCompactAppContext,
  openCompactApp,
}: {
  handleSubscribeToFullscreenAppContext: (
    appId: string,
    listener: FullscreenAppContextListener,
  ) => AppContextUnsubscribeFn
  handleSubscribeToCompactAppContext: (appId: string, listener: CompactAppContextListener) => AppContextUnsubscribeFn
  openCompactApp: OpenCompactAppHandler
}): Pick<AppsContextValue, 'getFullscreenAppCustomProps' | 'getCompactAppCustomProps'> => {
  const { jwt } = useAuth()
  const { openNavigation } = useOsState()
  const openApp = useOpenApp()
  const triggerError = useTriggerError()

  const tokenRef = useRef(jwt)
  tokenRef.current = jwt

  const getAccessToken = useCallback(() => tokenRef.current, [])

  const osApi: OsApiContext = useMemo(
    () => ({
      getAccessToken,
      analytics: {
        track: trackAnalytics,
      },
      navigation: {
        openMenu: openNavigation,
        openApp,
        openCompactApp,
        triggerError,
      },
    }),
    [getAccessToken, openApp, openCompactApp, openNavigation, triggerError],
  )

  const getFullscreenAppCustomProps = useCallback(
    ({ stableId }: Pick<BaseAppConfig, 'stableId'>): FullscreenAppCustomProps => ({
      domElementGetter: () => document.getElementById(MICRO_APP_DOM_ELEMENT_ID)!,
      osApi,
      osContext: {
        subscribe: listener => handleSubscribeToFullscreenAppContext(stableId, listener),
      },
    }),
    [handleSubscribeToFullscreenAppContext, osApi],
  )

  const getCompactAppCustomProps = useCallback(
    ({ stableId }: Pick<BaseAppConfig, 'stableId'>): CompactAppCustomProps => ({
      osApi,
      osContext: {
        subscribe: listener => handleSubscribeToCompactAppContext(stableId, listener),
      },
    }),
    [handleSubscribeToCompactAppContext, osApi],
  )

  return {
    getFullscreenAppCustomProps,
    getCompactAppCustomProps,
  }
}
