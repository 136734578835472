import { LegacyTheme } from 'legacy/styles/osTheming/models'
import { LegacyTenantCode } from 'legacy/types/osWebRootApi'

export const wavemakerTheme: LegacyTheme = {
  name: 'Wavemaker',
  code: LegacyTenantCode.WAVEMAKER,

  colors: {
    brand: {
      primary: {
        background: '#FFF7F4',
        lighter: '#FFE5DA',
        light: '#F48654',
        normal: '#ED6B31',
        dark: '#D45922',
        darker: '#B84816',
      },
      secondary: {
        background: '#F8F4F6',
        lighter: '#9B5C80',
        light: '#93627E',
        normal: '#672A4E',
        dark: '#682A4E',
        darker: '#36122E',
      },
    },
    system: {
      information: {
        background: '#F3F6FB',
        lighter: '#7993CD',
        light: '#5C7BC0',
        normal: '#4064B2',
        dark: '#244CA4',
        darker: '#133786',
      },
      error: {
        background: '#FFF5F6',
        lighter: '#FBE4E5',
        light: '#EF6872',
        normal: '#E74854',
        dark: '#CE3742',
        darker: '#B22833',
      },
      warning: {
        background: '#FFFBF3',
        lighter: '#F4DEB9',
        light: '#FFC358',
        normal: '#F8AF2A',
        dark: '#E19D1E',
        darker: '#C48A13',
      },
      success: {
        background: '#F5FBFA',
        lighter: '#EAF7F1',
        light: '#47A68C',
        normal: '#47A68C',
        dark: '#30856E',
        darker: '#26745F',
      },
    },
    grey: {
      white: '#FFFFFF',
      50: '#F3F4F7',
      100: '#E7E7EC',
      200: '#D3D3DC',
      300: '#BFBFCC',
      400: '#ACACBB',
      500: '#ACACBB',
      600: '#838390',
      700: '#6F6F7B',
      800: '#757585',
      900: '#46464F',
      1000: '#1C1B21',
      black: '#000000',
      'white-rgb': '255, 255, 255',
      'black-rgb': '0, 0, 0',
    },
    special: {
      background: '#F3F4F7',
      overlay: '#ACACBB',
      contrastBackground: '#1C1B21',
      contrastPrimaryLight: '#FFE5DA',
      contrastPrimary: '#ED6B31',
      contrastPrimaryDark: '#D45922',
    },
    text: {
      text: '#46464F',
      textMuted: '#6F6F7B',
      textLink: '#B84816',
      contrastText: '#FFFFFFE5',
      contrastTextMuted: '#FFFFFFBF',
      contrastTextLink: '#ED6B31',
      textInfo: '#4064B2',
      textError: '#CE3742',
      textWarning: '#886304',
      textSuccess: '#26745F',
    },
    dataVisualisation: {
      sequential: {
        S0: {
          100: '#E7E7EC',
          200: '#D3D3DC',
          300: '#BFBFCC',
          400: '#ACACBB',
          500: '#9797A6',
          600: '#838390',
        },
        S1: {
          100: '#D4DDF1',
          200: '#B5C4E5',
          300: '#97ACDA',
          400: '#7993CD',
          500: '#5C7BC0',
          600: '#4064B2',
        },
        S2: {
          100: '#FDCFD3',
          200: '#FAABB1',
          300: '#F58991',
          400: '#EF6872',
          500: '#E74854',
          600: '#CE3742',
        },
        S3: {
          100: '#FFEABC',
          200: '#FFD788',
          300: '#FFC358',
          400: '#F8AF2A',
          500: '#E19D1E',
          600: '#C48A13',
        },
        S4: {
          100: '#C8E8DF',
          200: '#9BD3C4',
          300: '#70BDA8',
          400: '#47A68C',
          500: '#3B967D',
          600: '#30856E',
        },
        S5: {
          100: '#FFDAC9',
          200: '#FDBDA0',
          300: '#FAA17A',
          400: '#F48654',
          500: '#ED6B31',
          600: '#D45922',
        },
        S6: {
          100: '#E4D6DE',
          200: '#D0B9C6',
          300: '#BC9CAE',
          400: '#A77F96',
          500: '#93627E',
          600: '#7D4666',
        },
        S7: {
          100: '#E5DEF5',
          200: '#D1C7EC',
          300: '#BFB0E4',
          400: '#AC99DA',
          500: '#9984D1',
          600: '#876EC7',
        },
      },
      comparative: {
        C0: '#9797A6',
        C1: '#5C7BC0',
        C2: '#E74854',
        C3: '#E19D1E',
        C4: '#3B967D',
        C5: '#ED6B31',
        C6: '#93627E',
        C7: '#9984D1',
      },
      diverging: {
        D0: '#ED6B31',
        D1: '#7D4666',
      },
    },
  },

  typography: {
    font: 'Roboto',
    sizes: {
      '5xl': {
        size: '36px',
        height: '40px',
      },
      '4xl': {
        size: '30px',
        height: '36px',
      },
      '3xl': {
        size: '24px',
        height: '32px',
      },
      '2xl': {
        size: '20px',
        height: '28px',
      },
      xl: {
        size: '18px',
        height: '28px',
      },
      l: {
        size: '16px',
        height: '24px',
      },
      m: {
        size: '14px',
        height: '20px',
      },
      s: {
        size: '12px',
        height: '16px',
      },
      xs: {
        size: '10px',
        height: '16px',
      },
    },
    weights: {
      bold: 600,
      medium: 500,
      regular: 400,
    },
    boxShadows: {
      '3xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
      '2xl': '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
      xl: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      l: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
      m: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
      s: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
      inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
    },
    borderRadius: {
      card: '6px',
      dropdown: '6px',
      popup: '12px',
      button: '3px',
      input: '6px',
    },
  },
}
