import clsx from 'clsx'
import { useCss } from 'react-use'

import bgEffectImgSrc from 'assets/playground/bg-effect.png'
import bgImgSrc from 'assets/playground/bg-login.jpg'
import styles from 'layout/authLayout/authPlaygroundLayout/AuthPlaygroundLayout.module.scss'

export const AuthPlaygroundLayout = ({ className, children, ...rest }: JSX.IntrinsicElements['div']) => {
  return (
    <div {...rest} className={clsx(styles.container, className)}>
      <div
        className={clsx(
          styles.background,
          useCss({
            backgroundImage: `url(${bgImgSrc})`,
          }),
        )}
      />
      <div className={clsx(styles.backgroundEffect)}>
        <img src={bgEffectImgSrc} height="100%" alt="" />
      </div>
      {children}
    </div>
  )
}
