import { workfrontApi } from 'api'

export const updateTaskApproval = ({
  taskId,
  approvalStepID,
  approve,
}: {
  taskId: string
  approvalStepID: string
  approve: boolean
}) =>
  workfrontApi.put(`/pending-tasks-approvals/${taskId}/${approve ? 'approve' : 'reject'}`, {
    approvalStepID: approvalStepID,
  })
