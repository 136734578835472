// import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
// import { getHomeUrl } from 'utils/navigation'
import { routesManager } from 'utils/routesManager'

export const useHomeUrl = () => {
  // const { defaultHub } = useCurrentTenantData()

  // return getHomeUrl(defaultHub)
  return routesManager.home.root.routePath
}
