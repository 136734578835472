import { WppSkeleton, WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { LineConnection } from 'components/connection/lineConnection/LineConnection'
import { useProjectBreadcrumbsApps } from 'layout/header/breadcrumbs/project/appsProvider/ProjectBreadcrumbsAppsProvider'
import { ProjectBreadcrumbsMenuAppItem } from 'layout/header/breadcrumbs/project/menuAppItem/ProjectBreadcrumbsMenuAppItem'
import { ConnectionItemWrapper } from 'layout/header/breadcrumbs/project/projectFluid/connectionItemWrapper/ConnectionItemWrapper'
import styles from 'layout/header/breadcrumbs/project/projectFluid/menu/ProjectFluidBreadcrumbsMenu.module.scss'
import { FluidMenuItemTag, useFluidConnectionTracking } from 'layout/header/breadcrumbs/project/projectFluid/menu/utils'
import { FLUID_MENU_COLUMNS_GAP } from 'layout/header/breadcrumbs/project/projectFluid/ProjectFluidBreadcrumbs'
import { ProjectCanvasApplicationShort } from 'types/projects/projectNavigation'

interface MenuProps {
  previousApps: ProjectCanvasApplicationShort[]
  currentApp: ProjectCanvasApplicationShort
  nextApps: ProjectCanvasApplicationShort[]
}

const Skeleton = () => {
  return (
    <Flex direction="column" className={styles.column}>
      <WppSkeleton height={20} />
    </Flex>
  )
}

export const ProjectFluidBreadcrumbsMenu = ({ previousApps, currentApp, nextApps }: MenuProps) => {
  const { t } = useTranslation()
  const [rootRef, setRootRef] = useState<MayBeNull<HTMLDivElement>>(null)
  const { prevConnections, nextConnections, connect, disconnect } = useFluidConnectionTracking()

  const hasLeftColumn = previousApps.length > 0
  const hasRightColumn = nextApps.length > 0

  const { isLoading } = useProjectBreadcrumbsApps()

  useEffect(() => {
    if (rootRef) {
      connect(rootRef)

      return () => disconnect(rootRef)
    }
  }, [connect, disconnect, rootRef])

  return (
    <Flex
      ref={node => setRootRef(node)}
      className={clsx(FluidMenuItemTag.Root, styles.root)}
      gap={8}
      direction="column"
    >
      <Flex className={styles.headers} gap={8} direction="column">
        <Flex gap={FLUID_MENU_COLUMNS_GAP}>
          {hasLeftColumn && (
            <Flex className={styles.column} align="center" justify="center">
              <WppTypography className={styles.columnTitle} type="2xs-strong">
                {t('os.header.breadcrumbs.project_columns.previous')}
              </WppTypography>
            </Flex>
          )}

          <Flex className={styles.column} align="center" justify="center">
            <WppTypography className={styles.columnTitle} type="2xs-strong">
              {t('os.header.breadcrumbs.project_columns.current')}
            </WppTypography>
          </Flex>

          {hasRightColumn && (
            <Flex className={styles.column} align="center" justify="center">
              <WppTypography className={styles.columnTitle} type="2xs-strong">
                {t('os.header.breadcrumbs.project_columns.next')}
              </WppTypography>
            </Flex>
          )}
        </Flex>

        <div className={styles.headerSeparator} />
      </Flex>

      <Flex gap={FLUID_MENU_COLUMNS_GAP}>
        {hasLeftColumn &&
          (isLoading ? (
            <ConnectionItemWrapper tag={FluidMenuItemTag.Prev} connect={connect} disconnect={disconnect}>
              <Skeleton />
            </ConnectionItemWrapper>
          ) : (
            <Flex
              className={styles.column}
              direction="column"
              gap={4}
              data-testid="breadcrumbs-menu-project-fluid-prev-column"
            >
              {previousApps.map(app => (
                <ConnectionItemWrapper
                  key={app.id}
                  tag={FluidMenuItemTag.Prev}
                  connect={connect}
                  disconnect={disconnect}
                >
                  <ProjectBreadcrumbsMenuAppItem key={app.id} canvasApp={app} />
                </ConnectionItemWrapper>
              ))}
            </Flex>
          ))}
        {isLoading ? (
          <ConnectionItemWrapper tag={FluidMenuItemTag.Current} connect={connect} disconnect={disconnect}>
            <Skeleton />
          </ConnectionItemWrapper>
        ) : (
          <Flex
            className={styles.column}
            direction="column"
            gap={4}
            data-testid="breadcrumbs-menu-project-fluid-current-column"
          >
            <ConnectionItemWrapper tag={FluidMenuItemTag.Current} connect={connect} disconnect={disconnect}>
              <ProjectBreadcrumbsMenuAppItem canvasApp={currentApp} />
            </ConnectionItemWrapper>
          </Flex>
        )}

        {hasRightColumn &&
          (isLoading ? (
            <ConnectionItemWrapper tag={FluidMenuItemTag.Next} connect={connect} disconnect={disconnect}>
              <Skeleton />
            </ConnectionItemWrapper>
          ) : (
            <Flex
              className={styles.column}
              direction="column"
              gap={4}
              data-testid="breadcrumbs-menu-project-fluid-next-column"
            >
              {nextApps.map(app => (
                <ConnectionItemWrapper
                  key={app.id}
                  tag={FluidMenuItemTag.Next}
                  connect={connect}
                  disconnect={disconnect}
                >
                  <ProjectBreadcrumbsMenuAppItem canvasApp={app} />
                </ConnectionItemWrapper>
              ))}
            </Flex>
          ))}
      </Flex>

      <div className={styles.connectionsContainer}>
        {prevConnections.map((connection, index) => (
          <LineConnection key={index} withArrow={index === 0} coordinates={connection} />
        ))}
        {nextConnections.map((connection, index) => (
          <LineConnection key={index} withArrow coordinates={connection} />
        ))}
      </div>
    </Flex>
  )
}
