import { MayBeNull } from '@wpp-open/core'
import { useMemo } from 'react'

import { useAppInstanceApi } from 'api/apps/queries/useAppInstanceApi'
import { is403Error, is404Error } from 'api/utils'
import { AppDisabledError } from 'components/renderError'
import { OsStateError } from 'providers/osState/sideEffects/OsStateError'
import { isValueSet, UnsetValue } from 'providers/osState/utils/conditionalData'
import { useAppInstanceLoaded } from 'providers/osState/utils/resolvers/appInstance/useAppInstanceLoaded'
import { getInvalidResolvedAppData, getLoadingResolvedAppData } from 'providers/osState/utils/resolvers/common'
import { ResolvedAppData } from 'providers/osState/utils/useResolveAppData'
import { AppInstanceDataFromUrl } from 'types/osState/appDataFromUrl'
import { isAppInstanceDeleted, isAppInstanceInactive, mapAppInstanceToApp } from 'utils/appInstances'

interface Params {
  appDataShort: AppInstanceDataFromUrl
}

export const useAppInstanceAppDataResolver = (params: MayBeNull<Params>): MayBeNull<ResolvedAppData> => {
  const isEnabled = !!params
  const appInstanceId = isEnabled ? params.appDataShort.appInstanceId : UnsetValue

  // Load App Instance if enabled
  const {
    isLoading: isAppInstanceLoading,
    data: appInstance,
    error: appInstanceError,
  } = useAppInstanceApi({
    params: {
      id: isValueSet(appInstanceId) ? appInstanceId : '',
    },
    enabled: isEnabled,
  })

  let result: MayBeNull<ResolvedAppData> = null

  // Process loading state and errors
  if (isEnabled) {
    if (isAppInstanceLoading) {
      result = getLoadingResolvedAppData()
    } else if (appInstanceError) {
      if (is404Error(appInstanceError)) {
        result = getInvalidResolvedAppData()
      } else {
        result = getLoadingResolvedAppData({
          sideEffectNode: <OsStateError isForbidden={is403Error(appInstanceError)} />,
        })
      }
    } else if (appInstance) {
      if (isAppInstanceDeleted(appInstance)) {
        // Deleted instances are ignored and considered the same as 404 response
        result = getInvalidResolvedAppData()
      } else if (isAppInstanceInactive(appInstance)) {
        // A separate error is shown for other inactive app statuses
        result = getLoadingResolvedAppData({
          sideEffectNode: <AppDisabledError />,
        })
      }
    }
  }

  // App Instance is loaded and ready to be processed
  const isAppInstanceLoaded = isEnabled && !result && appInstance
  const app = useMemo(
    () => (isAppInstanceLoaded ? mapAppInstanceToApp(appInstance) : UnsetValue),
    [appInstance, isAppInstanceLoaded],
  )

  // Processing part is split in a separate hook to simplify the conditional logic
  const appInstanceLoadedResult = useAppInstanceLoaded(
    isAppInstanceLoaded && isValueSet(app)
      ? {
          appInstance,
          app,
          appDataShort: params.appDataShort,
        }
      : null,
  )

  if (appInstanceLoadedResult) {
    result = appInstanceLoadedResult
  }

  return isEnabled ? result || getInvalidResolvedAppData() : null
}
