import { WppSpinner, WppTypography } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import styles from 'providers/otherTenantsAndUserData/userSetupLoadingPage/UserSetupLoadingPage.module.scss'

export const UserSetupLoadingPage = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.container}>
      <Flex direction="column" gap={16} align="center" justify="center">
        <WppSpinner size="l" />

        <Flex direction="column" gap={8} align="center" justify="center">
          <WppTypography type="m-strong">{t('os.user_setup.title')}</WppTypography>
          <WppTypography type="m-body">{t('os.user_setup.body')}</WppTypography>
        </Flex>
      </Flex>
    </div>
  )
}
