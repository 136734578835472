import {
  HierarchyContainerNodeId,
  NavigationTree,
  Tenant,
  TenantConfigNavigationType,
  ProjectsContainerNodeId,
  ProjectNodeType,
  HierarchyLevel,
  ChildHierarchyLevelType,
  getNavigationNodeType,
} from '@wpp-open/core'

import { Tree, PointerNode, NodesMapping } from 'utils/mapping/common'
import { isNavigationTypeEnabled } from 'utils/navigation'

export const getIsHierarchyColumnVisible = <T extends Tree>({
  pointerNode,
  navigationHierarchy,
}: {
  navigationHierarchy: HierarchyLevel<ChildHierarchyLevelType>[]
  pointerNode: PointerNode<T>
}) =>
  pointerNode.nodeId === HierarchyContainerNodeId ||
  navigationHierarchy.some(({ type }) => type === getNavigationNodeType(pointerNode.node))

export const getIsProjectsColumnVisible = <T extends Tree>({ nodeId, node }: PointerNode<T>) =>
  nodeId === ProjectsContainerNodeId || node.type === ProjectNodeType

export const getIsComposedColumnVisible = (currentTenant: Tenant) => {
  const isHierarchyNavigationEnabled = isNavigationTypeEnabled({
    type: TenantConfigNavigationType.Hierarchy,
    currentTenant,
  })

  const isProjectsNavigationEnabled = isNavigationTypeEnabled({
    type: TenantConfigNavigationType.Projects,
    currentTenant,
  })

  const isCustomNavigationEnabled = isNavigationTypeEnabled({
    type: TenantConfigNavigationType.Custom,
    currentTenant,
  })

  return isCustomNavigationEnabled || (isHierarchyNavigationEnabled && isProjectsNavigationEnabled)
}

export const getProjectsPointerNodes = (nodesMapping: NodesMapping<NavigationTree>) =>
  nodesMapping[ProjectsContainerNodeId].children
