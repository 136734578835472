import { Permission } from 'constants/permission'
import { hasAccessToMaximize, NavigationConfigFunctions } from 'legacy/navigation/common'
import { navigation as defaultNavigation } from 'legacy/navigation/default'
import { LegacyPermission } from 'legacy/navigation/permission'
import { hasPermission } from 'legacy/navigation/utils'
import { AccountPermissions, NavigationSection } from 'legacy/types/osWebRootApi'

export const navigation: NavigationConfigFunctions = {
  ...defaultNavigation,
  avatarNavigation: (permissions: AccountPermissions[]): NavigationSection => [
    {
      title: 'OS_HEADER.HEADER.ADMINISTRATION',
      nav: [
        {
          title: 'OS_HEADER.HEADER.APP_SETTING',
          hidden: !hasPermission(permissions, Permission.APPS_MARKET_MANAGE),
          path: '/apps/admin-panel',
        },
      ],
    },
    {
      title: 'OS_HEADER.HEADER.PERMISSIONS',
      nav: [
        {
          title: 'OS_HEADER.HEADER.ROLES',
          hidden: !hasPermission(permissions, LegacyPermission.AdminAppAccess),
          path: '/os-admin/panel/roles',
        },
        {
          title: 'OS_HEADER.HEADER.USERS',
          hidden: !hasPermission(permissions, LegacyPermission.AdminAppAccess),
          path: '/os-admin/panel/users',
        },
        {
          title: 'OS_HEADER.HEADER.GROUPS',
          hidden: !hasPermission(permissions, LegacyPermission.AdminAppAccess),
          path: '/os-admin/panel/groups',
        },
      ],
    },
    {
      title: 'OS_HEADER.HEADER.USER',
      nav: [
        {
          title: 'OS_HEADER.HEADER.PROFILE',
          disabled: !hasPermission(permissions, Permission.OS_USERDETAILS_APP_ACCESS),
          path: '/user/profile',
        },
        {
          title: 'OS_HEADER.HEADER.ACCOUNT_SETTINGS',
          path: '/user/settings',
        },
        {
          title: 'OS_HEADER.HEADER.FEEDBACK',
          disabled: true,
          path: 'userFeedback',
        },
        {
          title: 'OS_HEADER.HEADER.LOGOUT',
          path: 'logout',
        },
      ],
    },
  ],
  main: (permissions: AccountPermissions[], countryCode?: string): NavigationSection => [
    {
      title: 'Know Me',
      nav: [
        { title: 'Change Audit', disabled: true },
        { title: 'KPI Setter', disabled: true },
        { title: 'Human ID', disabled: true },
      ],
    },
    {
      title: 'Anticipate Me',
      breadcrumbTitle: 'OS_HEADER.NAVIGATION_MAIN.OPTIMISE',
      nav: [
        {
          title: 'Architect',
          nav: [
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.AUDIENCES',
              path: '/audiences/map',
              disabled:
                !hasAccessToMaximize(countryCode) ||
                !hasPermission(permissions, LegacyPermission.MxmzAudiencesAppAccess),
            },
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.TOUCHPOINTS',
              path: '/touchpoints/touchpoints',
              disabled:
                !hasAccessToMaximize(countryCode) ||
                !hasPermission(permissions, LegacyPermission.MxmzTouchpointsAppAccess),
            },
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.ARCHITECT_JUNIOR',
              path: '/junior',
              disabled:
                !hasAccessToMaximize(countryCode) || !hasPermission(permissions, LegacyPermission.MxmzJuniorAppAccess),
              hidden:
                !hasAccessToMaximize(countryCode) || !hasPermission(permissions, LegacyPermission.MxmzJuniorAppAccess),
            },
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.CAMPAIGNS_PARENT',
              disabled:
                !hasAccessToMaximize(countryCode) ||
                (!hasPermission(permissions, LegacyPermission.MxmzCampaignsAppAccess) &&
                  !hasPermission(permissions, LegacyPermission.MxmzMediaPlansComparisonAppAccess)),
              nav: [
                {
                  title: 'OS_HEADER.NAVIGATION_MAIN.CAMPAIGNS',
                  path: '/briefs/list',
                  disabled: !hasPermission(permissions, LegacyPermission.MxmzCampaignsAppAccess),
                },
                {
                  title: 'OS_HEADER.NAVIGATION_MAIN.MP_UPLOADER',
                  path: '/custom-campaigns/list',
                  hidden: !hasPermission(permissions, LegacyPermission.MxmzMediaPlansUploaderAppAccess),
                },
                {
                  title: 'OS_HEADER.NAVIGATION_MAIN.MP_COMPARISON',
                  path: '/mp-comparison/list',
                  disabled: !hasPermission(permissions, LegacyPermission.MxmzMediaPlansComparisonAppAccess),
                },
              ],
            },
          ],
        },
        { title: 'Media for Growth', disabled: true },
        { title: 'Planning For Intention', disabled: true },
      ],
    },
    {
      title: 'Listen to Me',
      nav: [
        { title: 'Outcomes Design', disabled: true },
        { title: 'MAP', disabled: true },
        { title: 'DPR', disabled: true },
        { title: 'Mexcellence', disabled: true },
        { title: 'Accelerate', disabled: true },
      ],
    },
  ],
}
