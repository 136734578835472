import { Theme, ThemeContent, createTheme } from '@platform-ui-kit/components-library'
import { useMemo } from 'react'

import { useDomElementCss } from 'hooks/useDomElementCss'

// Check for usages when adding support of other theme variants
export const SUPPORTED_THEME: keyof ThemeContent = 'light'

export const useApplyTheme = ({ theme, mode }: { theme?: Theme; mode: keyof ThemeContent }) => {
  const themeCssVariablesMap = useMemo(() => (theme ? createTheme(theme, mode) : {}), [theme, mode])

  useDomElementCss(themeCssVariablesMap)
}
