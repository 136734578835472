import { setDefaultOptions, format } from 'date-fns'
import enGB from 'date-fns/locale/en-GB'
import { zonedTimeToUtc } from 'date-fns-tz'

// Make it dynamic after locale change functionality enabled in profile
export const dateLocale = enGB

export const setDateLocale = () => {
  setDefaultOptions({ locale: enGB })
}

export const getCurrentTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone

/**
 * cons tz = 'Europe/Bucharest'
 * formatTimeZoneToLabel(tz) // 'Bucharest, Europe'
 */
export const formatTimeZoneToLabel = (timeZone: string) => timeZone.replace(/(\w+)\/(\w+)/, '$2, $1')

export const getCurrentTimeZoneLabel = () => formatTimeZoneToLabel(getCurrentTimeZone())

export const isDate = (value: unknown): value is Date => value instanceof Date

export const isValidDate = (value: Date) => !isNaN(value.getTime())

export const convertToUTC = (date: string | Date, timezone: string): string => {
  const dateInUTC = zonedTimeToUtc(new Date(date), timezone)

  return dateInUTC.toISOString()
}

export const convertToUTCFromUserTimezone = (date: string | Date) => convertToUTC(date, getCurrentTimeZone())

export const getTime = (date = '') => format(new Date(date), 'HH:mm')
