import { FullscreenApp } from 'components/apps/fullscreen/FullscreenApp'
import { CriticalError, NotFoundError } from 'components/renderError'
import { LoadingPage } from 'layout/loadingPage/LoadingPage'
import { useMicroAppErrorHandler } from 'pages/fullscreenAppsNavigation/utils'
import { useOsState } from 'providers/osState/OsStateProvider'
import { AppDataType } from 'types/osState/appDataResolved'

export const FullscreenAppsNavigation = () => {
  const { appDataDebug, appData } = useOsState()
  const { isMicroAppError } = useMicroAppErrorHandler()

  if (appData.type === AppDataType.Loading) {
    return <LoadingPage />
  }

  if (appData.type === AppDataType.Invalid) {
    console.warn('Invalid navigation url or app data', location, appDataDebug)

    return <NotFoundError />
  }

  return (
    <>
      {isMicroAppError && <CriticalError />}

      <FullscreenApp appData={appData} />
    </>
  )
}
