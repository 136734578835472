import { useEffect, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import { Outlet } from 'react-router-dom'

import { LoadingPage } from 'layout/loadingPage/LoadingPage'

export const SilentLogin = () => {
  const { isAuthenticated, isLoading, signinSilent, removeUser } = useAuth()
  const [didTrySilentSignIn, setDidTrySilentSignIn] = useState(false)

  // Try silent sign in on page load if we don't have user data yet
  useEffect(() => {
    if (!didTrySilentSignIn && !isLoading) {
      if (isAuthenticated) {
        setDidTrySilentSignIn(true)
      } else {
        // The library may use an expired refresh token to sign in.
        // So if the user is not authenticated by this point,
        // just remove user info before trying silent sign in.
        // See: https://github.com/authts/oidc-client-ts/issues/644
        removeUser().finally(() => {
          signinSilent()
            .catch(error => {
              // Ignore the error. User is not signed in, we'll just redirect them to the login page.
              console.error('Silent sign in error', error)
            })
            .finally(() => {
              setDidTrySilentSignIn(true)
            })
        })
      }
    }
  }, [isAuthenticated, isLoading, didTrySilentSignIn, signinSilent, removeUser])

  if (!didTrySilentSignIn || isLoading) {
    return <LoadingPage />
  }

  return <Outlet />
}
