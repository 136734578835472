import { LegacyTheme } from 'legacy/styles/osTheming/models'
import { LegacyTenantCode } from 'legacy/types/osWebRootApi'

export const mindshareTheme: LegacyTheme = {
  name: 'MindShare',
  code: LegacyTenantCode.MINDSHARE,

  colors: {
    brand: {
      primary: {
        background: '#F4FBFF',
        lighter: '#FFC8FD',
        light: '#55BDFF',
        normal: '#9A3EFF',
        dark: '#2297DB',
        darker: '#1580B8',
      },
      secondary: {
        background: '#F9F3FB',
        lighter: '#99C2FF',
        light: '#A560BD',
        normal: '#1E78FF',
        dark: '#0A54C2',
        darker: '#2D0C4B',
      },
    },
    system: {
      information: {
        background: '#F4FBFF',
        lighter: '#7ACDFF',
        light: '#55BDFF',
        normal: '#32ADFF',
        dark: '#2297DB',
        darker: '#1580B8',
      },
      error: {
        background: '#FFDED8',
        lighter: '#FA90A3',
        light: '#FF5A3C',
        normal: '#F0516D',
        dark: '#D53F59',
        darker: '#B82F47',
      },
      warning: {
        background: '#FFF2DA',
        lighter: '#FFCF77',
        light: '#FFD282',
        normal: '#F8AF2A',
        dark: '#DB991B',
        darker: '#B8820F',
      },
      success: {
        background: '#CCF1E6',
        lighter: '#7DD0AA',
        light: '#00B982',
        normal: '#31AE75',
        dark: '#259B65',
        darker: '#1A8655',
      },
    },
    grey: {
      white: '#FFFFFF',
      50: '#F2F0F0',
      100: '#EBEBEB',
      200: '#D7D7D7',
      300: '#CAC5C4',
      400: '#B4B4B4',
      500: '#969292',
      600: '#919191',
      700: '#808080',
      800: '#565151',
      900: '#444444',
      1000: '#171414',
      black: '#000000',
      'white-rgb': '255, 255, 255',
      'black-rgb': '0, 0, 0',
    },
    special: {
      background: '#F2F0F0',
      overlay: '#0000004C',
      contrastBackground: '#000000',
      contrastPrimaryLight: '#E6F0FF',
      contrastPrimary: '#99C2FF',
      contrastPrimaryDark: '#66A3FF',
    },
    text: {
      text: '#333339',
      textMuted: '#63636E',
      textLink: '#791C99',
      contrastText: '#FFFFFFE6',
      contrastTextMuted: '#FFFFFFB3',
      contrastTextLink: '#BB84CE',
      textInfo: '#4568B4',
      textError: '#D53F59',
      textWarning: '#946B06',
      textSuccess: '#1A8655',
    },
    dataVisualisation: {
      sequential: {
        S0: {
          100: '#EBEBEB',
          200: '#D7D7D7',
          300: '#C6C6C6',
          400: '#B4B4B4',
          500: '#A3A3A3',
          600: '#919191',
        },
        S1: {
          100: '#C9ECFF',
          200: '#A0DDFF',
          300: '#7ACDFF',
          400: '#55BDFF',
          500: '#32ADFF',
          600: '#2297DB',
        },
        S2: {
          100: '#FBC7D5',
          200: '#F69DB4',
          300: '#F07495',
          400: '#E74D76',
          500: '#DD2858',
          600: '#C61B48',
        },
        S3: {
          100: '#FFF7C2',
          200: '#FEEE91',
          300: '#FBE461',
          400: '#F4D831',
          500: '#ECCB00',
          600: '#D5B600',
        },
        S4: {
          100: '#E2EBC3',
          200: '#CBD995',
          300: '#B2C668',
          400: '#99B23C',
          500: '#7F9C12',
          600: '#718C0A',
        },
        S5: {
          100: '#C9ECFF',
          200: '#A0DDFF',
          300: '#7ACDFF',
          400: '#55BDFF',
          500: '#32ADFF',
          600: '#2297DB',
        },
        S6: {
          100: '#E4CDED',
          200: '#D0A8DD',
          300: '#BB84CE',
          400: '#A560BD',
          500: '#8F3EAB',
          600: '#791C99',
        },
      },
      comparative: {
        C0: '#A3A3A3',
        C1: '#55BDFF',
        C2: '#DD2858',
        C3: '#ECCB00',
        C4: '#7F9C12',
        C5: '#32ADFF',
        C6: '#8F3EAB',
      },
      diverging: {
        D0: '#32ADFF',
        D1: '#8F3EAB',
      },
    },
  },

  typography: {
    font: 'Montserrat',
    sizes: {
      '5xl': {
        size: '36px',
        height: '40px',
      },
      '4xl': {
        size: '30px',
        height: '36px',
      },
      '3xl': {
        size: '24px',
        height: '32px',
      },
      '2xl': {
        size: '20px',
        height: '28px',
      },
      xl: {
        size: '18px',
        height: '28px',
      },
      l: {
        size: '16px',
        height: '24px',
      },
      m: {
        size: '14px',
        height: '20px',
      },
      s: {
        size: '12px',
        height: '16px',
      },
      xs: {
        size: '10px',
        height: '16px',
      },
    },
    weights: {
      bold: 600,
      medium: 500,
      regular: 400,
    },
    boxShadows: {
      '3xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
      '2xl': '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
      xl: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      l: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
      m: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
      s: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
      inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
    },
    borderRadius: {
      card: '0px',
      dropdown: '0px',
      popup: '0px',
      button: '3px',
      input: '3px',
    },
  },
}
