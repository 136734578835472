import { PropsWithChildren, useRef, useState } from 'react'

import { AppsContext } from 'providers/apps/AppsContext'
import { CompactAppOverlay } from 'providers/apps/compactAppOverlay/CompactAppOverlay'
import { useCompactAppContextValue, useFullscreenAppContextValue } from 'providers/apps/utils/contextValue'
import { useGetAppCustomProps } from 'providers/apps/utils/customProps'
import { useOverlay } from 'providers/apps/utils/overlays'
import {
  useHandleSubscribeToCompactAppContext,
  useHandleSubscribeToFullscreenAppContext,
  useSubscriptionEffects,
} from 'providers/apps/utils/subscription'

export const AppsProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isFullscreenMicroAppActive, setIsFullscreenMicroAppActive] = useState(false)
  const fullscreenSubscribersRef = useRef(new Set<string>())
  const compactSubscribersRef = useRef(new Set<string>())

  const { overlay, openCompactApp, closeCompactApp, apps } = useOverlay()

  const fullscreenAppContextValue = useFullscreenAppContextValue()
  const fullscreenAppContextValueRef = useRef(fullscreenAppContextValue)
  fullscreenAppContextValueRef.current = fullscreenAppContextValue

  const compactAppContextValue = useCompactAppContextValue()
  const compactAppContextValueRef = useRef(compactAppContextValue)
  compactAppContextValueRef.current = compactAppContextValue

  // Handle publish on context changes and unsubscription cleanup
  useSubscriptionEffects({
    fullscreenSubscribersRef,
    fullscreenAppContextValue,
    compactSubscribersRef,
    compactAppContextValue,
  })

  const handleSubscribeToFullscreenAppContext = useHandleSubscribeToFullscreenAppContext({
    fullscreenSubscribersRef,
    fullscreenAppContextValueRef,
  })

  const handleSubscribeToCompactAppContext = useHandleSubscribeToCompactAppContext({
    compactSubscribersRef,
    compactAppContextValueRef,
  })

  const { getFullscreenAppCustomProps, getCompactAppCustomProps } = useGetAppCustomProps({
    handleSubscribeToFullscreenAppContext,
    handleSubscribeToCompactAppContext,
    openCompactApp,
  })

  return (
    <AppsContext.Provider
      value={{
        isFullscreenMicroAppActive,
        setIsFullscreenMicroAppActive,
        getFullscreenAppCustomProps,
        getCompactAppCustomProps,
      }}
    >
      {overlay && (
        <CompactAppOverlay
          key={overlay.id}
          app={apps[overlay.appStableId]}
          overlayProps={overlay.overlayProps}
          close={() => closeCompactApp()}
        />
      )}

      {children}
    </AppsContext.Provider>
  )
}
