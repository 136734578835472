import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useRejectHubAccessRequestApi } from 'api/accessRequests/mutations/useRejectHubAccessRequestApi'
import { Flex } from 'components/common/flex/Flex'
import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import { Modal } from 'components/surface/modal/Modal'
import { useForm } from 'hooks/form/useForm'
import { useDateWithTimeFormat } from 'hooks/useDateWithTimeFormat'
import {
  CHARACTERS_LIMIT,
  useValidationSchema,
} from 'pages/admin/requests/hubAccessRequests/rejectHubAccessRequestModal/utils'
import { handleReloadHubAccessRequestsTables } from 'pages/admin/requests/hubAccessRequests/utils'
import styles from 'pages/admin/requests/requestDetailsSideModal/RequestDetailsSideModal.module.scss'
import { UsersInfoCard } from 'pages/admin/requests/usersInfoCard/UsersInfoCard'
import { useToast } from 'providers/toast/ToastProvider'
import { HubAccessRequest } from 'types/requests/requests'
import { capitalize } from 'utils/common'
import { NiceModalWrappedProps, createNiceModal } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  request: HubAccessRequest
  getAttachmentUrlByKey: (key?: string) => string
  onSubmitted?: () => void
}

const RejectHubAccessRequestModal = ({
  request,
  getAttachmentUrlByKey,
  isOpen,
  onSubmitted,
  onClose,
  onCloseComplete,
  id,
}: Props) => {
  const { enqueueToast } = useToast()
  const { t } = useTranslation()
  const { formatDate } = useDateWithTimeFormat()

  const form = useForm({
    validationSchema: useValidationSchema(),
    defaultValues: { rejectReason: '' },
  })

  const { mutateAsync: handleRejectHubAccessRequest } = useRejectHubAccessRequestApi()

  const { handleSubmit, formState } = form
  const { isValid, isSubmitting } = formState

  const onSubmit = handleSubmit(async ({ rejectReason }) => {
    try {
      await handleRejectHubAccessRequest({
        requestId: request.id,
        rejectReason,
        tenantId: request.tenantId,
      })

      handleReloadHubAccessRequestsTables()

      enqueueToast({
        type: 'success',
        message: capitalize(t('os.requests.actions_modal.toasts.reject_success')),
      })
    } catch (error) {
      enqueueToast({
        message: capitalize(t('os.common.errors.general')),
        type: 'error',
      })
    } finally {
      onSubmitted?.()
      onClose()
    }
  })

  return (
    <FormProvider {...form}>
      <Modal
        open={isOpen}
        onWppModalClose={onClose}
        onWppModalCloseComplete={onCloseComplete}
        size="m"
        data-testid={id}
        formConfig={{ onSubmit }}
      >
        <WppTypography type="xl-heading" slot="header">
          {t('os.requests.hub_access_requests.actions_modal.reject_hub_access.title')}
        </WppTypography>
        <Flex direction="column" slot="body" gap={16}>
          <Flex direction="column" gap={4}>
            <WppTypography type="s-strong" className={styles.label}>
              {t('os.requests.hub_access_requests.actions_modal.reject_hub_access.hub_name')}
            </WppTypography>
            <WppTypography type="s-body">{request.hubName || '-'}</WppTypography>
          </Flex>

          <UsersInfoCard
            request={request}
            getAttachmentUrlByKey={getAttachmentUrlByKey}
            date={formatDate(request.createdAt)}
          />

          <FormTextareaInput
            required
            labelConfig={{
              text: t('os.requests.hub_access_requests.actions_modal.reject_hub_access.reject_reason.label'),
            }}
            charactersLimit={CHARACTERS_LIMIT}
            warningThreshold={CHARACTERS_LIMIT}
            name="rejectReason"
            placeholder={t('os.requests.hub_access_requests.actions_modal.reject_hub_access.reject_reason.placeholder')}
          />
        </Flex>

        <Flex slot="actions" gap={12} justify="end">
          <WppButton variant="secondary" onClick={onClose} data-testid="cancel">
            {t('os.common.cancel')}
          </WppButton>
          <WppButton variant="primary" type="submit" loading={isSubmitting} disabled={!isValid} data-testid="apply">
            {capitalize(t('os.requests.actions.reject'))}
          </WppButton>
        </Flex>
      </Modal>
    </FormProvider>
  )
}

export const { showModal: showRejectHubAccessRequestModal } = createNiceModal(
  RejectHubAccessRequestModal,
  'reject-hub-access-request-modal',
)
