import { createContext, useContext } from 'react'

import { OidcUser } from 'types/auth/user'

export interface AuthContextValue {
  jwt: string
  user: OidcUser
  logout: () => void
}

export const AuthContext = createContext<AuthContextValue>(null!)

export const useAuth = () => useContext(AuthContext)
