interface Props {
  variant: 'line' | 'arrow-right'
  color: string
}

export const Timeline = ({ color, variant }: Props) => {
  if (variant === 'line') {
    return (
      <svg width="1050" height="67" viewBox="0 0 1050 33.5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 0H976.5C985.06 0 992 6.93959 992 15.5C992 24.0604 985.06 31 976.5 31H0V0Z" fill={color} />
      </svg>
    )
  }

  return (
    <svg width="100%" height="67" viewBox="0 0 1050 67" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M990.484 6.03385C990.484 1.41505 995.484 -1.4717 999.484 0.837702L1046.41 27.9298C1050.41 30.2392 1050.41 36.0127 1046.41 38.3221L999.484 65.4142C995.484 67.7236 990.484 64.8369 990.484 60.2181V47.9999H0V16.9999H990.484V6.03385Z"
        fill={color}
      />
    </svg>
  )
}

export default Timeline
