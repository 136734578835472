import { Activity } from 'single-spa'

import { APPLICATION_URL_PREFIX, WORKSPACE_URL_PREFIX } from 'constants/routes'
import { MicroAppConfig, MicroAppType } from 'types/apps/microApps'
import { excludeFalsy } from 'utils/common'

export const getActiveWhen = ({ stableId, type, osRoute }: MicroAppConfig): Activity => {
  const formattedOsRoute = osRoute.startsWith('/') ? osRoute.slice(1) : osRoute

  const isSystemMicroApp = type === MicroAppType.System
  const isLocalMicroApp = type === MicroAppType.Local || type === MicroAppType.LocalLegacy
  const isHardcodedLegacyUrl = type === MicroAppType.Legacy && formattedOsRoute.includes('/:')

  const hasAppInstanceUrl = !isLocalMicroApp && !isSystemMicroApp
  const hasWorkspaceUrl = isLocalMicroApp
  const hasRootUrl = isSystemMicroApp || isLocalMicroApp || isHardcodedLegacyUrl

  return [
    hasAppInstanceUrl && `/${APPLICATION_URL_PREFIX}/${stableId}/${formattedOsRoute}`,
    hasWorkspaceUrl && `/${WORKSPACE_URL_PREFIX}/:workspaceId:/${formattedOsRoute}`,
    hasRootUrl && `/${formattedOsRoute}`,
  ].filter(excludeFalsy)
}
