// import IconBook from 'pages/home/components/pitch/playgroundDashboard/assets/icons/project/book.svg'
// import IconChat from 'pages/home/components/pitch/playgroundDashboard/assets/icons/project/chat.svg'
// import IconList from 'pages/home/components/pitch/playgroundDashboard/assets/icons/project/list.svg'
// import IconOptions from 'pages/home/components/pitch/playgroundDashboard/assets/icons/project/options.svg'

export enum Categories {
  ALL = 0,
  TASKS = 1,
  APPROVALS = 2,
  REQUESTS = 3,
}

export enum ProjectProgress {
  ASSEMBLE = 1,
  EXPLORE = 2,
  CREATE = 3,
}

export enum AssignmentStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'INP',
  COMPLETED = 'CPL',
  DELAYED = 'DLY',
  APPROVAL = 'APPR',
  DONE_WITH_MY_PART = 'AD',
  NOT_DONE_WITH_MY_PART = 'NAD',
}

export enum RiskStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'INP',
  AWAITING_FEEDBACK = 'AWF',
  ON_HOLD = 'ONH',
  WONT_RESOLVE = 'WTR',
  CLOSED = 'CLS',
}

export enum AssignmentMyStatus {
  AWAITING = 'AA',
  WORKING = 'AD',
  DONE = 'DN',
}

export enum AssignmentObjCode {
  TASK = 'TASK',
  OPTASK = 'OPTASK',
}

export enum AssignmentTaskStatus {
  LATE = 'LATE',
  ON_TRACK = 'ONTRACK',
  DELAYED = 'DELAYED',
  RISK = 'RISK',
  REQUEST = 'REQUEST',
  APPROVAL = 'APPROVAL',
  MT_INTERNAL = 'MT_INTERNAL',
}

export enum AssignmentButtonAction {
  OPEN_NEW_TAB = 'open_new_tab',
}

export enum AssignmentButtonIcon {
  ARROW_RIGHT = 'arrow_right',
}

export const AssignmentStatusStrings = {
  [AssignmentStatus.NEW]: 'NEW',
  [AssignmentStatus.IN_PROGRESS]: 'IN PROGRESS',
  [AssignmentStatus.COMPLETED]: 'COMPLETED',
  [AssignmentStatus.DELAYED]: 'DELAYED',
  [AssignmentStatus.APPROVAL]: 'IN APPROVAL',
  [AssignmentStatus.DONE_WITH_MY_PART]: 'DONE WITH MY PART',
  [AssignmentStatus.NOT_DONE_WITH_MY_PART]: 'NOT DONE WITH MY PART',
}

export const RiskStatusStrings = {
  [RiskStatus.NEW]: 'NEW',
  [RiskStatus.IN_PROGRESS]: 'IN PROGRESS',
  [RiskStatus.AWAITING_FEEDBACK]: 'AWAITING FEEDBACK',
  [RiskStatus.ON_HOLD]: 'ON HOLD',
  [RiskStatus.WONT_RESOLVE]: 'WONT RESOLVE',
  [RiskStatus.CLOSED]: 'CLOSED',
}

export const AssignmentStatusStringsDropdown = ['NEW', 'INP', 'CPL', 'DLY']
export const AssignmentMyStatusStringsDropdown = ['NEW', 'INP', 'NAD', 'AD', 'CPL', 'DLY']

export const RiskStatusStringsDropdown = ['NEW', 'INP', 'AWF', 'ONH', 'WTR', 'CLS']

export interface ProjectApiDto {
  ID: string
  actualStartDate: string
  name: string
  milestone: ProjectProgress
  description: string
  canStart: boolean
  percentComplete: number
  dueDate: string
  plannedCompletionDate: string
  plannedStartDate: string
  priority: number
  project: ProjectDto
  status: AssignmentStatus
  objCode: AssignmentObjCode

  taskStatus: AssignmentTaskStatus

  myStatus: AssignmentMyStatus
  myAssignmentID: string
  phase: {
    numberOfPhases: number
    phaseName: string
    phaseNumber: number
    phaseDueDate: string
  }

  buttons: {
    action: AssignmentButtonAction
    icon: AssignmentButtonIcon
    label: string
    url: string
  }[]

  gateDocuments: {
    ID: string
    attachmentName: string
    description: string
    downloadUrl: string
    name: string
  }[]

  userDocuments: {
    ID: string
    attachmentName: string
    description: string
    downloadUrl: string
    name: string
  }[]

  otherAssignees: {
    ID: string
    name: string
    role: {
      ID: string
      name: string
    }
  }[]
}

export interface TaskApprovalsDto {
  ID: string
  approvalStepID: string
  name: string
  description: string
  canApprove: boolean
  dueDate: string
  plannedCompletionDate: string
  plannedStartDate: string
  project: ProjectDto
  status: AssignmentStatus
  objCode: AssignmentObjCode

  taskStatus: AssignmentTaskStatus
  phase: {
    numberOfPhases: number
    phaseName: string
    phaseNumber: number
    phaseDueDate: string
  }

  requestedBy: {
    ID: string
    name: string
    emailAddr: string
    country: string
  }

  buttons?: {
    action: AssignmentButtonAction
    icon: AssignmentButtonIcon
    label: string
    url: string
  }[]

  gateDocuments?: {
    ID: string
    attachmentName: string
    description: string
    downloadUrl: string
    name: string
  }[]

  userDocuments?: {
    ID: string
    attachmentName: string
    description: string
    downloadUrl: string
    name: string
  }[]

  otherAssignees?: {
    ID: string
    name: string
    role: {
      ID: string
      name: string
    }
  }[]
}

export interface ProjectDto {
  ID: string
  name: string
  objCode: string
  status: string
  inMarketDate: string
}

// export interface AssignmmentDto {
//   id: string
//   task: {
//     ID: string
//     name: string
//     milestone: ProjectProgress
//     description: string
//     canstart: boolean
//     status: AssignmentStatus
//   }
//   optask: null | any
// }

// interface Task {
//   id: string
//   name: string
//   milestone: ProjectProgress | null
//   tasknumber: number
//   status: AssignmentStatus
// }

export const assignmentsMock = [
  {
    ID: 'test',
    actualStartDate: 'test',
    name: 'Assignment name Assignment name Assignment nameAssignment name Assignment name Assignment name Assignment name Assignment name Assignment name Assignment name Assignment name Assignment name Assignment name v Assignment name Assignment name',
    milestone: ProjectProgress.ASSEMBLE,
    description: 'Assignment description',
    canStart: false,
    percentComplete: 2,
    plannedCompletionDate: 'test',
    plannedStartDate: '2024-06-12T02:00:00:000-0400',
    priority: 2,
    project: {
      ID: 'string',
      name: 'Project Name',
      objCode: 'string',
      status: 'string',
    },
    status: AssignmentStatus.NEW,
  },
  {
    ID: 'test',
    actualStartDate: 'test',
    name: 'test',
    milestone: ProjectProgress.ASSEMBLE,
    description: 'test',
    canStart: false,
    percentComplete: 2,
    plannedCompletionDate: 'test',
    plannedStartDate: '2024-06-12T02:00:00:000-0400',
    priority: 2,
    project: {
      ID: 'string',
      name: 'string',
      objCode: 'string',
      status: 'string',
    },
    status: AssignmentStatus.DELAYED,
  },
]

export const categoriesProjects: Record<Categories, ProjectApiDto[]> = {
  [Categories.ALL]: [],
  // Row Data
  [Categories.TASKS]: [],
  [Categories.APPROVALS]: [],

  [Categories.REQUESTS]: [],
}
