// import { WppIconChevron } from '@platform-ui-kit/components-library-react'
import { WppListItem, WppSelect } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
// import { useState } from 'react'

import { Flex } from 'components/common/flex/Flex'
// import { ProjectHealthCard } from 'pages/playground/playgroundDashboard/components/projectHealthCard/ProjectHealthCard'
import GifAssignmentUrl from 'pages/playground/playgroundDashboard/assets/icons/assignment.gif'
// import { RadioSelect } from 'pages/playground/playgroundDashboard/components/radioSelect/RadioSelect'
import styles from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/Header/Header.module.scss'
// import { ProjectDto } from 'pages/playground/playgroundDashboard/components/sections/sectionProjectSummary/sectionProjectSummaryMockData'
// import { SectionProjectSummaryGraph } from 'pages/playground/playgroundDashboard/components/sections/sectionProjectSummary/graph/Graph'
import stylesMain from 'pages/playground/playgroundDashboard/PlaygroundDashboard.module.scss'

interface Props {
  filterAssignments?: string
  setSelectedFilterAssignments: (id: string) => void
  workfrontError: boolean
  assignmentsLength: number
  sortAssingmentsBy?: string
  setSortAssingmentsBy: (id: string) => void
}

const filters = [
  {
    id: 'View All',
    name: 'View All',
  },
  {
    id: 'Tasks',
    name: 'Tasks',
  },
  {
    id: 'Approvals',
    name: 'Approvals',
  },
  {
    id: 'Requests',
    name: 'Requests',
  },
  {
    id: 'Risk Requests',
    name: 'Risk Requests',
  },
]

const sorting = [
  {
    id: 'Due Date',
    name: 'Due Date',
  },
  {
    id: 'Project name A-Z',
    name: 'Project name A-Z',
  },
  {
    id: 'Assignment type',
    name: 'Assignment type',
  },
]

export const SectionAssignmentsHeader = ({
  filterAssignments,
  setSelectedFilterAssignments,
  workfrontError,
  assignmentsLength,
  sortAssingmentsBy,
  setSortAssingmentsBy,
}: Props) => {
  const handleChangeFilter = (event: CustomEvent) => {
    setSelectedFilterAssignments(event.detail.value || '')
  }

  const handleSortAssignmentsBy = (event: CustomEvent) => {
    setSortAssingmentsBy(event.detail.value || '')
  }

  return (
    <div className={styles.container}>
      <Flex className={clsx(stylesMain.black5XL, styles.title)} justify="center" gap={16}>
        <Flex align="center">
          <img src={GifAssignmentUrl} alt="" width={48} />
        </Flex>
        Your Assignments
      </Flex>

      {!workfrontError && assignmentsLength !== 0 && (
        <Flex className={clsx(stylesMain.mt42, stylesMain.black2xsStrong)} justify="center" gap={30}>
          <Flex gap={16} align="center">
            <div>SHOW</div>
            <WppSelect onWppChange={handleChangeFilter} placeholder="Placeholder" value={filterAssignments}>
              {filters?.map((filter, idx) => (
                <WppListItem key={idx} value={filter.id}>
                  <p slot="label">{filter?.name}</p>
                </WppListItem>
              ))}
            </WppSelect>
          </Flex>
          <Flex gap={16} align="center">
            <div>SORT BY</div>
            <WppSelect onWppChange={handleSortAssignmentsBy} placeholder="Placeholder" value={sortAssingmentsBy}>
              {sorting?.map((sortBy, idx) => (
                <WppListItem key={idx} value={sortBy.id}>
                  <p slot="label">{sortBy?.name}</p>
                </WppListItem>
              ))}
            </WppSelect>
          </Flex>
        </Flex>
      )}
    </div>
  )
}
