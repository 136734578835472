import { WppCard, WppTypography } from '@platform-ui-kit/components-library-react'
import { Trans, useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import styles from 'components/notificationItemSideModal/NotificationItemSideModal.module.scss'
import { FeedContext } from 'types/feeds/feeds'

export const HierarchyApprovedSideModalContent = ({
  requestReason,
}: {
  requestReason: FeedContext['request_reason']
}) => {
  const { t } = useTranslation()

  return (
    <Flex direction="column" gap={16}>
      <WppTypography type="s-body" data-testid="notification-item-content-hierarchy-approved">
        <Trans
          i18nKey="os.notification.access.approved_access"
          values={{
            type: `<0>${t('os.notification.access.hierarchy.navigation')}</0>`,
          }}
          components={[
            <WppTypography key="0" type="s-strong">
              type
            </WppTypography>,
          ]}
        />
      </WppTypography>
      {requestReason && (
        <WppCard variant="secondary">
          <Flex direction="column" gap={4}>
            <WppTypography
              type="xs-strong"
              className={styles.greyTypography}
              data-testid="notification-item-request-reason-title"
            >
              {t('os.notification.access.your_request')}
            </WppTypography>
            <WppTypography
              type="xs-body"
              className={styles.greyTypography}
              data-testid="notification-item-request-reason"
            >
              {requestReason}
            </WppTypography>
          </Flex>
        </WppCard>
      )}
    </Flex>
  )
}
