import { WppSegmentedControl, WppSegmentedControlItem } from '@platform-ui-kit/components-library-react'
import { ComponentProps } from 'react'
import { useTranslation } from 'react-i18next'

import styles from 'components/notificationsSideModal/notificationsSegmentedControl/NotificationsSegmentedControl.module.scss'
import { NotificationSegment } from 'components/notificationsSideModal/utils'

type Props = Pick<ComponentProps<typeof WppSegmentedControl>, 'value' | 'onWppChange'>

export const NotificationsSegmentedControl = ({ value, onWppChange }: Props) => {
  const { t } = useTranslation()

  return (
    <WppSegmentedControl size="s" value={value} className={styles.root} onWppChange={onWppChange}>
      <WppSegmentedControlItem value={NotificationSegment.ALL}>{t('os.notification.all')}</WppSegmentedControlItem>
      <WppSegmentedControlItem value={NotificationSegment.OS}>{t('os.notification.os')}</WppSegmentedControlItem>
      <WppSegmentedControlItem value={NotificationSegment.PROJECTS}>
        {t('os.notification.projects')}
      </WppSegmentedControlItem>
      <WppSegmentedControlItem value={NotificationSegment.APPS}>
        {t('os.notification.apps.title')}
      </WppSegmentedControlItem>
    </WppSegmentedControl>
  )
}
