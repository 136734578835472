import {
  addMinutes,
  differenceInMinutes,
  format,
  isDate,
  isEqual,
  isFuture,
  isSameDay,
  isThisWeek,
  isToday,
  isWithinInterval,
  startOfDay,
} from 'date-fns'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useStableCallback } from 'hooks/useStableCallback'
import { CalendarWidgetData } from 'pages/home/types'
import { CalendarWidgetConfig, CalendarWidgetDTO, CalendarWidgetEventDTO } from 'types/widgets/widget'
import { groupBy } from 'utils/common'

export const TIME_FORMAT = 'HH:mm'
export const DEFAULT_DATE_FORMAT = 'EEE d MMM, yyyy'
export const MIN_CALENDAR_WIDTH = 4
export const MIN_CALENDAR_HEIGHT = 1
const DEFAULT_DATE_FORMAT_SHORT = 'EEE d MMM'
const RESPONSE_DATE_FORMAT = 'yyyy-MM-dd'
const HOURS_IN_DAY = 24
const MINUTES_IN_HOUR = 60

export const getDate = (date: Date | string, dateFormat = RESPONSE_DATE_FORMAT) =>
  format(isDate(date) ? (date as Date) : new Date(date), dateFormat)

export const useGetFormattedTime = (
  showDate = false,
  dateFormat = DEFAULT_DATE_FORMAT_SHORT,
  dateDateTimeFormat = `${DEFAULT_DATE_FORMAT_SHORT}, ${TIME_FORMAT}`,
) => {
  const formatDate = useStableCallback((date: Date) => format(date, dateFormat))
  const formatTime = useStableCallback((date: Date) => format(date, TIME_FORMAT))
  const formatDateTime = useStableCallback((date: Date) => format(date, dateDateTimeFormat))

  return useCallback(
    (startsAt: Date, endsAt: Date) => {
      const allDayEvent = isAllDayEvent(startsAt, endsAt)

      return !showDate && isToday(new Date(endsAt)) && isFuture(new Date(endsAt))
        ? `${formatTime(startsAt)} - ${formatTime(endsAt)}`
        : isSameDay(new Date(startsAt), new Date(endsAt))
          ? allDayEvent
            ? `${formatDate(startsAt)}`
            : `${formatDateTime(startsAt)} - ${formatTime(endsAt)}`
          : allDayEvent
            ? `${formatDate(startsAt)} - ${formatDate(endsAt)}`
            : `${formatDateTime(startsAt)} - ${formatDateTime(endsAt)}`
    },
    [formatDate, formatTime, formatDateTime, showDate],
  )
}

export const isAllDayEvent = (startsAt: Date, endsAt: Date) => {
  // timeZonesRangeOffsetInHours is needed to detect summer/winter time
  const timeZonesRangeOffset = startsAt.getTimezoneOffset() - endsAt.getTimezoneOffset()
  const rangeDifference = Math.abs(differenceInMinutes(startsAt, addMinutes(endsAt, 1))) + timeZonesRangeOffset

  // This event is an entire day event if it starts from 00:00 (ends at 23:59) and the difference in hours
  // between the start date and the end date is a multiple of the count of hours per day.
  return (
    isEqual(startOfDay(startsAt), startsAt) &&
    rangeDifference > 0 &&
    rangeDifference % (HOURS_IN_DAY * MINUTES_IN_HOUR) === 0
  )
}

enum EventPeriods {
  Today = 'today',
  ThisWeek = 'week',
  Upcoming = 'upcoming',
}

export const useGroupByDateEvents = (data: CalendarWidgetEventDTO[]) => {
  const { t } = useTranslation()
  return useMemo(
    () =>
      data.length
        ? groupBy(
            data.filter(({ endsAt }) => isFuture(new Date(endsAt))),
            ({ startsAt, endsAt }) =>
              isWithinInterval(new Date(), { start: new Date(startsAt), end: new Date(endsAt) }) ||
              (isToday(new Date(startsAt)) && isFuture(new Date(startsAt)))
                ? t(`os.home.calendar_widget.event_periods.${EventPeriods.Today}`)
                : isThisWeek(new Date(startsAt))
                  ? t(`os.home.calendar_widget.event_periods.${EventPeriods.ThisWeek}`)
                  : t(`os.home.calendar_widget.event_periods.${EventPeriods.Upcoming}`),
          )
        : [],
    [data, t],
  )
}

export enum GroupedEvents {
  Group = 0,
  Events = 1,
}

export type EventsGroup = [string, CalendarWidgetEventDTO[]]

export const calendarWidgetSizes = [
  { width: 4, height: 1, isCalendarView: false, isListView: true },
  { width: 8, height: 1, isCalendarView: false, isListView: true },
  { width: 8, height: 2, isCalendarView: true, isListView: false },
  { width: 8, height: 2, isCalendarView: false, isListView: true },
  { width: 12, height: 2, isCalendarView: true, isListView: true },
]

export const defaultCalendarWidgetConfig = {
  isListView: false,
  isCalendarView: true,
  calendarId: '',
} satisfies CalendarWidgetConfig

export const resolveCalendarWidgetConfig = ({
  config,
}: CalendarWidgetDTO | CalendarWidgetData): CalendarWidgetConfig => ({
  ...defaultCalendarWidgetConfig,
  ...config,
})
