import { LocalLegacyMicroAppConfig, LocalMicroAppConfig, SystemMicroAppConfig } from 'types/apps/microApps'

export enum AppDataFromUrlType {
  AppInstance = 'AppInstance',
  SystemApp = 'SystemApp',
  LocalApp = 'LocalApp',
  LocalLegacyApp = 'LocalLegacyApp',
  Invalid = 'Invalid',
}

export type AppDataFromUrl =
  | AppInstanceDataFromUrl
  | SystemAppDataFromUrl
  | LocalAppDataFromUrl
  | LocalLegacyAppDataFromUrl
  | InvalidDataFromUrl

export interface AppInstanceDataFromUrl {
  type: AppDataFromUrlType.AppInstance
  appInstanceId: string
  appRoute: {
    /** A URL pathname that doesn't begin with a '/' */
    path: string
    /** A URL search string, beginning with a '?' */
    search: string
    /** A URL anchor identifier, beginning with a '#' */
    hash: string
  }
}

export interface SystemAppDataFromUrl {
  type: AppDataFromUrlType.SystemApp
  app: SystemMicroAppConfig
}

export interface LocalAppDataFromUrl {
  type: AppDataFromUrlType.LocalApp
  app: LocalMicroAppConfig
  workspaceAzId?: string
}

export interface LocalLegacyAppDataFromUrl {
  type: AppDataFromUrlType.LocalLegacyApp
  app: LocalLegacyMicroAppConfig
  workspaceAzId?: string
}

// Means url didn't match any known route template
export interface InvalidDataFromUrl {
  type: AppDataFromUrlType.Invalid
}
