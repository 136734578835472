import { matchAndThriveApi } from 'api'

export const createFeedback = ({
  assignmentId,
  nudgeId,
  type,
  assignmentName,
  nudgeName,
  nudgeObj,
}: {
  assignmentId: string
  nudgeId: string
  type: string
  assignmentName: string
  nudgeName: string
  nudgeObj: any
}) =>
  matchAndThriveApi.post<{ id: string }>('/nudges/feedback', {
    assignmentId: assignmentId,
    nudgeId: nudgeId,
    type: type,
    assignmentName: assignmentName,
    nudgeName: nudgeName,
    nudgeObj: nudgeObj,
  })
