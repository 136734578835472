import { AppInstanceShort } from '@wpp-open/core'
import { Fragment, ReactNode } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { LoadingTreeList } from 'components/treeList/loadingTreeList/LoadingTreeList'
import { GroupedItem } from 'utils/appsGroups'

interface Props {
  isLoading: boolean
  ungrouped: AppInstanceShort[]
  grouped: GroupedItem[]
  renderApp: (appInstance: AppInstanceShort, i: number, source: AppInstanceShort[]) => JSX.Element
  renderGroups: (source: GroupedItem[]) => JSX.Element
  emptyState?: ReactNode
  action?: ReactNode
}

export const CustomNavigationAppsList = ({
  isLoading,
  ungrouped,
  grouped,
  renderGroups,
  renderApp,
  emptyState = null,
  action = null,
}: Props) => {
  if (isLoading) {
    return <LoadingTreeList />
  }

  return (
    <Flex direction="column" gap={2} data-testid="apps-tree-list">
      {!!grouped.length && <Fragment>{renderGroups(grouped)}</Fragment>}

      {!!ungrouped.length && (
        <Flex direction="column" gap={2} data-testid="ungrouped-apps">
          {ungrouped.map((appInstance, i) => (
            <Fragment key={appInstance.id}>{renderApp(appInstance, i, ungrouped)}</Fragment>
          ))}
        </Flex>
      )}

      {emptyState}
      {action}
    </Flex>
  )
}
