import { MayBeNull, ProjectTaskStatus } from '@wpp-open/core'

export enum ProjectPhaseStatus {
  ToDo = 'TO_DO',
  Active = 'IN_PROGRESS',
  Completed = 'COMPLETED',
}

export interface ProjectPhaseInfoShort {
  id: string
  name: string
  orderNumber: number
  status: ProjectPhaseStatus
}

export interface ProjectPhase {
  id: string
  name: string
  description: MayBeNull<string>
  orderNumber: number
  startDate: MayBeNull<string>
  endDate: MayBeNull<string>
  assignUser: MayBeNull<string>
  status: ProjectPhaseStatus
  phaseItems: ProjectPhaseItem[]
}

export interface ProjectPhaseItem {
  id: string
  phaseId: string
  itemType: ProjectPhaseItemType
  item: ProjectCanvasItem
  orderNumber: number
}

export interface ProjectCanvasApplication {
  id: string | number
  appInstanceId: MayBeNull<string>
  type: ProjectCanvasApplicationType
  externalAppId: string
  externalAppName: string
  externalAppOriginId: MayBeNull<string>
  name: MayBeNull<string>
  logoUrl: MayBeNull<string>
  config: MayBeNull<{}>
  startDate: MayBeNull<string>
  endDate: MayBeNull<string>
  agencyExternalId: MayBeNull<string>
  assignUser: MayBeNull<string>
  isOutdated: MayBeNull<boolean>
  presentationSlideId: MayBeNull<string>
  task: MayBeNull<ProjectTask>
}

export interface ProjectCanvasActivity {
  id: string
  name: string
  startDate: MayBeNull<string>
  endDate: MayBeNull<string>
  assignUser: MayBeNull<string>
  items: ProjectActivityItem[]
  externalLinks: ProjectActivityExternalLink[]
  task: MayBeNull<ProjectTask>
  files: ProjectCanvasActivityFile[]
}

export type ProjectCanvasItem = ProjectCanvasApplication | ProjectCanvasActivity

export interface ProjectActivityItem {
  id: string
  orderNumber: number
  application: ProjectCanvasApplication
}

export interface ProjectActivityExternalLink {
  id: string
  url: string
  alias: MayBeNull<string>
  createdAt: string
}

export interface ProjectCanvasActivityFile {
  id: string
  key: string
  name: string
  size: number
  fileType: string
  scope: ProjectCanvasActivityFileScope
  createdByEmail: MayBeNull<string>
}

export enum ProjectCanvasActivityFileScope {
  Project = 'PROJECT',
  Item = 'ITEM',
}

export enum ProjectPhaseItemType {
  Application = 'application',
  Activity = 'activity',
}

export enum ProjectCanvasApplicationType {
  Native = 'native',
  LeanAppsNative = 'lean_apps_native',
  CodeEmbedded = 'code_embedded',
  LinkDetached = 'link_detached',
  LinkEmbedded = 'link_embedded',
  CustomPage = 'custom_page',
  MiroBoard = 'miro_board',
  MiroBoardInstance = 'miro_board_instance',
  Caas = 'caas',
}

export interface ProjectTask {
  id: string
  status: ProjectTaskStatus
}

export enum ProjectFluidConnectionType {
  Data = 'DATA',
  Flow = 'FLOW',
}
