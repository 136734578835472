export const isEmbedded = (): boolean => {
  let embedded: boolean
  try {
    embedded = window.location.host !== window.parent.location.host
  } catch {
    // in the case we'll not have access to the parent location means that the app is iframed inside the different domain
    embedded = true
  }

  return embedded
}
