import { MayBeNull } from '@wpp-open/core'
import { matchPath } from 'react-router-dom'

import { APPLICATION_URL_PREFIX } from 'constants/routes'
import { AppDataFromUrlType, AppInstanceDataFromUrl } from 'types/osState/appDataFromUrl'

interface Params {
  pathname: string
  search: string
  hash: string
}

export const getAppInstanceDataFromUrl = ({ pathname, search, hash }: Params): MayBeNull<AppInstanceDataFromUrl> => {
  const appInstanceMatch = matchPath(`/${APPLICATION_URL_PREFIX}/:appInstanceId/*`, pathname)

  if (!appInstanceMatch) {
    return null
  }

  const appInstanceId = appInstanceMatch.params.appInstanceId || ''
  const appRoutePath = appInstanceMatch.params['*'] || ''

  return {
    type: AppDataFromUrlType.AppInstance,
    appInstanceId,
    appRoute: {
      path: appRoutePath,
      search,
      hash,
    },
  }
}
