export type DateFormatType = {
  [key in 'en-US' | 'zh-cn' | 'de' | 'en-gb' | 'it']: string
}

export const DateFormat: DateFormatType = {
  'en-US': 'MM/dd/yyyy',
  'zh-cn': 'yyyy/MM/dd',
  de: 'dd.MM.yyyy',
  'en-gb': 'dd/MM/yyyy',
  it: 'dd/MM/yy',
} as const

export const DateFormatWithTime: DateFormatType = {
  'en-US': 'MM/dd/yyyy, HH:mm',
  'zh-cn': 'yyyy/MM/dd, HH:mm',
  de: 'dd.MM.yyyy, HH:mm',
  'en-gb': 'dd/MM/yyyy, HH:mm',
  it: 'dd/MM/yy, HH:mm',
} as const

export const DateFormatLong: DateFormatType = {
  'en-US': 'PPp',
  'zh-cn': 'PPp',
  de: 'PPp',
  'en-gb': 'PPp',
  it: 'PPp',
} as const
