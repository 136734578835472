import { facadeApi } from 'api'
import { HubAccessRequest } from 'types/requests/requests'

export interface Params {
  id: string
  tenantId: string
}

export const fetchHubAccessRequestApi = ({ id, tenantId }: Params) =>
  facadeApi.get<HubAccessRequest>(`/hub-access-requests/${id}`, { headers: { 'X-Tenant-Id': tenantId } })
