import { HierarchyBreadcrumbs } from 'layout/header/breadcrumbs/hierarchy/HierarchyBreadcrumbs'
import { ProjectFluidBreadcrumbs } from 'layout/header/breadcrumbs/project/projectFluid/ProjectFluidBreadcrumbs'
import { ProjectLinearBreadcrumbs } from 'layout/header/breadcrumbs/project/projectLinear/ProjectLinearBreadcrumbs'
import { BreadcrumbsData, BreadcrumbsType } from 'types/osState/breadcrumbs'

interface Props {
  data: BreadcrumbsData
}

export const Breadcrumbs = ({ data }: Props) => {
  if (data.type === BreadcrumbsType.ProjectLinear) {
    return <ProjectLinearBreadcrumbs items={data.items} />
  }

  if (data.type === BreadcrumbsType.ProjectFluid) {
    return <ProjectFluidBreadcrumbs items={data.items} />
  }

  return <HierarchyBreadcrumbs items={data.items} />
}
