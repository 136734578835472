// Always have to be imported first
import 'ag-grid-community/styles/ag-grid.css'
import '@platform-ui-kit/components-library/dist/collection/ag-theme-wpp.css'

import { WppBackToTopButton, WppSpinner, WppTooltip } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import {
  ICellRendererParams,
  ILoadingOverlayParams,
  INoRowsOverlayParams,
  BodyScrollEndEvent,
  ColDef as LibColDef,
  RowClickedEvent,
  GetRowIdFunc,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import clsx from 'clsx'
import {
  forwardRef,
  ComponentPropsWithoutRef,
  useMemo,
  Ref,
  useContext,
  createContext,
  useState,
  useEffect,
  useRef,
} from 'react'
import { useTranslation } from 'react-i18next'
import { mergeRefs } from 'react-merge-refs'
import { useScrollbarWidth, useSetState } from 'react-use'
// Reusing tippy.js from CL
import tippy from 'tippy.js'

import { SortOrder } from 'api/common/types'
import { Portal } from 'components/common/portal/Portal'
import { DefaultColumnHeader } from 'components/common/table/defaultColumnHeader/DefaultColumnHeader'
import styles from 'components/common/table/Table.module.scss'
import { registerTable } from 'components/common/table/utils'
import { TableKey, TableDefaults } from 'constants/table'
import { useStableCallback } from 'hooks/useStableCallback'
import { isNumber } from 'utils/common'

export type FullWidthCellRenderer<TData = any> = (params: ICellRendererParams<TData>) => JSX.Element
export type LoadingOverlayComponent<TData = any> = (props: ILoadingOverlayParams<TData>) => JSX.Element
export type NoRowsOverlayComponent<TData = any> = (props: INoRowsOverlayParams<TData>) => JSX.Element
export type OnRowClicked<TData = any> = (params: RowClickedEvent<TData>) => void

export interface ColDef<TData = any> extends Omit<LibColDef<TData>, 'cellRenderer'> {
  cellRenderer?: (params: ICellRendererParams<TData>) => MayBeNull<JSX.Element>
  loadingCellRenderer?: (params: ICellRendererParams<TData>) => JSX.Element
}

export type TableProps<TData = any> = Omit<
  ComponentPropsWithoutRef<typeof AgGridReact<TData>>,
  | 'scrollbarWidth'
  | 'fullWidthCellRenderer'
  | 'loadingOverlayComponent'
  | 'noRowsOverlayComponent'
  | 'columnDefs'
  | 'onRowClicked'
> & {
  tableKey?: TableKey
  columnDefs: ColDef<TData>[]
  fullWidthCellRenderer?: FullWidthCellRenderer<TData>
  loadingOverlayComponent?: LoadingOverlayComponent<TData>
  noRowsOverlayComponent?: NoRowsOverlayComponent<TData>
  onRowClicked?: OnRowClicked<TData>
}

const DynamicOverlayComponentsContext = createContext<{
  loadingOverlayComponent?: LoadingOverlayComponent
  noRowsOverlayComponent?: NoRowsOverlayComponent
}>({})

const LoadingOverlayComponentDynamic: LoadingOverlayComponent = props => {
  const dynamicOverlayComponents = useContext(DynamicOverlayComponentsContext)
  const Component = dynamicOverlayComponents.loadingOverlayComponent!

  return <Component {...props} />
}

const DefaultLoadingOverlayComponent: LoadingOverlayComponent = () => (
  <WppSpinner data-testid="loading-overlay" size="l" />
)

const NoRowsOverlayComponentDynamic: NoRowsOverlayComponent = props => {
  const dynamicOverlayComponents = useContext(DynamicOverlayComponentsContext)
  const Component = dynamicOverlayComponents.noRowsOverlayComponent!

  return <Component {...props} />
}

export const Table = forwardRef(function Table<TData = any>(
  {
    tableKey,
    className,
    suppressMultiSort = true,
    suppressMovableColumns = true,
    defaultColDef,
    columnDefs,
    cacheBlockSize = TableDefaults.CacheBlockSize,
    rowHeight = 48,
    onColumnResized,
    onBodyScrollEnd,
    rowClassRules,
    onRowClicked,
    getRowId,
    loadingOverlayComponent = DefaultLoadingOverlayComponent,
    noRowsOverlayComponent,
    ...rest
  }: TableProps<TData>,
  ref: Ref<AgGridReact<TData>>,
) {
  /*
    For cases when in OS preferences scrollbars behaviors set to "show only on scroll"
    it is imposible to track their width programmatically and hook will return 0 that causes horizontal scroll.
    So we fallback to minimum size to omit it.
  */
  const scrollbarWidth = useScrollbarWidth() || 8

  const innerRef = useRef<AgGridReact<TData>>(null)
  const [instanceId] = useState(() => `t-${crypto.randomUUID()}`)
  const hasFlexColumns = useMemo(() => columnDefs.some(({ flex }) => isNumber(flex)), [columnDefs])

  const [{ isReady, isBackToTopVisible }, setState] = useSetState({
    isReady: !hasFlexColumns,
    isBackToTopVisible: false,
  })

  useEffect(() => {
    if (tableKey) {
      return registerTable(tableKey, () => innerRef.current)
    }
  }, [tableKey])

  const rowClassRulesInner = useMemo(
    () => ({
      ...rowClassRules,
      [styles.clickableRow]: () => !!onRowClicked,
    }),
    [rowClassRules, onRowClicked],
  )

  const defaultColDefInner = useMemo<ColDef<TData>>(
    () => ({
      sortable: false,
      sort: null,
      sortingOrder: ['asc', 'desc'] as SortOrder[],
      headerComponent: DefaultColumnHeader,
      ...defaultColDef,
      cellClassRules: {
        ...defaultColDef?.cellClassRules,

        // These styles apply only for custom cell renderers.
        [styles.customCell]: params => !!params.colDef.cellRenderer,
      },
    }),
    [defaultColDef],
  )

  const onBodyScrollEndInner = useStableCallback((event: BodyScrollEndEvent<TData>) => {
    const { top, direction } = event

    if (direction === 'vertical') {
      setState({
        isBackToTopVisible: top >= rowHeight * 10,
      })
    }

    onBodyScrollEnd?.(event)
  })

  const overlayComponents = useMemo(
    () => ({
      loadingOverlayComponent,
      noRowsOverlayComponent,
    }),
    [loadingOverlayComponent, noRowsOverlayComponent],
  )

  const onRowClickedInner = useStableCallback<OnRowClicked<TData>>(params => {
    const selection = window.getSelection()

    if (!selection || selection.isCollapsed) {
      onRowClicked?.(params)
    }
  })

  const getRowIdInner: GetRowIdFunc<TData> = useStableCallback(params => getRowId?.(params)!)

  return (
    <DynamicOverlayComponentsContext.Provider value={overlayComponents}>
      <AgGridReact
        ref={mergeRefs([innerRef, ref])}
        {...rest}
        className={clsx('ag-theme-wpp', instanceId, styles.root, { [styles.ready]: isReady }, className)}
        defaultColDef={defaultColDefInner}
        columnDefs={columnDefs}
        scrollbarWidth={scrollbarWidth}
        suppressMultiSort={suppressMultiSort}
        suppressMovableColumns={suppressMovableColumns}
        loadingOverlayComponent={LoadingOverlayComponentDynamic}
        onBodyScrollEnd={onBodyScrollEndInner}
        cacheBlockSize={cacheBlockSize}
        rowClassRules={rowClassRulesInner}
        onRowClicked={onRowClickedInner}
        rowHeight={rowHeight}
        {...(!!getRowId && { getRowId: getRowIdInner })}
        {...(!!noRowsOverlayComponent && { noRowsOverlayComponent: NoRowsOverlayComponentDynamic })}
        onColumnResized={
          isReady
            ? onColumnResized
            : event => {
                if (event.finished) {
                  setState({ isReady: true })
                }

                onColumnResized?.(event)
              }
        }
      />
      {isBackToTopVisible && (
        <TableBackToTopButton
          instanceId={instanceId}
          onClick={() => {
            innerRef.current?.api.ensureIndexVisible(0)
          }}
        />
      )}
    </DynamicOverlayComponentsContext.Provider>
  )
}) as <TData = any>(props: { ref?: Ref<AgGridReact<TData>> } & TableProps<TData>) => JSX.Element

function TableBackToTopButton({ instanceId, onClick }: { instanceId: string; onClick: () => void }) {
  const { t } = useTranslation()
  const [content] = useState(() => document.createElement('div'))

  useEffect(() => {
    const tableNode = document.querySelector<HTMLDivElement>(`.${instanceId}`)!
    const tableOffsetParentZIndex = getComputedStyle(tableNode.offsetParent || document.body).zIndex

    const instance = tippy(tableNode, {
      zIndex: isNumber(+tableOffsetParentZIndex) ? +tableOffsetParentZIndex : 0,
      content,
      interactive: true,
      showOnCreate: true,
      hideOnClick: false,
      trigger: 'manual',
      offset: [0, -14],
      placement: 'right-end',
      appendTo: document.body,
      popperOptions: {
        strategy: 'fixed',
        modifiers: [
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['bottom', 'right'],
            },
          },
        ],
      },
    })

    return () => {
      instance.destroy()
    }
  }, [instanceId, content])

  return (
    <Portal target={content}>
      <WppTooltip text={t('os.common.back_to_top')}>
        <WppBackToTopButton onClick={onClick} />
      </WppTooltip>
    </Portal>
  )
}
