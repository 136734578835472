import { CompactMicroApp } from 'components/apps/compact/microApp/CompactMicroApp'
import { CompactNoCodeApp } from 'components/apps/compact/noCodeApp/CompactNoCodeApp'
import { MicroAppType, NativeMicroAppConfig } from 'types/apps/microApps'
import { NoCodeAppMiroBoard, RenderableNoCodeApp } from 'types/apps/noCodeApps'

// TODO: Temporary solution.
//  Compact app types should be separated from fullscreen types for all subcomponents
export type CompactAppConfig = Exclude<RenderableNoCodeApp, NoCodeAppMiroBoard> | NativeMicroAppConfig

interface Props {
  app: CompactAppConfig
}

// TODO: Review styles for subcomponents, allow passing className.
export const CompactApp = ({ app }: Props) => {
  if (app.type === MicroAppType.Native) {
    return <CompactMicroApp app={app} />
  }

  return <CompactNoCodeApp app={app} />
}
