import { MayBeNull } from '@wpp-open/core'

import { getSystemMicroAppData } from 'providers/osState/utils/appData'
import { ResolvedAppData } from 'providers/osState/utils/useResolveAppData'
import { SystemAppDataFromUrl } from 'types/osState/appDataFromUrl'

interface Params {
  appDataShort: SystemAppDataFromUrl
}

export const useSystemAppDataResolver = (params: MayBeNull<Params>): MayBeNull<ResolvedAppData> => {
  if (params) {
    const { app } = params.appDataShort
    const appData = getSystemMicroAppData({ app })

    return {
      appData,
      sideEffectNode: null,
    }
  }

  return null
}
