import { workfrontApi } from 'api'
import { environment } from 'environment'

export const fetchWorkfrontAuthenticate = ({
  code,
  state,
  redirectUri,
  jwt,
  tenantId = environment.PLAYGROUND_TENANT_ID,
}: {
  code: string
  state: string
  redirectUri: string
  jwt: string
  tenantId?: string
}) =>
  workfrontApi.get<{ accessToken: string }>(
    `/auth/authenticate?code=${code}&state=${state}&redirectUri=${redirectUri}`,
    {
      headers: {
        'X-Tenant-Id': tenantId,
        Authorization: `Bearer ${jwt}`,
      },
    },
  )
