export function adjustTextSize(
  containerElement: HTMLElement | null,
  textElement: HTMLElement | null,
  maxFontSizeParam?: number,
) {
  let minFontSize = 1 // Minimum font size
  let maxFontSize = maxFontSizeParam ? maxFontSizeParam : 150 // Maximum font size, start big enough
  let fontSize

  if (containerElement === null || textElement === null) return
  while (minFontSize <= maxFontSize) {
    fontSize = ((minFontSize + maxFontSize) / 2) | 0 // Integer division
    textElement.style.fontSize = fontSize + 'px'

    if (textElement.scrollWidth <= containerElement.offsetWidth) {
      minFontSize = fontSize + 1 // Try a bigger size
    } else {
      maxFontSize = fontSize - 1 // Try a smaller size
    }
  }

  // Set the font size to the last best fit
  textElement.style.fontSize = minFontSize - 1 + 'px'
}
