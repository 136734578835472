export enum TaxonomyEntities {
  app = 'app',
  apps = 'apps',
  application = 'application',
  applications = 'applications',
  project = 'project',
  projects = 'projects',
  client = 'client',
  clients = 'clients',
  brand = 'brand',
  brands = 'brands',
}

export type TaxonomyKeys = keyof typeof TaxonomyEntities

export type Taxonomy = {
  [key in TaxonomyKeys]: string
}
