import { WppTag } from '@platform-ui-kit/components-library-react'
import { useTranslation } from 'react-i18next'

export const RequestStatusTag = ({
  isApproved,
  isHierarchyStatus,
}: {
  isApproved: boolean
  isHierarchyStatus?: boolean
}) => {
  const { t } = useTranslation()

  return (
    <WppTag
      label={t(`os.requests.statuses.${isApproved ? (isHierarchyStatus ? 'assigned' : 'approved') : 'rejected'}`)}
      variant={isApproved ? 'positive' : 'negative'}
    />
  )
}
