import clsx from 'clsx'
import { ComponentPropsWithoutRef, Suspense } from 'react'

import styles from 'components/renderError/boundary/renderErrorBoundaryLayout/RenderErrorBoundaryLayout.module.scss'
import { RenderErrorBoundaryHeight } from 'components/renderError/boundary/renderErrorBoundaryLayout/utils'

type Props = ComponentPropsWithoutRef<'div'> & {
  height?: RenderErrorBoundaryHeight
}

export const RenderErrorBoundaryLayout = ({
  height = RenderErrorBoundaryHeight.Adapt,
  className,
  children,
  ...rest
}: Props) => (
  <div
    {...rest}
    className={clsx(
      styles.root,
      {
        [styles.fullscreen]: height === RenderErrorBoundaryHeight.Fullscreen,
        [styles.fullPage]: height === RenderErrorBoundaryHeight.FullPage,
      },
      className,
    )}
  >
    <Suspense>{children}</Suspense>
  </div>
)
