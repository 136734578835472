import {
  FramedCompactAppMethods,
  FramedCompactAppParentMethods,
  FramedFullscreenAppMethods,
  FramedFullscreenAppParentMethods,
} from '@wpp-open/core'
import { connectToChild, Methods } from 'penpal'
import { ComponentPropsWithoutRef, useEffect, useRef } from 'react'

import { useAppsContext } from 'providers/apps/AppsContext'

type Props = ComponentPropsWithoutRef<'iframe'> & {
  isFullscreen: boolean
  appId: string
}

export const PenpalParentFrame = ({ isFullscreen, appId, ...iframeProps }: Props) => {
  const { getFullscreenAppCustomProps, getCompactAppCustomProps } = useAppsContext()
  const ref = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    if (isFullscreen) {
      const { osApi, osContext } = getFullscreenAppCustomProps({ stableId: appId })

      const parentMethods: Methods = {
        osApi: { ...osApi },
      } satisfies FramedFullscreenAppParentMethods

      const connection = connectToChild<FramedFullscreenAppMethods>({
        iframe: ref.current!,
        methods: parentMethods,
        debug: false,
      })

      const unsubscribeFn = osContext.subscribe(osContext => {
        connection.promise.then(child => {
          child.receiveOsContext(osContext)
        })
      })

      connection.promise.catch(error => {
        console.error('Penpal parent context error:', error)
      })

      return () => {
        unsubscribeFn()
        connection.destroy()
      }
    } else {
      const { osApi, osContext } = getCompactAppCustomProps({ stableId: appId })

      const parentMethods: Methods = {
        osApi: { ...osApi },
      } satisfies FramedCompactAppParentMethods

      const connection = connectToChild<FramedCompactAppMethods>({
        iframe: ref.current!,
        methods: parentMethods,
        debug: false,
      })

      const unsubscribeFn = osContext.subscribe(osContext => {
        connection.promise.then(child => {
          child.receiveOsContext(osContext)
        })
      })

      connection.promise.catch(error => {
        console.error('Penpal parent context error:', error)
      })

      return () => {
        unsubscribeFn()
        connection.destroy()
      }
    }
  }, [appId, getCompactAppCustomProps, getFullscreenAppCustomProps, isFullscreen])

  return <iframe ref={ref} {...iframeProps} />
}
