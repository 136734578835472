import {
  uploadUserDetailsFileApi,
  generateUserDetailsUploadUrlsApi,
} from 'api/attachments/fetchers/uploadUserDetailsFileApi'
import { createUseMutation } from 'api/common/createUseMutation'

export const useUploadUserDetailsFileApi = createUseMutation({
  fetcher: uploadUserDetailsFileApi,
})

export const useGenerateUserDetailsUploadUrlsApi = createUseMutation({
  fetcher: generateUserDetailsUploadUrlsApi,
})
