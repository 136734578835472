import { createUseQuery } from 'api/common/createUseQuery'
import { fetchCurrentUserApi } from 'api/users/fetchers/fetchCurrentUserApi'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { defaults } from 'legacy/utils/defaults'

export const useCurrentUserApi = createUseQuery({
  queryKey: ApiQueryKeys.CURRENT_USER,
  fetcher: fetchCurrentUserApi,
  selector: res => {
    const userDetails = res?.data?.data
    // @TODO: Remove `displayLanguage` when locale service will be implemented
    return userDetails
      ? {
          ...userDetails,
          ...(!userDetails.numberLocale && { numberLocale: defaults.defaultLocale.numberLocale }),
          ...(!userDetails.dateLocale && { dateLocale: defaults.defaultLocale.dateLocale }),
          displayLanguage: defaults.defaultLanguageCode,
        }
      : null
  },
})
