/**
 * Helper value that indicates an unset value in conditional logic.
 * It is used instead of null or undefined to clearly separate empty data from unset data.
 */
export const UnsetValue = Symbol('UnsetValue')
export type UnsetValue = typeof UnsetValue

export type ConditionalValue<T> = T | UnsetValue

export const isValueSet = <T>(value: ConditionalValue<T>): value is T => value !== UnsetValue
