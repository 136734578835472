import { LegacyTheme } from 'legacy/styles/osTheming/models'
import { LegacyTenantCode } from 'legacy/types/osWebRootApi'

export const wppOpenTheme: LegacyTheme = {
  name: 'WPP Open',
  code: LegacyTenantCode.WPP_OPEN,

  colors: {
    brand: {
      primary: {
        background: '#F9F3FF',
        lighter: '#B267F3',
        light: '#A047EC',
        normal: '#8E28E5',
        dark: '#7617C7',
        darker: '#5E0BA4',
      },
      secondary: {
        background: '#F2F2FB',
        lighter: '#6C68CC',
        light: '#4F45BF',
        normal: '#3423B0',
        dark: '#1A00A1',
        darker: '#090086',
      },
    },
    system: {
      information: {
        background: '#F2FEFE',
        lighter: '#31D8E1',
        light: '#00CAD5',
        normal: '#00B9C0',
        dark: '#00A5A9',
        darker: '#008E8E',
      },
      error: {
        background: '#FFF6F2',
        lighter: '#FF8E51',
        light: '#FF7528',
        normal: '#FF5C00',
        dark: '#D24600',
        darker: '#A63300',
      },
      warning: {
        background: '#FFFDF2',
        lighter: '#FFC107',
        light: '#FFB014',
        normal: '#DB9707',
        dark: '#B87E00',
        darker: '#946400',
      },
      success: {
        background: '#FAFDF3',
        lighter: '#90C623',
        light: '#83B519',
        normal: '#76A210',
        dark: '#688E0A',
        darker: '#587804',
      },
    },
    grey: {
      white: '#FFFFFF',
      50: '#FAFAFA',
      100: '#E9E9E9',
      200: '#D7D7D7',
      300: '#C6C6C6',
      400: '#B4B4B4',
      500: '#A3A3A3',
      600: '#919191',
      700: '#808080',
      800: '#626262',
      900: '#444444',
      1000: '#262626',
      black: '#000000',
      'white-rgb': '255, 255, 255',
      'black-rgb': '0, 0, 0',
    },
    special: {
      background: '#EBEBEB',
      overlay: '#B4B4B4',
      contrastBackground: '#00003C',
      contrastPrimaryLight: '#A047EC',
      contrastPrimary: '#8E28E5',
      contrastPrimaryDark: '#7617C7',
    },
    text: {
      text: '#333339',
      textMuted: '#63636E',
      textLink: '#8E28E5',
      contrastText: '#FFFFFFE5',
      contrastTextMuted: '#FFFFFFBF',
      contrastTextLink: '#B267F3',
      textInfo: '#00706F',
      textError: '#D24600',
      textWarning: '#946400',
      textSuccess: '#587804',
    },
    dataVisualisation: {
      sequential: {
        S0: {
          100: '#E9E9E9',
          200: '#D7D7D7',
          300: '#C6C6C6',
          400: '#B4B4B4',
          500: '#A3A3A3',
          600: '#919191',
        },
        S1: {
          100: '#C2F7FA',
          200: '#91EEF4',
          300: '#61E4EC',
          400: '#31D8E1',
          500: '#00CAD5',
          600: '#00B9C0',
        },
        S2: {
          100: '#FFDCCA',
          200: '#FFC1A2',
          300: '#FFA779',
          400: '#FF8E51',
          500: '#FF7528',
          600: '#FF5C00',
        },
        S3: {
          100: '#FFF1B6',
          200: '#FFE379',
          300: '#FFD33D',
          400: '#FFC107',
          500: '#FFB014',
          600: '#DB9707',
        },
        S4: {
          100: '#E1F3BB',
          200: '#C7E686',
          300: '#ACD753',
          400: '#90C623',
          500: '#83B519',
          600: '#76A210',
        },
        S5: {
          100: '#E8CEFE',
          200: '#D6AAFB',
          300: '#C488F8',
          400: '#B267F3',
          500: '#A047EC',
          600: '#8E28E5',
        },
        S6: {
          100: '#D0D0F1',
          200: '#ADADE5',
          300: '#8B8AD9',
          400: '#6C68CC',
          500: '#4F45BF',
          600: '#3423B0',
        },
      },
      comparative: {
        C0: '#A3A3A3',
        C1: '#00CAD5',
        C2: '#FF7528',
        C3: '#FFB014',
        C4: '#83B519',
        C5: '#A047EC',
        C6: '#4F45BF',
      },
      diverging: {
        D0: '#8E28E5',
        D1: '#3423B0',
      },
    },
  },

  typography: {
    font: 'WPP',
    sizes: {
      '5xl': {
        size: '36px',
        height: '40px',
      },
      '4xl': {
        size: '30px',
        height: '36px',
      },
      '3xl': {
        size: '24px',
        height: '32px',
      },
      '2xl': {
        size: '20px',
        height: '28px',
      },
      xl: {
        size: '18px',
        height: '28px',
      },
      l: {
        size: '16px',
        height: '24px',
      },
      m: {
        size: '14px',
        height: '20px',
      },
      s: {
        size: '12px',
        height: '16px',
      },
      xs: {
        size: '10px',
        height: '16px',
      },
    },
    weights: {
      bold: 600,
      medium: 500,
      regular: 400,
    },
    boxShadows: {
      '3xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
      '2xl': '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
      xl: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      l: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
      m: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
      s: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
      inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
    },
    borderRadius: {
      card: '13px',
      dropdown: '5px',
      popup: '13px',
      button: '5px',
      input: '5px',
    },
  },
}
