import { AppData } from 'types/osState/appDataResolved'
import { ProjectProcessType } from 'types/projects/project'
import {
  ProjectCanvasActivityShort,
  ProjectCanvasApplicationShort,
  ProjectCanvasItemShort,
  ProjectNavigation,
  ProjectNavigationFluid,
  ProjectNavigationLinear,
} from 'types/projects/projectNavigation'

export const isProjectApplicationData = (itemData: ProjectCanvasItemShort): itemData is ProjectCanvasApplicationShort =>
  'externalAppId' in itemData

export const isProjectActivityData = (itemData: ProjectCanvasItemShort): itemData is ProjectCanvasActivityShort =>
  'items' in itemData

export const isProjectAppActive = (projectAppData: ProjectCanvasApplicationShort, app: AppData['app']): boolean => {
  return !!app && projectAppData.appInstanceId === app.stableId
}

export const isProjectAppVisible = ({ hidden }: ProjectCanvasApplicationShort) => !hidden

export const isProjectNavigationLinear = (
  projectNavigation: ProjectNavigation,
): projectNavigation is ProjectNavigationLinear => projectNavigation.project.processType === ProjectProcessType.Linear

export const isProjectNavigationFluid = (
  projectNavigation: ProjectNavigation,
): projectNavigation is ProjectNavigationFluid => projectNavigation.project.processType === ProjectProcessType.Fluid
