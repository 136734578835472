import { WppIconClose, WppIconDislike, WppIconLike, WppSpinner } from '@platform-ui-kit/components-library-react'
import { animated, useSpring, config } from '@react-spring/web'
import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

import { FeedbackHelpfull } from 'api/workfront/fetchers/publishFeedback'
import { usePublishFeedback } from 'api/workfront/mutations/usePublishFeedback'
import { Flex } from 'components/common/flex/Flex'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { Button } from 'pages/playground/playgroundDashboard/components/general/button/Button'
import styles from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/assignmentCard/feedbackBanner/FeedbackBanner.module.scss'
import stylesMain from 'pages/playground/playgroundDashboard/PlaygroundDashboard.module.scss'

export interface Props {
  nudgeId: string
  nudgeName: string
  assignmentName: string
  loading: boolean
  handleFeedback: (helpfull: boolean) => void
  handleNudgePreview: () => void
  onClose?: () => void
}

const FeedbackBanner = ({
  nudgeId,
  nudgeName,
  assignmentName,
  handleFeedback,
  handleNudgePreview,
  loading,
  onClose,
}: Props) => {
  const queryClient = useQueryClient()
  const [visible, setVisible] = useState(false)

  const { mutateAsync: publishFeedback, isLoading: publishFeedbackLoading } = usePublishFeedback()

  useEffect(() => {
    setTimeout(() => {
      setVisible(true)
    }, 1000)
  }, [])

  const handleCloseClick = async () => {
    try {
      await publishFeedback({ feedbackId: nudgeId, helpfull: FeedbackHelpfull.NO_ANSWER, feedback: '' })
      setVisible(false)
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.FEEDBACK] })
      onClose && onClose()
    } catch (e) {
      console.error(e)
    }
  }

  const fadeCookie = useSpring({
    opacity: visible ? 1 : 0,
    bottom: visible ? '30px' : '-100px',
    config: config.slow,
  })

  return (
    <animated.div className={styles.banner} style={fadeCookie}>
      <Flex className={styles.bannerInner} gap={10} justify="between" align="center">
        <Flex gap={10} justify="center" align="center">
          <Flex justify="center" align="center" className={styles.BannerLeftContainer}>
            <svg
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                zIndex: 1,
              }}
              width="171"
              height="104"
              viewBox="0 0 171 104"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M132.901 154C156.353 126.365 170.5 90.5845 170.5 51.5C170.5 12.8853 156.691 -22.5043 133.743 -50H-20.5C-24.9183 -50 -28.5 -46.4183 -28.5 -42V146C-28.5 150.418 -24.9183 154 -20.5 154H132.901Z"
                fill="#EBFF00"
              />
            </svg>

            <img
              src={require('pages/playground/playgroundDashboard/components/sections/sectionAssignments/assignmentCard/assets/feedback_thumbs.gif')}
              width="102"
              height="102"
              style={{
                position: 'relative',
                zIndex: 2,
              }}
            />
          </Flex>
          <div className={stylesMain.whiteAction}>
            Did
            <span onClick={handleNudgePreview} className={stylesMain.pointer}>
              {` ${nudgeName} `}
            </span>
            for {assignmentName} help you achieve your best work?
          </div>
        </Flex>

        <Flex className={styles.pointer} justify="center" align="center" gap={12}>
          <Button
            text="Yes"
            iconLeft={loading ? <WppSpinner size="s" color="black" /> : <WppIconLike color="black" />}
            small
            onClick={() => (!loading ? handleFeedback(true) : {})}
          />
          <Button
            text="No"
            iconLeft={<WppIconDislike color="white" />}
            small
            variant="transparent"
            onClick={() => handleFeedback(false)}
          />
          <Flex className={styles.pointer} justify="center" align="center" onClick={handleCloseClick}>
            {publishFeedbackLoading ? <WppSpinner size="s" color="white" /> : <WppIconClose color="white" />}
          </Flex>
        </Flex>
      </Flex>
    </animated.div>
  )
}

export default FeedbackBanner
