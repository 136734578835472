import { InfiniteQueryObserverBaseResult } from '@tanstack/query-core'
import { useEffect } from 'react'

interface Props {
  loadMoreRef: HTMLElement
  isFetching: boolean
  fetchNextPage: InfiniteQueryObserverBaseResult['fetchNextPage']
  hasNextPage?: boolean
}

export const useInfiniteFetchNextPage = ({ loadMoreRef, isFetching, fetchNextPage, hasNextPage }: Props) => {
  useEffect(() => {
    if (!hasNextPage || !loadMoreRef) return
    const observer = new IntersectionObserver(entries =>
      entries.forEach(entry => entry.isIntersecting && !isFetching && fetchNextPage()),
    )

    observer.observe(loadMoreRef)
    return () => {
      observer.disconnect()
    }
  }, [loadMoreRef, hasNextPage, fetchNextPage, isFetching])
}
