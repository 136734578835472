import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

export const CHARACTERS_LIMIT = 200

export const useValidationSchema = () => {
  const { t } = useTranslation()

  return useMemo(
    () =>
      zod.object({
        rejectReason: zod
          .string()
          .min(
            1,
            t('os.requests.hierarchy_access_requests.actions_modal.reject_hierarchy.reject_reason.validation_min'),
          )
          .max(
            CHARACTERS_LIMIT,
            t('os.requests.hierarchy_access_requests.actions_modal.reject_hierarchy.reject_reason.validation_max', {
              charactersLimit: CHARACTERS_LIMIT,
            }),
          ),
      }),
    [t],
  )
}
