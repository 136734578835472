import { useMemo } from 'react'
import * as zod from 'zod'

import { UpdateUsersRolesPayload } from 'api/users/fetchers/updateUsersRolesApi'

export const HIERARCHY_ACCESS_ROLE = '4.0 Hierarchy Access'

export interface ManageHierarchyAccessFormDTO {
  navigation: { name: string; id: string }[]
}

export const mapFormValuesPayload = (
  formValues: ManageHierarchyAccessFormDTO,
  roleId: string,
  email: string,
): UpdateUsersRolesPayload => {
  return {
    create: formValues.navigation.flatMap(({ id }) => ({
      user_id: email,
      role_id: roleId,
      account_id: id,
    })),
    delete: [],
  }
}

export const useValidationSchema = () =>
  useMemo(
    () =>
      zod.object({
        navigation: zod.array(zod.object({ id: zod.string(), name: zod.string() })),
      }),
    [],
  )
