import { AnalyticsAction } from '@wpp-open/core'

import { Breadcrumbs } from 'legacy/types/osWebRootApi/breadcrumbs.model'
import { LegacyWorkspace } from 'legacy/types/osWebRootApi/workspace.model'
import { ValueOf } from 'types/common/utils'

export const MicroAppActionType = {
  SET_BREADCRUMBS: '[Microfrontend] Set Breadcrumbs',
  UPDATE_BREADCRUMBS: '[Microfrontend] Update Header Breadcrumbs',
  RESET_BREADCRUMBS: '[Microfrontend] Reset Breadcrumbs Details',
  CHANGE_ROUTE: '[Microfrontend] Change Route',
  USER_UNAUTHORIZED: '[Microfrontend] User Unauthorized',
  SET_HAS_PRODUCT_TOURS: '[Microfrontend] Set has product tours',
  RUN_PRODUCT_TOURS_REMINDER: '[Microfrontend] Run product tours reminder',
  HIDE_PRODUCT_TOURS_REMINDER: '[Microfrontend] Hide product tours reminder',
  TRACK_ACTION: '[Microfrontend] Track action',
  TRACK_ANALYTICS: '[Microfrontend] Track analytics',
} as const

export const ProtectedMicroAppActionType = {
  CHANGE_DATE_FORMAT: '[Microfrontend] Change Date Format',
  CHANGE_NUMBER_FORMAT: '[Microfrontend] Change Number Format',
  CHANGE_DISPLAY_LANGUAGE: '[Microfrontend] Change Display Language',
  CHANGE_WORKSPACE: '[Microfrontend] Change Workspace',
  LOGOUT: '[Microfrontend] Logout',
  RUN_PRODUCT_TOURS: '[Microfrontend] Run Product Tours',
} as const

export const publicAccessActions = Object.values(MicroAppActionType)
export const protectedAccessActions = Object.values(ProtectedMicroAppActionType)

interface MicroAppActionBase {
  type: ValueOf<typeof MicroAppActionType> | ValueOf<typeof ProtectedMicroAppActionType>
}

export class MicrofrontendUnauthorized implements MicroAppActionBase {
  public type = MicroAppActionType.USER_UNAUTHORIZED
}

export class MicrofrontendSetHeaderBreadcrumbs implements MicroAppActionBase {
  public type = MicroAppActionType.SET_BREADCRUMBS
  public data: Breadcrumbs

  constructor(data: Breadcrumbs) {
    this.data = data
  }
}

export class MicrofrontendUpdateHeaderBreadcrumbs implements MicroAppActionBase {
  public type = MicroAppActionType.UPDATE_BREADCRUMBS
  public data: { name: string }[]

  constructor(data: { name: string }[]) {
    this.data = data
  }
}

export class MicrofrontendResetBreadcrumbsDetails implements MicroAppActionBase {
  public type = MicroAppActionType.RESET_BREADCRUMBS
}

export class MicrofrontendChangeRoute implements MicroAppActionBase {
  public type = MicroAppActionType.CHANGE_ROUTE
  public data: { route: string }[]
  public extras?: any

  constructor(data: { route: string }[], extras?: any) {
    this.data = data
    this.extras = extras
  }
}

export class MicrofrontendRunProductToursReminder implements MicroAppActionBase {
  public type = MicroAppActionType.RUN_PRODUCT_TOURS_REMINDER
}

export class MicrofrontendHideProductToursReminder implements MicroAppActionBase {
  public type = MicroAppActionType.HIDE_PRODUCT_TOURS_REMINDER
}

export class MicrofrontendSetHasProductTours implements MicroAppActionBase {
  public type = MicroAppActionType.SET_HAS_PRODUCT_TOURS
  public data: boolean

  constructor(data: boolean) {
    this.data = data
  }
}

export class MicrofrontendTrackAction implements MicroAppActionBase {
  public type = MicroAppActionType.TRACK_ACTION
  public data: any

  constructor(data: any) {
    this.data = data
  }
}

export class MicrofrontendTrackAnalytics implements MicroAppActionBase {
  public type = MicroAppActionType.TRACK_ANALYTICS
  public data: AnalyticsAction

  constructor(data: AnalyticsAction) {
    this.data = data
  }
}

export class MicrofrontendChangeDateFormat implements MicroAppActionBase {
  public type = ProtectedMicroAppActionType.CHANGE_DATE_FORMAT
  public data: string

  constructor(data: string) {
    this.data = data
  }
}

export class MicrofrontendChangeNumberFormat implements MicroAppActionBase {
  public type = ProtectedMicroAppActionType.CHANGE_NUMBER_FORMAT
  public data: string

  constructor(data: string) {
    this.data = data
  }
}

export class MicrofrontendChangeDisplayLanguage implements MicroAppActionBase {
  public type = ProtectedMicroAppActionType.CHANGE_DISPLAY_LANGUAGE
  public data: string

  constructor(data: string) {
    this.data = data
  }
}

export class MicrofrontendChangeWorkspace implements MicroAppActionBase {
  public type = ProtectedMicroAppActionType.CHANGE_WORKSPACE
  public data: LegacyWorkspace

  constructor(data: LegacyWorkspace) {
    this.data = data
  }
}

export class MicrofrontendLogout implements MicroAppActionBase {
  public type = ProtectedMicroAppActionType.LOGOUT
}

export class MicrofrontendRunProductTours implements MicroAppActionBase {
  public type = ProtectedMicroAppActionType.RUN_PRODUCT_TOURS
}

export type MicroAppAction =
  | MicrofrontendSetHasProductTours
  | MicrofrontendChangeRoute
  | MicrofrontendResetBreadcrumbsDetails
  | MicrofrontendUpdateHeaderBreadcrumbs
  | MicrofrontendSetHeaderBreadcrumbs
  | MicrofrontendUnauthorized
  | MicrofrontendRunProductToursReminder
  | MicrofrontendHideProductToursReminder
  | MicrofrontendTrackAction
  | MicrofrontendTrackAnalytics
  | MicrofrontendChangeDateFormat
  | MicrofrontendChangeNumberFormat
  | MicrofrontendChangeDisplayLanguage
  | MicrofrontendChangeWorkspace
  | MicrofrontendLogout
  | MicrofrontendRunProductTours
