import { WppIconChevron, WppIconInfo, WppSkeleton, WppTooltip } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useMemo } from 'react'

import { Flex } from 'components/common/flex/Flex'
// import { ProjectHealthCard } from 'pages/playground/playgroundDashboard/components/projectHealthCard/ProjectHealthCard'
import CustomDropdown from 'pages/playground/playgroundDashboard/components/general/dropdown/Dropdown'
import { SectionOauth } from 'pages/playground/playgroundDashboard/components/sections/sectionOauth/SectionOauth'
import styles from 'pages/playground/playgroundDashboard/components/sections/sectionProjectSummary/SectionProjectSummary.module.scss'
import {
  ProjectDto,
  ProjectSummaryCondition,
  ProjectSummaryDto,
} from 'pages/playground/playgroundDashboard/components/sections/sectionProjectSummary/sectionProjectSummaryMockData'
// import { SectionProjectSummaryGraph } from 'pages/playground/playgroundDashboard/components/sections/sectionProjectSummary/graph/Graph'
import stylesMain from 'pages/playground/playgroundDashboard/PlaygroundDashboard.module.scss'

export const SectionProjectSummary = ({
  selectedProjectId,
  projects,
  setSelectedProjectId,
  onHide,
  workfrontError,
  projectSummary,
  projectsLength,
  loading,
}: {
  selectedProjectId: string
  projects?: ProjectDto[]
  setSelectedProjectId: (id: string) => void
  onHide: () => void
  projectSummary: ProjectSummaryDto
  workfrontError: boolean
  projectsLength: number
  loading: boolean
}) => {
  const handleChange = (event: string) => {
    setSelectedProjectId(event || '')
  }

  const ProjectSummaryOptions = useMemo(() => {
    return {
      [ProjectSummaryCondition.ON_TARGET]: {
        color: '#55D04A',
        text: 'On Target',
        tooltip:
          'When the progress status of the last task on the critical path is On Time, the condition of the project will be On Target. The project is on track to finish on schedule.',
      },
      [ProjectSummaryCondition.AT_RISK]: {
        color: '#E59D00',
        text: 'At Risk',
        tooltip:
          "When the progress status of the last task on the critical path is Behind or At Risk, then the condition of the project is At Risk. The project is on track to finish late but isn't late",
      },
      [ProjectSummaryCondition.IN_TROUBLE]: {
        color: '#F40000',
        text: 'In Trouble',
        tooltip:
          'When the progress status of the last task on the critical path is Late, then the condition of the project is In Trouble. The due date is in the past and the project is now late.',
      },
    }
  }, [])

  return (
    <div>
      <Flex className={clsx(stylesMain.black5XL, styles.title)} justify="center" gap={16}>
        Your Project Summary
        <Flex align="center">
          <WppIconChevron direction="up" color="black" width={40} className={stylesMain.pointer} onClick={onHide} />
        </Flex>
      </Flex>

      {loading ? (
        <div>
          <Flex className={clsx(stylesMain.mt82, styles.title)} justify="between">
            <WppSkeleton variant="rectangle" width="30%" height="40px" />
            <WppSkeleton variant="rectangle" width="30%" height="40px" />
          </Flex>
        </div>
      ) : workfrontError ? (
        <div className={clsx(stylesMain.mt40, styles.minH120)}>
          <SectionOauth loading={false} type="link-projects" />
        </div>
      ) : projectsLength === 0 ? (
        <div className={clsx(stylesMain.mt40, styles.minH120)}>
          <SectionOauth loading={false} type="empty-projects" />
        </div>
      ) : (
        <Flex className={clsx(stylesMain.mt82, styles.title)} justify="between">
          <div>
            <Flex align="center" gap={16}>
              <div className={styles.showMe}>SHOW ME:</div>
              <div className={stylesMain.relative}>
                <CustomDropdown
                  value={selectedProjectId}
                  options={projects?.map(el => {
                    return { title: el.name, id: el.ID }
                  })}
                  onWppChange={(event: { id: string }) => handleChange(event.id)}
                />

                {/* <WppSelect
                onWppChange={handleChange}
                placeholder="Placeholder"
                value={selectedProjectId}
                withSearch
                className={styles.select}
              >
                {projects?.map((project, idx) => (
                  <WppListItem key={idx} value={project.ID}>
                    <p slot="label">{project?.name}</p>
                  </WppListItem>
                ))}
              </WppSelect> */}
              </div>
            </Flex>

            <Flex gap={8} className={stylesMain.mt24} align="center">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <circle cx="8" cy="8" r="8" fill={ProjectSummaryOptions[projectSummary.condition].color} />
                </svg>
              </div>
              <div className={stylesMain.blackMMidi}>{ProjectSummaryOptions[projectSummary.condition].text}</div>
              <WppTooltip
                // header={ProjectSummaryOptions[ProjectSummaryCondition.AT_RISK].text}
                text={`<div style="color: white; font-weight: 700;font-size: 16px;font-family: TCCC-UnityText, sans-serif;line-height: 24px;">${ProjectSummaryOptions[projectSummary.condition].text}</div><div style="color: white; font-weight: 400;font-size: 14px;font-family: TCCC-UnityText, sans-serif;line-height: 22px;">${ProjectSummaryOptions[projectSummary.condition].tooltip}</div>`}
                config={{ allowHTML: true }}
              >
                <WppIconInfo color="black" width={20} />
              </WppTooltip>
            </Flex>
          </div>
          <Flex gap={24}>
            <div className={stylesMain.brownLMidi}>{`In phase: ${
              projectSummary?.phase ? projectSummary?.phase.phaseName : 'No data'
            }`}</div>
            {/* <RadioSelect
              selectedIndex={projectSummary?.phase?.phaseNumber > 9 ? 9 : projectSummary?.phase?.phaseNumber}
              length={projectSummary?.phase?.numberOfPhases > 9 ? 9 : projectSummary?.phase?.numberOfPhases}
              disabled
            /> */}
          </Flex>
        </Flex>
      )}
    </div>
  )
}
