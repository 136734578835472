import { Permission } from 'constants/permission'
import { hasAccessToMaximize, NavigationConfigFunctions, UserSectionNavigationName } from 'legacy/navigation/common'
import { LegacyPermission } from 'legacy/navigation/permission'
import { hasPermission } from 'legacy/navigation/utils'
import { AccountPermissions, NavigationSection } from 'legacy/types/osWebRootApi'

export const navigation: NavigationConfigFunctions = {
  dashboard: (permissions: AccountPermissions[]): NavigationSection => [
    {
      title: 'OS_HEADER.HEADER.YOU',
      path: '/you',
    },
    {
      title: 'OS_HEADER.HEADER.NETWORK',
      path: '/network/contacts',
      disabled: !hasPermission(permissions, Permission.NETWORK_APP_ACCESS),
    },
    {
      title: 'OS_HEADER.HEADER.APPS',
      path: '/apps/list',
      disabled: !hasPermission(permissions, Permission.APPS_APP_ACCESS),
    },
  ],
  main: (permissions: AccountPermissions[], countryCode?: string): NavigationSection => [
    {
      title: 'OS_HEADER.NAVIGATION_MAIN.UNLOCK',
      nav: [
        {
          title: 'OS_HEADER.NAVIGATION_MAIN.SCORECARDS',
          nav: [
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.SUMMARY',
              path: '/scorecards/summary',
            },
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.SITE_AND_APP_AUDIT',
              path: '/scorecards/scorecard/00000000-0000-0000-0000-000000000003/intro',
            },
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.PPC_AUDIT',
              path: '/scorecards/scorecard/00000000-0000-0000-0000-000000000001/intro',
            },
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.CONTENT_AUDIT',
              path: '/scorecards/scorecard/00000000-0000-0000-0000-000000000004/intro',
            },
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.SOCIAL_AUDIT',
              path: '/scorecards/scorecard/00000000-0000-0000-0000-000000000002/intro',
            },
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.ECOMMERCE_AUDIT',
              path: '/scorecards/scorecard/00000000-0000-0000-0000-000000000006/intro',
            },
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.AUDIENCE_AUDIT',
              path: '/scorecards/scorecard/00000000-0000-0000-0000-000000000005/intro',
            },
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.PROGRAMMATIC_AUDIT',
              path: '/scorecards/scorecard/00000000-0000-0000-0000-000000000007/intro',
            },
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.VIDEO_AUDIT',
              path: '/scorecards/scorecard/00000000-0000-0000-0000-000000000008/intro',
            },
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.TECHNOLOGY_AUDIT',
              path: '/scorecards/scorecard/00000000-0000-0000-0000-000000000009/intro',
            },
          ],
          // TODO: Uncomment after testing
          // disabled: !hasPermission(permissions, Permission.ScorecardsAccess),
        },
        {
          title: 'OS_HEADER.NAVIGATION_MAIN.OPPORTUNITIES',
          path: '/growth-opportunities',
        },
      ],
    },
    {
      title: 'OS_HEADER.NAVIGATION_MAIN.OPTIMISE',
      nav: [
        {
          title: 'OS_HEADER.NAVIGATION_MAIN.OPTIMISE_TOOLKIT',
          nav: [
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.SINGLE_SOURCE',
              path: '/retail-compass',
            },
          ],
        },
        {
          title: 'OS_HEADER.NAVIGATION_MAIN.AUDIENCES',
          path: '/audiences/map',
          disabled:
            !hasAccessToMaximize(countryCode) || !hasPermission(permissions, LegacyPermission.MxmzAudiencesAppAccess),
        },
        {
          title: 'OS_HEADER.NAVIGATION_MAIN.TOUCHPOINTS',
          path: '/touchpoints/touchpoints',
          disabled:
            !hasAccessToMaximize(countryCode) || !hasPermission(permissions, LegacyPermission.MxmzTouchpointsAppAccess),
        },
        {
          title: 'OS_HEADER.NAVIGATION_MAIN.ARCHITECT_JUNIOR',
          path: '/junior',
          disabled:
            !hasAccessToMaximize(countryCode) || !hasPermission(permissions, LegacyPermission.MxmzJuniorAppAccess),
          hidden:
            !hasAccessToMaximize(countryCode) || !hasPermission(permissions, LegacyPermission.MxmzJuniorAppAccess),
        },
        {
          title: 'OS_HEADER.NAVIGATION_MAIN.CAMPAIGNS_PARENT',
          disabled:
            !hasAccessToMaximize(countryCode) ||
            (!hasPermission(permissions, LegacyPermission.MxmzCampaignsAppAccess) &&
              !hasPermission(permissions, LegacyPermission.MxmzMediaPlansComparisonAppAccess)),
          nav: [
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.CAMPAIGNS',
              path: '/briefs/list',
              disabled: !hasPermission(permissions, LegacyPermission.MxmzCampaignsAppAccess),
            },
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.MP_UPLOADER',
              path: '/custom-campaigns/list',
              hidden: !hasPermission(permissions, LegacyPermission.MxmzMediaPlansUploaderAppAccess),
            },
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.MP_COMPARISON',
              path: '/mp-comparison/list',
              disabled: !hasPermission(permissions, LegacyPermission.MxmzMediaPlansComparisonAppAccess),
            },
          ],
        },
      ],
    },
    {
      title: 'OS_HEADER.NAVIGATION_MAIN.TRANSFORM',
      nav: [
        {
          title: 'OS_HEADER.NAVIGATION_MAIN.IDEAS',
          path: 'https://insidemedia.sharepoint.com/sites/WMGTransform/SitePages/Ideas.aspx',
          external: true,
        },
        {
          title: 'OS_HEADER.NAVIGATION_MAIN.INSIGHTS',
          nav: [
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.TOOLKIT_LABEL',
              path: 'https://insidemedia.sharepoint.com/sites/WMGTransform/SitePages/Insights.aspx',
              external: true,
              disabled: false,
            },
          ],
        },
        {
          title: 'OS_HEADER.NAVIGATION_MAIN.STRATEGY',
          nav: [
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.TOOLKIT_LABEL',
              path: 'https://insidemedia.sharepoint.com/sites/WMGTransform/SitePages/Strategy.aspx',
              external: true,
            },
          ],
        },
        {
          title: 'OS_HEADER.NAVIGATION_MAIN.PRECISION',
          nav: [
            {
              title: 'OS_HEADER.NAVIGATION_MAIN.TOOLKIT_LABEL',
              path: 'https://insidemedia.sharepoint.com/sites/WMGTransform/SitePages/Precision.aspx',
              external: true,
            },
          ],
        },
        {
          title: 'OS_HEADER.NAVIGATION_MAIN.MODELLING',
          path: 'https://insidemedia.sharepoint.com/sites/WMGTransform/SitePages/Modelling.aspx',
          external: true,
          disabled: true,
        },
      ],
    },
  ],
  avatarNavigation: (permissions: AccountPermissions[]): NavigationSection => [
    {
      title: 'OS_HEADER.HEADER.ADMINISTRATION',
      nav: [
        {
          title: 'OS_HEADER.HEADER.APP_SETTING',
          hidden: !hasPermission(permissions, Permission.APPS_MARKET_MANAGE),
          path: '/apps/admin-panel',
        },
        {
          title: 'OS_HEADER.HEADER.MARKET',
          hidden: !hasPermission(permissions, LegacyPermission.OsWorkspaceManage),
          path: '/market-admin/panel',
        },
      ],
    },
    {
      title: 'OS_HEADER.HEADER.PERMISSIONS',
      nav: [
        {
          title: 'OS_HEADER.HEADER.ROLES',
          hidden: !hasPermission(permissions, LegacyPermission.AdminAppAccess),
          path: '/os-admin/panel/roles',
        },
        {
          title: 'OS_HEADER.HEADER.USERS',
          hidden: !hasPermission(permissions, LegacyPermission.AdminAppAccess),
          path: '/os-admin/panel/users',
        },
        {
          title: 'OS_HEADER.HEADER.GROUPS',
          hidden: !hasPermission(permissions, LegacyPermission.AdminAppAccess),
          path: '/os-admin/panel/groups',
        },
      ],
    },
    {
      title: 'OS_HEADER.HEADER.USER',
      nav: [
        {
          title: 'OS_HEADER.HEADER.PROFILE',
          disabled: !hasPermission(permissions, Permission.OS_USERDETAILS_APP_ACCESS),
          path: '/user/profile',
        },
        {
          title: 'OS_HEADER.HEADER.ACCOUNT_SETTINGS',
          path: '/user/settings',
        },
        {
          title: 'OS_HEADER.HEADER.FEEDBACK',
          disabled: true,
          path: 'userFeedback',
        },
        {
          title: 'OS_HEADER.HEADER.LOGOUT',
          path: 'logout',
        },
      ],
    },
  ],
  userSection: (): NavigationSection => [
    {
      title: UserSectionNavigationName.community,
      hidden: false,
      disabled: true,
    },
    {
      title: UserSectionNavigationName.help,
      hidden: false,
      disabled: false,
      nav: [
        {
          title: UserSectionNavigationName.helpTrainingPortal,
          hidden: true,
          disabled: true,
        },
        {
          title: UserSectionNavigationName.helpProductTour,
          hidden: false,
          disabled: false,
        },
      ],
    },
  ],
  userSettings: (permissions: AccountPermissions[]): NavigationSection => [
    {
      title: 'OS_HEADER.HEADER.PROFILE',
      path: '/user/profile',
      disabled: !hasPermission(permissions, Permission.OS_USERDETAILS_APP_ACCESS),
    },
    {
      title: 'OS_HEADER.HEADER.ACCOUNT_SETTINGS',
      path: '/user/settings',
    },
  ],
  networkSection: (): NavigationSection => [
    {
      title: 'OS_HEADER.HEADER.CONTACTS',
      path: 'contacts',
      disabled: false,
    },
  ],
}
