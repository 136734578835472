import { WppIconClose, WppIconCookies } from '@platform-ui-kit/components-library-react'
import { animated, useSpring, config } from '@react-spring/web'
import { useEffect, useState } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/login/customLogin/cookieBanner/CookieBanner.module.scss'
import stylesMain from 'pages/playground/playgroundDashboard/PlaygroundDashboard.module.scss'

const CookieBanner = () => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setVisible(true)
    }, 1000)
  }, [])

  const fadeCookie = useSpring({
    opacity: visible ? 1 : 0,
    bottom: visible ? '30px' : '-100px',
    config: config.slow,
  })

  return (
    <animated.div className={styles.banner} style={fadeCookie}>
      <Flex className={styles.bannerInner} gap={10} justify="center" align="center">
        <Flex justify="center" align="center">
          <WppIconCookies color="white" />
        </Flex>
        <div className={stylesMain.whiteAction}>
          Please be aware, we use cookies and other in-browser tracking mechanisms for analytical purposes to improve
          the experience of this site.
        </div>
        <Flex className={styles.pointer} justify="center" align="center" onClick={() => setVisible(false)}>
          <WppIconClose color="white" />
        </Flex>
      </Flex>
    </animated.div>
  )
}

export default CookieBanner
