import { Theme, ThemeStyles } from '@platform-ui-kit/components-library'
import { Taxonomy } from '@wpp-open/core'
import { createContext, useContext } from 'react'

export interface PublicDataContextValue {
  theme: Theme
  resolvedTheme: ThemeStyles
  currentTaxonomy: Taxonomy
}

export const PublicDataContext = createContext<PublicDataContextValue>(null!)

export const usePublicData = () => useContext(PublicDataContext)
