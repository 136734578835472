import { WppRadio, WppRadioGroup, WppTextareaInput, WppIconCross } from '@platform-ui-kit/components-library-react'
import { useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'
import { useEffect, useState } from 'react'

import { FeedbackHelpfull } from 'api/workfront/fetchers/publishFeedback'
import { usePublishFeedback } from 'api/workfront/mutations/usePublishFeedback'
import { Flex } from 'components/common/flex/Flex'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import BubbleFixed from 'pages/playground/playgroundDashboard/components/general/bubble/Bubble'
import { Button } from 'pages/playground/playgroundDashboard/components/general/button/Button'
import styles from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/assignmentCard/feedback/Feedback.module.scss'
import stylesMain from 'pages/playground/playgroundDashboard/PlaygroundDashboard.module.scss'

interface Props {
  feedbackId: string
  nudgeName?: string
  setModal: (visible: boolean) => void
  open?: boolean
  onStepChanged?: (step: number) => void
}

export const Feedback = ({ feedbackId, setModal, nudgeName, open, onStepChanged }: Props) => {
  const queryClient = useQueryClient()
  const { mutateAsync: publishFeedback } = usePublishFeedback()
  const [step, setStep] = useState(2)
  const [radioGroupValue, setRadioGroupValue] = useState('right_time')
  const [customFeedback, setCustomFeedback] = useState('')
  const [characterLimitReached, setCharacterLimitReached] = useState(false)

  const characterLimit = 400

  useEffect(() => {
    if (open) {
      if (window.Intercom) window.Intercom('update', { hide_default_launcher: true })
    }
    if (!open) {
      if (window.Intercom) window.Intercom('update', { hide_default_launcher: false })
      setRadioGroupValue('right_time')
      setCustomFeedback('')
    }
    setStep(2)
  }, [open])

  useEffect(() => {
    if (customFeedback.length > characterLimit) {
      setCharacterLimitReached(true)
    } else {
      setCharacterLimitReached(false)
    }
  }, [customFeedback])

  useEffect(() => {
    onStepChanged && onStepChanged(step)
    if (step > 2) {
      if (window.Intercom) window.Intercom('update', { hide_default_launcher: false })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])

  const radioButtons = [
    {
      name: 'right_time',
      value: 'Content was not relevant',
    },
    {
      name: 'distracted',
      value: 'Content did not address my issue',
    },
    {
      name: 'complete_task',
      value: 'Content needs additional information',
    },
    {
      name: 'confusing',
      value: 'Content was confusing',
    },
    {
      name: 'other',
      value: 'Other',
    },
  ]

  const handleTextAreaChange = (e: CustomEvent) => {
    const value = e.detail.value
    if (value.length <= characterLimit) {
      setCustomFeedback(value)
    }
    if (value.length > characterLimit) {
      setCustomFeedback(value.substring(0, characterLimit))
    }
  }

  const handleSave = async (helpfull: boolean) => {
    setStep(3)
    try {
      const radioGroupValueString = radioButtons.find(r => r.name === radioGroupValue)?.value

      if (radioGroupValueString) {
        const feedback = helpfull ? '' : radioGroupValue === 'other' ? customFeedback : radioGroupValueString
        await publishFeedback({ feedbackId: feedbackId, helpfull: FeedbackHelpfull.NO, feedback: feedback })
        queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.FEEDBACK] })
        setStep(4)
      }
    } catch (e) {
      console.error(e)
      setStep(4)
    }
  }

  const handleClose = () => {
    if (window.Intercom) window.Intercom('update', { hide_default_launcher: false })
    setModal(false)
  }

  return (
    <div>
      <Flex className={styles.feedbackModalHeader}>
        <span className={styles.headerText}>Feedback for {nudgeName}</span>
        <WppIconCross onClick={() => handleClose()} className={styles.closeIcon} />
      </Flex>
      <Flex direction="row">
        <div className={styles.smileyContainer}>
          <div className={styles.svgCircle}>
            <svg xmlns="http://www.w3.org/2000/svg" width="123" height="122" viewBox="0 0 123 122" fill="none">
              <circle cx="61.75" cy="61" r="61" fill="#EBFF00" />
            </svg>
          </div>
          <img
            src={require('pages/playground/playgroundDashboard/components/sections/sectionAssignments/assignmentCard/assets/sad_smiley.gif')}
            width="120"
            height="120"
            className={styles.smileyGif}
          />
        </div>
        <div className={styles.container}>
          <Flex direction="column" align="center" justify="center" gap={40}>
            <div className={clsx(styles.title, stylesMain.preWrap, stylesMain['text-center'])}>
              We’re sorry the learning content did meet your needs. Your feedback helps us improve so our next
              suggestion is more helpful.
              <BubbleFixed className={styles.bubble} direction="up" text="Don't hold back" />
            </div>
            <WppRadioGroup value={radioGroupValue} onWppChange={e => setRadioGroupValue(String(e.detail.value))}>
              <Flex direction="column" gap={16}>
                {radioButtons.map((radioButton, index) => (
                  <div key={index} className={styles.radioOption}>
                    <WppRadio
                      name={radioButton.name}
                      value={radioButton.name}
                      labelConfig={{ text: radioButton.value }}
                      required
                      className={clsx(radioGroupValue === radioButton.name && styles.radio)}
                    />
                  </div>
                ))}
              </Flex>
            </WppRadioGroup>

            {radioGroupValue === 'other' && (
              <Flex direction="column">
                <WppTextareaInput
                  name="description"
                  required
                  placeholder="Tell us how you really feel, (seriously)."
                  maxMessageLength={400}
                  className={styles.textarea}
                  onWppChange={handleTextAreaChange}
                  value={customFeedback}
                  charactersLimit={characterLimit}
                  warningThreshold={characterLimit - 50}
                  onKeyDown={e => {
                    if (characterLimit - customFeedback.length <= 0 && e.key !== 'Backspace') {
                      e.preventDefault()
                    }
                  }}
                />
              </Flex>
            )}
          </Flex>
        </div>
      </Flex>
      <Flex className={styles.feedbackModalFooter} justify="end">
        <div
          style={{
            opacity: characterLimitReached ? 0.9 : 1,
          }}
        >
          <Button
            disabled={
              (characterLimitReached && radioGroupValue === 'other') ||
              (customFeedback.length === 0 && radioGroupValue === 'other')
            }
            text="Save"
            variant="primary"
            small
            onClick={() => {
              if (
                (radioGroupValue === 'other' && customFeedback.length === 0) ||
                (radioGroupValue !== 'other' && characterLimitReached)
              ) {
                return
              }
              handleSave(false)
            }}
          />
        </div>
      </Flex>
    </div>
  )
}
