import { useInfiniteQuery, UseInfiniteQueryOptions, InfiniteData } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useMemo } from 'react'

import { QueryFetcher, QueryFetcherParams, QueryFetcherResponse } from 'api/common/types'

export function createUseInfiniteQuery<
  F extends QueryFetcher,
  S,
  P = QueryFetcherParams<F>,
  R = QueryFetcherResponse<F>,
>({
  queryKey,
  fetcher,
  selector,
  getNextPageParam,
}: {
  queryKey: string
  fetcher: F
  selector: (data?: InfiniteData<R>) => S
  getNextPageParam: (lastPage: R, allPages: R[]) => Partial<QueryFetcherParams<F>> | undefined
}) {
  return function useCustomInfiniteQuery(
    options: {
      params?: P
      // Required
      // https://tanstack.com/query/latest/docs/react/guides/migrating-to-v5#infinite-queries-now-need-a-initialpageparam
      initialPageParam: Partial<P>
    } & Omit<
      UseInfiniteQueryOptions<R, AxiosError>,
      | 'queryKey'
      | 'queryFn'
      | 'queryHash'
      | 'queryKeyHashFn'
      | 'select'
      | 'getPreviousPageParam'
      | 'getNextPageParam'
      | 'initialPageParam'
    >,
  ) {
    const { params = {}, enabled = true, initialPageParam, ...queryOptions } = options

    const { data, isPending, ...rest } = useInfiniteQuery<R, AxiosError>({
      queryKey: [queryKey, params],
      queryFn: ({ signal, pageParam }) => fetcher({ ...params, ...(pageParam as {}) })(signal) as R,
      initialPageParam,
      enabled,
      getNextPageParam,
      ...queryOptions,
    })

    return {
      ...rest,
      isLoading: enabled && isPending,
      data: useMemo(() => selector(data), [data]),
      response: data,
    }
  }
}
