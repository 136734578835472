import { useMemo } from 'react'

import { useFetchProjectsCount } from 'api/workfront/queries/matchAndThrive/useFetchProjectsCount'

export const useProjectsCount = () => {
  const { data: projectsCountAPI, isLoading: isLoadingProjectsCount } = useFetchProjectsCount()
  return useMemo(
    () => ({ total: projectsCountAPI?.total || 0, projectsCountAPI, isLoadingProjectsCount }),
    [projectsCountAPI, isLoadingProjectsCount],
  )
}
