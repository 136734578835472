import { MayBeNull, OpenMenuHandler } from '@wpp-open/core'
import { createContext, PropsWithChildren, useContext } from 'react'

import { useMiroConfigStatusApi } from 'api/miro/queries/useMiroConfigStatusApi'
import { useAppDataFromUrl } from 'providers/osState/utils/useAppDataFromUrl'
import { useNavigationState } from 'providers/osState/utils/useNavigationState'
import { useResolveAppData } from 'providers/osState/utils/useResolveAppData'
import { MiroConfigStatusResponse } from 'types/miro'
import { AppDataFromUrl } from 'types/osState/appDataFromUrl'
import { AppData } from 'types/osState/appDataResolved'

export interface OsStateContextValue {
  isNavigationOpen: boolean
  preselectedHierarchyNodeId: MayBeNull<string>
  appDataDebug: AppDataFromUrl // This is logged to console in case of invalid app launch
  appData: AppData
  openNavigation: OpenMenuHandler
  closeNavigation: () => void
  miroConfigStatus: MayBeNull<MiroConfigStatusResponse>
}

export const OsStateContext = createContext<OsStateContextValue>(null!)

export const useOsState = () => useContext(OsStateContext)

export const OsStateProvider = ({ children }: PropsWithChildren<{}>) => {
  const { isNavigationOpen, preselectedHierarchyNodeId, openNavigation, closeNavigation } = useNavigationState()
  const appDataShort = useAppDataFromUrl()

  const { data: miroConfigStatus } = useMiroConfigStatusApi()

  const { appData, sideEffectNode } = useResolveAppData({ appDataShort })

  return (
    <OsStateContext.Provider
      value={{
        isNavigationOpen,
        preselectedHierarchyNodeId,
        appDataDebug: appDataShort,
        appData,
        openNavigation,
        closeNavigation,
        miroConfigStatus,
      }}
    >
      {sideEffectNode}

      {children}
    </OsStateContext.Provider>
  )
}
