import { useEffect } from 'react'

import { MICRO_APP_DOM_ELEMENT_ID } from 'constants/apps'
import { useAppsContext } from 'providers/apps/AppsContext'
import { NativeMicroAppData } from 'types/osState/appDataResolved'
import { getActiveWhen } from 'utils/apps'
import { registerMicroApp } from 'utils/singleSpa'

interface Props {
  appData: NativeMicroAppData
}

export const FullscreenMicroApp = ({ appData }: Props) => {
  const { getFullscreenAppCustomProps, setIsFullscreenMicroAppActive } = useAppsContext()

  const { app } = appData

  useEffect(() => {
    const { stableId, libraryType, bundleUrl, windowLibraryName } = app

    registerMicroApp({
      stableId,
      libraryType,
      windowLibraryName,
      bundleUrl,
      domElementId: MICRO_APP_DOM_ELEMENT_ID,
      activeWhen: getActiveWhen(app),
      customProps: getFullscreenAppCustomProps({ stableId }),
    })
  }, [app, getFullscreenAppCustomProps])

  useEffect(() => {
    setIsFullscreenMicroAppActive(true)

    return () => setIsFullscreenMicroAppActive(false)
  }, [setIsFullscreenMicroAppActive])

  return null
}
