import { MayBeNull } from '@wpp-open/core'
import { ReactNode } from 'react'

import { getInvalidResolvedAppData } from 'providers/osState/utils/resolvers/common'
import { useAppInstanceAppDataResolver } from 'providers/osState/utils/resolvers/useAppInstanceAppDataResolver'
import { useLocalAppDataResolver } from 'providers/osState/utils/resolvers/useLocalAppDataResolver'
import { useSystemAppDataResolver } from 'providers/osState/utils/resolvers/useSystemAppDataResolver'
import { AppDataFromUrl, AppDataFromUrlType } from 'types/osState/appDataFromUrl'
import { AppData } from 'types/osState/appDataResolved'

export interface ResolvedAppData {
  appData: AppData
  sideEffectNode: MayBeNull<ReactNode>
}

/**
 * Resolving AppData from url involves a lot of conditional logic.
 * This conditional logic also includes necessary data-fetching hooks and occasional useMemo().
 * The default approach is to split this logic into different components and render them conditionally.
 *
 * But due to React's reconciliation strategy, top-level providers should render a stable React Tree.
 * Otherwise, all child components will be remounted on every render.
 *
 * This means that all conditional logic has to sit in-between the hooks that should always be called.
 */
export const useResolveAppData = ({ appDataShort }: { appDataShort: AppDataFromUrl }): ResolvedAppData => {
  const { type } = appDataShort

  const invalidAppData = getInvalidResolvedAppData()

  const isLocalApp = type === AppDataFromUrlType.LocalApp || type === AppDataFromUrlType.LocalLegacyApp
  const localAppData = useLocalAppDataResolver(isLocalApp ? { appDataShort } : null)

  const isSystemApp = type === AppDataFromUrlType.SystemApp
  const systemAppData = useSystemAppDataResolver(isSystemApp ? { appDataShort } : null)

  const isAppInstance = type === AppDataFromUrlType.AppInstance
  const appInstanceAppData = useAppInstanceAppDataResolver(isAppInstance ? { appDataShort } : null)

  /**
   * Important:
   * Every hook should return a value if it received non-empty params or null otherwise.
   */
  return localAppData || systemAppData || appInstanceAppData || invalidAppData
}
