// Used in development only
export const GENERIC_TENANT_DEV_ROUTE = 'dev/generic'

// Application instance is always set with this prefix `/{prefix}/:appInstanceId/{app-route}`
export const APPLICATION_URL_PREFIX = 'application'

// Workspace is always set with this prefix `/{prefix}/:workspaceId/{app-route}`
// Note: Used for local routes only
export const WORKSPACE_URL_PREFIX = 'w'

// Url part that matches local app library type
export enum LocalMicroAppLibraryType {
  Window = 'window',
  SystemJS = 'sjs',
  ESM = 'esm',
}
