import { facadeApi } from 'api'
import { PageSize } from 'constants/pageSize'
import { UserAssignedRoles } from 'types/users/users'

export const fetchUserAssignedRolesApi = ({ id, email }: { id: string; email: string }) =>
  facadeApi.post<UserAssignedRoles[]>('/az/users/assigned-roles/search', {
    offset: 0,
    limit: PageSize.All,
    user_ids: [id],
    user_emails: [email],
  })
