import { RenderErrorTrigger } from 'components/renderError/RenderErrorTrigger'
import { RenderErrorType } from 'components/renderError/utils'

export const OsIsNotAvailableError = () => <RenderErrorTrigger type={RenderErrorType.OsIsNotAvailable} />
export const DataIsNotAvailableError = () => <RenderErrorTrigger type={RenderErrorType.DataIsNotAvailable} />
export const ForbiddenPageError = () => <RenderErrorTrigger type={RenderErrorType.ForbiddenPage} />
export const ForbiddenOSAccessError = () => <RenderErrorTrigger type={RenderErrorType.ForbiddenOSAccess} />
export const NotFoundError = () => <RenderErrorTrigger type={RenderErrorType.NotFound} />
export const CriticalError = () => <RenderErrorTrigger type={RenderErrorType.CriticalError} />
export const AppDisabledError = () => <RenderErrorTrigger type={RenderErrorType.AppDisabled} />
export const ForbiddenHubAccessError = () => <RenderErrorTrigger type={RenderErrorType.ForbiddenHubAccess} />

export { RootRenderErrorBoundary } from 'components/renderError/boundary/RootRenderErrorBoundary'
export { RenderErrorBoundary } from 'components/renderError/boundary/RenderErrorBoundary'
export { RouteRenderErrorBoundary } from 'components/renderError/boundary/RouteRenderErrorBoundary'
export { RenderErrorBoundaryHeight } from 'components/renderError/boundary/renderErrorBoundaryLayout/utils'
export { RenderErrorType }
