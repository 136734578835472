import { facadeApi } from 'api'
import { omitParams } from 'api/common/utils'
import { environment } from 'environment'

export interface FetchFilesParams {
  recursive?: boolean
  name?: string
  parentId?: string
  assignmentId?: string
  assignmentType?: string
  createdBy?: string
  fileTypes?: string[]
  statusesInclude?: string[]
  statusesExclude?: string[]
  type?: string
  category?: string
  updatedAt?: {}
  createdAt?: {}
  isFavorite?: boolean
  tagIds?: string[]
  blinkLink?: string
  isPinned?: boolean
  isPublic?: boolean
  allowInAiAssistant?: boolean
  sortField?: string
  sortDirection?: string
  defaultSorting?: boolean
  jwt?: string
}
export interface FetchFilesResponse {
  id: string
  name: string
  accountId: string
  type: string
  status: string
  description: string
  metadata: {
    name: string
    category: string
    type: string
    size: number
  }
  assignmentId: string
  assignmentType: string
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
  parentId: string
  isPinned: boolean
  isPublic: boolean
  allowInAiAssistant: boolean
  tags: {
    id: string
    name: string
  }[]
  isFavorite: boolean
  blinkLink: string
}

export const fetchFiles = (params: FetchFilesParams) =>
  facadeApi.post<{ data: FetchFilesResponse[] }>(
    '/file-manager/v1/files/search',
    {
      ...omitParams(params, ['jwt']),
    },
    {
      headers: {
        'X-Tenant-Id': environment.PLAYGROUND_TENANT_ID,
        Authorization: `Bearer ${params.jwt}`,
      },
    },
  )
