import {
  HierarchyContainerNodeId,
  NavigationTree,
  ProjectsContainerNodeId,
  Tenant,
  TenantConfigNavigationType,
} from '@wpp-open/core'
import { matchPath } from 'react-router-dom'

import { systemApps } from 'constants/apps'
import { SystemAppCode } from 'types/apps/microApps'
import { AppData, AppDataType } from 'types/osState/appDataResolved'
import { getNavigationTypeFlags } from 'utils/navigation'

export const getActiveNodeIdFromAppState = ({
  pathname,
  currentTenant,
  navigationTree,
  appData,
}: {
  pathname: string
  currentTenant: Tenant
  navigationTree: NavigationTree
  appData: AppData
}) => {
  const { type, app, activeWorkspaceId, appInstance, project } = appData
  const { rootId, mapping, projectsMapping } = navigationTree

  const hierarchyFlags = getNavigationTypeFlags({
    type: TenantConfigNavigationType.Hierarchy,
    currentTenant,
  })

  const projectsFlags = getNavigationTypeFlags({
    type: TenantConfigNavigationType.Projects,
    currentTenant,
  })

  const customFlags = getNavigationTypeFlags({
    type: TenantConfigNavigationType.Custom,
    currentTenant,
  })

  // Match hardcoded Orchestration routes to projects module
  if (type === AppDataType.SystemMicroApp && app.stableId === SystemAppCode.Orchestration && projectsFlags.enabled) {
    const orchestrationOsRoute = systemApps[SystemAppCode.Orchestration].osRoute
    const matchProjectsList = matchPath(`/${orchestrationOsRoute}/projects/*`, pathname)
    const matchProject = matchPath(`/${orchestrationOsRoute}/project/:projectId/*`, pathname)
    const projectId = matchProject?.params.projectId

    if (projectId && projectsMapping[projectId]) {
      return projectId
    } else if ((projectId || matchProjectsList) && mapping[ProjectsContainerNodeId]) {
      return ProjectsContainerNodeId
    }
  }

  // App with active hierarchy context
  if (activeWorkspaceId && !project) {
    return activeWorkspaceId
  }

  // App within a custom menu group
  if (appInstance?.group && !project) {
    return rootId
  }

  // If only Hierarchy navigation enabled
  if (hierarchyFlags.enabled && !projectsFlags.enabled && !customFlags.enabled) {
    return HierarchyContainerNodeId
  }

  // If only projects navigation enabled
  if (projectsFlags.enabled && !hierarchyFlags.enabled && !customFlags.enabled) {
    return ProjectsContainerNodeId
  }

  // If several navigation types enabled - choose one that is set to be opened by default
  if (hierarchyFlags.enabled && hierarchyFlags.expanded) {
    return HierarchyContainerNodeId
  }

  if (projectsFlags.enabled && projectsFlags.expanded) {
    return ProjectsContainerNodeId
  }

  return rootId
}
