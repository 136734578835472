import { WppTag } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ComponentPropsWithoutRef, forwardRef } from 'react'
import { useTranslation } from 'react-i18next'

import styles from 'components/common/meTag/MeTag.module.scss'

type Props = Omit<ComponentPropsWithoutRef<typeof WppTag>, 'variant' | 'label'>

export const MeTag = forwardRef<HTMLWppTagElement, Props>(function MeTag({ className, ...rest }, ref) {
  const { t } = useTranslation()

  return (
    <WppTag
      ref={ref}
      label={t('os.common.me')}
      variant="neutral"
      className={clsx([styles.root, className])}
      {...rest}
    />
  )
})
