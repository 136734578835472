import { WppIconChevron, WppListItem, WppSelect } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/playground/playgroundDashboard/components/general/pagination/Pagination.module.scss'
import stylesMain from 'pages/playground/playgroundDashboard/PlaygroundDashboard.module.scss'

export const DEFAULT_ITEMS_PER_PAGE = 6

interface Props {
  page: number
  allPages: number
  itemsPerPage: number
  allItems: number
  onSelectPage: (page: number) => void
  onSelectItemsPerPage: (number: number) => void
}

export const Pagination = ({ page, allPages, itemsPerPage, allItems, onSelectPage, onSelectItemsPerPage }: Props) => {
  return (
    <div style={{ paddingLeft: 14, paddingRight: 14, marginTop: 24, width: '100%' }}>
      <Flex align="center" justify="between">
        <Flex gap={6} align="center">
          <div style={{ color: '#4C4C4C' }} className={stylesMain.SBody}>
            Items per page:
          </div>

          {/* <PaginationDropdown options={[6, 8, 10, 12]} value={itemsPerPage} onWppChange={onSelectItemsPerPage} /> */}

          <WppSelect
            type="text"
            dropdownWidth="auto"
            dropdownConfig={{ interactive: true }}
            size="s"
            onWppChange={e => {
              onSelectItemsPerPage(e.detail.value)
            }}
            value={itemsPerPage}
          >
            <WppListItem value={6}>
              <p slot="label">6</p>
            </WppListItem>
            <WppListItem value={8}>
              <p slot="label">8</p>
            </WppListItem>
            <WppListItem value={10}>
              <p slot="label">10</p>
            </WppListItem>
            <WppListItem value={12}>
              <p slot="label">12</p>
            </WppListItem>
          </WppSelect>

          <div className={styles.separator} />
          <div
            style={{ color: '#4C4C4C', marginLeft: 16 }}
            className={stylesMain.SBody}
          >{`${(page - 1) * itemsPerPage + 1}-${page * itemsPerPage} of ${allItems} items`}</div>
        </Flex>
        <Flex align="center" gap={8}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => (page !== 1 ? onSelectPage(page - 1) : {})}
          >
            <WppIconChevron color="#A5A5A5" direction="left" />
          </div>
          <div className={clsx(styles.currentPage, stylesMain.blackPMobile)}>{page}</div>
          <div className={styles.separator} />
          <div className={clsx(styles.allPages, stylesMain.blackPMobile)}>{allPages}</div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
            }}
            onClick={() => (page !== allPages ? onSelectPage(page + 1) : {})}
          >
            <WppIconChevron color="#8B919A" direction="right" />
          </div>
        </Flex>
      </Flex>
    </div>
  )
}
