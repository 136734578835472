import { useEffect, useState } from 'react'

import { LoopModal } from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/assignmentCard/components/phaseModal/LoopModal'

const PhaseRemapping = (phase: string) => {
  return {
    '0 - Prioritize': 'prioritize',
    '1 - Assemble': 'assemble',
    '2 - Explore': 'explore',
    '3 - Create': 'create',
    '4 - Shape': 'shape',
    '5 - Produce': 'produce',
    '6 - Learn': 'learn',
    'Stage 0: Prioritize': 'idea',
    'Stage 1: Strategy to Idea': 'concept',
    'Stage 2: Develop Concept & Business Case': 'solution',
    'Stage 3: Solution Development': 'launch',
    'Stage 4: Launch Preparation': 'market',
    'Stage 5: Market Execution': 'simplify',
  }[phase]
}

export interface PhaseModalProps {
  open?: boolean
  onClose?: () => void
  onOpen?: () => void
  phase: keyof typeof PhaseRemapping | string
}

export const PhaseModal = (props: PhaseModalProps) => {
  const { open = false, onClose = () => {}, onOpen = () => {}, phase } = props

  const [isOpen, setIsOpen] = useState(open)
  const [loopPhase, setLoopPhase] = useState<string | ''>(PhaseRemapping(phase) ?? '')

  useEffect(() => {
    setIsOpen(open)
    if (open) {
      onOpen()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useEffect(() => {
    setLoopPhase(PhaseRemapping(phase) ?? '')
  }, [phase])

  const getAppVariant = (phase: string): 'experience-loop' | 'innovation-process' => {
    switch (phase) {
      case 'prioritize':
      case 'assemble':
      case 'explore':
      case 'create':
      case 'shape':
      case 'produce':
      case 'learn':
        return 'experience-loop'
      case 'idea':
      case 'concept':
      case 'solution':
      case 'launch':
      case 'market':
      case 'simplify':
        return 'innovation-process'
      default:
        return 'experience-loop'
    }
  }

  const handleModalClose = () => {
    onClose()
    setIsOpen(false)
  }

  return <LoopModal variant={getAppVariant(loopPhase)} open={isOpen} loopPhase={loopPhase} onClose={handleModalClose} />
}
