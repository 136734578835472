import { NoCodeAppType } from '@wpp-open/core'

import { FullscreenDefaultNoCodeAppRenderer } from 'components/apps/fullscreen/noCodeApp/defaultNoCodeAppRenderer/FullscreenDefaultNoCodeAppRenderer'
import styles from 'components/apps/fullscreen/noCodeApp/FullscreenNoCodeApp.module.scss'
import { FullscreenMiroBoardAppRenderer } from 'components/apps/fullscreen/noCodeApp/miroBoardAppRenderer/FullscreenMiroBoardAppRenderer'
import { RenderableNoCodeApp } from 'types/apps/noCodeApps'

interface Props {
  app: RenderableNoCodeApp
}

export const FullscreenNoCodeApp = ({ app }: Props) => (
  <div className={styles.root}>
    {app.type === NoCodeAppType.MiroBoard ? (
      <FullscreenMiroBoardAppRenderer app={app} />
    ) : (
      <FullscreenDefaultNoCodeAppRenderer app={app} />
    )}
  </div>
)
