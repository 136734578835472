import { ReactGridLayoutProps } from 'react-grid-layout'

type CompactType = Exclude<ReactGridLayoutProps['compactType'], undefined>

export const DASHBOARD_COLS_COUNT = 24
export const UI_COLS_COUNT = 6
export const COMPACT_TYPE: CompactType = 'vertical'
export const DASHBOARD_ROW_HEIGHT = 200
export const DASHBOARD_GRID_GAP = 28
export const UI_TO_DASHBOARD_COLS_RATIO = UI_COLS_COUNT / DASHBOARD_COLS_COUNT
