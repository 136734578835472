import { WppTypography } from '@platform-ui-kit/components-library-react'
import { Trans, useTranslation } from 'react-i18next'

import styles from 'components/apps/fullscreen/noCodeApp/miroBoardAppRenderer/FullscreenMiroBoardAppRenderer.module.scss'
import { Flex } from 'components/common/flex/Flex'
import { SvgForbidden } from 'components/svg/renderError/SvgForbidden'
import { mailto } from 'utils/links'

export const MiroAdminIssuesState = ({ email = '' }: { email?: string }) => {
  const { t } = useTranslation()

  return (
    <Flex
      justify="center"
      align="center"
      direction="column"
      className={styles.fullHeight}
      gap={20}
      data-testid="miro-issue-page"
    >
      <SvgForbidden />
      <WppTypography className={styles.title} type="xl-heading" data-testid="warning-title">
        {t('os.navigation_menu.miro_admin_not_authorized.title')}
      </WppTypography>
      <WppTypography type="s-body" className={styles.issueDescription} data-testid="warning-description">
        <Trans
          i18nKey="os.navigation_menu.miro_admin_not_authorized.description"
          values={{
            email,
          }}
          components={[
            <a key="0" href={mailto(email)} className={styles.email}>
              email
            </a>,
          ]}
        />
      </WppTypography>
    </Flex>
  )
}
