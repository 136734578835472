import { Navigate } from 'react-router-dom'

// import { useHomeUrl } from 'hooks/useHomeUrl'
import { routesManager } from 'utils/routesManager'

export const NavigateToHome = () => {
  // const homeUrl = useHomeUrl()

  // TODO: This is a temporary solution for Playground homepage. It should be changed to homeUrl once home page MICRO FE integration is enabled.
  return <Navigate replace to={routesManager.home.root.routePath} />
}
