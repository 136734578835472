import qs from 'qs'

import { facadeApi } from 'api'
import { PaginatedResponse } from 'api/common/types'
import { AccessRequestStatus } from 'pages/admin/requests/utils'
import { HubAccessRequest } from 'types/requests/requests'

export type HubAccessRequestsListResponse = PaginatedResponse<HubAccessRequest>

export enum HubAccessRequestStatus {
  NEW = 'new',
  REJECTED = 'rejected',
  APPROVED = 'approved',
}

interface Params {
  search?: string
  status: AccessRequestStatus[]
  sort: string
  itemsPerPage: number
  page: number
}

export const fetchHubAccessRequestsListApi = ({ status, search, sort, itemsPerPage, page }: Params) =>
  facadeApi.get<HubAccessRequestsListResponse>('/hub-access-requests', {
    params: {
      page,
      itemsPerPage,
      sort,
      filter: {
        status,
        ...(!!search && { search }),
      },
    },
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
  })
