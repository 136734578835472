import { LegacyTheme } from 'legacy/styles/osTheming/models'
import { LegacyTenantCode } from 'legacy/types/osWebRootApi'

export const essenceTheme: LegacyTheme = {
  name: 'Essence',
  code: LegacyTenantCode.ESSENCE,

  colors: {
    brand: {
      primary: {
        background: '#FFF6F4',
        lighter: '#FBDDD8',
        light: '#F06C58',
        normal: '#EA533C',
        dark: '#CA3E29',
        darker: '#A62C19',
      },
      secondary: {
        background: '#F9F6F8',
        lighter: '#B6C3C6',
        light: '#9F9F9B',
        normal: '#74807F',
        dark: '#73736F',
        darker: '#1F3634',
      },
    },
    system: {
      information: {
        background: '#F2FAFD',
        lighter: '#31A5D2',
        light: '#008EC3',
        normal: '#007EB0',
        dark: '#006D9A',
        darker: '#005A82',
      },
      error: {
        background: '#FBF3F4',
        lighter: '#FBD8D7',
        light: '#C87070',
        normal: '#EA3A37',
        dark: '#B1423E',
        darker: '#A42D26',
      },
      warning: {
        background: '#FEFBF5',
        lighter: '#FDF1E1',
        light: '#E1A450',
        normal: '#EBB463',
        dark: '#AC6F2F',
        darker: '#8F5622',
      },
      success: {
        background: '#F4F9F7',
        lighter: '#DCE6DF',
        light: '#4E9979',
        normal: '#4F8260',
        dark: '#22714F',
        darker: '#175D3F',
      },
    },
    grey: {
      white: '#FFFFFF',
      50: '#F4F4F2',
      100: '#E8E8E7',
      200: '#D6D6D4',
      300: '#E6E6E2',
      400: '#B1B1AE',
      500: '#9F9F9B',
      600: '#898985',
      700: '#73736F',
      800: '#757571',
      900: '#464644',
      1000: '#131415',
      black: '#000000',
      'white-rgb': '255, 255, 255',
      'black-rgb': '0, 0, 0',
    },
    special: {
      background: '#F4F4F2',
      overlay: '#B1B1AE',
      contrastBackground: '#131415',
      contrastPrimaryLight: '#FBDDD8',
      contrastPrimary: '#EA533C',
      contrastPrimaryDark: '#CA3E29',
    },
    text: {
      text: '#464644',
      textMuted: '#73736F',
      textLink: '#CA3E29',
      contrastText: '#FFFFFFE5',
      contrastTextMuted: '#FFFFFFBF',
      contrastTextLink: '#F68675',
      textInfo: '#007EB0',
      textError: '#B1423E',
      textWarning: '#8F5622',
      textSuccess: '#2E845F',
    },
    dataVisualisation: {
      sequential: {
        S0: {
          100: '#E8E8E7',
          200: '#D6D6D4',
          300: '#C4C4C1',
          400: '#B1B1AE',
          500: '#9F9F9B',
          600: '#898985',
        },
        S1: {
          100: '#C2E6F5',
          200: '#91D1EC',
          300: '#61BCE0',
          400: '#31A5D2',
          500: '#008EC3',
          600: '#007EB0',
        },
        S2: {
          100: '#F2D8DA',
          200: '#E8BDC0',
          300: '#DEA3A6',
          400: '#D3898B',
          500: '#C87070',
          600: '#BD5857',
        },
        S3: {
          100: '#FBEAD2',
          200: '#F7D9AF',
          300: '#F1C78E',
          400: '#EAB66E',
          500: '#E1A450',
          600: '#C88A3F',
        },
        S4: {
          100: '#D2E7DE',
          200: '#B0D5C5',
          300: '#8FC1AB',
          400: '#6EAD92',
          500: '#4E9979',
          600: '#2E845F',
        },
        S5: {
          100: '#FED8D3',
          200: '#FDBCB2',
          300: '#FAA193',
          400: '#F68675',
          500: '#F06C58',
          600: '#EA533C',
        },
        S6: {
          100: '#E8E8E7',
          200: '#D6D6D4',
          300: '#C4C4C1',
          400: '#B1B1AE',
          500: '#9F9F9B',
          600: '#898985',
        },
      },
      comparative: {
        C0: '#9F9F9B',
        C1: '#008EC3',
        C2: '#C87070',
        C3: '#E1A450',
        C4: '#4E9979',
        C5: '#32ADFF',
        C6: '#F06C58',
      },
      diverging: {
        D0: '#EA533C',
        D1: '#898985',
      },
    },
  },

  typography: {
    font: 'Montserrat',
    sizes: {
      '5xl': {
        size: '36px',
        height: '40px',
      },
      '4xl': {
        size: '30px',
        height: '36px',
      },
      '3xl': {
        size: '24px',
        height: '32px',
      },
      '2xl': {
        size: '20px',
        height: '28px',
      },
      xl: {
        size: '18px',
        height: '28px',
      },
      l: {
        size: '16px',
        height: '24px',
      },
      m: {
        size: '14px',
        height: '20px',
      },
      s: {
        size: '12px',
        height: '16px',
      },
      xs: {
        size: '10px',
        height: '16px',
      },
    },
    weights: {
      bold: 600,
      medium: 500,
      regular: 400,
    },
    boxShadows: {
      '3xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
      '2xl': '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
      xl: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
      l: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
      m: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
      s: '0 1px 2px 0 rgba(0, 0, 0, 0.05)',
      inner: 'inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)',
    },
    borderRadius: {
      card: '3px',
      dropdown: '3px',
      popup: '5px',
      button: '3px',
      input: '3px',
    },
  },
}
