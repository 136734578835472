import { matchAndThriveApi } from 'api'
import {
  AssignmentStatus,
  AssignmentTaskStatus,
} from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/sectionAssignmentsMockData'

export interface AssignmentsResponse {
  records: MTAssignmentResponse[]
  count: number
  total: number
}

export enum AssignmentActionTypeEnum {
  NEW_TAB = 'NEW_TAB',
  NEW_WINDOW = 'NEW_WINDOW',
  MODAL = 'MODAL',
  NAVIGATE = 'NAVIGATE',
  ACTION = 'ACTION',
}

export interface MTAssignment {
  id: string
  name: string
  taskStatus: AssignmentTaskStatus
  status: AssignmentStatus
  dueDate: string
  actions: {
    name: string
    type: AssignmentActionTypeEnum
    url: string
  }[]
}

export interface MTAssignmentResponse {
  id: string
  assignmentBase?: {
    name: string
  }
  name?: string
  taskStatus: AssignmentTaskStatus
  status: AssignmentStatus
  dueDate: string
  actions: {
    name: string
    type: AssignmentActionTypeEnum
    url: string
  }[]
}

export interface Props {
  page: number
  limit: number
  types?: AssignmentTaskStatus[]
}

export const fetchAssignments = ({ page, limit }: Props) =>
  matchAndThriveApi.get<AssignmentsResponse>('/assignments', {
    params: {
      page: page,
      limit: limit,
    },
  })

export function convertMTAssignmentResponseArrayToMTAssignmentArray(
  responseArray: MTAssignmentResponse[],
): MTAssignment[] {
  return responseArray.map(response => ({
    id: response.id,
    name: response?.assignmentBase?.name || response.name || '',
    taskStatus: response.taskStatus,
    status: response.status,
    dueDate: response.dueDate,
    actions: response.actions,
    projectName: response.name || '',
  }))
}
