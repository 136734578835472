import styles from 'components/svg/common.module.scss'

export const SvgNoRecords = (props: JSX.IntrinsicElements['svg']) => (
  <svg width="150" height="161" viewBox="0 0 150 161" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M75 150C116.421 150 150 116.421 150 75C150 33.5786 116.421 0 75 0C33.5786 0 0 33.5786 0 75C0 116.421 33.5786 150 75 150Z"
      className={styles.fillPrimary100}
    />
    <g filter="url(#filter0_d_245_11675)">
      <path
        d="M118 43H32C29.2386 43 27 45.2386 27 48V153C27 155.761 29.2386 158 32 158H118C120.761 158 123 155.761 123 153V48C123 45.2386 120.761 43 118 43Z"
        className={styles.fillWhite}
      />
    </g>
    <path
      d="M65 58H39C37.3431 58 36 59.3431 36 61C36 62.6569 37.3431 64 39 64H65C66.6569 64 68 62.6569 68 61C68 59.3431 66.6569 58 65 58Z"
      className={styles.fillPrimary300}
    />
    <path
      d="M83 71H39C37.3431 71 36 72.3431 36 74C36 75.6569 37.3431 77 39 77H83C84.6569 77 86 75.6569 86 74C86 72.3431 84.6569 71 83 71Z"
      className={styles.fillPrimary100}
    />
    <path
      d="M65 85H39C37.3431 85 36 86.3431 36 88C36 89.6569 37.3431 91 39 91H65C66.6569 91 68 89.6569 68 88C68 86.3431 66.6569 85 65 85Z"
      className={styles.fillPrimary300}
    />
    <path
      d="M83 98H39C37.3431 98 36 99.3431 36 101C36 102.657 37.3431 104 39 104H83C84.6569 104 86 102.657 86 101C86 99.3431 84.6569 98 83 98Z"
      className={styles.fillPrimary100}
    />
    <path
      d="M65 131H39C37.3431 131 36 132.343 36 134C36 135.657 37.3431 137 39 137H65C66.6569 137 68 135.657 68 134C68 132.343 66.6569 131 65 131Z"
      className={styles.fillPrimary300}
    />
    <path
      d="M83 144H39C37.3431 144 36 145.343 36 147C36 148.657 37.3431 150 39 150H83C84.6569 150 86 148.657 86 147C86 145.343 84.6569 144 83 144Z"
      className={styles.fillPrimary100}
    />
    <g filter="url(#filter1_d_245_11675)">
      <path
        d="M118 83H32C29.2386 83 27 85.2386 27 88V118C27 120.761 29.2386 123 32 123H118C120.761 123 123 120.761 123 118V88C123 85.2386 120.761 83 118 83Z"
        className={styles.fillPrimary400}
      />
    </g>
    <path
      d="M65 94H39C37.3431 94 36 95.3431 36 97C36 98.6569 37.3431 100 39 100H65C66.6569 100 68 98.6569 68 97C68 95.3431 66.6569 94 65 94Z"
      className={styles.fillPrimary300}
    />
    <path
      d="M83 107H39C37.3431 107 36 108.343 36 110C36 111.657 37.3431 113 39 113H83C84.6569 113 86 111.657 86 110C86 108.343 84.6569 107 83 107Z"
      className={styles.fillWhite}
    />
    <defs>
      <filter
        id="filter0_d_245_11675"
        x="21"
        y="34"
        width="108"
        height="127"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-3" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_245_11675" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_245_11675" result="shape" />
      </filter>
      <filter
        id="filter1_d_245_11675"
        x="21"
        y="74"
        width="108"
        height="52"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-3" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_245_11675" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_245_11675" result="shape" />
      </filter>
    </defs>
  </svg>
)
