import { UserDetails } from '@wpp-open/core'

import { isString } from 'utils/common'

// TODO: Backend has strange typing for the preferredHubPerTenant field.
//  Empty object can mean there isn't a preferred hub for specific tenant.
export const getPreferredHubId = ({ userDetails, tenantId }: { userDetails: UserDetails; tenantId: string }) => {
  const preferredHubId = userDetails.preferredHubPerTenant?.[tenantId]

  return isString(preferredHubId) ? preferredHubId : undefined
}
