import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { join } from 'utils/common'

export const useUsernameFormat = () => {
  const { t } = useTranslation()

  return {
    formatUsername: useCallback(
      (firstname?: string, lastname?: string) => join([firstname, lastname], ' ') || t('os.common.unknown_username'),
      [t],
    ),
  }
}
