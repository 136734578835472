import { MayBeNull } from '@wpp-open/core'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'

import { useThrottleFn } from 'hooks/useThrottleFn'
import { BreadcrumbItem as BreadcrumbItemType } from 'types/osState/breadcrumbs'
import { arraySum } from 'utils/common'

export enum BreadcrumbsContainerItemTag {
  BreadcrumbItem = 'breadcrumb-item',
  MoreMenuItem = 'more-menu-item',
  HiddenItems = 'hidden-items',
}

const RESIZE_THROTTLE_WAIT_TIME = 300

interface Params<T> {
  rootRef: MayBeNull<HTMLDivElement>
  overflowContainerRef: MayBeNull<HTMLDivElement>
  items: BreadcrumbItemType<T>[]
  hiddenItemCount: number
  setHiddenItemCount: Dispatch<SetStateAction<number>>
}

interface State {
  rootEl: MayBeNull<HTMLDivElement>
  overflowContainerEl: MayBeNull<HTMLDivElement>
  hiddenItemCount: number
  totalItemCount: number
}

export const useFitBreadcrumbs = <T>({
  rootRef,
  overflowContainerRef,
  items,
  hiddenItemCount,
  setHiddenItemCount,
}: Params<T>) => {
  const totalItemCount = items.length
  const stateRef = useRef<State>({
    rootEl: rootRef,
    overflowContainerEl: overflowContainerRef,
    hiddenItemCount,
    totalItemCount,
  })

  stateRef.current.rootEl = rootRef
  stateRef.current.overflowContainerEl = overflowContainerRef
  stateRef.current.hiddenItemCount = hiddenItemCount
  stateRef.current.totalItemCount = totalItemCount

  const handleResize = () => {
    if (!stateRef.current.rootEl || !stateRef.current.overflowContainerEl) {
      return
    }

    // `children` includes elements:
    // - More menu item (when there is at least one hidden item)
    // - Breadcrumb items controlled by the container component
    // - Last breadcrumb item that is passed through children prop and wrapped in a div
    // - Element with hidden items
    const children = Array.from(stateRef.current.overflowContainerEl.children)

    const shownChildrenAll = children.filter(el => !el.classList.contains(BreadcrumbsContainerItemTag.HiddenItems))
    const shownChildrenItems = shownChildrenAll.filter(el =>
      el.classList.contains(BreadcrumbsContainerItemTag.BreadcrumbItem),
    )

    const displayedWidth = stateRef.current.rootEl.getBoundingClientRect().width
    const shownChildrenAllWidthArray = shownChildrenAll.map(el => el.getBoundingClientRect().width)
    const shownChildrenAllWidth = arraySum(shownChildrenAllWidthArray)

    const moreMenuItem = children.find(el => el.classList.contains(BreadcrumbsContainerItemTag.MoreMenuItem))
    const moreMenuItemWidth = moreMenuItem?.getBoundingClientRect().width || 0

    const hiddenChildrenContainer = children.find(el => el.classList.contains(BreadcrumbsContainerItemTag.HiddenItems))
    const hiddenChildren = Array.from(hiddenChildrenContainer?.children || [])
    const hiddenChildrenWidthArray = hiddenChildren.map(el => el.getBoundingClientRect().width)

    if (shownChildrenAllWidth > displayedWidth) {
      // Hide items when they overflow the container
      if (stateRef.current.hiddenItemCount < stateRef.current.totalItemCount) {
        let nextHiddenCount = stateRef.current.hiddenItemCount
        let widthToHide = 0

        const shownItemsWidthArray = shownChildrenItems.map(el => el.getBoundingClientRect().width)

        do {
          nextHiddenCount++
          widthToHide += shownItemsWidthArray.shift() || 0
        } while (
          shownChildrenAllWidth - widthToHide > displayedWidth &&
          nextHiddenCount < stateRef.current.totalItemCount
        )

        setHiddenItemCount(nextHiddenCount)
      }
    } else if (hiddenChildrenWidthArray.length > 0) {
      // Show items when there is space available
      let nextHiddenCount = stateRef.current.hiddenItemCount
      let widthToShow = 0

      for (let i = 0; i < hiddenChildrenWidthArray.length; i++) {
        const nextWidthWithMoreMenu = hiddenChildrenWidthArray[i]
        const nextWidthWithoutMoreMenu = arraySum(hiddenChildrenWidthArray.slice(i)) - moreMenuItemWidth
        const nextWidth = Math.min(nextWidthWithMoreMenu, nextWidthWithoutMoreMenu)

        if (shownChildrenAllWidth + widthToShow + nextWidth <= displayedWidth) {
          nextHiddenCount--
          widthToShow += nextWidthWithMoreMenu
        } else {
          break
        }
      }

      // In case hiddenChildren elements and hiddenItemCount are out of sync
      if (nextHiddenCount < 0) {
        nextHiddenCount = 0
      }

      setHiddenItemCount(nextHiddenCount)
    }
  }

  const handleResizeThrottled = useThrottleFn(handleResize, RESIZE_THROTTLE_WAIT_TIME)

  const [resizeObserver] = useState(() => new ResizeObserver(handleResizeThrottled))

  useEffect(() => {
    if (rootRef) {
      resizeObserver.observe(rootRef)

      return () => resizeObserver.unobserve(rootRef)
    }
  }, [resizeObserver, rootRef])

  useEffect(() => {
    if (overflowContainerRef) {
      resizeObserver.observe(overflowContainerRef)

      return () => resizeObserver.unobserve(overflowContainerRef)
    }
  }, [resizeObserver, overflowContainerRef])

  useEffect(() => {
    setHiddenItemCount(0)
  }, [items, setHiddenItemCount])
}
