import {
  CompactAppContext,
  FullscreenAppContext,
  HierarchyNodeType,
  MayBeNull,
  NavigationTree,
  SelectedWorkspace,
  SelectedWorkspaceItem,
} from '@wpp-open/core'
import { useMemo } from 'react'

import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { useOsRoot } from 'providers/osRoot/OsRootContext'
import { useOsState } from 'providers/osState/OsStateProvider'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { usePublicData } from 'providers/publicData/PublicDataContext'
import { resolveActiveHierarchy } from 'utils/workspace'

export const useFullscreenAppContextValue = (): FullscreenAppContext => {
  const { appData } = useOsState()
  const { userDetails } = useOtherTenantsAndUserData()
  const { currentTenant, navigationTreeWithHiddenLevel, permissions } = useCurrentTenantData()
  const { resolvedTheme, currentTaxonomy } = usePublicData()
  const { activePage } = useOsRoot()
  const { activeWorkspaceId, currentBaseUrl, project, projectItem, appCustomConfig, additionalContext, appInstance } =
    appData

  const contextHierarchy = useMemo(
    () => resolveContextHierarchy({ activeWorkspaceId, navigationTree: navigationTreeWithHiddenLevel }),
    [activeWorkspaceId, navigationTreeWithHiddenLevel],
  )

  return useMemo<FullscreenAppContext>(() => {
    const projectData: FullscreenAppContext['project'] =
      project && projectItem
        ? {
            id: project.id,
            name: project.name,
            type: project.type,
            itemId: String(projectItem.id),
            itemCompleteState: projectItem.task?.status ?? null,
          }
        : null

    return {
      baseUrl: currentBaseUrl,
      workspace: contextHierarchy,
      project: projectData,
      permissions,
      tenant: currentTenant,
      navigationTree: navigationTreeWithHiddenLevel,
      userDetails,
      theme: resolvedTheme,
      taxonomy: currentTaxonomy,
      appInstance,
      appCustomConfig,
      additional: additionalContext,
      activePage,
    }
  }, [
    activePage,
    additionalContext,
    appCustomConfig,
    appInstance,
    contextHierarchy,
    currentBaseUrl,
    currentTaxonomy,
    currentTenant,
    navigationTreeWithHiddenLevel,
    permissions,
    project,
    projectItem,
    resolvedTheme,
    userDetails,
  ])
}

export const useCompactAppContextValue = (): CompactAppContext => {
  const { userDetails } = useOtherTenantsAndUserData()
  const { currentTenant, navigationTreeWithHiddenLevel, permissions } = useCurrentTenantData()
  const { resolvedTheme, currentTaxonomy } = usePublicData()
  const { activePage } = useOsRoot()

  return useMemo<CompactAppContext>(() => {
    return {
      permissions,
      tenant: currentTenant,
      navigationTree: navigationTreeWithHiddenLevel,
      userDetails,
      theme: resolvedTheme,
      taxonomy: currentTaxonomy,
      activePage,
    }
  }, [
    activePage,
    currentTaxonomy,
    currentTenant,
    navigationTreeWithHiddenLevel,
    permissions,
    resolvedTheme,
    userDetails,
  ])
}

export const resolveContextHierarchy = ({
  activeWorkspaceId,
  navigationTree,
}: {
  activeWorkspaceId: MayBeNull<string>
  navigationTree: NavigationTree
}): MayBeNull<SelectedWorkspace> => {
  if (!activeWorkspaceId) {
    return null
  }

  const activeHierarchy = resolveActiveHierarchy({
    validHierarchyId: activeWorkspaceId,
    navigationTree,
  })

  return {
    azId: activeWorkspaceId,
    mapping: Object.fromEntries(
      activeHierarchy.map((nodeAzId, index) => [
        nodeAzId,
        getHierarchyStructureItem({
          azId: nodeAzId,
          parentAzId: index === 0 ? undefined : activeHierarchy[index - 1],
          navigationTree,
        }),
      ]),
    ),
  }
}

const getHierarchyStructureItem = ({
  azId,
  parentAzId,
  navigationTree,
}: {
  azId: string
  parentAzId?: string
  navigationTree: NavigationTree
}): SelectedWorkspaceItem => {
  const node = navigationTree.mapping[azId]

  return {
    azId,
    name: node.name,
    type: node.type as HierarchyNodeType,
    customTypeName: node.customTypeName,
    parentAzId,
  }
}
