import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useMemo } from 'react'

import { ForcedApiError, QueryFetcher, QueryFetcherParams, QueryFetcherResponse } from 'api/common/types'

export function createUseQuery<F extends QueryFetcher, S, P = QueryFetcherParams<F>, R = QueryFetcherResponse<F>>({
  queryKey,
  fetcher,
  selector,
  refetchInterval,
}: {
  queryKey: string
  fetcher: F
  selector: (response?: R) => S
  refetchInterval?: number
}) {
  return function useCustomQuery(
    options: {
      params?: P
      /**
       * return 'true' if a successful response should be considered as failed
       */
      forceError?: (response?: S) => boolean
    } & Omit<
      UseQueryOptions<R, AxiosError | ForcedApiError>,
      'queryKey' | 'queryFn' | 'queryHash' | 'queryKeyHashFn' | 'select'
    > = {},
  ) {
    const { params = {}, forceError, enabled = true, ...queryOptions } = options

    const { data, isPending, ...rest } = useQuery({
      queryKey: [queryKey, params],
      queryFn: async ({ signal }) => {
        const response = (await fetcher(params)(signal)) as R

        if (forceError?.(selector(response))) {
          throw new ForcedApiError('Forced request error')
        }

        return response
      },
      enabled,
      refetchInterval,
      ...queryOptions,
    })

    return {
      ...rest,
      isLoading: enabled && isPending,
      data: useMemo(() => selector(data), [data]),
      response: data,
    }
  }
}
