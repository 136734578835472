import { useEffect, useRef, useState } from 'react'

import { environment } from 'environment'

const azTokenKey = 'WPP_ALPHA_ZULU_TOKEN'

export const useAZToken = (wppToken: string) => {
  const prevWppToken = useRef<string>()
  const [token, setToken] = useState('')

  const setAndSaveToken = (token: string) => {
    setToken(token)
    localStorage.setItem(azTokenKey, token)
  }

  const fetchAZToken = async () => {
    try {
      const res = await fetch(`${environment.EXPERIENCE_BUILDER_API}/alpha-zulu/token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          client: 'playground-dev-fe',
        },
        body: JSON.stringify({
          token: wppToken,
        }),
      })
      const data = await res.json()
      setAndSaveToken(data.accessToken)
    } catch (error) {
      console.error('Error fetching token', error)
    }
  }

  const getAZToken = () => {
    const tmpToken = localStorage.getItem(azTokenKey) || ''
    if (tmpToken) {
      setToken(tmpToken)
      return
    }
    fetchAZToken()
  }

  useEffect(() => {
    if (!token && !!wppToken) {
      getAZToken()
      prevWppToken.current = wppToken
      return
    }
    if (prevWppToken.current !== wppToken) {
      fetchAZToken()
      prevWppToken.current = wppToken
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, wppToken])

  const removeToken = () => {
    setToken('')
    localStorage.removeItem(azTokenKey)
  }

  return {
    azAccessToken: token,
    setAndSaveToken,
    removeToken,
    refreshToken: fetchAZToken,
  }
}
