import { MayBeNull, AttachmentMetadata } from '@wpp-open/core'

import { facadeApi } from 'api'

interface Params {
  avatarOriginal?: MayBeNull<AttachmentMetadata>
  avatarThumbnail: MayBeNull<AttachmentMetadata>
}

export const updateUserAvatarApi = (params: Params) => facadeApi.patch('/users/me/avatar', { ...params })
