import { PermissionsRecord } from '@wpp-open/core'

import { facadeApi } from 'api'

export interface Params {
  email: string
  accountId: string
  depth?: number
}

export const fetchUserPermissionsApi = ({ email, accountId, depth = 0 }: Params) =>
  facadeApi.get<PermissionsRecord[]>(`/az/v2/users/email/${email}/permissions`, {
    params: {
      depth,
      account_id: accountId,
    },
  })
