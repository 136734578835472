import { useLocation, useSearchParams } from 'react-router-dom'
import * as z from 'zod'

import { useFetchMiroAuthLinkApi } from 'api/miro/queryFetchers/useFetchMiroAuthLinkApi'
import { useStableCallback } from 'hooks/useStableCallback'
import { join, safeParseJSON } from 'utils/common'
import { routesManager } from 'utils/routesManager'

export enum MiroAuthRedirectReason {
  Settings = 'settings',
  AuthToken = 'auth-token',
}

export const useMiroAuthCallbackParams = () => {
  const [searchParams] = useSearchParams()
  const code = searchParams.get('code')
  const clientId = searchParams.get('client_id')
  const teamId = searchParams.get('team_id')
  const state = safeParseJSON(searchParams.get('state'))

  return {
    authCode: code,
    clientId,
    teamId,
    state,
  }
}

const miroAuthRedirectParamsSchema = z.object({
  authCode: z.string().min(1),
  clientId: z.string().min(1),
  teamId: z.string().min(1),
  state: z.object({
    from: z.string().min(1),
    reason: z.nativeEnum(MiroAuthRedirectReason),
    context: z.any().optional(),
  }),
})

export type MiroAuthCallbackParams = z.infer<typeof miroAuthRedirectParamsSchema>

export const isValidMiroAuthCallbackParams = (value: Record<string, any>): value is MiroAuthCallbackParams =>
  miroAuthRedirectParamsSchema.safeParse(value).success

export interface MiroAuthRedirectState {
  from: string
  reason: MiroAuthRedirectReason
  context?: any
}

export const useInitiateMiroOAuth2Flow = () => {
  const { pathname, search } = useLocation()
  const fetchMiroAuthLink = useFetchMiroAuthLinkApi()

  return {
    handleInitiateMiroOAuth2Flow: useStableCallback(
      async ({
        clientId,
        teamId,
        context,
        reason = MiroAuthRedirectReason.AuthToken,
      }: {
        clientId?: string
        teamId?: string
        reason?: MiroAuthRedirectReason
        context?: any
      } = {}) => {
        const state = {
          from: join([pathname, search], ''),
          reason,
          ...(!!context && { context }),
        } satisfies MiroAuthRedirectState

        const { data } = await fetchMiroAuthLink({
          clientId,
          teamId,
          state: JSON.stringify(state),
        })

        window.location.assign(data.url)
      },
    ),
  }
}

export const getMiroAuthRedirectUrl = () => `${window.location.origin}${routesManager.miroAuthCallback.url()}`

const miroRedirectStateSchema = z.object({
  authCode: z.string().min(1),
  clientId: z.string().min(1),
  teamId: z.string().min(1),
})

export type MiroRedirectState = z.infer<typeof miroRedirectStateSchema>

export const isValidMiroRedirectState = (value: unknown): value is MiroRedirectState =>
  miroRedirectStateSchema.safeParse(value).success
