import { PermissionsRecord } from '@wpp-open/core'

import { facadeApi } from 'api'

export const fetchMePermissionByAccountApi = ({
  account_ids,
  perms,
  perm_prefixes,
}: {
  account_ids: string[]
  perms?: string[]
  perm_prefixes?: string[]
}) =>
  facadeApi.post<PermissionsRecord[]>('/az/v5/users/me/permissions', {
    account_ids,
    ...(!!perms && { perms }),
    ...(!!perm_prefixes && { perm_prefixes }),
  })
