import { TypographyType } from '@platform-ui-kit/components-library/dist/types/components/wpp-typography/types'
import { WppActionButton, WppIconCalendarAdd, WppTypography } from '@platform-ui-kit/components-library-react'
import { t } from 'i18next'

import { Flex } from 'components/common/flex/Flex'
import { SvgNoCalendarEvents } from 'components/svg/SvgNoCalendarEvents'
import { Permission } from 'constants/permission'
import { useHasPermission } from 'hooks/useHasPermission'

interface Props {
  textType?: TypographyType
  className?: string
  isCompact?: boolean
  withAction?: boolean
  onAddEvent: () => void
}

export const EventsEmptyState = ({
  className,
  textType = 'm-strong',
  isCompact = false,
  withAction = true,
  onAddEvent,
}: Props) => {
  const { hasPermission } = useHasPermission()
  return (
    <Flex direction="column" align="center" justify="start" gap={24} data-testid="empty-state" className={className}>
      {!isCompact && <SvgNoCalendarEvents />}
      <Flex direction="column" align="center" justify="center" gap={8}>
        <WppTypography type={textType} data-testid="empty-state-text">
          {t('os.home.calendar_widget.view_events.empty_state')}
        </WppTypography>
        {hasPermission(Permission.OS_ADMIN_SETTING_ACCESS) && withAction && (
          <WppActionButton onClick={onAddEvent}>
            <WppIconCalendarAdd slot="icon-start" />
            {t('os.home.calendar_widget.view_events.add_event_button')}
          </WppActionButton>
        )}
      </Flex>
    </Flex>
  )
}
