import { WppPopover, WppActionButton, WppIconChevron } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useRef, useState } from 'react'

import { ColorPaletteMini } from 'components/common/colorPaletteMini/ColorPaletteMini'
import { Flex } from 'components/common/flex/Flex'
import { useStableCallback } from 'hooks/useStableCallback'
import { DotIndicator } from 'pages/home/systemWidgets/calendarWidget/dotIndicator/DotIndicator'
import styles from 'pages/home/systemWidgets/calendarWidget/manageEventSideModal/colorInput/ColorInput.module.scss'

interface Props {
  color: string
  className?: string
  onChange: (value: string) => void
}

export const ColorInput = ({ color, className, onChange }: Props) => {
  const popoverRef = useRef<HTMLWppPopoverElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  const onChangeStable = useStableCallback((selectedColor: string) => {
    onChange(selectedColor)
    popoverRef.current?.closePopover()
  })

  return (
    <WppPopover
      className={clsx(styles.popover, className)}
      ref={popoverRef}
      shouldCloseOnOutsideClick={() => true}
      config={{
        appendTo: () => document.querySelector('#root')!,
        zIndex: 10000,
        placement: 'left',
        onShow: () => setIsOpen(true),
        onHide: () => setIsOpen(false),
      }}
      data-testid="color-input"
    >
      <WppActionButton
        type="button"
        variant="secondary"
        slot="trigger-element"
        className={styles.button}
        onClick={e => {
          e.stopPropagation()
          e.preventDefault()
        }}
        data-testid="hex-color-button"
      >
        <Flex slot="icon-start" gap={8}>
          <DotIndicator size={25} color={color} className={styles.dotIndicator} />
          <WppIconChevron direction={isOpen ? 'up' : 'down'} />
        </Flex>
      </WppActionButton>
      <div>
        <ColorPaletteMini onChange={onChangeStable} selectedColor={color} />
      </div>
    </WppPopover>
  )
}
