export const EVENTS = {
  PLAYGROUND_HOMEPAGE: 'pg_pv_homepage',

  PLAYGROUND_OPEN_PLAYLIST: 'pg_act_homepage_header_openplaylist',
  PLAYGROUND_HIDE_SUMMARY: 'pg_act_homepage_projecthealth_hideprojectsummary',
  PLAYGROUND_SHOW_SUMMARY: 'pg_act_homepage_showprojectsummary',
  PLAYGROUND_NOTIFY_PERSON: 'pg_act_homepage_projecthealth_latetasks_notifyperson',
  PLAYGROUND_VIEW_RISK: 'pg_act_homepage_projecthealth_atriskprojects_viewrisk',
  PLAYGROUND_VIEW_ASSIGNMENTS: 'pg_act_homepage_header_dailycount_viewassignments',
}
