import { WppSpinner } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'

import { Flex } from 'components/common/flex/Flex'
// import { ReactComponent as IconArrow } from 'pages/playground/playgroundDashboard/assets/icons/arrow-diagonal.svg'
import styles from 'pages/playground/playgroundDashboard/components/sections/sectionNews/infoCard/InfoCard.module.scss'
import stylesMain from 'pages/playground/playgroundDashboard/PlaygroundDashboard.module.scss'

interface Props {
  text: string
  number: number
  onClick: () => void
  lateNumber?: number
  loadingAssignments: boolean
}

export const InfoCard = ({ text, number, onClick, lateNumber = 0, loadingAssignments }: Props) => {
  return (
    <Flex gap={15} className={clsx(styles.card, 'tour-home-daily-snapshot-assignments')} align="end" onClick={onClick}>
      <div className={clsx(stylesMain.whiteBigStrong, styles.numberContainer)}>
        {loadingAssignments ? <WppSpinner color="white" size="m" /> : number}
      </div>
      <div className={clsx(stylesMain.white2xl, styles.textContainer)}>
        {lateNumber > 0 && (
          <div className={styles.numberLateContainerOuter}>
            <span className={clsx(stylesMain.blackSMidi, styles.numberLateContainer)}>{`${lateNumber} Late Task`}</span>
          </div>
        )}
        {text}
      </div>
      {/* <div className={styles.arrowContainer}>
        <IconArrow />
      </div> */}
    </Flex>
  )
}
