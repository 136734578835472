import { facadeApi } from 'api'
import { PageBuilderContent } from 'types/apps/leanApps'

interface Params {
  configId: string
}

interface PageBuilderConfig {
  createdAt: string
  updatedAt: string
  createdByEmail: string
  updatedByEmail: string
  id: string
  name: string
  config: PageBuilderContent
}

export const fetchPageBuilderConfigApi = ({ configId }: Params) =>
  facadeApi.get<PageBuilderConfig>(`page-builder/configs/${configId}`)
