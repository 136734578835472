import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useWrikeAuthAuthenticateApi } from 'api/wrike/queries/useWrikeAuthAuthenticateApi'
import { useStableCallback } from 'hooks/useStableCallback'
import { LoadingPage } from 'layout/loadingPage/LoadingPage'
import { WrikeAuthParams } from 'types/wrike'
import { routesManager } from 'utils/routesManager'

export const WrikeAuthCallback = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const params = Object.fromEntries(searchParams.entries()) as Omit<WrikeAuthParams, 'redirectUri'>

  const { mutateAsync: handleUpdateUserWrikeAuthToken } = useWrikeAuthAuthenticateApi()

  const handleSetTokenAndRedirect = useStableCallback(async () => {
    try {
      await handleUpdateUserWrikeAuthToken({
        code: params?.code,
        state: params?.state,
        redirectUri: `${window.location.origin}${routesManager.wrikeAuthCallback.url()}`,
      })

      navigate(routesManager.user.profile.url(), { state: { type: 'wrike' } })
    } catch (e) {
      navigate(routesManager.user.profile.url(), { state: { type: 'wrike', e } })
    }
  })

  useEffect(() => {
    handleSetTokenAndRedirect()
  }, [handleSetTokenAndRedirect])

  return <LoadingPage />
}
