import { WppTag } from '@platform-ui-kit/components-library/components/wpp-tag'
import { t } from 'i18next'
import { useMemo } from 'react'

import { Requests } from 'constants/requests'
import { AccessRequestStatus } from 'pages/admin/requests/utils'

const getLabel = (requestType: Requests, status?: AccessRequestStatus) => {
  if (isApproved(status)) {
    return requestType === Requests.HIERARCHY_ACCESS
      ? t('os.requests.statuses.assigned')
      : t('os.requests.statuses.approved')
  }

  if (isRejected(status)) {
    return t('os.requests.statuses.rejected')
  }
}

export const isApproved = (status?: AccessRequestStatus) => {
  return status === AccessRequestStatus.APPROVED
}

export const isRejected = (status?: AccessRequestStatus) => {
  return status === AccessRequestStatus.REJECTED
}

export const isCompleted = (status?: AccessRequestStatus) => {
  return isApproved(status) || isRejected(status)
}

export const useStatusTagOptions = (
  requestType: Requests,
  status?: AccessRequestStatus,
): {
  variant: WppTag['variant']
  label?: string
} => {
  const variant = useMemo(() => {
    if (isApproved(status)) {
      return 'positive'
    }
    if (isRejected(status)) {
      return 'negative'
    }
    return 'neutral'
  }, [status])

  const label = useMemo(() => getLabel(requestType, status), [requestType, status])

  return { variant, label }
}
