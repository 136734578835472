import { facadeApi } from 'api'

export interface FileData {
  name: string
  contentType: string
}

export interface FileDataResponse {
  id: string
  name: string
  uploadUrl: string
}

export const uploadWidgetAssetApi = (files: FileData[]) =>
  facadeApi.post<FileDataResponse[]>('/widgets/assets/upload-urls', files)
