import { Outlet } from 'react-router-dom'

import { InfoBanner } from 'layout/infoBanner/InfoBanner'
import styles from 'layout/pageContainer/PageContainer.module.scss'

export const PageContainer = () => (
  <main className={styles.root}>
    <InfoBanner />
    <Outlet />
  </main>
)
