import { ZendeskWidgetV2, ZendeskSettings } from 'zendesk-widget-typings'

class ZendeskAPI {
  widget: ZendeskWidgetV2

  constructor() {
    this.widget = (type, command, payload) => {
      if (window.zE) {
        window.zE(type, command, payload)
      } else {
        if (process.env.DEV) {
          console.warn('Zendesk widget api is not available')
        }
      }
    }
  }

  open() {
    this.widget('webWidget', 'open')
  }

  close() {
    this.widget('webWidget', 'close')
  }

  show() {
    this.widget('webWidget', 'show')
  }

  hide() {
    this.widget('webWidget', 'hide')
  }

  reset() {
    this.widget('webWidget', 'reset')
  }

  onOpen(handler: () => void) {
    this.widget('webWidget:on', 'open', handler)
  }

  onClose(handler: () => void) {
    this.widget('webWidget:on', 'close', handler)
  }

  prefill(values: Record<string, { value?: string; readOnly?: boolean }>) {
    this.widget('webWidget', 'prefill', values)
  }

  setLocale(locale: string) {
    this.widget('webWidget', 'setLocale', locale)
  }

  updateSettings(settings: ZendeskSettings) {
    this.widget('webWidget', 'updateSettings', settings)
  }

  getSettings(): ZendeskSettings {
    return window.zESettings!
  }
}

export const zendeskAPI = new ZendeskAPI()
