import {
  WppAccordion,
  WppButton,
  WppFullScreenModal,
  WppIconCross,
  WppTypography,
  WppIconChevron,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import React, { useEffect, useCallback, useMemo, CSSProperties } from 'react'

import { Flex } from 'components/common/flex/Flex'
import { ClockBackground } from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/assignmentCard/assets/clock_circle.svg'
import { Bubble } from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/assignmentCard/components/bubble/Bubble'
import { Dot } from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/assignmentCard/components/dot/Dot'
import { AccordionHeader } from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/assignmentCard/components/phaseModal/AccordionHeader'
import {
  LoopModalData,
  InnovationModalData,
} from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/assignmentCard/components/phaseModal/LoopModal.data'
import styles from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/assignmentCard/components/phaseModal/LoopModal.module.scss'
import { Timeline } from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/assignmentCard/components/timeline/Timeline'

export interface ObjectType {
  title: string
  subtitle: string
  bubbleText: string
  backgroundColor: string
  titleColor: string
  nextPhase: string
  timeline: any
  dropdown: any
}

interface Props {
  open: boolean
  loopPhase: string
  exploreSubheadRef?: React.RefObject<HTMLDivElement>
  onClose?: () => void
  variant?: 'experience-loop' | 'innovation-process'
}

export const LoopModal = (props: Props) => {
  const { open, loopPhase, exploreSubheadRef, onClose, variant } = props

  const modalDataObject = variant === 'experience-loop' ? LoopModalData : InnovationModalData

  const [modalData, setModalData] = React.useState<ObjectType | null>(null)
  const [currentIndex, setCurrentIndex] = React.useState(0)
  const [currentPhase, setCurrentPhase] = React.useState(loopPhase)
  const loopPhases = useMemo(() => Object.keys(modalDataObject[0]), [modalDataObject])

  const modalContentRef = React.useRef<HTMLDivElement>(null)

  const urls = {
    stage: {
      'experience-loop':
        '/application/90501b22-a24e-46e4-ada9-115b372c8989/experience-loop_0a6802ee-900c-1917-8190-117380350000_experience-loop',
      'innovation-process':
        '/application/4f5b4b11-f8cb-4454-bfbf-1e1ec30719f4/innovation-process_0a68014c-9034-1fc2-8190-65bac1a4002f_innovation-process',
    },
    prod: {
      'experience-loop':
        '/application/efdba7cf-19f0-43cb-b5ff-10e4b31a77d4/experience-loop-process_0a0c000e-907d-10df-8191-2739c5440012_experience-loop-process',
      'innovation-process':
        '/application/f3c1affd-57a8-4dc2-be75-57ea341d3030/innovation-process_0a0c0366-907d-1244-8191-36ac27f70047_innovation-process',
    },
  }
  const isStage =
    window.location.href.includes('playground-stage.coke.com') || window.location.href.includes('localhost')

  const variantUrl = (() => {
    if (!variant) return
    if (isStage) return urls.stage[variant]
    return urls.prod[variant]
  })()

  const [isOpen, setIsOpen] = React.useState(open)

  useEffect(() => {
    const data = modalDataObject[0][loopPhase]
    const loopPhaseIndex = loopPhases.indexOf(loopPhase.toString())
    setCurrentIndex(loopPhaseIndex)

    if (!loopPhase) {
      setIsOpen(false)
      return
    }

    if (data && typeof data === 'object') {
      setModalData(data as ObjectType)
    }
  }, [loopPhase, setIsOpen, loopPhases, modalDataObject])

  useEffect(() => {
    setCurrentPhase(loopPhases[currentIndex])
  }, [currentIndex, loopPhases])

  useEffect(() => {
    setIsOpen(open)
  }, [open])

  const handleCloseModal = () => {
    onClose?.()
    setIsOpen(false)
    if (exploreSubheadRef && exploreSubheadRef.current)
      exploreSubheadRef?.current.scrollIntoView({ behavior: 'smooth' })
  }

  const handleArrowClick = useCallback(
    (direction: 'left' | 'right') => {
      let newIndex
      // setRotatedIndex(-1)

      if (direction === 'left') {
        if (currentIndex > 0) {
          newIndex = currentIndex - 1
        }
      } else {
        if (currentIndex < loopPhases.length - 1) {
          newIndex = currentIndex + 1
        }
      }

      if (newIndex !== undefined) {
        setCurrentIndex(newIndex)
        const newLoopPhase = loopPhases[newIndex]
        const newModalData = modalDataObject[0][newLoopPhase]
        if (newModalData && typeof newModalData === 'object') {
          setModalData(newModalData as ObjectType)
        }
      }
    },
    [currentIndex, loopPhases, modalDataObject],
  )

  if (!modalData) {
    return null
  }

  const isGrowthPhase = currentPhase === 'simplify'

  const { title, subtitle, bubbleText, backgroundColor, titleColor, nextPhase, timeline, dropdown } = modalData
  const containerStyle: CSSProperties = { backgroundColor: backgroundColor }

  return (
    <WppFullScreenModal open={isOpen} className={styles.modalContainer}>
      <div slot="body" style={{ height: '100%' }}>
        <Flex ref={modalContentRef} style={{ height: 'fit-content', minHeight: '100%' }}>
          <div className={styles.sideTextContainer} style={containerStyle}>
            <WppTypography
              type="m-strong"
              tag="p"
              className={styles.verticalText}
              style={{ WebkitTextStroke: `2px ${titleColor}` }}
            >
              {title}
            </WppTypography>
          </div>
          <div className={styles.rightContainer}>
            <Flex direction="column" className={styles.headerContainer}>
              <Flex justify="end" align="center" className={styles.closeButtonWrapper}>
                <div className={styles.closeButton}>
                  <WppIconCross
                    height={20}
                    width={20}
                    color="#000000"
                    className={styles.closeIcon}
                    onClick={handleCloseModal}
                  />
                </div>
              </Flex>
              <Flex gap={58} align="start" className={styles.headerTopContainer}>
                <Flex align="end" className={styles.titleWrapper} gap={16}>
                  {currentIndex > 0 && (
                    <WppIconChevron
                      width={32}
                      height={32}
                      color="#000000"
                      direction="left"
                      className={styles.arrow}
                      onClick={() => handleArrowClick('left')}
                    />
                  )}
                  <WppTypography type="2xl-heading" tag="h3" className={styles.modalTitle}>
                    {title}
                  </WppTypography>
                  {currentIndex < loopPhases.length - 1 && (
                    <WppIconChevron
                      width={32}
                      height={32}
                      color="#000000"
                      direction="right"
                      className={styles.arrow}
                      onClick={() => handleArrowClick('right')}
                    />
                  )}
                </Flex>
                {!isGrowthPhase && (
                  <div className={styles.textWrapper}>
                    <WppTypography type="l-midi" tag="p" className={styles.modalSubtitle}>
                      <Bubble color="#E4FF3C" text={bubbleText} direction="down" className={styles.bubble} />
                      {subtitle}
                    </WppTypography>
                  </div>
                )}
              </Flex>
              {!isGrowthPhase && (
                <Flex gap={15} direction="column" align="start" className={styles.timelineContainer}>
                  <Flex justify="between" style={{ width: '92%' }}>
                    <p className={styles.timelineStart}>Start</p>
                    <p className={styles.timelineStart}>{nextPhase}</p>
                  </Flex>
                  <div className={styles.timelineWrap}>
                    <Flex className={styles.timelineWrapper}>
                      <Timeline
                        color={backgroundColor}
                        variant={currentIndex + 1 >= loopPhases.length ? 'line' : 'arrow-right'}
                      />
                    </Flex>
                    <Flex className={clsx(styles.timeline, styles.timelineTextWrapper)}>
                      {Object.values(timeline || {}).map((item: any, index: number) => {
                        const isLastItem = index === Object.values(timeline || {}).length - 1
                        return (
                          <Flex direction="column" gap={28} className={styles.timelineText} key={item.title}>
                            <Dot containerClassName={styles.dotContainer} fill={titleColor} className={styles.dot} />
                            <WppTypography type="m-strong" tag="p" className={styles.timelineText}>
                              <Flex
                                direction="row"
                                gap={5}
                                className={isLastItem && styles?.lastTimelineText ? styles.lastTimelineText : ''}
                              >
                                <div>{index + 1 + '. '}</div>
                                <div dangerouslySetInnerHTML={{ __html: item.title }} />
                              </Flex>
                            </WppTypography>
                          </Flex>
                        )
                      })}
                      <div className={styles.empty} style={{ display: title === 'Learn' ? 'none' : 'flex' }}>
                        <Dot
                          style={{ justifyContent: 'end' }}
                          containerClassName={styles.dotContainer}
                          fill={titleColor}
                          className={styles.dot}
                        />
                      </div>
                    </Flex>
                  </div>
                </Flex>
              )}
            </Flex>
            {!isGrowthPhase && (
              <div className={styles.middleTitleContainer}>
                <WppTypography type="4xl-display" tag="p" className={clsx(styles.middleTitle, styles.highlight)}>
                  Key Tasks
                </WppTypography>
                <div className={styles.starWrapper} />
              </div>
            )}
            {!isGrowthPhase && (
              <div className={styles.accordionContainer}>
                {Object.values(dropdown || {}).map((item: any) => {
                  return (
                    <WppAccordion className={styles.accordion} key={item.title}>
                      <AccordionHeader title={item.title} />
                      <Flex gap={52} direction="column" className={styles.textContainer}>
                        <div className={styles.textWrapper}>
                          <WppTypography type="m-body" tag="p" className={styles.accordionText}>
                            {item.text}
                          </WppTypography>
                        </div>
                        {item.table && (
                          <table
                            style={{
                              overflow: 'hidden',
                              borderCollapse: 'separate',
                              border: '2px solid',
                              borderColor: backgroundColor,
                              borderRadius: '16px',
                            }}
                          >
                            <thead
                              style={{
                                backgroundColor: backgroundColor,
                              }}
                            >
                              <tr>
                                {Object.keys(item.table).map(columnKey => (
                                  <th className={styles.tableHead} key={columnKey}>
                                    <WppTypography type="m-strong" tag="p" className={styles.tableHeadText}>
                                      {item.table[columnKey].title}
                                    </WppTypography>
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody className={styles.tableBody}>
                              <tr className={styles.tableRow}>
                                {Object.entries(item.table).map(([columnKey, columnValue], index: number) => (
                                  <td
                                    className={styles.tableCell}
                                    key={columnKey}
                                    style={
                                      index === 1
                                        ? {
                                            borderLeft: '4px solid',
                                            borderRight: '4px solid',
                                            borderColor: backgroundColor,
                                          }
                                        : {}
                                    }
                                  >
                                    <ul style={{ marginBottom: `${item?.margin?.value}`, marginLeft: '10px' }}>
                                      {typeof columnValue === 'object' &&
                                        columnValue !== null &&
                                        Object.entries(columnValue).map(([rowKey, rowValue]) => {
                                          if (rowKey.startsWith('row')) {
                                            return (
                                              <li className={styles.cellText} key={rowKey}>
                                                <WppTypography type="m-body" tag="p" className={styles.tableCellText}>
                                                  <div dangerouslySetInnerHTML={{ __html: rowValue }} />
                                                </WppTypography>
                                              </li>
                                            )
                                          }
                                          if (rowKey === 'subrow' && typeof rowValue === 'object') {
                                            return (
                                              <ul key={rowKey} style={{ marginLeft: '24px' }}>
                                                {Object.entries(rowValue).map(([subRowKey, subRowValue]) => (
                                                  <li key={subRowKey}>
                                                    <WppTypography
                                                      type="m-body"
                                                      tag="p"
                                                      className={styles.tableCellText}
                                                    >
                                                      <div dangerouslySetInnerHTML={{ __html: String(subRowValue) }} />
                                                    </WppTypography>
                                                  </li>
                                                ))}
                                              </ul>
                                            )
                                          }
                                          return null
                                        })}
                                    </ul>
                                  </td>
                                ))}
                              </tr>
                            </tbody>
                          </table>
                        )}
                      </Flex>
                    </WppAccordion>
                  )
                })}
                <div className={styles.buttonContainer}>
                  <a href={variantUrl}>
                    <WppButton
                      variant="primary"
                      size="m"
                      color="#ffffff"
                      onClick={handleCloseModal}
                      className={styles.button}
                    >
                      <WppTypography type="s-strong" tag="span" className={styles.buttonText}>
                        {variant === 'experience-loop'
                          ? 'See Full Experience Loop Process'
                          : 'See Full Innovation Process'}
                      </WppTypography>
                    </WppButton>
                  </a>
                </div>
              </div>
            )}
            {isGrowthPhase && (
              <Flex
                direction="column"
                justify="center"
                align="center"
                style={{
                  width: '100%',
                }}
              >
                <Flex
                  style={{
                    position: 'relative',
                    paddingBottom: '24px',
                  }}
                >
                  <ClockBackground width={130} height={130} className={styles.clockSVG} />
                  <img
                    src={require('pages/playground/playgroundDashboard/components/sections/sectionAssignments/assignmentCard/assets/clock.gif')}
                    width="120px"
                    height="120px"
                    className={styles.clockGIF}
                  />
                </Flex>
                <WppTypography type="4xl-display" tag="p" className={clsx(styles.comingSoonText)}>
                  Coming Soon
                </WppTypography>
                <WppButton
                  variant="primary"
                  size="m"
                  color="#ffffff"
                  onClick={handleCloseModal}
                  className={styles.button}
                  style={{
                    padding: '56px 0 32px 0',
                  }}
                >
                  <WppTypography type="s-strong" tag="span" className={styles.buttonText}>
                    {variant === 'experience-loop' ? 'See Full Experience Loop Process' : 'See Full Innovation Process'}
                  </WppTypography>
                </WppButton>
              </Flex>
            )}
          </div>
        </Flex>
      </div>
    </WppFullScreenModal>
  )
}

export default LoopModal
