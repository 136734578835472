import { useEffect, useRef } from 'react'

interface Props {
  id?: string
  src: string
  async?: true
  onLoad?: HTMLScriptElement['onload']
  onError?: HTMLScriptElement['onerror']
  target?: HTMLElement
}

export const Script = ({ id, src, async, onLoad, onError, target = document.head }: Props) => {
  const persistedProps = { onLoad, onError }

  const persistedPropsRef = useRef(persistedProps)
  persistedPropsRef.current = persistedProps

  useEffect(() => {
    const script = document.createElement('script')
    script.src = src

    const { onLoad, onError } = persistedPropsRef.current

    if (id) {
      script.id = id
    }

    if (async) {
      script.async = true
    }

    script.onload = function (e) {
      onLoad?.call(this, e)
    }

    script.onerror = function (e) {
      onError?.call(this, e)
      script?.remove()
    }

    target.appendChild(script)

    return () => {
      script?.remove()
    }
  }, [id, async, src, target])

  return null
}
