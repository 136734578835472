import { WppActionButton, WppCard, WppIconCross, WppTypography } from '@platform-ui-kit/components-library-react'
import { ComponentProps, useState } from 'react'
import { Rnd } from 'react-rnd'

import { CompactApp, CompactAppConfig } from 'components/apps/compact/CompactApp'
import { Portal } from 'components/common/portal/Portal'
import styles from 'providers/apps/compactAppOverlay/CompactAppOverlay.module.scss'

interface Props {
  app: CompactAppConfig
  overlayProps?: Partial<ComponentProps<typeof Rnd>>
  close: () => void
}

// TODO: Temporary approach for AI assistant
export const CompactAppOverlay = ({ app, overlayProps, close }: Props) => {
  // Used to prevent pointer events interception by app's iframe during drag and resize
  const [isCoverShown, setIsCoverShown] = useState(false)

  return (
    <Portal>
      <div className={styles.draggableArea}>
        <Rnd
          className={styles.overlay}
          default={{ x: 50, y: 50, width: 800, height: 500 }}
          {...overlayProps}
          onDragStart={() => setIsCoverShown(true)}
          onDragStop={() => setIsCoverShown(false)}
          onResizeStart={() => setIsCoverShown(true)}
          onResizeStop={() => setIsCoverShown(false)}
          // Override this prop so that overlay is correctly positioned on pages with scroll
          bounds="parent"
        >
          <WppCard size="l" className={styles.card}>
            <div slot="header">
              <WppTypography type="l-strong">{app.name}</WppTypography>
            </div>
            <div slot="actions">
              <WppActionButton variant="secondary" onClick={() => close()}>
                <WppIconCross slot="icon-start" data-testid="compact-app-overlay-close" />
              </WppActionButton>
            </div>

            <div className={styles.app}>
              {isCoverShown && <div className={styles.cover} data-testid="compact-app-overlay-cover" />}
              <CompactApp app={app} />
            </div>
          </WppCard>
        </Rnd>
      </div>
    </Portal>
  )
}
