import { environment } from 'environment'
import { LegacyBoxEnv } from 'environment/common'
import { LegacyTenantCode } from 'legacy/types/osWebRootApi'

export const agencyUrlsConfig: Partial<Record<LegacyTenantCode | 'domain', string>> = (() => {
  switch (environment.LEGACY_BOX_ENV) {
    case LegacyBoxEnv.Production:
      return {
        essence: 'https://essence.planningos.app/',
        greenhouse: 'https://greenhouse.planningos.app/',
        groupm: 'https://groupm.planningos.app/',
        mediacom: 'https://mediacom.planningos.app/',
        mindshare: 'https://mindshare.planningos.app/',
        msix: 'https://msix.planningos.app/',
        wavemaker: 'https://wavemaker.planningos.app/',
        wms: 'https://wms.planningos.app/',
        domain: 'https://planningos.app/',
        'wpp-open': 'https://wpp.planningos.app/',
      }

    case LegacyBoxEnv.Staging:
      return {
        essence: 'https://es.box-staging.gdtwaw.net/',
        greenhouse: 'https://gh.box-staging.gdtwaw.net/',
        groupm: 'https://gm.box-staging.gdtwaw.net/',
        mediacom: 'https://mc.box-staging.gdtwaw.net/',
        mindshare: 'https://ms.box-staging.gdtwaw.net/',
        msix: 'https://msix.box-staging.gdtwaw.net/',
        wavemaker: 'https://wm.box-staging.gdtwaw.net/',
        wms: 'https://wms.box-staging.gdtwaw.net/',
        domain: 'https://box-staging.gdtwaw.net/',
        'wpp-open': 'wpp.box-staging.gdtwaw.net',
      }

    case LegacyBoxEnv.Development:
    default:
      return {
        essence: 'https://es.box-dev.gdtwaw.net/',
        greenhouse: 'https://gh.box-dev.gdtwaw.net/',
        groupm: 'https://gm.box-dev.gdtwaw.net/',
        mediacom: 'https://mc.box-dev.gdtwaw.net/',
        mindshare: 'https://ms.box-dev.gdtwaw.net/',
        msix: 'https://msix.box-dev.gdtwaw.net/',
        wavemaker: 'https://wm.box-dev.gdtwaw.net/',
        wms: 'https://wms.box-dev.gdtwaw.net/',
        domain: 'https://box-dev.gdtwaw.net/',
        'wpp-open': 'wpp.box-dev.gdtwaw.net',
      }
  }
})()
