import {
  WppTypography,
  WppIconTableSort,
  WppIconTableSortAsc,
  WppIconTableSortDesc,
} from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { IHeaderParams } from 'ag-grid-community'
import { useEffect, useState } from 'react'

import { SortOrder } from 'api/common/types'
import { Flex } from 'components/common/flex/Flex'

export const DefaultColumnHeader = ({ enableSorting, column, setSort, displayName }: IHeaderParams) => {
  const [order, setOrder] = useState<MayBeNull<SortOrder>>(null)

  const handleSortChange = () => {
    const sortingOrder = column.getColDef().sortingOrder!
    const sortIndex = sortingOrder.indexOf(order)

    setSort(sortingOrder[(sortIndex + 1) % sortingOrder.length])
  }

  useEffect(() => {
    const onSortChanged = () => {
      setOrder(column.isSortAscending() ? 'asc' : column.isSortDescending() ? 'desc' : null)
    }

    column.addEventListener('sortChanged', onSortChanged)
    onSortChanged()

    return () => {
      column.removeEventListener('sortChanged', onSortChanged)
    }
  }, [column])

  const SortIcon = order === 'asc' ? WppIconTableSortAsc : order === 'desc' ? WppIconTableSortDesc : WppIconTableSort

  return (
    <Flex
      className="ag-header-cell-comp"
      justify="between"
      {...(enableSorting && {
        onClick: handleSortChange,
      })}
    >
      <WppTypography type="s-strong">{displayName}</WppTypography>
      {enableSorting && <SortIcon className="ag-sorting" />}
    </Flex>
  )
}
