import { WppDatepicker } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { format } from 'date-fns'
import { ComponentPropsWithoutRef, forwardRef, useImperativeHandle, useRef } from 'react'
import { mergeRefs } from 'react-merge-refs'

import { useCommonLabelProps } from 'components/common/utils'
import { useField } from 'hooks/form/useField'
import { isDate, isValidDate } from 'utils/date'

export interface FormDatepickerProps
  extends Omit<ComponentPropsWithoutRef<typeof WppDatepicker>, 'value' | 'onChange' | 'minDate' | 'maxDate'> {
  name: string
  minDate?: Date | string
  maxDate?: Date | string
  mode?: 'date' | 'formattedDate'
}

export const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy'

const defaultLocale = {
  dateFormat: DEFAULT_DATE_FORMAT,
}

export const FormDatepicker = forwardRef<HTMLWppDatepickerElement, FormDatepickerProps>(
  (
    {
      name,
      mode = 'formattedDate',
      message,
      messageType,
      onWppBlur,
      labelConfig,
      labelTooltipConfig,
      locale,
      minDate,
      maxDate,
      onWppChange,
      onWppDateClear,
      tooltipConfig,
      ...rest
    },
    ref,
  ) => {
    const innerRef = useRef<HTMLWppDatepickerElement>(null)

    const {
      field: { ref: fieldRef, value: fieldValue, onChange, onBlur },
      fieldState: { isTouched, error },
    } = useField({ name })

    useImperativeHandle(
      fieldRef,
      () => ({
        focus: () => innerRef.current?.setFocus(),
      }),
      [],
    )

    const errorText = error?.message
    const shouldShowError = isTouched && !!errorText
    const shouldShowErrorMessage = isTouched && !!errorText

    const labelProps = useCommonLabelProps({ labelConfig, labelTooltipConfig })

    const localeInner = {
      ...defaultLocale,
      ...locale,
    }

    const value = fieldValue as MayBeNull<string | Date>

    const normalize = (dateValue: typeof value) => {
      if (isDate(dateValue)) {
        return isValidDate(dateValue) ? format(dateValue, localeInner.dateFormat) : ''
      }

      return dateValue || ''
    }

    return (
      <WppDatepicker
        {...rest}
        {...labelProps}
        ref={mergeRefs([innerRef, ref])}
        name={name}
        value={normalize(value)}
        locale={localeInner}
        {...(!!minDate && { minDate: normalize(minDate) })}
        {...(!!maxDate && { maxDate: normalize(maxDate) })}
        onWppChange={e => {
          onChange(mode === 'date' ? e.detail.date || null : e.detail.formattedDate || '')
          onWppChange?.(e)
        }}
        onWppDateClear={e => {
          onBlur()
          onWppDateClear?.(e)
        }}
        onWppBlur={e => {
          onBlur()
          onWppBlur?.(e)
        }}
        message={shouldShowErrorMessage ? errorText : message}
        messageType={shouldShowError ? 'error' : messageType}
        tooltipConfig={{
          ...tooltipConfig,
          onHidden: instance => {
            tooltipConfig?.onHidden?.(instance)
            onBlur()
          },
        }}
      />
    )
  },
)
