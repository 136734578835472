import clsx from 'clsx'
import { Outlet } from 'react-router-dom'

import { Header } from 'layout/header/Header'
import { HideHeader } from 'layout/hideHeader/HideHeader'
import styles from 'layout/SecureLayout.module.scss'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { isEmbedded } from 'utils/layout'

export const SecureLayout = () => {
  const { currentTenant } = useCurrentTenantData()

  return (
    <div className={clsx(currentTenant.subdomain, styles.root)}>
      {isEmbedded() ? <HideHeader /> : <Header />}
      <Outlet />
    </div>
  )
}
