import 'utils/exposeGlobals'
import { createRoot } from 'react-dom/client'

import { App } from 'app/App'
import { setDateLocale } from 'utils/date'
import { runSingleSpa } from 'utils/singleSpa'

import 'i18n/i18n'

setDateLocale()
runSingleSpa()

const root = createRoot(document.getElementById('root')!)

root.render(<App />)
