import { MayBeNull } from '@wpp-open/core'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useStableCallback } from 'hooks/useStableCallback'
import styles from 'legacy/LegacyMicroApp.module.scss'
import { LegacyAppWindowLibraryName } from 'legacy/types/apps'
import {
  Breadcrumbs,
  LegacyWorkspace,
  MicrofrontendSetHeaderBreadcrumbs,
  WorkspaceType,
} from 'legacy/types/osWebRootApi'
import { LegacyState, legacyState } from 'legacy/utils/state'
import { useOpenLegacyApp } from 'legacy/utils/useOpenLegacyApp'
import { useAuth } from 'providers/auth/AuthContext'
import { MicroAppLibraryType } from 'types/apps/microApps'
import { AppDataType, LegacyMicroAppData } from 'types/osState/appDataResolved'
import { getActiveWhen } from 'utils/apps'
import { registerMicroApp, unregisterMicroApp } from 'utils/singleSpa'

// Some apps require a workspace to be set by header
/**
 * @deprecated This was used before required hierarchy config was available
 */
const WORKSPACE_DEPENDANT_APPS: LegacyAppWindowLibraryName[] = [
  LegacyAppWindowLibraryName.Audiences,
  LegacyAppWindowLibraryName.Touchpoints,
  LegacyAppWindowLibraryName.CampaignsCreate,
  LegacyAppWindowLibraryName.MediaPlansComparison,
  LegacyAppWindowLibraryName.Cart,
  LegacyAppWindowLibraryName.Scorecards,
]

// Some apps require a wrapper with custom background styles
const APPS_WITH_CUSTOM_BACKGROUND: LegacyAppWindowLibraryName[] = [
  LegacyAppWindowLibraryName.AudienceDB,
  LegacyAppWindowLibraryName.Contacts,
  LegacyAppWindowLibraryName.IDNOnboarding,
  LegacyAppWindowLibraryName.MediaPlanner,
  LegacyAppWindowLibraryName.SpeedDate,
]

const APP_BREADCRUMBS: Partial<Record<LegacyAppWindowLibraryName, Breadcrumbs>> = {
  [LegacyAppWindowLibraryName.Audiences]: {
    category: 'OS_HEADER.NAVIGATION_MAIN.OPTIMISE',
    app: 'OS_HEADER.NAVIGATION_MAIN.AUDIENCES',
  },
  [LegacyAppWindowLibraryName.Touchpoints]: {
    category: 'OS_HEADER.NAVIGATION_MAIN.OPTIMISE',
    app: 'OS_HEADER.NAVIGATION_MAIN.TOUCHPOINTS',
  },
  [LegacyAppWindowLibraryName.CampaignsCreate]: {
    category: 'OS_HEADER.NAVIGATION_MAIN.OPTIMISE',
    app: 'OS_HEADER.NAVIGATION_MAIN.CAMPAIGNS',
  },
  [LegacyAppWindowLibraryName.MediaPlansComparison]: {
    category: 'OS_HEADER.NAVIGATION_MAIN.OPTIMISE',
    app: 'OS_HEADER.NAVIGATION_MAIN.MP_COMPARISON',
  },
  [LegacyAppWindowLibraryName.Cart]: {
    text: 'OS_HEADER.NAVIGATION_MAIN.CART',
  },
  [LegacyAppWindowLibraryName.Scorecards]: {
    category: 'OS_HEADER.NAVIGATION_MAIN.UNLOCK',
    app: 'OS_HEADER.NAVIGATION_MAIN.SCORECARDS',
  },
}

interface Props {
  appData: LegacyMicroAppData
  selectedWorkspace: MayBeNull<LegacyWorkspace>
}

export const LegacyMicroApp = ({ appData, selectedWorkspace }: Props) => {
  const navigate = useNavigate()
  const navigateStable = useStableCallback(navigate)
  const [isUnregistering, setIsUnregistering] = useState(false)
  const isUnregisteringRef = useRef(isUnregistering)
  const updateIsUnregistering = useCallback((isUnregistering: boolean) => {
    setIsUnregistering(isUnregistering)
    isUnregisteringRef.current = isUnregistering
  }, [])
  const { logout } = useAuth()
  const openApp = useOpenLegacyApp()

  const { type, app } = appData

  const windowLibraryName = app.windowLibraryName as LegacyAppWindowLibraryName
  const isBrandWorkspaceSet = selectedWorkspace?.type === WorkspaceType.Brand
  const isWorkspaceManagement = windowLibraryName === LegacyAppWindowLibraryName.WorkspaceManagement
  const isWorkspaceDependant = WORKSPACE_DEPENDANT_APPS.includes(windowLibraryName)
  const isLocalApp = type === AppDataType.LocalLegacyMicroApp
  const shouldRegister = !isWorkspaceDependant || isBrandWorkspaceSet || isLocalApp
  const workspaceKey = isWorkspaceDependant || isWorkspaceManagement ? selectedWorkspace?.azId : null

  useEffect(() => {
    // Register only after previous unregister finishes.
    // This covers cases when the same legacy app should be re-registered
    if (!isUnregisteringRef.current && shouldRegister) {
      const { eventEmitter$, subscription } = LegacyState.getEventEmitterData(navigateStable, logout, app.name)
      const customProps = legacyState.getCustomProps(app, eventEmitter$, openApp)

      registerMicroApp({
        stableId: app.stableId,
        libraryType: MicroAppLibraryType.Window,
        windowLibraryName,
        bundleUrl: app.bundleUrl,
        domElementId: app.containerId,
        activeWhen: getActiveWhen(app),
        customProps,
      })

      if (APP_BREADCRUMBS[windowLibraryName]) {
        legacyState.emitEvent(new MicrofrontendSetHeaderBreadcrumbs(APP_BREADCRUMBS[windowLibraryName]!))
      }

      return () => {
        subscription.unsubscribe()
        updateIsUnregistering(true)
        unregisterMicroApp(app).finally(() => {
          // This is a quick workaround to handle the async execution of `unregisterApp()`.
          // The next register should wait for it to finish, but it finishes within one render cycle
          // and `isUnregistering` stays the same on next update without `setTimeout()`
          setTimeout(() => updateIsUnregistering(false), 100)
        })
      }
    }
  }, [
    app,
    isUnregistering,
    logout,
    navigateStable,
    openApp,
    shouldRegister,
    updateIsUnregistering,
    windowLibraryName,
    workspaceKey,
  ])

  let customBackgroundStyles: Record<string, string> = {}

  if (APPS_WITH_CUSTOM_BACKGROUND.includes(windowLibraryName)) {
    customBackgroundStyles = {
      backgroundColor: '#f3f4f7',
    }
  }

  return <div style={customBackgroundStyles} className={styles.container} id={app.containerId} />
}
