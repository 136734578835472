import { matchAndThriveApi } from 'api'
import {
  AssignmentButtonAction,
  AssignmentButtonIcon,
} from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/sectionAssignmentsMockData'

export interface FeedbackResponse {
  id: string
  assignmentId: string
  assignmentName: string
  nudgeId: string
  nudgeName: string
  nudgeObj: {
    action: AssignmentButtonAction
    icon: AssignmentButtonIcon
    label: string
    url: string
  }
}

export const fetchFeedback = () => matchAndThriveApi.get<FeedbackResponse[]>('/nudges/feedback', {})
