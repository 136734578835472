import { useEffect } from 'react'

import { RenderErrorType, useDispatchRenderError } from 'components/renderError/utils'

interface Props {
  type: RenderErrorType
}

export const RenderErrorTrigger = ({ type }: Props) => {
  const dispatchError = useDispatchRenderError()

  useEffect(() => {
    dispatchError(type)
  }, [type, dispatchError])

  return null
}
