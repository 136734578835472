import { MayBeNull } from '@wpp-open/content-builder'

import { facadeApi } from 'api'
import { isDefined } from 'utils/common'

interface Props {
  tenantId: MayBeNull<string>
  snoozedUntil?: MayBeNull<Date>
}

export const updateFeedsLastSeenAtApi = ({ tenantId, snoozedUntil }: Props) => {
  return facadeApi.patch('/users/me/activities/feeds', isDefined(snoozedUntil) && { data: { snoozedUntil } }, {
    headers: {
      'X-Tenant-Id': tenantId,
    },
  })
}
