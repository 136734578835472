import clsx from 'clsx'
import { useMemo } from 'react'

import styles from 'pages/playground/playgroundDashboard/components/general/bubble/BubbleFixed.module.scss'

interface Props {
  text: string
  icon?: string
  direction?: 'up' | 'down'
  className?: string
  variant?: string
}

const BubbleFixed = ({ icon, className, direction, text, variant = 'yellow' }: Props) => {
  const backgroundColor = useMemo(() => {
    return variant === 'yellow' ? '#EBFF00' : variant === 'red' ? '#E23232' : '#EBFF00'
  }, [variant])
  const color = useMemo(() => {
    return variant === 'yellow' ? '#282828' : variant === 'red' ? 'white' : '#282828'
  }, [variant])

  function bubbleBorder() {
    if (direction === 'up') {
      return '0 26px 26px 26px'
    } else {
      return '26px 26px 26px 0'
    }
  }

  function bubblePadding() {
    if (icon) {
      return '11px 19px'
    } else {
      return '12px 18px'
    }
  }

  return (
    <div
      className={clsx(styles.container, className)}
      style={{ backgroundColor: backgroundColor, color: color, borderRadius: bubbleBorder(), padding: bubblePadding() }}
    >
      {icon && (
        <div className={styles.iconWrapper}>
          <img src={icon} alt="" />
        </div>
      )}
      <div className={styles.textWrapper}>
        <p>{text}</p>
      </div>
    </div>
  )
}

export default BubbleFixed
