import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useUpdateUserMiroAuthToken } from 'api/miro/mutations/useUpdateUserMiroAuthToken'
import { useStableCallback } from 'hooks/useStableCallback'
import { LoadingPage } from 'layout/loadingPage/LoadingPage'
import {
  MiroAuthRedirectReason,
  MiroAuthCallbackParams,
  useMiroAuthCallbackParams,
  isValidMiroAuthCallbackParams,
} from 'utils/miro'

interface MiroAuthHistoryState {
  clientId: string
  teamId: string
  authCode: string
  context?: any
}

const getMiroAuthHistoryState = ({
  clientId,
  teamId,
  authCode,
  state,
}: MiroAuthCallbackParams): MiroAuthHistoryState => ({
  clientId,
  teamId,
  authCode,
  context: state.context,
})

export const MiroAuthCallback = () => {
  const params = useMiroAuthCallbackParams()
  const navigate = useNavigate()
  const { mutateAsync: handleUpdateUserMiroAuthToken } = useUpdateUserMiroAuthToken()

  const handleRedirect = useStableCallback(async () => {
    if (isValidMiroAuthCallbackParams(params)) {
      const { state } = params
      const { from, reason } = state

      const miroAuthHistoryState = getMiroAuthHistoryState(params)

      if (reason === MiroAuthRedirectReason.Settings) {
        navigate(from, { state: miroAuthHistoryState })
        return
      }

      if (reason === MiroAuthRedirectReason.AuthToken) {
        try {
          await handleUpdateUserMiroAuthToken({ authCode: miroAuthHistoryState.authCode })

          navigate(from, { state: miroAuthHistoryState })
        } catch {
          navigate('/')
        }
        return
      }
    }

    navigate('/')
  })

  useEffect(() => {
    handleRedirect()
  }, [handleRedirect])

  return <LoadingPage />
}
