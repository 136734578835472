import { WppIconChevron, WppListItem, WppTypography } from '@platform-ui-kit/components-library-react'
import { Dispatch, SetStateAction, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { ProjectLinearBreadcrumbsAppsColumn } from 'layout/header/breadcrumbs/project/projectLinear/appsColumn/ProjectLinearBreadcrumbsAppsColumn'
import styles from 'layout/header/breadcrumbs/project/projectLinear/ProjectLinearBreadcrumbs.module.scss'
import { useFilterVisiblePhaseItems } from 'layout/header/breadcrumbs/project/projectLinear/utils'
import { ProjectCanvasLinearShort, ProjectPhaseShort } from 'types/projects/projectNavigation'
import { sortBy } from 'utils/common'

interface Props {
  linearCanvas: ProjectCanvasLinearShort
  selectedPhase: ProjectPhaseShort
  setSelectedPhase: Dispatch<SetStateAction<ProjectPhaseShort>>
}

export const ProjectLinearBreadcrumbsFullMenu = ({ linearCanvas, selectedPhase, setSelectedPhase }: Props) => {
  const { t } = useTranslation()
  const filterVisiblePhaseItems = useFilterVisiblePhaseItems()

  const sortedPhases = useMemo(
    () => sortBy(linearCanvas.phases, ({ orderNumber }) => orderNumber),
    [linearCanvas.phases],
  )

  return (
    <Flex className={styles.menuRoot} gap={8} direction="column">
      <Flex className={styles.columnHeaders} gap={24}>
        <WppTypography
          className={styles.columnHeader}
          type="2xs-strong"
          data-testid="breadcrumbs-menu-project-linear-column"
        >
          {t('os.header.breadcrumbs.project_columns.phases')}
        </WppTypography>
        <WppTypography
          className={styles.columnHeader}
          type="2xs-strong"
          data-testid="breadcrumbs-menu-project-linear-column"
        >
          {t('os.header.breadcrumbs.project_columns.applications')}
        </WppTypography>
      </Flex>

      <div className={styles.headerSeparator} />

      <Flex className={styles.columns} gap={8}>
        <Flex className={styles.phasesColumn} gap={4} direction="column">
          {sortedPhases.map(phase => {
            const { id, name, phaseItems } = phase
            const hasVisibleApps = filterVisiblePhaseItems(phaseItems).length > 0

            return (
              <div key={id}>
                <WppListItem
                  isExtended
                  checked={id === selectedPhase.id}
                  disabled={!hasVisibleApps}
                  onWppChangeListItem={() => {
                    setSelectedPhase(phase)
                  }}
                  data-testid="breadcrumbs-menu-project-linear-phase"
                >
                  <span slot="label">{name}</span>
                  <WppIconChevron slot="right" direction="right" />
                </WppListItem>
              </div>
            )
          })}
        </Flex>

        <ProjectLinearBreadcrumbsAppsColumn selectedPhase={selectedPhase} />
      </Flex>
    </Flex>
  )
}
