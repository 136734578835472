import qs from 'qs'

import { facadeApi } from 'api'
import { PaginatedResponse } from 'api/common/types'
import { skipEmptyParams } from 'api/common/utils'
import { TenantRole } from 'types/roles/tenantRole'

interface Params {
  tenantId: string
  search?: string
  page?: number
  itemsPerPage?: number
  sort?: string
  /** Array of group `uid`s */
  groupId?: string[]
  /** Array of role sources */
  accountId?: string[]
  userId?: string[]
  roleIds?: string[]
}

export enum RolesListSortBy {
  account_name = 'account_name',
  name = 'name',
  groups = 'groups',
  users = 'users',
}

export type RolesListResponse = PaginatedResponse<TenantRole>

export const fetchRolesListApi = ({
  tenantId,
  page,
  itemsPerPage,
  sort,
  search,
  groupId = [],
  userId = [],
  accountId = [],
  roleIds = [],
}: Params) =>
  facadeApi.get<RolesListResponse>(`/tenants/${tenantId}/roles`, {
    params: skipEmptyParams({
      page,
      itemsPerPage,
      sort,
      filter: {
        search,
        group_id: groupId,
        user_id: userId,
        account_id: accountId,
        role_id: roleIds,
      },
    }),
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
  })
