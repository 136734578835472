import {
  ChildHierarchyLevelType,
  DefaultChildHierarchyLevelType,
  DefaultHierarchyLevelType,
  HierarchyCustomNodeType,
  HierarchyLevel,
  HierarchyLevelType,
  HierarchyNode,
  NavigationTreeNode,
  RootHierarchyLevelType,
  Tenant,
} from '../types'

export const getRootHierarchyLevel = (tenant: Tenant): HierarchyLevel<RootHierarchyLevelType> =>
  tenant.hierarchyLevels.find(
    (level: HierarchyLevel): level is HierarchyLevel<RootHierarchyLevelType> =>
      level.type === DefaultHierarchyLevelType.Tenant,
  )!

export const getChildHierarchyLevels = (tenant: Tenant): HierarchyLevel<ChildHierarchyLevelType>[] =>
  tenant.hierarchyLevels.filter(
    (level: HierarchyLevel): level is HierarchyLevel<ChildHierarchyLevelType> =>
      level.type !== DefaultHierarchyLevelType.Tenant,
  )

export const isDefaultHierarchyLevelType = (levelType: HierarchyLevelType): levelType is DefaultHierarchyLevelType => {
  const defaultTypes: HierarchyLevelType[] = Object.values(DefaultHierarchyLevelType)

  return defaultTypes.includes(levelType)
}
export const isDefaultChildHierarchyLevelType = (
  levelType: HierarchyLevelType,
): levelType is DefaultChildHierarchyLevelType =>
  isDefaultHierarchyLevelType(levelType) && levelType !== DefaultHierarchyLevelType.Tenant

export const getVisibleHierarchyLevels = (tenant: Tenant): HierarchyLevel[] =>
  tenant.hierarchyLevels.filter((level, index) => !tenant.flags.isFirstLevelHidden || index !== 1)

/**
 * Returns navigation node type.
 * For custom hierarchy nodes it returns the actual custom hierarchy level name.
 */
export const getNavigationNodeType = (
  node: NavigationTreeNode,
): Exclude<NavigationTreeNode, HierarchyNode>['type'] | HierarchyLevelType =>
  node.type === HierarchyCustomNodeType ? node.customTypeName! : node.type
