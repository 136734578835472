import React from 'react'

interface Props {
  fill: string
  containerClassName?: string
  className?: string
  style?: React.CSSProperties
}

export const Dot = ({ fill, containerClassName, className, style }: Props) => (
  <div className={containerClassName} style={style}>
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle cx="7" cy="7" r="7" fill={fill} />
    </svg>
  </div>
)

export default Dot
