import { MayBeNull } from '@wpp-open/core'
import { useMemo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import { WORKSPACE_URL_PREFIX } from 'constants/routes'

export interface LocalContext {
  workspaceAzId: string | undefined
  appRoute: string
}

export const useLocalContextFromUrl = (): MayBeNull<LocalContext> => {
  const { pathname } = useLocation()

  return useMemo(() => {
    const appWorkspaceMatch = matchPath(`/${WORKSPACE_URL_PREFIX}/:workspaceAzId/*`, pathname)

    let workspaceAzId: string | undefined
    let appRoute: string | undefined

    if (appWorkspaceMatch) {
      const { params } = appWorkspaceMatch

      workspaceAzId = params.workspaceAzId
      appRoute = params['*']
    } else {
      appRoute = pathname.slice(1)
    }

    if (!appRoute) {
      return null
    }

    return {
      workspaceAzId,
      appRoute,
    }
  }, [pathname])
}
