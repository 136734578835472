export enum LegacyPermission {
  OsDevelopers = 'OS_DEVELOPERS',

  OsAgencyAccess = 'OS_AGENCY_ACCESS',
  OsAgencyManage = 'OS_AGENCY_MANAGE',
  OsWorkspaceAccess = 'OS_WORKSPACE_ACCESS',
  OsWorkspaceAccessArchived = 'OS_WORKSPACE_ACCESS_ARCHIVED',
  OsWorkspaceManage = 'OS_WORKSPACE_MANAGE',
  OsWorkspacePreview = 'OS_WORKSPACE_PREVIEW',
  OsProteusOnboardingAppAccess = 'OS_PROTEUS_ONBOARDING_APP_ACCESS',

  AdminAppAccess = 'ADMIN_APP_ACCESS',

  DocumentationAccess = 'MXMZ_DOCS_GENERAL_ACCESS',

  CartAppAccess = 'CART_APP_ACCESS',

  ScorecardsAccess = 'SCORECARDS_APP_ACCESS',

  MxmzAudiencesAppAccess = 'MXMZ_AUDIENCES_APP_ACCESS',
  MxmzCampaignsAppAccess = 'MXMZ_CAMPAIGNS_APP_ACCESS',
  MxmzDatasourcesAppAccess = 'MXMZ_DATASOURCES_APP_ACCESS',
  MxmzDatasourcesDsAccess = 'MXMZ_DATASOURCES_DS_ACCESS',
  MxmzDatasourcesMatchedCreate = 'MXMZ_DATASOURCES_MATCHED_CREATE',
  MxmzMediaPlansComparisonAppAccess = 'MXMZ_CAMPAIGNS_APP_ACCESS', // Copied: for now the same as campaigns
  MxmzRfcurvesAppAccess = 'MXMZ_RFCURVES_APP_ACCESS',
  MxmzTouchpointscharacteristicsAppAccess = 'MXMZ_TOUCHPOINTSCHARACTERISTICS_APP_ACCESS',
  MxmzTouchpointsinsightsAppAccess = 'MXMZ_TOUCHPOINTSINSIGHTS_APP_ACCESS',
  MxmzTouchpointsAppAccess = 'MXMZ_TOUCHPOINTS_APP_ACCESS',
  MxmzTouchpointsListCreate = 'MXMZ_TOUCHPOINTS_LIST_CREATE',
  MxmzUseruploadsAppAccess = 'MXMZ_USERUPLOADS_APP_ACCESS',
  MxmzDataSourcesUploadAppAccess = 'MXMZ_DATA_SOURCES_UPLOAD_APP_ACCESS',
  MxmzRFCurvesAdminAppAccess = 'MXMZ_RF_CURVES_ADMIN_APP_ACCESS',

  AudienceTranslatorAccess = 'OS_AUDIENCE_TRANSLATOR_APP_ACCESS',
  AudiencesExplorerAccess = 'OS_AUDIENCES_VIEWER_APP_ACCESS',
  AudiencesComposerAccess = 'OS_AUDIENCES_COMPOSER_APP_ACCESS',
  AudiencesBuilderAccess = 'OS_AUDIENCES_BUILDER_APP_ACCESS',
  AudiencesProfilerAccess = 'OS_AUDIENCE_PROFILER_APP_ACCESS',
  MxmzJuniorAppAccess = 'MXMZ_JUNIOR_APP_ACCESS',
  MxmzMediaPlansUploaderAppAccess = 'MXMZ_MP_UPLOADER_FF', // TODO: in the future will be the same as campaigns
}
