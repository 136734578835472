import {
  WppIconAttach,
  WppIconDocument,
  WppIconInfo,
  WppIconTaskList,
  WppIconTaskListSquare,
  WppSelect,
  WppListItem,
  WppIconArrow,
  WppIconChevron,
  WppFileUpload,
  WppIconClose,
  WppProgressIndicator,
  WppSpinner,
  WppTooltip,
} from '@platform-ui-kit/components-library-react'
import { animated, useSpring } from '@react-spring/web'
import { useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'
import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { FetchFilesResponse } from 'api/fileManager/fetchers/fetchFiles'
import { useFetchFiles } from 'api/fileManager/mutations/useFetchFiles'
import { useCreateFeedback } from 'api/workfront/mutations/useCreateFeedback'
import { useDeleteWorkfrontDocument } from 'api/workfront/mutations/useDeleteWorkfrontDocument'
import { usePatchOptask } from 'api/workfront/mutations/usePatchOptask'
import { usePatchTask } from 'api/workfront/mutations/usePatchTask'
import { usePatchWorkfrontAssignment } from 'api/workfront/mutations/usePatchWorkfrontAssignment'
import { Flex } from 'components/common/flex/Flex'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { environment } from 'environment'
import { ReactComponent as IconCardBorder } from 'pages/playground/playgroundDashboard/assets/icons/card-border.svg'
import { ReactComponent as IconCalendar } from 'pages/playground/playgroundDashboard/assets/icons/project/calendar.svg'
import { ReactComponent as YellowUnderline } from 'pages/playground/playgroundDashboard/assets/icons/yellow-underline.svg'
import { Button } from 'pages/playground/playgroundDashboard/components/general/button/Button'
import styles from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/assignmentCard/AssignmentCard.module.scss'
import { NudgePreviewModal } from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/assignmentCard/components/NudgePreviewModal'
import { OtherAssignees } from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/assignmentCard/otherAssignees/OtherAssignees'
import {
  ProjectApiDto,
  AssignmentStatus,
  AssignmentStatusStrings,
  AssignmentStatusStringsDropdown,
  AssignmentButtonIcon,
  AssignmentObjCode,
  AssignmentMyStatusStringsDropdown,
  RiskStatusStringsDropdown,
  RiskStatusStrings,
  RiskStatus,
} from 'pages/playground/playgroundDashboard/components/sections/sectionAssignments/sectionAssignmentsMockData'
import stylesMain from 'pages/playground/playgroundDashboard/PlaygroundDashboard.module.scss'
import { canURLBePreviewed, isBlinkUrl, isEmbedableFile } from 'pages/playground/playgroundDashboard/utils/file'
import { useAuth } from 'providers/auth/AuthContext'
import { useToast } from 'providers/toast/ToastProvider'

interface Props {
  assignment: ProjectApiDto
  isLoadingProgress: boolean
  onPhaseClick?: (phase: Pick<ProjectApiDto['phase'], 'phaseNumber' | 'phaseName'>) => void
}

export const specialFormatDate = (dateString: string) => {
  const date = new Date(dateString.replace(/:(\d{3})([-+]\d{4})$/, '.$1$2'))
  // Check if the date is valid
  if (!isNaN(date.getTime())) {
    // Format the date to "October 23, 2024" or equivalent
    const formattedDate = date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    })

    return formattedDate // Outputs the formatted date
  } else {
    return 'Invalid Date'
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const AssignmentCard = ({ isLoadingProgress, assignment, onPhaseClick }: Props) => {
  const { jwt } = useAuth()

  const queryClient = useQueryClient()
  const { enqueueToast } = useToast()

  const { mutateAsync: createFeedback } = useCreateFeedback()
  const { mutateAsync: deleteDocument, isLoading: loadingDelete } = useDeleteWorkfrontDocument()
  const { mutateAsync: fetchFiles, data: fetchFilesResult, isError: fetchFilesError } = useFetchFiles()
  const { mutateAsync: patchTask, isLoading: patchLoadingTask } = usePatchTask()
  const { mutateAsync: patchOptask, isLoading: patchLoadingOptask } = usePatchOptask()
  const { mutateAsync: patchMyAssignment, isLoading: patchLoadingMyAssignment } = usePatchWorkfrontAssignment()

  const formatDate = (dateString: string) => {
    // Hack for invalid date from workfront
    const dateStringTmp = dateString?.substring(0, dateString.lastIndexOf(':'))
    const date = new Date(dateStringTmp)
    const monthName = date.toLocaleString('default', { month: 'long' })
    const day = date.getDate()
    return `${monthName.toLocaleUpperCase()} ${day}`
  }

  const [expanded, setExpanded] = useState(false)

  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false)
  const [previewUrl, setPreviewUrl] = useState('')
  // eslint-disable-next-line
  const [filesResult, setFilesResult] = useState<FetchFilesResponse[] | null>(null)

  const assignmentStatus = useMemo(() => {
    return assignment.status.includes(':A') ? AssignmentStatus.APPROVAL : assignment.status
  }, [assignment.status])

  const AssignmentStatusesOptions = useMemo(() => {
    if (assignment.otherAssignees.length > 0) {
      return assignment.status.includes(':A')
        ? [...AssignmentMyStatusStringsDropdown, AssignmentStatus.APPROVAL]
        : AssignmentMyStatusStringsDropdown
    } else {
      return assignment.status.includes(':A')
        ? [...AssignmentStatusStringsDropdown, AssignmentStatus.APPROVAL]
        : AssignmentStatusStringsDropdown
    }
  }, [assignment.otherAssignees.length, assignment.status])

  const handleBlink = (button: { label: string; url: string; action: string }) => {
    fetchFiles({
      recursive: true,
      blinkLink: button.url,
      jwt: jwt,
    })
      .then(resp => {
        if (resp.data?.data?.length <= 0) {
          window.open(button.url, '_blank')
          return
        }
        if (!resp.data?.data) {
          window.open(button.url, '_blank')
          return
        }
        if (!resp.data?.data[0]?.blinkLink) {
          window.open(button.url, '_blank')
          return
        }
        if (!resp.data?.data[0]) {
          window.open(button.url, '_blank')
          return
        }

        const file = resp.data?.data[0]
        if (isEmbedableFile(file.type)) {
          setIsPreviewModalOpen(true)
          setPreviewUrl(file.blinkLink)
        } else window.open(file.blinkLink, '_blank')
      })
      // eslint-disable-next-line
      .catch(e => {
        window.open(button.url, '_blank')
      })
  }

  useEffect(() => {
    if (fetchFilesError) {
      console.error('Error fetching files', fetchFilesError)
      return
    }
    const getFiles = async () => {
      if (!fetchFilesResult) return
      const results = await fetchFilesResult.data
      setFilesResult(results.data)
    }

    getFiles()
    // eslint-disable-next-line
  }, [fetchFilesError, fetchFilesResult])

  const handleChange = async (event: CustomEvent) => {
    try {
      if (assignment.objCode === AssignmentObjCode.TASK) {
        if (event.detail.value === AssignmentStatus.DONE_WITH_MY_PART) {
          await patchMyAssignment({ myAssignmentID: assignment.myAssignmentID, done: true })
        } else if (event.detail.value === AssignmentStatus.NOT_DONE_WITH_MY_PART) {
          await patchMyAssignment({ myAssignmentID: assignment.myAssignmentID, done: false })
        } else {
          await patchTask({ taskId: assignment.ID, status: event.detail.value })
        }
      } else if (assignment.objCode === AssignmentObjCode.OPTASK) {
        await patchOptask({ taskId: assignment.ID, status: event.detail.value })
      }

      const prev = queryClient.getQueriesData({ queryKey: [ApiQueryKeys.WORKFRONT_ASSIGNMENTS] })

      if (prev) {
        const { data: assignmentsOld } = prev[0][1] as { data: { data: ProjectApiDto[] } }
        const updated = assignmentsOld.data.map((item: any) => {
          if (item.ID === assignment.ID) {
            return { ...item, status: event.detail.value }
          }
          return item
        })

        queryClient.setQueriesData({ queryKey: [ApiQueryKeys.WORKFRONT_ASSIGNMENTS] }, (old: any) => {
          return {
            ...old,
            data: { data: updated },
          }
        })
      }

      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.WORKFRONT_PROJECTS] })
    } catch (e: any) {
      if (e.response.status === 400) {
        enqueueToast({
          message: 'This is a parent task and status can`t be changed.',
          type: 'error',
        })
      } else {
        enqueueToast({
          message: 'Error updating task status. Please try again later.',
          type: 'error',
        })
      }

      console.error('Error patching task', e)
    }
  }

  const cardExpand = useSpring({
    // maxHeight: expanded ? '200px' : '0px',
    opacity: expanded ? 1 : 0,
    display: expanded ? 'block' : 'none',
  })

  const cardExpandButton = useSpring({
    transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
    cursor: 'pointer',
  })

  const [files, setFiles] = useState(assignment.userDocuments || [])
  const [uploadedFile, setUploadedFile] = useState(null as any)
  const [uploadedFilesLength, setUploadedFilesLength] = useState(0)
  const [errorMessage, setErrorMessage] = useState('')
  const [loadingFile, setLoadingFile] = useState(true)
  const [deletingFileId, setDeletingFileId] = useState('')

  const acceptAllButScriptsConfig = {
    // Documents
    'application/pdf': ['.pdf'],
    'application/msword': ['.doc', '.dot'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template': ['.dotx'],
    'application/vnd.ms-word.document.macroEnabled.12': ['.docm'],
    'application/vnd.ms-word.template.macroEnabled.12': ['.dotm'],

    // Spreadsheets
    'application/vnd.ms-excel': ['.xls', '.xlt', '.xla'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template': ['.xltx'],
    'application/vnd.ms-excel.sheet.macroEnabled.12': ['.xlsm'],
    'application/vnd.ms-excel.template.macroEnabled.12': ['.xltm'],
    'application/vnd.ms-excel.addin.macroEnabled.12': ['.xlam'],
    'application/vnd.ms-excel.sheet.binary.macroEnabled.12': ['.xlsb'],

    // Presentations
    'application/vnd.ms-powerpoint': ['.ppt', '.pot', '.pps', '.ppa'],
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
    'application/vnd.openxmlformats-officedocument.presentationml.template': ['.potx'],
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow': ['.ppsx'],
    'application/vnd.ms-powerpoint.addin.macroEnabled.12': ['.ppam'],
    'application/vnd.ms-powerpoint.presentation.macroEnabled.12': ['.pptm'],
    'application/vnd.ms-powerpoint.template.macroEnabled.12': ['.potm'],
    'application/vnd.ms-powerpoint.slideshow.macroEnabled.12': ['.ppsm'],

    // Images
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/png': ['.png'],
    'image/gif': ['.gif'],
    'image/bmp': ['.bmp'],
    'image/tiff': ['.tiff', '.tif'],
    'image/svg+xml': ['.svg'],

    // Audio
    'audio/mpeg': ['.mp3'],
    'audio/wav': ['.wav'],
    'audio/ogg': ['.ogg'],
    'audio/aac': ['.aac'],
    'audio/flac': ['.flac'],

    // Video
    'video/mp4': ['.mp4'],
    'video/x-msvideo': ['.avi'],
    'video/x-matroska': ['.mkv'],
    'video/quicktime': ['.mov'],
    'video/webm': ['.webm'],

    // Text files
    'text/plain': ['.txt'],
    'text/csv': ['.csv'],
    'text/rtf': ['.rtf'],
    'text/html': ['.html', '.htm'],
    'application/json': ['.json'],

    // Other common file types
    'application/vnd.ms-publisher': ['.pub'],
    'application/vnd.oasis.opendocument.text': ['.odt'],
    'application/vnd.oasis.opendocument.spreadsheet': ['.ods'],
    'application/vnd.oasis.opendocument.presentation': ['.odp'],
    'application/x-shockwave-flash': ['.swf'],
    'application/x-dosexec': [], // Explicitly exclude .exe files
    'application/x-shellscript': [], // Exclude shell scripts
    'application/x-msdos-program': [], // Exclude MS-DOS programs
    'application/x-bat': [], // Exclude .bat files
    'application/x-python': [], // Exclude Python scripts
    'application/x-perl': [], // Exclude Perl scripts
    'application/x-ruby': [], // Exclude Ruby scripts
  }

  const locales = {
    info: () => 'Maximum file size for upload is 1 GB.',
    label: 'Choose a file',
    text: 'to upload or drag it here',
    sizeError: 'File exceeds size limit',
    formatError: 'Wrong format',
  }

  const handleFileUploadChange = (event: CustomEvent) => {
    if (event.detail.value.length !== uploadedFilesLength) {
      setUploadedFilesLength(event.detail.value.length)
      setUploadedFile(event.detail.value[event.detail.value.length - 1])
      uploadFile(event.detail.value[event.detail.value.length - 1])
      setErrorMessage('')
    } else {
      setErrorMessage('File is to big or wrong format. Please try again.')
    }
  }

  const uploadFile = async (file: any) => {
    setLoadingFile(true)
    try {
      const formDataAPI = new FormData()
      formDataAPI.append('file', file, file.name)
      const response = await fetch(`${environment.WORKFRONT_API}/tasks/${assignment.ID}/upload-file`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
          'X-Tenant-Id': environment.PLAYGROUND_TENANT_ID,
        },
        body: formDataAPI,
        method: 'post',
      })
      const data = JSON.parse(JSON.stringify(await response.json())).data
      setFiles(prev => [...prev, data])
    } catch (e) {
      console.error('Error editing project data', e)
      setErrorMessage('There has been an error. Please try again later.')
    }
    setLoadingFile(false)
  }

  const deleteFile = async (fileId: string) => {
    try {
      setDeletingFileId(fileId)
      await deleteDocument({ documentId: fileId })
      setFiles(prev => prev.filter(file => file.ID !== fileId))
    } catch (e) {
      console.error('Error deleting file', e)
    }
  }

  const handleCreateFeedback = async (nudgeId: string) => {
    try {
      await createFeedback({
        assignmentId: assignment.ID,
        nudgeId,
        type: assignment.taskStatus !== 'REQUEST' && assignment.taskStatus !== 'RISK' ? 'TASK' : assignment.taskStatus,
        assignmentName: assignment.name,
        nudgeName: nudgeId,
        nudgeObj: assignment.buttons.find(button => button.label === nudgeId),
      })
      queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.FEEDBACK] })
    } catch (e) {
      console.error('Error deleting file', e)
    }
  }

  const handlePhaseClick = (phase: Pick<ProjectApiDto['phase'], 'phaseNumber' | 'phaseName'>) => {
    if (!phase) return
    if (onPhaseClick) onPhaseClick(phase)
  }

  return (
    <div className={clsx(styles.container, stylesMain.mt40)}>
      <Flex gap={0} justify="between">
        <Flex
          justify="between"
          className={clsx(
            styles.containerLeft,
            styles[assignment.taskStatus],
            expanded && styles.containerLeftExpanded,
          )}
        >
          <div className={styles.containerLeftInner}>
            {assignment.taskStatus !== 'REQUEST' && (
              <Flex gap={16}>
                <div className={stylesMain.black2xsStrong}>{assignment?.project?.name}</div>
                <div>
                  <WppTooltip
                    text={`Project In-Market Date: ${assignment?.project?.inMarketDate ? specialFormatDate(assignment?.project?.inMarketDate) : 'No data'}`}
                    config={{ interactive: true, placement: 'top' }}
                  >
                    <WppIconInfo color="black" width={20} />
                  </WppTooltip>
                </div>
              </Flex>
            )}

            <Flex justify="between">
              <Link
                to={
                  assignment.taskStatus === 'RISK' || assignment.taskStatus === 'REQUEST'
                    ? `${environment.WORKFRONT_URL}?location=/issue/${assignment.ID}/overview`
                    : `${environment.WORKFRONT_URL}?location=/task/${assignment.ID}/overview`
                }
                className={clsx(stylesMain.blackUnderline, stylesMain.mt10, styles.assignmentName)}
              >
                <div>{assignment.name}</div>
              </Link>

              {assignment.taskStatus !== 'REQUEST' && (
                <div onClick={() => setExpanded(!expanded)}>
                  <animated.div style={cardExpandButton}>
                    <WppIconChevron color="black" direction="down" />
                  </animated.div>
                </div>
              )}
            </Flex>

            <Flex align="center" gap={16} className={clsx(styles.assignmentSections, stylesMain.mt20)}>
              <Flex align="center" gap={8}>
                {assignment.taskStatus === 'REQUEST' ? (
                  <WppIconTaskListSquare color="black" />
                ) : (
                  <WppIconTaskList color="black" />
                )}
                <div className={styles.assignmentSections}>
                  {assignment.taskStatus === 'REQUEST' ? 'REQUEST' : assignment.taskStatus === 'RISK' ? 'RISK' : 'TASK'}
                </div>
              </Flex>
              <div className={stylesMain.blackSMidiUnityText}>
                {'In phase: '}
                <u onClick={() => handlePhaseClick(assignment.phase)} className={styles.phaseText}>
                  {assignment?.phase ? assignment?.phase.phaseName : 'No data'}
                </u>
              </div>
              <Flex align="center">
                <WppTooltip
                  text={`Phase Due Date: ${assignment?.phase?.phaseDueDate ? specialFormatDate(assignment?.phase?.phaseDueDate) : 'No data'}`}
                  config={{ interactive: true, placement: 'bottom' }}
                >
                  <WppIconInfo color="black" width={20} />
                </WppTooltip>
              </Flex>
              <Flex align="center" gap={8}>
                <IconCalendar color="black" />
                {assignment.taskStatus === 'LATE' || assignment.taskStatus === 'DELAYED' ? (
                  <div className={styles.assignmentSectionsLate}>{`ASSIGNMENT PAST DUE: ${formatDate(
                    assignment.dueDate,
                  )}`}</div>
                ) : (
                  <div>{`ASSIGNMENT DUE: ${formatDate(assignment.dueDate)}`}</div>
                )}
              </Flex>
            </Flex>
          </div>

          <div>
            <IconCardBorder className={styles.separator} />
          </div>
        </Flex>

        {assignment.taskStatus !== 'REQUEST' ? (
          <Flex className={styles.containerRight} direction="column" justify="center" gap={16}>
            {assignment.taskStatus === 'RISK' ? (
              <WppSelect
                onWppChange={handleChange}
                placeholder="Placeholder"
                value={assignmentStatus}
                className={styles.dropdown}
                disabled={patchLoadingTask || patchLoadingOptask || patchLoadingMyAssignment}
              >
                {RiskStatusStringsDropdown.map((status, idx) => (
                  <WppListItem key={idx} value={status}>
                    <p slot="label">{RiskStatusStrings[status as RiskStatus]}</p>
                  </WppListItem>
                ))}
              </WppSelect>
            ) : (
              <WppSelect
                onWppChange={handleChange}
                placeholder="Placeholder"
                value={assignmentStatus}
                className={styles.dropdown}
                disabled={patchLoadingTask || patchLoadingOptask || patchLoadingMyAssignment}
              >
                {AssignmentStatusesOptions.map((status, idx) => (
                  <WppListItem key={idx} value={status}>
                    <p slot="label">{AssignmentStatusStrings[status as AssignmentStatus]}</p>
                  </WppListItem>
                ))}
              </WppSelect>
            )}

            {assignment.buttons.length > 0 && <div className={styles.separatorLine} />}
            {isLoadingProgress && assignment.buttons.length === 0 && (
              <div>
                <div className={styles.separatorLine} />
                <div className={clsx(stylesMain['text-center'], stylesMain.mt16)}>
                  <WppSpinner size="m" />
                </div>
              </div>
            )}
            {assignment.buttons.map((button, idx) => (
              <div key={idx} className={stylesMain['text-center']}>
                {canURLBePreviewed(button.url) ? (
                  <span
                    onClick={() => {
                      setIsPreviewModalOpen(true)
                      setPreviewUrl(button.url)
                      handleCreateFeedback(button.label)
                    }}
                    rel="noreferrer"
                  >
                    <Button
                      text={button.label}
                      variant="tertiary"
                      iconRight={button.icon === AssignmentButtonIcon.ARROW_RIGHT && <WppIconArrow color="black" />}
                      minWidth={280}
                    />
                  </span>
                ) : isBlinkUrl(button.url) ? (
                  <span>
                    <Button
                      text={button.label}
                      variant="tertiary"
                      onClick={() => {
                        handleCreateFeedback(button.label)
                        handleBlink({ label: button.label, url: button.url, action: button.action })
                      }}
                      iconRight={button.icon === AssignmentButtonIcon.ARROW_RIGHT && <WppIconArrow color="black" />}
                      minWidth={280}
                    />
                  </span>
                ) : (
                  <a
                    href={button.url}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => handleCreateFeedback(button.label)}
                  >
                    <Button
                      text={button.label}
                      variant="tertiary"
                      iconRight={button.icon === AssignmentButtonIcon.ARROW_RIGHT && <WppIconArrow color="black" />}
                      minWidth={280}
                    />
                  </a>
                )}
              </div>
            ))}
            {/* <div className={stylesMain['text-center']}>
          <Link to={`${environment.WORKFRONT_URL}?location=/task/${assignment.ID}`}>
            <Button text="Open Task" variant="tertiary" />
          </Link>
        </div> */}
          </Flex>
        ) : (
          <Flex className={styles.containerRight} direction="column" justify="center" gap={24}>
            <Link to={`${environment.WORKFRONT_URL}?location=/issue/${assignment.ID}/overview`}>
              <Button
                text="View Request"
                variant="tertiary"
                iconRight={<WppIconArrow color="black" />}
                minWidth={280}
              />
            </Link>
          </Flex>
        )}
      </Flex>

      {assignment.taskStatus !== 'REQUEST' && (
        <animated.div style={cardExpand} className={styles.expandedContainer}>
          <Flex gap={12}>
            <div className={clsx(styles.leftExpanded, stylesMain.mt24)}>
              <div className={stylesMain.blackMStrong}>
                Task Description
                <div>
                  <YellowUnderline />
                </div>
              </div>

              <Flex direction="column" gap={16} className={stylesMain.mt12}>
                <div className={clsx(stylesMain.blackMBody, styles.taskDescription)}>{assignment.description}</div>
                <Flex gap={16}>
                  {assignment.gateDocuments.map((document, idx) => (
                    <a
                      key={idx}
                      href={document.downloadUrl}
                      target="_blank"
                      rel="noreferrer"
                      className={styles.attachment}
                    >
                      <Flex gap={8}>
                        <WppIconAttach color="black" />
                        <div className={stylesMain.blackXSStrong}>{document.attachmentName}</div>
                      </Flex>
                    </a>
                  ))}
                </Flex>
                {assignment.gateDocuments.length !== 0 && (
                  <Link to={`${environment.WORKFRONT_URL}?location=/project/${assignment.project.ID}`}>
                    <Flex gap={16}>
                      <WppIconDocument color="black" />
                      <div className={stylesMain.blackSBodyUnderline}>More info in attached documents</div>
                    </Flex>
                  </Link>
                )}
              </Flex>

              <div className={clsx(stylesMain.blackMStrong, stylesMain.mt42)}>Upload Document(s)</div>

              <Flex gap={12} className={stylesMain.mt16}>
                <div className={styles.leftExpanded}>
                  <WppFileUpload
                    message={errorMessage}
                    messageType={errorMessage?.length > 0 ? 'error' : undefined}
                    acceptConfig={acceptAllButScriptsConfig}
                    onWppChange={handleFileUploadChange}
                    className={styles.fileUpload}
                    size={1000}
                    locales={locales}
                  />
                </div>
                <Flex className={styles.rightExpanded} direction="column" gap={15}>
                  {files.map((file, idx) => (
                    <Flex key={idx} gap={16} justify="between" align="center" className={styles.document}>
                      <WppIconDocument color="black" width={28} />
                      <div className={stylesMain.wFull}>
                        <a
                          href={file.downloadUrl}
                          target="_blank"
                          rel="noreferrer"
                          className={stylesMain.greenUnderline}
                        >
                          {file.name}
                        </a>
                      </div>
                      {loadingDelete && deletingFileId === file.ID ? (
                        <WppSpinner size="s" />
                      ) : (
                        <WppIconClose
                          color="black"
                          className={stylesMain.pointer}
                          onClick={() => deleteFile(file.ID)}
                        />
                      )}
                    </Flex>
                  ))}
                  {uploadedFile && loadingFile && (
                    <Flex gap={16} justify="between" align="center" className={styles.document}>
                      <WppIconDocument color="black" width={28} />
                      <div className={stylesMain.wFull}>
                        <div className={stylesMain.greenUnderline}>{uploadedFile.name}</div>
                        <WppProgressIndicator variant="bar" color="green" className={styles.progressBar} />
                      </div>
                    </Flex>
                  )}
                </Flex>
              </Flex>
            </div>

            <div className={styles.rightExpanded}>
              <OtherAssignees assignees={assignment.otherAssignees} status={assignment.taskStatus} />
            </div>
          </Flex>
        </animated.div>
      )}

      <NudgePreviewModal
        className={styles.previewModal}
        nudgeUrl={previewUrl}
        open={isPreviewModalOpen}
        onClose={() => setIsPreviewModalOpen(false)}
      />
    </div>
  )
}
