import { WppToastContainer } from '@platform-ui-kit/components-library-react'
import { createContext, PropsWithChildren, useCallback, useContext, useRef } from 'react'

import { Delay } from 'constants/delay'

type EnqueToastFn = HTMLWppToastContainerElement['addToast']
type HideToastFn = HTMLWppToastContainerElement['hideToast']

export interface ToastContextValue {
  enqueueToast: EnqueToastFn
  dequeueToast: HideToastFn
}

export const ToastContext = createContext<ToastContextValue>(null!)

export const useToast = () => useContext(ToastContext)

export const ToastProvider = ({ children }: PropsWithChildren<{}>) => {
  const ref = useRef<HTMLWppToastContainerElement>(null)

  const enqueueToast = useCallback<EnqueToastFn>(
    ({ duration = Delay.ToastShort, ...config }) => ref.current?.addToast({ duration, ...config })!,
    [],
  )

  const dequeueToast = useCallback<HideToastFn>(id => ref.current?.hideToast(id)!, [])

  return (
    <ToastContext.Provider
      value={{
        enqueueToast,
        dequeueToast,
      }}
    >
      <WppToastContainer ref={ref} maxToastsToDisplay={5} data-testid="toast-container" />
      {children}
    </ToastContext.Provider>
  )
}
