import { WppDivider, WppIconChevron } from '@platform-ui-kit/components-library-react'
import { ChildHierarchyLevelType, HierarchyLevel } from '@wpp-open/core'
import { Fragment, ReactNode } from 'react'

import { Avatar } from 'components/common/avatar/Avatar'
import { FadeIn } from 'components/common/fadeIn/FadeIn'
import { Flex } from 'components/common/flex/Flex'
import styles from 'components/treeList/hierarchyTreeList/HierarchyTreeList.module.scss'
import { TreeListItem } from 'components/treeList/treeListItem/TreeListItem'
import { sortBy } from 'utils/common'
import { NodesMapping, PointerNode, Tree } from 'utils/mapping/common'

interface Props<T extends Tree> {
  hierarchyLevels: HierarchyLevel<ChildHierarchyLevelType>[]
  workspacePointerNodes: PointerNode<T>[]
  nodesMapping: NodesMapping<T>
  onParentNodeClick: (pointerNode: PointerNode<T>) => void
  getNodeLogoUrl: (key?: string) => string
  renderNode: (pointerNode: PointerNode<T>) => ReactNode
  emptyState?: ReactNode
  action?: ReactNode
}

export function HierarchyTreeList<T extends Tree>({
  hierarchyLevels,
  workspacePointerNodes,
  nodesMapping,
  onParentNodeClick,
  renderNode,
  getNodeLogoUrl,
  action,
  emptyState,
}: Props<T>) {
  const displayedParentPointerNodes =
    workspacePointerNodes.length < hierarchyLevels.length ? workspacePointerNodes : workspacePointerNodes.slice(0, -1)

  const { nodeId } = displayedParentPointerNodes.at(-1)!

  const childrenPointerNodes = sortBy(nodesMapping[nodeId].children, ({ node }) => node.name)

  return (
    <Flex as={FadeIn} direction="column" gap={4} data-testid="hierarchy-tree-list">
      <Flex className={styles.parentNodes} direction="column" gap={4} data-testid="hierarchy-tree-list-parent-nodes">
        {displayedParentPointerNodes.map((pointerNode, index) => {
          const { node, nodeId } = pointerNode
          const { name, logoThumbnail } = node
          const isFirstParent = index === 0

          return (
            <TreeListItem key={nodeId} onClick={() => onParentNodeClick(pointerNode)}>
              {isFirstParent && <Avatar slot="left" src={getNodeLogoUrl(logoThumbnail?.key)} name={name} />}
              <span slot="label">{name}</span>
              <WppIconChevron slot="right" direction="down" />
            </TreeListItem>
          )
        })}
        <WppDivider />
      </Flex>

      <Flex
        key={nodeId}
        as={FadeIn}
        className={styles.childrenNodes}
        direction="column"
        gap={2}
        data-testid="hierarchy-tree-list-children-nodes"
      >
        {childrenPointerNodes.length ? (
          <>
            {childrenPointerNodes.map(pointerNode => {
              const { nodeId } = pointerNode

              return <Fragment key={nodeId}>{renderNode(pointerNode)}</Fragment>
            })}
          </>
        ) : (
          <>{!!emptyState && <div className={styles.emptyState}>{emptyState}</div>}</>
        )}
        {action}
      </Flex>
    </Flex>
  )
}
